import React from "react";
import { Dropdown } from "../../ui";

export const TeamSummary = ({ totalPoints, zmlBet, setZmlBet, battleId }) => {
  const OneItem = ({ title, value, invalid }) => (
    <p className={invalid ? "text-red-500" : ""}>
      {title}:{" "}
      <span
        className={`${invalid ? "text-red-500" : "text-sky-200"} font-semibold`}
      >
        {value}
      </span>
    </p>
  );

  return (
    <div className={"flex justify-center text-sm text-center bg-main/30 py-2"}>
      <div
        className={
          "flex flex-row items-center border-l border-r border-white/10 px-10"
        }
      >
        <OneItem
          title={"My Power"}
          invalid={totalPoints[0] > totalPoints[1]}
          value={totalPoints[0]}
        />
      </div>
      {totalPoints[1] && (
        <div
          className={
            "flex flex-row items-center border-l border-r border-white/10 px-10"
          }
        >
          <OneItem title={"Opponent's Power"} value={totalPoints[1]} />
        </div>
      )}
      <div
        className={
          "flex flex-row gap-2 border-l border-r border-white/10 px-10"
        }
      >
        <div className={"mt-3"}>Bet:</div>
        {battleId ? (
          <div className={"mt-2 mb-3 text-lg font-semibold text-amber-400"}>
            {zmlBet}
          </div>
        ) : (
          <Dropdown
            zindex="z-30"
            options={[
              { title: "50", onClick: () => setZmlBet(50) },
              { title: "100", onClick: () => setZmlBet(100) },
              { title: "250", onClick: () => setZmlBet(250) },
              { title: "375", onClick: () => setZmlBet(375) },
              { title: "500", onClick: () => setZmlBet(500) },
              { title: "750", onClick: () => setZmlBet(750) },
              { title: "1000", onClick: () => setZmlBet(1000) },
              { title: "1500", onClick: () => setZmlBet(1500) },
              { title: "2000", onClick: () => setZmlBet(2000) },
              { title: "5000", onClick: () => setZmlBet(5000) },
            ]}
            selected={zmlBet}
          />
        )}

        <div className={"mt-3"}>ZML</div>
      </div>
    </div>
  );
};

import ky from 'ky';

const BASE_URL = process.env.API_URL;

// Fetch GET method
export const get = async (url, params) => {
  if (params) {
    const queryParams = new URLSearchParams(params);
    url = `${url}?${queryParams}`;
  }

  return await ky.get(`${BASE_URL}/${url}`, {
    timeout: 10000,
    limit: 3,
    methods: ['get'],
  }).json();
};

// Fetch POST method
export const post = async (url, body, timeoutSec = 30) => {
  return await ky.post(`${BASE_URL}/${url}`, {
    json: body,
    timeout: timeoutSec * 1000,
  }).json();
};

import Big from "big.js";
import { timeDiffSeconds } from "./timeFormat";
import { mintZombiesLimit } from "./config";

export const defaultGas = Big(60)
  .times(10 ** 12)
  .toFixed();

export const formatLandId = (landType, tokenId, size = "md") => {
  let id = tokenId.split(":");
  if (size === "sm") {
    return `${landType} #${id[1]}`;
  }
  return `${landType} Land #${id[1]}`;
};

export const getMedia = (media) => {
  if (!media.toLocaleLowerCase().includes(".png")) {
    media += ".png";
  }
  return `${process.env.SPACES_URL}/${media}`;
};

export const convertFromYocto = (amount = 0, digits = 1) => {
  return Big(amount)
    .div(10 ** 24)
    .toFixed(digits);
};

export const convertFromNanoSeconds = (timestamp) => {
  return parseInt(Big(timestamp).div(1000000).toFixed());
};

export const shortAccountAddress = (address) => {
  if (address.length > 18) {
    return address.slice(0, 18);
  }
  return address;
};

export const convertToYocto = (amount) => {
  return Big(amount)
    .times(10 ** 24)
    .toFixed();
};
export const convertToTera = (amount) => {
  return Big(amount)
    .times(10 ** 12)
    .toFixed();
};

export const arrayEquals = (a, b) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

export const transformZombieMonster = (item) => {
  if (item.sale_price) {
    item.price = item.sale_price.toString();
    item.sale_price = formatPrice(item.sale_price);
  }
  if (item.modifier_items) {
    let tmpModifiers = {};
    item.modifier_items.map((md) => {
      const modifier = md.split("|");
      tmpModifiers[modifier[0].toLowerCase()] = modifier[1];
    });
    item.modifier_items = tmpModifiers;
  }
  if (item.next_land_discovery) {
    item.next_land_discovery = convertFromNanoSeconds(item.next_land_discovery);
  }
  return item;
};

export const transformItem = (item) => {
  const nftType = item?.nft_type ? item.nft_type.toLowerCase() : "inventory";
  item.media = `${nftType}/${item.item_type}`;
  if (item.sale_price) {
    item.price = item.sale_price.toString();
    item.sale_price = convertFromYocto(item.sale_price, 0);
  }
  return item;
};

export const transformMonsterPart = (item) => {
  if (item.sale_price) {
    item.price = item.sale_price.toString();
    item.sale_price = formatPrice(item.sale_price);
  }
  return item;
};

export const formatTitle = (nft_type, token_id) => {
  if (token_id) {
    if (["Land", "Zombie", "Monster", "MonsterPart"].includes(nft_type)) {
      return `#${nftTokenId(token_id)}`;
    }
    return `#${token_id}`;
  }
};

const nftTokenId = (token_id) => {
  const token = token_id.split(":");
  const idData = token[1].split("-");
  return idData[0];
};

export const statusColorTextMap = (status) => {
  let result = "text-gray-500";
  if (status === "Medium" || status === "Uncommon" || status === 1) {
    result = "text-green-500";
  } else if (status === "Large" || status === "Rare" || status === 2) {
    result = "text-blue-500";
  } else if (status === "Giant" || status === "Epic" || status === 3) {
    result = "text-rose-500";
  }
  return result;
};

export const statusColorBgMap = (status) => {
  let result = "bg-mainLight";
  if (status === "Small" || status === "Common") {
    result = "bg-gray-500";
  } else if (status === "Medium" || status === "Uncommon") {
    result = "bg-green-500";
  } else if (status === "Large" || status === "Rare") {
    result = "bg-blue-500";
  } else if (status === "Giant" || status === "Epic") {
    result = "bg-rose-500";
  }
  return result;
};

export const statusColorBorderMap = (status) => {
  let result = "border-gray-500";
  if (status === "Medium" || status === "Uncommon" || status === 1) {
    result = "border-green-500";
  } else if (status === "Large" || status === "Rare" || status === 2) {
    result = "border-blue-500";
  } else if (status === "Giant" || status === "Epic" || status === 3) {
    result = "border-rose-500";
  }
  return result;
};

export const formatPrice = (price) => {
  const priceFormatted = convertFromYocto(price, 3);
  if (priceFormatted[priceFormatted.length - 1] === "0") {
    return convertFromYocto(price, 2);
  }
  return priceFormatted;
};

export const rarityOptions = (setFilterRarity) => {
  let result = [];
  const options = ["All", "Common", "Uncommon", "Rare", "Epic"];
  options.map((item, index) => {
    result.push({
      title: item,
      onClick: () => setFilterRarity(index ? item : null),
    });
  });
  return result;
};

export const maxDiscoveryMapping = {
  Small: 59,
  Medium: 139,
  Large: 299,
  Giant: 599,
};

export const maxDiscoveryEvents = (land_type) => {
  if (land_type === "Small") {
    return 59;
  } else if (land_type === "Medium") {
    return 139;
  } else if (land_type === "Large") {
    return 299;
  } else if (land_type === "Giant") {
    return 599;
  }
};

export const inventoryOptions = (setFilterType) => {
  let result = [];
  const options = [
    "All",
    "Bomb",
    "Bow",
    "Sword",
    "Armor",
    "Shield",
    "Health",
    "Potion",
    "Poison",
  ];
  options.map((item, index) => {
    result.push({
      title: item,
      onClick: () => setFilterType(index ? item : null),
    });
  });
  return result;
};

export const modifierOptions = (setFilterType) => {
  let result = [];
  const options = ["All", "Attack", "Health", "Brain"];
  options.map((item, index) => {
    result.push({
      title: item,
      onClick: () => setFilterType(index ? item : null),
    });
  });
  return result;
};

export const canClaim = (land) => {
  if (
    !land.need_import &&
    land.count_minted_zombies < mintZombiesLimit[land.land_type]
  ) {
    return (
      timeDiffSeconds(
        land.last_zombie_claim,
        land.land_type === "Giant" ? 12 : 24
      ) < 0
    );
  }
  return false;
};

export const getNftType = (section) => {
  if (section === "zombies") {
    return "Zombie";
  } else if (section === "monsters") {
    return "Monster";
  } else if (section === "inventory") {
    return "Inventory";
  } else if (section === "modifiers") {
    return "Modifier";
  } else if (section === "monster_parts") {
    return "MonsterPart";
  }
};

export const partition = (input, spacing) => {
  let output = [];
  for (let i = 0; i < input.length; i += spacing) {
    output[output.length] = input.slice(i, i + spacing);
  }
  return output;
};

export const uniq = (value, index, self) => self.indexOf(value) === index;

export const selectAll = (selectedList, setSelectedList, list) => {
  const newSelected = list.filter((z) => !z.sale_price);
  setSelectedList([...selectedList, ...newSelected].filter(uniq));
};

export const deselectAll = (setSelectedList) => setSelectedList([]);

export const formatNumber = (num) => {
  return new Intl.NumberFormat().format(num, "en_US", true);
};

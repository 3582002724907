import React, { useContext, useEffect, useState } from "react";
import {
  convertToYocto,
  transformItem,
  transformZombieMonster,
} from "../../utils/utils";
import { Col, Row } from "../../assets/styles/common.style";
import {
  Button,
  Loader,
  Dropdown,
  Popup,
  CardRotate,
  CardItem,
} from "../../ui";
import { SelectZombiesPopup } from "../SelectZombiesPopup";
import { PlusIcon } from "@heroicons/react/solid";
import { post, get } from "../../utils/api";
import { SelectInventoryPopup } from "../../pages/inventory/SelectInventoryPopup";
import { CLAN_BATTLE_BET, LEVEL_MAP } from "../../utils/config";
import { NearContext } from "../../contexts/NearWallet";
import { joinBattleTransferAssetsService } from "../../services/ClanService";
import Big from "big.js";
import { useDispatch, useSelector } from "react-redux";
import { updateUserBalance } from "../../services/UserService";

const ZOMBIES_PAGE_LIMIT = 1000;

export const RequestClanBattlePopup = ({
  clan,
  visible,
  setVisible,
  title,
  isCreate,
  battleId,
  btnTitle,
  successJoined,
  levelParam,
}) => {
  const { wallet, ftContract, currentUser, mainContract } =
    useContext(NearContext);
  const balance = useSelector((state) => state.user.balance);
  const dispatch = useDispatch();
  const [isReady, setIsReady] = useState(false);
  const [userZombies, setUserZombies] = useState([0, []]);
  const [currentZombiePage, setCurrentZombiePage] = useState(1);
  const [zombiesPopupVisible, setZombiesPopupVisible] = useState(false);
  const [inventoryPopupVisible, setInventoryPopupVisible] = useState(false);

  const [currentZombiePosition, setCurrentZombiePosition] = useState(0);
  const [currentInventoryPosition, setCurrentInventoryPosition] = useState(0);
  const [selectedZombies, setSelectedZombies] = useState([]);
  const [selectedInventory, setSelectedInventory] = useState([]);

  // fields
  const [playersSize, setPlayersSize] = useState(0);
  const [rarity, setRarity] = useState("Common");
  const [level, setLevel] = useState(levelParam || 0);
  const [withInventory, setWithInventory] = useState(true);
  const [availableToJoin, setAvailableToJoin] = useState(0);

  const power = (zombie) =>
    zombie.health + zombie.brain + zombie.attack + zombie.speed;

  async function fetchUserZombies(currentPage, rarity) {
    let requestParams = {
      account_id: currentUser,
      page_num: currentPage,
      page_limit: ZOMBIES_PAGE_LIMIT,
      filter_rarity: rarity,
    };

    let zombies = await mainContract.userZombies(requestParams);
    zombies[1] = zombies[1]
      .map((zombie) => transformZombieMonster(zombie))
      .filter((zombie) => power(zombie) <= LEVEL_MAP[rarity][level])
      .sort((a, b) => power(b) - power(a));

    setUserZombies(zombies);
  }

  const loadPopupData = async () => {
    setIsReady(false);

    if (!isCreate) {
      const battle = await get(`api/clan-battle/info/${battleId}`);
      if (battle.data?.cardRarity) {
        setRarity(battle.data.cardRarity);
        setPlayersSize(battle.data.requiredSize);
        setWithInventory(battle.data.withInventory);
        await fetchUserZombies(1, battle.data.cardRarity);
      }
    } else {
      setPlayersSize(10);
      await fetchUserZombies(1, rarity);
    }
  };

  useEffect(() => {
    if (isCreate) {
      let limitPlaces = playersSize === 6 ? 1 : playersSize / 2;
      setAvailableToJoin(limitPlaces);
      generateEmptyZombiesList(limitPlaces, withInventory);
    } else {
      get(`api/clan-battle/joined-count/${battleId}`, {
        clanId: clan.id,
      }).then((battleJoined) => {
        const toJoin = playersSize / 2 - battleJoined.data;
        setAvailableToJoin(toJoin);
        generateEmptyZombiesList(playersSize === 6 ? 1 : toJoin, withInventory);
      });
    }
  }, [playersSize, withInventory, rarity, level]);

  const generateEmptyZombiesList = (limit, withInventory = true) => {
    setSelectedZombies(Array(limit > 0 ? limit : 0).fill(null));
    if (withInventory)
      setSelectedInventory(Array(limit > 0 ? limit : 0).fill(null));
  };

  useEffect(() => {
    if (visible && rarity) {
      loadPopupData().then(() => {
        setIsReady(true);
      });
    } else {
      // reset on close
      setPlayersSize(0);
      setIsReady(false);
    }
  }, [rarity, visible, level]);

  const onZombiePageChanged = (page) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentZombiePage(page);
    fetchUserZombies(page, rarity);
  };

  const selectZombie = (zombie) => {
    selectedZombies[currentZombiePosition] = zombie;
    setSelectedZombies([...selectedZombies]);

    if (
      playersSize === 6 ||
      selectedZombies.filter((item) => item).length === availableToJoin
    ) {
      setZombiesPopupVisible(false);
    } else {
      for (let i = 0; i < playersSize; i++) {
        if (!selectedZombies[i]) {
          setCurrentZombiePosition(i);
          break;
        }
      }
    }
  };

  const selectInventory = (item) => {
    selectedInventory[currentInventoryPosition] = item;
    setSelectedInventory([...selectedInventory]);
    if (
      playersSize === 6 ||
      selectedInventory.filter((item) => item).length ===
        selectedZombies.filter((item) => item).length
    ) {
      setInventoryPopupVisible(false);
    } else {
      for (let i = 0; i < selectedZombies.filter((item) => item).length; i++) {
        if (!selectedInventory[i]) {
          setCurrentInventoryPosition(i);
          break;
        }
      }
    }
  };

  // TODO: useMemo
  const zombiesInPopup = () => {
    if (zombiesPopupVisible && userZombies) {
      return userZombies[1]
        .filter((zombie) => {
          let exists = false;
          selectedZombies.map((innerZombie) => {
            if (innerZombie && innerZombie.token_id === zombie.token_id) {
              exists = true;
            }
          });
          return !exists;
        })
        .sort((a, b) => (a.card_rarity > b.card_rarity ? -1 : 1));
    }
    return [];
  };

  const totalBattleZML = (rarity) => {
    const zombieCount = selectedZombies.filter((zm) => zm).length;
    return CLAN_BATTLE_BET[rarity].stake * zombieCount;
  };

  const winBattleZML = () => {
    const zombieCount = selectedZombies.filter((zm) => zm).length;
    return CLAN_BATTLE_BET[rarity].prize[level] * zombieCount;
  };

  const canParticipateInBattle = (rarity) => {
    const battleZML = totalBattleZML(rarity);
    const battleZMLYocto = convertToYocto(battleZML);
    if (Big(balance.toString()).lt(battleZMLYocto)) {
      alert("Not enough ZML balance to participate in this battle");
      return false;
    }
    if (!selectedZombies.filter((zm) => zm).length) {
      alert("Please select zombie");
      return false;
    }
    return battleZML;
  };

  const createBattle = async () => {
    const battleZML = CLAN_BATTLE_BET[rarity].stake;
    if (battleZML) {
      setIsReady(false);
      try {
        const battleResponse = await post("api/clan-battle/create", {
          accountId: currentUser,
          cardRarity: rarity,
          toClanId: clan.id,
          requiredSize: playersSize,
          entryFee: battleZML,
          level,
          withInventory,
        });

        if (!battleResponse.error) {
          // save temporary variable to show "Battle Created" popup
          localStorage.setItem("pending_create_battle", battleResponse.data.id);
          await joinBattle(battleResponse.data.id);
        } else {
          alert(battleResponse.error);
          setIsReady(true);
          setVisible(false);
        }
      } catch (error) {
        console.error(error);
        alert("Blockchain error, please try one more time in few minutes.");
        setIsReady(true);
        setVisible(false);
      }
    }
  };

  const joinBattle = async (battleId) => {
    const battle = await get(`api/clan-battle/info/${battleId}`);
    if (canParticipateInBattle(battle.data.cardRarity)) {
      setIsReady(false);

      const inventoryIdList = selectedInventory
        .filter((i) => i)
        .map((item) => item.token_id);
      const zombieIdList = selectedZombies
        .filter((zm) => zm)
        .map((item) => item.token_id);

      const joinResponse = await post("api/clan-battle/join", {
        accountId: currentUser,
        clanBattleId: battleId,
        inventoryIdList,
        zombieIdList,
      });

      if (!joinResponse.error) {
        joinBattleTransferAssetsService(
          battle,
          zombieIdList,
          inventoryIdList,
          wallet,
          ftContract,
          mainContract
        )
          .then(() => {
            successJoined();
            updateUserBalance(dispatch, ftContract, currentUser);
          })
          .catch((error) => {
            console.log("Error", error);
          })
          .finally(() => {
            setIsReady(true);
            setVisible(false);
            setSelectedZombies([]);
            setSelectedInventory([]);
          });
      } else {
        alert(`Can't join this battle. ${joinResponse.error}`);
        setIsReady(true);
        setVisible(false);
      }
    } else {
      setIsReady(true);
    }
  };

  return (
    <Popup
      title={title}
      popupVisible={visible}
      setPopupVisible={setVisible}
      width={"sm:w-[920px]"}
    >
      {isReady ? (
        <>
          <div>
            {isCreate && (
              <div className="flex flex-row justify-center gap-8 mb-10 -mt-1">
                <Col className="gap-1 text-center w-32">
                  <label className={"text-sm font-semibold"}>
                    Battle Format
                  </label>
                  <Dropdown
                    zindex="z-30"
                    options={[
                      {
                        title: "3x3",
                        onClick: () => {
                          setPlayersSize(6);
                          generateEmptyZombiesList(1);
                          setSelectedInventory([]);
                        },
                      },
                      {
                        title: "5x5",
                        onClick: () => {
                          setPlayersSize(10);
                          generateEmptyZombiesList(5);
                          setSelectedInventory([]);
                        },
                      },
                      {
                        title: "7x7",
                        onClick: () => {
                          setPlayersSize(14);
                          generateEmptyZombiesList(7);
                          setSelectedInventory([]);
                        },
                      },
                      {
                        title: "9x9",
                        onClick: () => {
                          setPlayersSize(18);
                          generateEmptyZombiesList(9);
                          setSelectedInventory([]);
                        },
                      },
                    ]}
                    selected={`${playersSize / 2} x ${playersSize / 2}`}
                  />
                </Col>

                <Col className="gap-1 text-center w-48">
                  <label className={"text-sm font-semibold"}>Card Type</label>
                  <Dropdown
                    zindex="z-20"
                    options={[
                      {
                        title: "Common",
                        onClick: () => {
                          setRarity("Common");
                          setPlayersSize(playersSize);
                          generateEmptyZombiesList(playersSize);
                        },
                      },
                      {
                        title: "Uncommon",
                        onClick: () => {
                          setRarity("Uncommon");
                          setPlayersSize(playersSize);
                          generateEmptyZombiesList(playersSize);
                        },
                      },
                      {
                        title: "Rare",
                        onClick: () => {
                          setRarity("Rare");
                          setPlayersSize(playersSize);
                          generateEmptyZombiesList(playersSize);
                        },
                      },
                      {
                        title: "Epic",
                        onClick: () => {
                          setRarity("Epic");
                          setPlayersSize(playersSize);
                          generateEmptyZombiesList(playersSize);
                        },
                      },
                    ]}
                    selected={rarity}
                  />
                </Col>

                {rarity && (
                  <Col className="text-center gap-1 w-32">
                    <label className={"text-sm font-semibold"}>
                      Power Limit
                    </label>
                    <Dropdown
                      zindex="z-10"
                      options={[
                        {
                          title: `Max ${LEVEL_MAP[rarity][0]}`,
                          onClick: () => {
                            setLevel(0);
                            setPlayersSize(playersSize);
                            generateEmptyZombiesList(playersSize);
                          },
                        },
                        {
                          title: `Max ${LEVEL_MAP[rarity][1]}`,
                          onClick: () => {
                            setLevel(1);
                            setPlayersSize(playersSize);
                            generateEmptyZombiesList(playersSize);
                          },
                        },
                        {
                          title: `Max ${LEVEL_MAP[rarity][2]}`,
                          onClick: () => {
                            setLevel(2);
                            setPlayersSize(playersSize);
                            generateEmptyZombiesList(playersSize);
                          },
                        },
                        {
                          title: `Max ${LEVEL_MAP[rarity][3]}`,
                          onClick: () => {
                            setLevel(3);
                            setPlayersSize(playersSize);
                            generateEmptyZombiesList(playersSize);
                          },
                        },
                      ]}
                      selected={`Max ${LEVEL_MAP[rarity][level]}`}
                    />
                  </Col>
                )}

                <Col className="gap-1 text-center w-32">
                  <label className={"text-sm font-semibold"}>Inventory</label>
                  <Dropdown
                    zindex="z-30"
                    options={[
                      { title: "Yes", onClick: () => setWithInventory(true) },
                      {
                        title: "No",
                        onClick: () => {
                          setWithInventory(false);
                          setSelectedInventory([]);
                        },
                      },
                    ]}
                    selected={withInventory ? "Yes" : "No"}
                  />
                </Col>
              </div>
            )}

            <p className={"mb-6 w-2/3 mx-auto"}>
              Choose
              {selectedZombies.length > 1
                ? " one or more zombies "
                : " zombie "}
              for clan battle, and you have the option to utilize your inventory
              to boost zombie power.
            </p>

            <div className="flex flex-row flex-wrap gap-6 justify-center">
              {selectedZombies.map((zombie, index) => (
                <div
                  className={`flex gap-2 ${
                    selectedZombies.length > 1 ? "flex-col" : "place-items-end"
                  }`}
                  key={index}
                >
                  <div
                    className={`w-[140px] h-[196px] rounded-xl transition duration-200 cursor-pointer 
                    ${zombie ? "" : "bg-main hover:bg-main/50"}`}
                    onClick={() => {
                      setZombiesPopupVisible(true);
                      setCurrentZombiePosition(index);
                    }}
                  >
                    {zombie ? (
                      <CardRotate nft={zombie} size="sm" />
                    ) : (
                      <>
                        <PlusIcon className="w-8 h-8 mx-auto mt-20" />
                        <div className="text-center text-sm mt-12">
                          Select Zombie
                        </div>
                      </>
                    )}
                  </div>

                  {withInventory && (
                    <div
                      className={`w-[140px] min-h-[100px] rounded-xl transition duration-200 cursor-pointer
                    ${selectedInventory[index] ? "" : "bg-main"} ${zombie ? "" : "opacity-40 pointer-events-none cursor-none"}`}
                      onClick={() => {
                        setInventoryPopupVisible(true);
                        setCurrentInventoryPosition(index);
                      }}
                    >
                      {selectedInventory[index] ? (
                        <div className={"ml-1"}>
                          <CardItem
                            isItem
                            item={selectedInventory[index]}
                            noFlip
                            size="sm"
                          />
                        </div>
                      ) : (
                        <>
                          <PlusIcon className="w-6 h-6 mx-auto mt-6" />
                          <div className="text-center text-sm mt-4">
                            Add Inventory
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="my-6 bg-main/30 py-3 gap-2 text-cyan-200">
            <div>
              <span>Your bet: </span>
              <span className="text-amber-600 font-semibold">
                {totalBattleZML(rarity)} ZML
              </span>
            </div>
            <div>
              <span>On win you will receive: </span>
              <span className="text-amber-600 font-bold">
                {totalBattleZML(rarity) + winBattleZML()} ZML
              </span>
            </div>
          </div>

          <Button
            title={btnTitle}
            size="md"
            onClick={() => (isCreate ? createBattle() : joinBattle(battleId))}
            disabled={!isReady || !selectedZombies.filter((zm) => zm).length}
          />

          <SelectZombiesPopup
            title="Select Zombie for Battle"
            zombiesPopupVisible={zombiesPopupVisible}
            setZombiesPopupVisible={setZombiesPopupVisible}
            zombiesInPopup={zombiesInPopup}
            userCollectionZombies={userZombies}
            selectZombie={selectZombie}
            currentPage={currentZombiePage}
            pageLimit={ZOMBIES_PAGE_LIMIT}
            onPageChanged={onZombiePageChanged}
            nftType={"zombie"}
          />
          {withInventory && (
            <SelectInventoryPopup
              title="Select Inventory Item"
              inventoryPopupVisible={inventoryPopupVisible}
              setInventoryPopupVisible={setInventoryPopupVisible}
              selectInventory={selectInventory}
              selectedInventory={selectedInventory}
            />
          )}
        </>
      ) : (
        <Loader />
      )}
    </Popup>
  );
};

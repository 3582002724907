import React, { useContext, useEffect, useState } from "react";
import { ModifiersContent } from "../../utils/content";
import { modifierOptions, selectAll, transformItem } from "../../utils/utils";
import { ListWrapper } from "../../assets/styles/common.style";
import { Footer, Header, SelectedItemsFooter } from "../../components";
import {
  Loader,
  Dropdown,
  InnerPageHead,
  Pagination,
  CardItem,
} from "../../ui";
import { useNavigate } from "react-router-dom";
import { NearContext } from "../../contexts/NearWallet";
import {
  Container,
  InnerPageWrapper,
  ListSmall,
  Wrapper,
} from "../../assets/styles/common.style";

const PAGE_LIMIT = 40;

export const Modifiers = () => {
  const { mainContract, currentUser } = useContext(NearContext);
  const [isReady, setIsReady] = useState(false);
  const [userModifiers, setUserModifiers] = useState([0, []]); // [<count>, [<arrayOfItems>]]
  const [currentPage, setCurrentPage] = useState(1);
  const [filterType, setFilterType] = useState(null);
  const [selectedModifiers, setSelectedModifiers] = useState([]);
  const navigate = useNavigate();

  async function fetchUserModifiers(currentPage, itemType) {
    let requestParams = {
      account_id: currentUser,
      page_num: currentPage,
      page_limit: PAGE_LIMIT,
    };
    if (itemType) {
      requestParams["filter_type"] = itemType;
    }
    let items = await mainContract.userModifierItems(requestParams);

    // Convert price from Yocto NEAR
    items[1] = items[1].map((item) => transformItem(item));
    setUserModifiers(items);
    setIsReady(true);
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = JSON.parse(searchParams.has("page"))
      ? parseInt(searchParams.get("page"))
      : currentPage;
    const type = JSON.parse(searchParams.has("type"))
      ? searchParams.get("type")
      : filterType;

    setCurrentPage(page);
    setFilterType(type);
    fetchUserModifiers(page, type);
  }, []);

  useEffect(() => {
    if (isReady) {
      setCurrentPage(1);
      fetchUserModifiers(1, filterType);
      navigate(buildUrl());
    }
  }, [filterType]);

  useEffect(() => navigate(buildUrl()), [currentPage]);

  const buildUrl = () => {
    let url = `/modifiers?page=${currentPage}`;
    if (filterType) url = `${url}&type=${filterType}`;
    return url;
  };

  const onPageChanged = (page) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentPage(page);
    fetchUserModifiers(page, filterType);
  };

  const rmFromMarket = async (item) => {
    setIsReady(false);
    await mainContract.removeFromMarket(item.token_id, item.nft_type);
    fetchUserModifiers(currentPage, filterType);
  };

  const selectModifier = (mod) => {
    const findItem = selectedModifiers.findIndex(
      (value) => value.token_id === mod.token_id
    );
    if (findItem < 0)
      return setSelectedModifiers((prevState) => [...prevState, mod]);
    return setSelectedModifiers(
      selectedModifiers.filter((_, ind) => findItem !== ind)
    );
  };

  const isSelected = (modId) =>
    selectedModifiers.filter((mod) => mod.token_id === modId).length > 0;

  return (
    <InnerPageWrapper>
      <Header />

      <Wrapper>
        <Container className="text-white text-center mt-6">
          <InnerPageHead
            title={ModifiersContent.title}
            description={ModifiersContent.description}
          />

          {isReady ? (
            <>
              <div className="flex justify-between mt-8 z-30 relative">
                <div className="basis-1/2 text-lg text-left pt-2 pl-1">
                  Available:
                  <span
                    className="ml-2 font-semibold text-amber-600 cursor-pointer"
                    onClick={() =>
                      selectAll(
                        selectedModifiers,
                        setSelectedModifiers,
                        userModifiers[1]
                      )
                    }
                  >
                    {userModifiers[0]} item{userModifiers[0] > 1 ? "s" : ""}
                  </span>
                </div>

                <div className="basis-1/2 z-10 text-right">
                  <div className="inline-block mr-1">
                    <Dropdown
                      title="Item Type"
                      selected={filterType}
                      options={modifierOptions(setFilterType)}
                    />
                  </div>
                </div>
              </div>

              <ListWrapper>
                {userModifiers[0] > 0 ? (
                  <ListSmall>
                    {userModifiers[1]?.map((item) => (
                      <CardItem
                        item={item}
                        rmFromMarket={() => rmFromMarket(item)}
                        key={item.token_id}
                        handleSelect={() => selectModifier(item)}
                        isSelected={isSelected(item.token_id)}
                      />
                    ))}
                  </ListSmall>
                ) : (
                  <div>You don't have {filterType} Modifiers.</div>
                )}
              </ListWrapper>

              {filterType && (
                <div className="mt-10">
                  <a
                    className="link cursor-pointer"
                    onClick={() => setFilterType(null)}
                  >
                    Reset Filters
                  </a>
                </div>
              )}

              <div className="mb-8">
                <Pagination
                  total={parseInt(userModifiers[0])}
                  limit={PAGE_LIMIT}
                  selectedPage={currentPage}
                  onPageChanged={onPageChanged}
                />
              </div>
            </>
          ) : (
            <Loader />
          )}
        </Container>
      </Wrapper>

      {selectedModifiers.length > 0 && (
        <SelectedItemsFooter
          selectedItems={selectedModifiers}
          nftType="Modifier"
          deselectItem={selectModifier}
          setSelectedItems={setSelectedModifiers}
          items={userModifiers[1]}
          handleReloadData={() => {
            setSelectedModifiers([]);
            fetchUserModifiers(currentPage, filterType);
          }}
        />
      )}

      <Footer />
    </InnerPageWrapper>
  );
};

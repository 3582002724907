import styled from "styled-components";

export const identifyColorForColumn = (value) => {
  switch (value.toLowerCase()) {
    case "won": {
      return "text-green-500";
    }
    case "lost": {
      return "text-red-500";
    }
    default: {
      return "text-gray-300";
    }
  }
};

const TabsSection = styled.section.attrs({
  className: "w-full mt-10 pb-10",
})``;

const TabContainer = styled.div.attrs({
  className:
    "flex md:flex-row flex-col items-center justify-center md:space-x-8 my-10 ",
})``;

const Tab = styled.span.attrs(({ selectedTab, enabled }) => {
  return {
    className: `
      cursor-pointer
      text-xl
      md:border-bottom
      md:border-b-2
      pb-2
      font-semibold
      ${
        selectedTab
          ? "text-amber-600 border-amber-600"
          : "text-blue-500 border-blue-500"
      }
    `,
  };
})``;

const TabContent = styled.div.attrs(({ className }) => {
  return {
    className: `${className} bg-mainLight/70 mt-10 rounded-lg px-10 py-5`,
  };
})``;

const Table = {
  Header: styled.div.attrs({
    className: "flex w-full justify-between text-sm text-gray-500",
  })``,
  UserColumn: styled.div.attrs({
    className: "w-1/2 mr-5 truncate items-center",
  })``,
  ItemColumn: styled.div.attrs((props) => {
    return {
      className: `${props?.color} flex justify-start items-center`,
    };
  })``,
  Content: styled.div.attrs({
    className: "flex mt-5 justify-between",
  })``,
};

const HeadingTertiary = styled.h3.attrs({
  className: "mb-1 text-indigo-300 font-semibold",
})``;
const DetailsTertiary = styled.h3.attrs({
  className: "mb-5 last:mb-0",
})``;

const InfoContainer = styled.div.attrs({
  className: "flex w-full mt-10 sm:flex-row flex-col relative",
})``;

const SectionWrapper = styled.div.attrs({
  className:
    "md:px-7 p-3 rounded-md bg-mainLight/60 border-2 border-mainLight text-left",
})``;

export default {
  Tab,
  TabContainer,
  TabContent,
  Table,
  HeadingTertiary,
  DetailsTertiary,
  SectionWrapper,
  InfoContainer,
  TabsSection,
};

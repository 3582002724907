import { convertToTera, convertToYocto, transformZombieMonster } from "../utils/utils";

class MainContract {
  contractId = "";
  wallet = null;

  constructor({contractId, walletToUse}) {
    this.contractId = contractId;
    this.wallet = walletToUse;
  }

  /**
   * Get Land info
   * @param id
   * @returns {Promise<undefined|any>}
   */
  landInfo(id) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'land_info',
      args: {
        id
      }
    });
  }

  userLandsInfo(id_list, user_id) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'user_lands_info',
      args: {
        id_list,
        user_id
      }
    });
  }

  userZombies(requestParams) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'user_zombies',
      args: requestParams
    });
  }

  getBurnedZombiesList(requestParams) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'burned_for_monster_mint',
      args: requestParams
    });
  }

  getLandParasSeries() {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_land_paras_series',
    });
  }

  getCollections() {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_collections',
    });
  }

  getOneCollection(collection_id) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_one_collection',
      args: {
        collection_id
      }
    });
  }

  userCollectionCounts(account_id) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'user_collection_counts',
      args: {
        account_id
      }
    });
  }

  getZombiesFromMarket(params) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_zombies_from_market',
      args: params
    });
  }

  getMonstersFromMarket(params) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_monsters_from_market',
      args: params
    });
  }

  getInventoryFromMarket(params) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_inventory_from_market',
      args: params
    });
  }

  getModifiersFromMarket(params) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_modifiers_from_market',
      args: params
    });
  }

  getMonsterPartsFromMarket(params) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_monster_parts_from_market',
      args: params
    });
  }

  userMonsters(requestParams) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'user_monsters',
      args: requestParams
    });
  }

  zombieKillTokens(tokens_list) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'zombie_kill_tokens',
      args: {
        tokens_list
      }
    });
  }

  isStakeMonster(user_id) {
    const monster = this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'is_stake_monster',
      args: {
        user_id
      }
    });

    if (monster) {
      return transformZombieMonster(monster);
    }
  }

  leaderboardStats() {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'leaderboard_stats',
    });
  }

  getLastMarketHistory() {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_last_market_history',
    });
  }

  getZombiesById(id_list) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_zombies_by_id',
      args: {
        id_list
      }
    });
  }

  getMonstersById(id_list) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_monsters_by_id',
      args: {
        id_list
      }
    });
  }

  getInventoryById(id_list) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_inventory_by_id',
      args: {
        id_list
      }
    });
  }

  getModifiersById(id_list) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_modifiers_by_id',
      args: {
        id_list
      }
    });
  }

  userModifierItems(requestParams) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'user_modifier_items',
      args: requestParams
    });
  }

  userInventoryItems(requestParams) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'user_inventory_items',
      args: requestParams
    });
  }

  getBoxOpenCounts() {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_box_open_count',
      args: {}
    });
  }

  monsterPartDetails(token_id) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'monster_part_details',
      args: {
        token_id
      }
    });
  }

  getUserMonsterParts(account_id) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_user_monster_parts',
      args: {
        account_id
      }
    });
  }

  getMonsterPartById(id_list) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_monster_part_by_id',
      args: {
        id_list
      }
    });
  }

  getClans(start, limit) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_clans',
      args: {
        start,
        limit
      }
    });
  }

  getClanById(clan_id, account_id) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_clan_by_id',
      args: {
        clan_id,
        account_id
      }
    });
  }

  getClanByIds(clan_ids) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_clan_by_ids',
      args: {
        clan_ids
      }
    });
  }

  getClanByAccountId(account_id) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_clan_by_account_id',
      args: {
        account_id
      }
    });
  }

  getClanDailyRewardTime(clan_id, account_id) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_clan_daily_reward_time',
      args: {
        clan_id,
        account_id
      }
    });
  }

  mintLandNft(gas, deposit) {
    const isRef = localStorage.getItem("zomland:isCommunityBonusZML") === "true";

    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'mint_land_nft',
      args: {
        is_ref: isRef
      },
      gas,
      deposit
    });
  }

  async mintFreeZombieNft(land_id, deposit) {
    return await this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'mint_free_zombie_nft',
      args: {
        land_id
      },
      gas: convertToTera("280"),
      deposit
    });
  }

  importParasLand(token_id) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'import_paras_land',
      args: {
        token_id
      },
      gas: convertToTera("50")
    });
  }

  publishOnMarket(token_price_list, nft_type) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'publish_on_market',
      args: {
        token_price_list,
        nft_type
      },
      gas: convertToTera("180"),
      deposit: convertToYocto("0.000001")
    });
  }

  removeFromMarket(token_id, nft_type) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'remove_from_market',
      args: {
        token_id,
        nft_type
      }
    });
  }

  transferNftOnMarket(token_id, nft_type) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'transfer_nft_on_market',
      args: {
        token_id,
        nft_type
      }
    });
  }

  transferZombieMonster(token_id, recipient_id, nft_type) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'transfer_zombie_monster',
      args: {
        token_id,
        recipient_id,
        nft_type
      }
    });
  }

  transferInventoryModifier(token_id, recipient_id, nft_type) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'transfer_inventory_modifier',
      args: {
        token_id,
        recipient_id,
        nft_type
      }
    });
  }

  mintCollection(zombie_list, collection_id) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'mint_collection',
      args: {
        zombie_list,
        collection_id
      }
    });
  }

  killZombie(id_list) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'kill_zombie',
      args: {
        id_list
      }
    });
  }

  killMonster(id_list) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'kill_monster',
      args: {
        id_list
      }
    });
  }

  stakeMonster(monster_id) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'stake_monster',
      args: {
        monster_id
      },
      gas: convertToTera("100"),
      deposit: "1"
    });
  }

  unstakeMonster() {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'unstake_monster',
      gas: convertToTera("100"),
      deposit: "1"
    });
  }

  joinClan(clan_id) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'join_clan',
      args: {
        clan_id
      },
      gas: convertToTera("100"),
      deposit: "1"
    });
  }

  leaveClan(clan_id) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'leave_clan',
      args: {
        clan_id
      },
      gas: convertToTera("100"),
    });
  }

  upgradeClan(clan_id, gas, deposit) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'upgrade_clan',
      args: {
        clan_id
      },
      gas,
      deposit
    });
  }

  kickUser(user, clan_id) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'kick_user',
      args: {
        user,
        clan_id
      }
    });
  }

  async discoverLand(land_id, monster_id, land_region) {
    return await this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'discover_land',
      args: {
        land_id,
        monster_id,
        land_region
      },
      gas: convertToTera("200")
    });
  }

  useModifierItemForZombie(zombie_id, modifier_list) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'use_modifier_item_for_zombie',
      args: {
        zombie_id,
        modifier_list
      }
    });
  }

  async claimClanDailyRewards(clan_id) {
    return await this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'claim_clan_daily_rewards',
      args: {
        clan_id
      }
    });
  }

  checkClanAllocation(clan_id) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'check_clan_allocation',
      args: {
        clan_id
      }
    });
  }

  modifyClanInfo(params) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'modify_clan_info',
      args: params,
      gas: convertToTera("100"),
      deposit: "1"
    });
  }

  // joinClanBattle(zombie_id, item_id) {
  //   return this.wallet?.callMethod({
  //     contractId: this.contractId,
  //     method: 'join_clan_battle',
  //     args: {
  //       zombie_id,
  //       item_id
  //     }
  //   });
  // }

  removeClan(clan_id) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'remove_clan',
      args: {
        clan_id
      },
      gas: convertToTera("100"),
    });
  }

  landRestoreDiscoveryEvents(land_id, deposit) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'land_restore_discovery_events',
      args: {
        land_id
      },
      gas: convertToTera("50"),
      deposit
    });
  }

  // createTeamTransferAssets(zombies, monster_id, inventory) {
  //   return this.wallet?.callMethod({
  //     contractId: this.contractId,
  //     method: 'create_team_transfer_assets',
  //     args: {
  //       zombies,
  //       monster_id,
  //       inventory
  //     },
  //     gas: convertToTera("280"),
  //   });
  // }

  upgradeClanFake() {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'clan_upgrade',
      args: {},
      gas: convertToTera("20"),
      deposit: convertToYocto("10")
    });
  }

  openMysteryBox(box_id) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'open_box',
      args: {box_id},
      gas: convertToTera("300"),
      deposit: convertToYocto("5")
    });
  }

}

export default MainContract;
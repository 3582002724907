import React, { useContext } from "react";
import { Button, Popup } from "../../ui";
import {
  convertFromYocto,
  convertToYocto,
  formatTitle,
  getMedia,
  statusColorBorderMap,
} from "../../utils/utils";
import { XIcon } from "@heroicons/react/outline";
import { NearContext } from "../../contexts/NearWallet";
import { isNFT } from "../../services/MarketService";

export const SellNftPopup = ({
  sellPopupVisible,
  setSellPopupVisible,
  nftList,
  nftType,
  cancelSell,
  handleReloadData,
}) => {
  const {mainContract} = useContext(NearContext);
  const [defaultPriceInput, setDefaultPriceInput] = React.useState("");

  const createSaleObject = () => {
    let sellObject = {};
    let isError = false;

    nftList
      .filter((_, index) => index < 10)
      .forEach((item) => {
        if (item.sale_price > 0) {
          sellObject[item.token_id] = convertToYocto(item.sale_price);
        } else {
          isError = true;
        }
      });
    return [isError, sellObject];
  };

  const handleSell = async () => {
    const [isError, sellObject] = createSaleObject();
    if (!isError) {
      await mainContract.publishOnMarket(sellObject, nftType).then(() => {
        setSellPopupVisible(false);
        handleReloadData();
      });
    } else {
      alert(`Please, provide sale price for all ${nftType}s`);
    }
  };

  const buttonText = () => {
    let countSell = nftList.length > 10 ? 10 : nftList.length;
    return `Sell ${countSell} ${nftType}${nftList.length > 1 ? "s" : ""}`;
  };

  const SellItem = ({nft, defaultPriceInput}) => {
    const [priceInput, setPriceInput] = React.useState("");

    return (
      <div
        style={{maxWidth: "45%"}}
        className="bg-main/50 flex justify-between items-start p-3 m-2 rounded-md"
      >
        <img
          className={`w-20 border-2 rounded-md mr-4 ${statusColorBorderMap(
            nft.card_rarity
          )}`}
          src={getMedia(nft.media)}
          alt={nft.token_id}
        />
        <div className="text-left w-full">
          {isNFT(nftType) ? (
            <>
              <div className="font-semibold mb-2">
                {nft.card_rarity} {formatTitle(nftType, nft.token_id)}
              </div>
              {nftType !== "MonsterPart" && (
                <div className="text-xs mb-2">
                  {convertFromYocto(nft.kill_tokens, 1)} ZML
                </div>
              )}
            </>
          ) : (
            <div className="font-semibold mb-2">
              {nft.item_type} #{nft.token_id}{" "}
              {nft.level ? `Level ${nft.level}` : ""}
            </div>
          )}
          <input
            type="number"
            max="10000"
            min={isNFT(nftType) ? "0.01" : "1"}
            step={isNFT(nftType) ? "0.01" : "1"}
            placeholder={defaultPriceInput || "Price"}
            value={priceInput}
            onChange={(e) => {
              const price = e.target.value;
              setPriceInput(price);
              nft.sale_price = price;
            }}
            className="w-16 md:w-20 px-2 py-1 text-sm rounded-md bg-transparent border-2 border-gray-600 mr-1 focus:outline-none focus:border-gray-500"
          />{" "}
          {isNFT(nftType) ? "NEAR" : "ZML"}
        </div>
        <XIcon
          className="h-6 w-6 hover:text-red-700 cursor-pointer transition duration-200"
          onClick={() => cancelSell(nft)}
        />
      </div>
    );
  };

  return (
    <Popup
      title={`Sell ${nftType}${nftList.length > 1 ? "s" : ""}`}
      popupVisible={sellPopupVisible}
      setPopupVisible={setSellPopupVisible}
    >
      <div className="mt-2 md:px-3 mx-auto text-center">
        <div className="w-7/12 mx-auto mb-6">
          <input
            type="number"
            max="10000"
            min={isNFT(nftType) ? "0.01" : "1"}
            step={isNFT(nftType) ? "0.01" : "1"}
            placeholder="Price for All"
            value={defaultPriceInput}
            onChange={(e) => {
              const price = e.target.value;
              setDefaultPriceInput(price);

              nftList
                .filter((_, index) => index < 10)
                .map((nft) => (nft.sale_price = price));
            }}
            className="w-1/2 px-2 py-1 rounded-md bg-transparent border-2 border-gray-600 mr-1 focus:outline-none focus:border-gray-500"
          />{" "}
          <span>{isNFT(nftType) ? "NEAR" : "ZML"}</span>
        </div>
        <div className="flex flex-wrap w-full justify-center mb-6">
          {nftList
            .filter((_, index) => index < 10)
            .map((nft) => (
              <SellItem nft={nft} defaultPriceInput={defaultPriceInput} key={nft.token_id} />
            ))}
        </div>
        {nftList.length > 10 && (
          <p className="text-center text-sm my-4 text-red-400">
            *NOTE: Sell limited by 10 items per transaction.
          </p>
        )}
        <Button title={buttonText()} onClick={handleSell} />
      </div>
    </Popup>
  );
};

import React from "react";
import { getMedia, statusColorTextMap } from "../../utils/utils";
import { BanIcon, CheckCircleIcon } from "@heroicons/react/outline";
import { InventoryClanBattle } from "../../utils/content";
import { Button, Price } from "../index";

import CardRotate, { Rarity } from "../../assets/styles/cardRotate.style";

export const CardItem = ({
  isItem,
  item,
  size,
  isSelected,
  rmFromMarket,
  handleBuy,
  handleSelect,
  noFlip,
  noText,
}) => {
  const itemSize = size || "md";

  const classMapping = {
    xsm: "x-small-square",
    sm: "square-small",
    md: "square",
  };

  return (
    <CardRotate.FlipCardModify
      className={`${classMapping[itemSize]} ${
        noFlip ? "pointer-events-none" : ""
      }`}
    >
      <CardRotate.Front isSelected={isSelected} type={item.level}>
        <section className="absolute z-10 left-0 right-0 bottom-0">
          <Rarity
            type={item.level}
            className={`text-sm ${itemSize !== "md" ? "" : "pl-1"}`}
          >
            <span className={`${statusColorTextMap(item.level)}`}>
              {item.item_type}
              {!noText && item.level ? ` #${item.token_id}` : ""}
            </span>
          </Rarity>
        </section>
        <img
          className={`absolute h-full w-full`}
          src={getMedia(item.media)}
          alt={item.token_id ? item.token_id : ""}
        />
        {isItem && !noText && (
          <div className="z-10 absolute left-1 top-1">
            <Price
              title={`${item.durabilityMin ?? "3"} / ${
                item.durabilityMax ?? "3"
              }`}
              token=" "
              size={itemSize}
            />
          </div>
        )}
        {item.sale_price > 0 && !noText && (
          <div className="z-10 absolute right-1 top-1">
            <Price
              title={item.sale_price}
              handleBuy={handleBuy}
              token="ZML"
              size={itemSize}
            />
          </div>
        )}
        {isSelected && (
          <div className="top-0 left-0 right-0 bottom-0 z-10 absolute shadow-[0_0_20px_#f97315a8_inset]" />
        )}
      </CardRotate.Front>

      <CardRotate.Back isSelected={isSelected} type={item.level}>
        <img
          className={`absolute left-0 top-0 opacity-20 h-full w-full scale-x-[-1]`}
          src={getMedia(item.media)}
          alt={item.token_id ? item.token_id : ""}
        />

        {!isItem ? (
          <div className="mt-16 font-semibold">
            {item.item_type} +{item.level * 5}
          </div>
        ) : (
          <div
            className={`${itemSize !== "md" ? "text-sm leading-4 pt-1" : "pt-3"}`}
          >
            <p className="text-green-500 mb-5 font-semibold uppercase">
              Level {item.level}
            </p>
            <b>Clan Battle bonus:</b>
            <div className="mt-2">
              <small>
                {InventoryClanBattle[item.item_type][item.level || 1]}
              </small>
            </div>
          </div>
        )}

        {itemSize === "md" && (
          <div className="absolute bottom-3 left-3 right-3">
            {item.token_id && !item.sale_price && (
              <Button
                title={isSelected ? "Deselect" : "Select"}
                className="w-full"
                secondary
                size="xs"
                icon={
                  isSelected ? (
                    <BanIcon className="h-5 w-5 ml-2 font-semibold" />
                  ) : (
                    <CheckCircleIcon className="h-5 w-5 ml-2 font-semibold" />
                  )
                }
                onClick={handleSelect}
              />
            )}

            {handleBuy ? (
              <Button
                title={
                  <>
                    <span className="mr-1">Buy for</span>
                    {item.sale_price} ZML
                  </>
                }
                size="xs"
                className="w-full"
                noIcon
                onClick={handleBuy}
              />
            ) : (
              item.sale_price > 0 &&
              itemSize === "md" && (
                <Button
                  title="Cancel sell"
                  size="xs"
                  noIcon
                  className="w-full"
                  secondary
                  onClick={rmFromMarket}
                />
              )
            )}
          </div>
        )}
      </CardRotate.Back>
    </CardRotate.FlipCardModify>
  );
};

import React, { useState } from "react";
import { CardRotate, Popup, Pagination } from "../ui";

export const SelectZombiesPopup = ({
  zombiesPopupVisible,
  setZombiesPopupVisible,
  userCollectionZombies,
  zombiesInPopup,
  selectZombie,
  currentPage,
  onPageChanged,
  pageLimit,
  title,
  nftType,
}) => {
  const [showStats, setShowStats] = useState(false);

  return (
    <Popup
      title={title}
      popupVisible={zombiesPopupVisible}
      width="lg:w-[900px]"
      setPopupVisible={setZombiesPopupVisible}
    >
      <div className="sm:mt-2 sm:px-6">
        {userCollectionZombies[0] > 0 ? (
          <>
            <label className="inline-flex relative items-center cursor-pointer mb-5">
              <input
                type="checkbox"
                value=""
                id="default-toggle"
                className="sr-only peer"
                onChange={() => setShowStats(!showStats)}
              />
              <div
                className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-main peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
              <span className="ml-3 font-medium text-white">
                Show {nftType} stats
              </span>
            </label>
            <div className="md:flex md:flex-row gap-4 md:flex-wrap">
              {zombiesInPopup().map((zombie) => (
                <div
                  className="w-34 mb-1 cursor-pointer"
                  key={zombie.token_id}
                  onClick={() => selectZombie(zombie)}
                >
                  <CardRotate nft={zombie} size="sm" reversed={showStats} />
                </div>
              ))}
            </div>
          </>
        ) : (
          <p>You don't have any {nftType}s {!title.includes("Battle") ? " from this Collection" : ""}</p>
        )}

        {userCollectionZombies[0] > pageLimit && (
          <div className="text-center">
            <Pagination
              total={parseInt(userCollectionZombies[0])}
              limit={pageLimit}
              selectedPage={currentPage}
              onPageChanged={onPageChanged}
            />
          </div>
        )}
      </div>
    </Popup>
  );
};

import React, { useContext, useEffect, useState } from "react";
import { Header, Footer } from "../components";
import { InnerPageHead, Loader } from "../ui";
import { LeaderboardContent } from "../utils/content";
import { convertFromYocto, formatNumber, uniq } from "../utils/utils";
import { get } from "../utils/api";
import S from "../assets/styles/clanLine.style";
import { NearContext } from "../contexts/NearWallet";
import {
  Col,
  Container,
  InnerPageWrapper,
  Row,
  Wrapper,
} from "../assets/styles/common.style";

import FirstPlace from "../assets/images/1.png";
import SecondPlace from "../assets/images/2.png";
import ThirdPlace from "../assets/images/3.png";
import { UserProfile } from "../components/Avatar";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { FireIcon } from "@heroicons/react/solid";
import { RATING_THRESHOLD, REF_DATE } from "../utils/config";

export const Leaderboard = () => {
  const { mainContract, socialContract, ftContract, currentUser } =
    useContext(NearContext);
  const [leaderboard, setLeaderboard] = useState([]);
  const [ratingClans, setRatingClans] = useState([]);
  const [refferals, setRefferals] = useState({});
  const [myRef, setMyRef] = useState();
  const [isReady, setIsReady] = useState(false);
  const [isReadyHolders, setIsReadyHolders] = useState(true);
  const [profiles, setProfiles] = useState({});
  const [holders, setHolders] = useState([]);

  const TEAM_ACCS = ["zomland.near", "team.zomland.near"];

  const loadProfiles = async () => {
    const profileObj = {};
    const nsProfiles = await Promise.all(
      leaderboard.map(
        async (user) =>
          await socialContract.get([`${user.accountId}/profile/**`])
      )
    );
    leaderboard.map((user) => {
      let profile = nsProfiles
        .filter((p) => p !== undefined)
        .find((p) => p[user.accountId]);
      profileObj[user.accountId] = profile
        ? profile[user.accountId]
        : undefined;
    });

    setProfiles(profileObj);
  };

  async function fetchLeaderboard() {
    setIsReady(false);
    const response = await get("api/users");

    if (response.data) {
      const clanIds = response.data
        .filter((u) => u.clanId)
        .map((u) => u.clanId)
        .filter(uniq);
      const clans = await mainContract.getClanByIds(clanIds);
      const users = response.data.map((u) => {
        u.clan = clans.find((c) => u.clanId === c.id);
        return u;
      });
      const _clans = users.reduce((group, user) => {
        const { clan } = user;
        if (!clan) return group;

        group[clan.name] = group[clan.name] ?? [];
        group[clan.name].push(user);

        return group;
      }, {});

      const _ratingClans = Object.entries(_clans)
        .map((arr) => {
          let obj = {};
          obj[arr[0]] = {
            localRating: arr[1]
              .map((u) => u.localRating)
              .reduce((prev, curr) => prev + curr, 0),
            media: arr[0].media,
          };

          return obj;
        })
        .sort(
          (a, b) =>
            Object.values(b)[0].localRating - Object.values(a)[0].localRating
        )
        .slice(0, 3);
      setLeaderboard(users);
      setRatingClans(_ratingClans);
    }

    setIsReady(true);
  }

  // async function fetchHolders() {
  //   setIsReadyHolders(false);

  //   let balances = [];
  //   let stakes = [];

  //   if (leaderboard.length) {
  //     balances = await Promise.all(
  //       leaderboard
  //         .filter((a) => !TEAM_ACCS.includes(a.accountId))
  //         .map(({ accountId }) => ftContract.ftBalanceOf(accountId))
  //     );
  //     stakes = await Promise.all(
  //       leaderboard
  //         .filter((a) => !TEAM_ACCS.includes(a.accountId))
  //         .map(({ accountId }) => ftContract.getUserStake(accountId))
  //     );
  //   }

  //   setHolders(
  //     leaderboard.map(({ accountId }, i) => {
  //       return {
  //         accountId,
  //         balance: convertFromYocto(balances[i], 0),
  //         staked: convertFromYocto(stakes[i], 0),
  //       };
  //     })
  //   );
  //   setIsReadyHolders(true);
  // }
  const firstUsersReward = (i, type) => {
    const rewardRates = {
      activity: [45, 25, 15, 10, 5],
      referral: [100, 70, 50, 20, 10, 10, 10, 10, 5, 5],
    };
    if (rewardRates[type].length > i) {
      return `${rewardRates[type][i]} USDC.e`;
    }
    return "";
  };

  const winner = (account) =>
    account.localRating >= RATING_THRESHOLD
      ? "text-amber-500 font-bold"
      : "text-gray-400";

  async function getRefferals() {
    const response = await get("api/users/refferals");

    if (response.data) {
      const refs = response.data.refferals;
      const result = Object.groupBy(refs, ({ accountId }) => accountId);

      setRefferals(result);
    }
  }

  async function myRefferal() {
    const response = await get("api/users/refferals", {
      accountId: currentUser,
    });

    if (response.data) {
      const refs = response.data.refferals;
      const result = refs.find((r) => r.accountId === currentUser).uid;

      setMyRef(result);
    }
  }

  const refferalLink = myRef ? `https://zomland.com?ref=${myRef}` : null;
  const myReferralsSize = () => {
    try {
      return Object.entries(refferals).find(
        ([accountId, _]) => accountId === currentUser
      )[1]?.length ?? 0;
    } catch (e) {
      return 0;
    }
  }

  useEffect(() => {
    myRefferal();
    getRefferals();
    fetchLeaderboard();
  }, []);

  useEffect(() => {
    // fetchHolders();
    loadProfiles();
  }, [leaderboard]);

  const activeAfter = (ref, date) =>
    ref.activated && Date.parse(ref.createdAt) > Date.parse(date);

  return (
    <InnerPageWrapper>
      <Header />
      <Wrapper>
        <Container className="flex flex-col text-white mt-6 items-center">
          <InnerPageHead title={LeaderboardContent.title} />

          {isReady ? (
            <>
              <h1 className="zombie-font mt-10 text-indigo-300 font-normal md:text-4xl text-4xl capitalize">
                TOP 3 Clans
              </h1>
              {ratingClans && (
                <div className="lg:w-1/3 w-full mt-7">
                  {ratingClans.map((obj, i) => (
                    <div className="flex flex-row py-2 border-b-2 border-gray-700/30 border-dashed">
                      <Row className="flex ml-2 gap-5 w-full lg:justify-start justify-center">
                        <section>
                          <img
                            className="w-10"
                            src={
                              i === 0
                                ? FirstPlace
                                : i === 1
                                  ? SecondPlace
                                  : ThirdPlace
                            }
                            alt={`${i + 1} place`}
                          />
                        </section>
                        <section className="text-left truncate w-full lg:w-full text-lg font-semibold">
                          {Object.keys(obj)[0]}
                        </section>
                      </Row>
                      <Row className="lg:my-0 my-4 max-w-full sm:text-base text-sm">
                        <S.Rating>
                          <div className="text-lg text-amber-600 font-semibold">
                            {Object.values(obj)[0].localRating}
                          </div>
                        </S.Rating>
                      </Row>
                    </div>
                  ))}
                </div>
              )}

              <h1 className="zombie-font mt-10 text-indigo-300 font-normal md:text-4xl text-4xl capitalize">
                TOP Referrals
              </h1>
              <div className="mt-5 flex tracking-wider text-center">
                <InformationCircleIcon className="text-indigo-300 w-5 h-5 mr-2" />
                <p>
                  Your referral link:{" "}
                  {refferalLink && (
                    <>
                      <a className="link" href={refferalLink}>
                        {refferalLink}
                      </a>
                      <br />
                      used{" "}
                      <span className="font-semibold text-amber-500">
                        {myReferralsSize()}
                      </span>{" "}
                      {myReferralsSize() === 1 ? "time" : "times"}
                    </>
                  )}
                </p>
              </div>
              {refferals && (
                <div className="p-5 mt-10 bg-main lg:w-3/4 w-full">
                  <Row className="border-b-2 pb-3 text-gray-500 font-bold border-gray-600/30 border-dashed">
                    <Col className="w-10">#</Col>
                    <Col className="flex-1">Account</Col>
                    <Col className="flex-1">Active Refs (from {REF_DATE})</Col>
                    <Col className="md:w-36 w-20">Total Active Refs</Col>
                    <Col className="md:w-28 w-12">Total Refs</Col>
                    {/* <Col className="md:w-28 w-12">Rewards</Col> */}
                  </Row>

                  <Col className="ref-table">
                    {Object.entries(refferals)
                      .filter(([_a, refs]) => refs.length - 1 > 0)
                      .sort((a, b) => {
                        const activeA = a[1].filter((ref) =>
                          activeAfter(ref, REF_DATE)
                        ).length;
                        const activeB = b[1].filter((ref) =>
                          activeAfter(ref, REF_DATE)
                        ).length;
                        const A = a[1].length;
                        const B = b[1].length;

                        return activeB * 1000 + B - (activeA * 1000 + A);
                      })
                      .map(([accountId, refs], i) => (
                        <Row
                          key={i + 1}
                          className={`${winner(
                            i,
                            10
                          )} py-3 border-b-2 border-gray-700/30 border-dashed"`}
                        >
                          <div className="w-10">{i + 1}</div>
                          <div className="flex items-center flex-1 w-20 truncate text-ellipsis mr-5">
                            <UserProfile
                              profile={profiles}
                              accountId={accountId}
                            />
                          </div>
                          <div className="flex-1">
                            {
                              refs.filter((ref) => activeAfter(ref, REF_DATE))
                                .length
                            }
                          </div>
                          <div className="md:w-36 w-20">
                            {refs.filter((ref) => ref.activated).length}
                          </div>
                          <div className="md:w-28 w-12">{refs.length - 1}</div>
                          {/* <div className="md:w-28 w-12 text-amber-600 font-semibold">
                            {refs.filter((ref) => activeAfter(ref, REF_DATE))
                              .length > 0 && firstUsersReward(i, "referral")}
                          </div> */}
                        </Row>
                      ))}
                  </Col>
                </div>
              )}

              <h1 className="zombie-font mt-10 text-indigo-300 font-normal md:text-4xl text-4xl capitalize">
                TOP Active Players
              </h1>
              <div className="mt-5 flex tracking-wider text-center">
                <InformationCircleIcon className="text-indigo-300 w-5 h-5 mr-2" />
                <p>
                  We will pay rewards in NEAR for top 10 active players based on
                  earned monthly active points.
                  <div className="flex my-3 font-semibold gap-1 items-center justify-center">
                    <span>Minimum activity points threshold:</span>
                    <div className="text-blue-500 flex gap-1 items-center justify-center">
                      <FireIcon className="h-4" />
                      {RATING_THRESHOLD}
                    </div>
                  </div>
                  {/* Read about{" "}
                  <a className="link" href="https://zomland.com/faq">
                    How to earn points?
                  </a> */}
                </p>
              </div>
              {leaderboard && (
                <div className="p-5 mt-10 bg-main lg:w-3/4 w-full">
                  <Row className="border-b-2 pb-3 text-gray-500 font-bold border-gray-600/30 border-dashed">
                    <Col className="w-10">#</Col>
                    <Col className="flex-1 mr-5">Account</Col>
                    <Col className="flex-1">Clan</Col>
                    <Col className="md:w-36 w-12">Monthly Points</Col>
                    <Col className="md:w-36 w-12">Global Points</Col>
                    <Col className="md:w-28 w-12">Reward</Col>
                  </Row>

                  <Col>
                    {leaderboard.slice(0, 10).map((account, i) => (
                      <Row
                        key={i + 1}
                        className={`${winner(account)} py-3 border-b-2 border-gray-700/30 border-dashed"`}
                      >
                        <div className="w-10">{i + 1}</div>
                        <div className="flex items-center flex-1 w-20 truncate text-ellipsis mr-5">
                          <UserProfile
                            profile={profiles}
                            accountId={account.accountId}
                          />
                        </div>
                        <div className="flex-1">
                          {account.clan?.name || "-"}
                        </div>
                        <div className="md:w-36 w-12">
                          {account.localRating}
                        </div>
                        <div className="md:w-36 w-12">{account.rating}</div>
                        <div className="md:w-28 w-12">
                          {account.localRating >= RATING_THRESHOLD &&
                            firstUsersReward(i, "activity")}
                        </div>
                      </Row>
                    ))}
                  </Col>
                </div>
              )}

              <h1 className="zombie-font mt-10 text-indigo-300 font-normal md:text-4xl text-4xl capitalize">
                Monthly Rewards
              </h1>
              {leaderboard && (
                <div className="p-5 mt-10 bg-main lg:w-3/4 w-full">
                  <Row className="border-b-2 pb-3 text-gray-500 font-bold border-gray-600/30 border-dashed">
                    <Col className="w-10">#</Col>
                    <Col className="flex-1 mr-5">Account</Col>
                    <Col className="md:w-36 w-12">Monthly Points</Col>
                    <Col className="md:w-28 w-12">Reward</Col>
                  </Row>

                  <Col>
                    {leaderboard
                      .filter((account) => account.monthlyRating > 0)
                      .sort((a, b) => b.monthlyRating - a.monthlyRating)
                      .map((account, i) => (
                        <Row
                          key={i + 1}
                          className={`py-3 border-b-2 border-gray-700/30 border-dashed"`}
                        >
                          <div className="w-10">{i + 1}</div>
                          <div className="flex items-center flex-1 w-20 truncate text-ellipsis mr-5">
                            <UserProfile
                              profile={profiles}
                              accountId={account.accountId}
                            />
                          </div>
                          <div className="md:w-36 w-12">
                            {account.monthlyRating}
                          </div>
                          <div className="md:w-28 w-12">
                            {firstUsersReward(i, "activity")}
                          </div>
                        </Row>
                      ))}
                  </Col>
                </div>
              )}
            </>
          ) : (
            <Loader />
          )}

          <h1 className="zombie-font mt-10 text-indigo-300 font-normal md:text-4xl text-4xl capitalize">
            Top ZML Holders
          </h1>
          <div className="p-5 mt-10 bg-main lg:w-3/4 w-full">
            <Row className="border-b-2 pb-3 text-gray-500 font-bold border-gray-600/30 border-dashed">
              <Col className="w-10">#</Col>
              <Col className="flex-1 mr-5">Account</Col>
              <Col className="md:w-32">ZML balance</Col>
              <Col className="md:w-32">ZML Staked</Col>
            </Row>

            {isReadyHolders ? (
              <Col>
                {holders
                  .sort(
                    (a, b) =>
                      parseInt(b.balance) +
                      parseInt(b.staked) -
                      (parseInt(a.balance) + parseInt(a.staked))
                  )
                  .slice(0, 10)
                  .map(({ accountId, balance, staked }, i) => (
                    <Row
                      key={i + 1}
                      className={`py-3 border-b-2 border-gray-700/30 border-dashed"`}
                    >
                      <div className="w-10">{i + 1}</div>
                      <div className="flex items-center flex-1 w-20 truncate text-ellipsis mr-5">
                        <UserProfile profile={profiles} accountId={accountId} />
                      </div>
                      <div className="md:w-32">{formatNumber(balance)}</div>
                      <div className="md:w-32">{formatNumber(staked)}</div>
                    </Row>
                  ))}
              </Col>
            ) : (
              <Loader />
            )}
          </div>
        </Container>
      </Wrapper>

      <Footer />
    </InnerPageWrapper>
  );
};

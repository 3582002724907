import React, { useContext, useEffect, useMemo, useState } from "react";
import { Pagination, Popup, CardItem, Dropdown } from "../../ui";
import { inventoryOptions, transformItem } from "../../utils/utils";
import { get } from "../../utils/api";
import { NearContext } from "../../contexts/NearWallet";
import { Loader } from "../../ui";

const INVENTORY_PAGE_LIMIT = 20;

export const SelectInventoryPopup = ({
  title,
  inventoryPopupVisible,
  setInventoryPopupVisible,
  selectedInventory,
  selectInventory,
}) => {
  const { currentUser, mainContract } = useContext(NearContext);
  const [isLoading, setIsLoading] = useState(true);
  const [currentItemPage, setCurrentItemPage] = useState(1);
  const [userInventory, setUserInventory] = useState([0, []]);
  const [filterType, setFilterType] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    if (inventoryPopupVisible) {
      fetchUserInventory(currentItemPage);
    }
  }, [inventoryPopupVisible]);

  useEffect(() => {
    if (inventoryPopupVisible) {
      setCurrentItemPage(1);
      fetchUserInventory(1);
    }
  }, [filterType]);

  async function fetchUserInventory(currentPage) {
    let requestParams = {
      account_id: currentUser,
      page_num: currentPage,
      page_limit: INVENTORY_PAGE_LIMIT,
    };
    if (filterType) {
      requestParams["filter_type"] = filterType;
    }
    let items = await mainContract.userInventoryItems(requestParams);

    const itemsResp = await get("api/items", {
      ids: items[1].map((item) => item.token_id),
      types: items[1].map((item) => item.nft_type),
    });

    let itemsDB = itemsResp.data;

    if (itemsDB) {
      // Convert price from Yocto NEAR and add durability
      items[1] = items[1]
        .filter((item) => item.owner_id === currentUser)
        .map((item) => {
          let itemDB = itemsDB.find(
            (i) =>
              i.itemId === item.token_id &&
              (!item.sale_price || item.sale_price < 0)
          );
          let transformedItem = transformItem(item);

          transformedItem.durabilityMin = itemDB?.durabilityMin;
          transformedItem.durabilityMax = itemDB?.durabilityMax;
          transformedItem.level = itemDB?.level;

          return transformedItem;
        })
        .sort((a, b) => a.item_type.localeCompare(b.item_type));

      setUserInventory(items);
    }

    setIsLoading(false);
  }

  const onPageItemChanged = (page) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentItemPage(page);
    fetchUserInventory(page);
  };

  const inventoryInPopup = useMemo(() => {
    if (inventoryPopupVisible && userInventory) {
      return userInventory[1].filter(
        (item) =>
          item &&
          !item.sale_price &&
          !selectedInventory.map((item) => item).includes(item.token_id)
      );
    }
    return [];
  }, [userInventory, selectedInventory]);

  return (
    <Popup
      title={title}
      popupVisible={inventoryPopupVisible}
      width="lg:w-[900px]"
      setPopupVisible={setInventoryPopupVisible}
    >
      <div className="sm:mt-2 sm:px-6">
        {isLoading ? (
          <div className={"mx-auto w-8 pt-2"}>
            <Loader />
          </div>
        ) : (
          <>
            {userInventory[0] > 0 ? (
              <>
                <div className={"absolute z-10 right-24 top-8"}>
                  <Dropdown
                    title="Item Type"
                    selected={filterType}
                    options={inventoryOptions(setFilterType)}
                  />
                </div>

                <div className="md:flex md:flex-row gap-4 justify-center md:flex-wrap min-h-[250px]">
                  {inventoryInPopup.map((item) => (
                    <div
                      className="w-32 mb-1 cursor-pointer"
                      key={item.token_id}
                      onClick={() => selectInventory(item)}
                    >
                      <CardItem
                        isItem
                        item={item}
                        key={item.token_id}
                        size="sm"
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <p>
                You don't have {filterType ? filterType : "inventory"} items
              </p>
            )}

            {userInventory[0] > 1 && (
              <div className="text-center">
                <Pagination
                  total={parseInt(userInventory[0])}
                  limit={INVENTORY_PAGE_LIMIT}
                  selectedPage={currentItemPage}
                  onPageChanged={onPageItemChanged}
                />
              </div>
            )}
          </>
        )}
      </div>
    </Popup>
  );
};

import React, { useContext, useState } from "react";
import { Button, Input, TextArea, Popup } from "../../ui";
import { NearContext } from "../../contexts/NearWallet";
import { createClanService } from "../../services/ClanService";
import { updateUserBalance } from "../../services/UserService";
import { useDispatch } from "react-redux";

export const CreateClanPopup = ({setVisible, visible, reloadClanList}) => {
  const {wallet, ftContract, mainContract, currentUser} = useContext(NearContext);
  const dispatch = useDispatch();
  const [name, setName] = useState();
  const [about, setAbout] = useState();
  const [entryFee, setEntryFee] = useState();
  const [media, setMedia] = useState();

  const params = {
    name: name,
    about: about || "",
    entry_fee: entryFee || 0,
    media: media || "",
  };

  const handleClanCreate = () => {
    createClanService(wallet, mainContract, ftContract, params).then(() => {
      setVisible(false);
      reloadClanList();
      updateUserBalance(dispatch, ftContract, currentUser);
    });
  }

  return (
    <Popup
      title="Create Clan"
      popupVisible={visible}
      setPopupVisible={setVisible}
    >
      <div className="gap-5 px-6 mb-5 flex flex-col">
        <Input
          placeholder="Clan Name"
          handleChange={(e) => setName(e.target.value)}
          value={name}
        />
        <TextArea
          placeholder="Info about your clan and rules"
          handleChange={(e) => setAbout(e.target.value)}
          value={about}
        />
        <Input
          type="number"
          placeholder="Entry price (ZML)"
          handleChange={(e) =>
            setEntryFee(e.target.value < 0 ? 0 : e.target.value)
          }
          value={entryFee}
        />
        <Input
          placeholder="Public Image URL"
          handleChange={(e) => setMedia(e.target.value)}
          value={media}
        />
        {/* <Input type="file" onChange={(e) => setMedia(e.target.files[0])} /> */}
      </div>
      <Button
        title="Create"
        disabled={!name}
        onClick={() => handleClanCreate()}
      />
    </Popup>
  );
};

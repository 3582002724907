import React, { useContext, useState } from "react";
import S from "../../../assets/styles/clanLine.style";
import { Button } from "../../../ui";
import { useNavigate } from "react-router-dom";
import { UserIcon, CheckIcon, XIcon } from "@heroicons/react/solid";
import { FireIcon } from "@heroicons/react/outline";
import { joinClanService } from "../../../services/ClanService";
import escapeStringRegexp from "escape-string-regexp";
import { Row } from "../../../assets/styles/common.style";
import { LockClosedIcon } from "@heroicons/react/outline";
import { NearContext } from "../../../contexts/NearWallet";

import attack_icon from "../../../assets/images/attack_icon.png";
import clan_image from "../../../assets/images/clan_image.png";
import { useSelector } from "react-redux";

export const ClanLine = ({
  clan,
  index,
  alreadyInClan,
  battleCount,
  reloadClanList,
}) => {
  const { currentUser, wallet, mainContract, ftContract } =
    useContext(NearContext);
  const balance = useSelector((state) => state.user.balance);
  const [isReady, setIsReady] = useState(true);
  const navigate = useNavigate();

  const handleClanRemove = async () => {
    if (
      confirm(
        "Please confirm clan removal. All data and stats will be removed!"
      )
    ) {
      await mainContract.removeClan(clan.id);
      reloadClanList();
      navigate("/clans");
    }
  };

  const handleJoinClan = async () => {
    setIsReady(false);
    await joinClanService(balance, clan, wallet, mainContract, ftContract);
    reloadClanList();
  };

  const JoinButton = () => {
    if (clan.owner_id === currentUser) {
      if (clan.participants.length === 1)
        return (
          <Button
            size={"sm"}
            title={"DELETE"}
            secondary
            icon={<XIcon className="h-5 ml-2" />}
            onClick={handleClanRemove}
          />
        );
      else
        return <Button size={"sm"} title={"OWNER"} noIcon disabled secondary />;
    } else if (
      clan.participants.some(([user, _entryFee]) => user === currentUser)
    ) {
      return <Button size={"sm"} title={"JOINED"} noIcon disabled secondary />;
    }

    if (clan.participants.length < clan.size && !alreadyInClan)
      return (
        <Button
          size={"sm"}
          title={isReady ? "JOIN" : "JOINING..."}
          icon={<CheckIcon className="ml-2 h-5" />}
          onClick={handleJoinClan}
          disabled={!isReady}
          secondary
        />
      );

    return "";
  };

  return (
    <S.Wrapper
      onClick={() =>
        isReady && navigate(`/clans/${clan.id}?current_tab=Battles`)
      }
    >
      <Row className="flex ml-2 gap-7 w-full lg:justify-start justify-center">
        <section className="flex text-gray-500 w-10">
          {index > 0 ? `#${index}` : "#"}
        </section>

        <img
          className="object-cover rounded-full h-12 w-12"
          src={clan.media ?? clan_image}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = clan_image;
          }}
          alt="Clan"
        />

        <section className="text-left truncate w-22 lg:w-full">
          {escapeStringRegexp(clan.name)}
        </section>
      </Row>
      <Row className="lg:my-0 my-4 max-w-full sm:text-base text-sm">
        <S.Rating isCountGraterThenZero={clan.rating}>
          <div className="text-xs text-gray-400 font-normal">Activity</div>
          <div className="flex items-center">
            <FireIcon className="h-5 mr-1" />
            {clan.rating}
          </div>
        </S.Rating>
        <S.Rating>
          <div className="text-xs text-gray-400 font-normal">Battles</div>
          <div className="flex items-center">
            <img src={attack_icon} className="h-4 mr-2" alt="battles total" />
            {battleCount}
          </div>
        </S.Rating>
        <S.Members
          isCountGraterThenZero={
            clan.participants.length > 0 && clan.participants.length < clan.size
          }
        >
          <div className="text-xs text-gray-400 font-normal">Participants</div>
          <div className="flex items-center">
            <UserIcon className="h-5 mr-2 text-gray-400" />
            {clan.participants.length} / {clan.size}
          </div>
        </S.Members>
        <S.ZMLWrapper>
          <div className="text-xs text-gray-400 font-normal">Entry Fee</div>
          <div className="flex items-center">{`ZML ${clan.entry_fee}`}</div>
        </S.ZMLWrapper>
      </Row>
      <Row className="lg:ml-5 w-64 items-center lg:justify-start justify-center">
        {clan.locked ? (
          <div className="flex items-center justify-center">
            <div className="border-2 border-gray-500 flex rounded-lg py-2 px-3 items-center justify-center">
              <span className="text-gray-400 text-sm uppercase font-semibold mr-2">
                Locked
              </span>
              <LockClosedIcon className="h-5 w-full text-gray-400" />
            </div>
          </div>
        ) : (
          <JoinButton />
        )}
      </Row>
    </S.Wrapper>
  );
};

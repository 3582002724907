import React, { useContext, useEffect, useState } from "react";
import { Button, Loader, Popup } from "../../ui";
import { convertFromYocto, formatTitle, getMedia, statusColorBorderMap } from "../../utils/utils";
import { XIcon } from "@heroicons/react/outline";
import { NearContext } from "../../contexts/NearWallet";
import { handleKillService } from "../../services/NftService";
import { updateUserBalance } from "../../services/UserService";
import { useDispatch } from "react-redux";
import { get } from "../../utils/api";

const KILL_LIMIT = 100;

export const KillNftPopup = ({
  killPopupVisible,
  setKillPopupVisible,
  nftList,
  nftType,
  cancelKill,
  handleReloadData,
}) => {
  const dispatch = useDispatch();
  const {wallet, currentUser, mainContract, ftContract} = useContext(NearContext);
  const [isReady, setIsReady] = useState(false);
  const [idList, setIdList] = useState([]);

  useEffect(() => {
    if (killPopupVisible) {
      setIsReady(false);
      const idList = nftList.filter((_, index) => index < KILL_LIMIT).map((item) => item.token_id);
      setIdList(idList);

      if (nftType === "Zombie") {
        loadZombieKillPrice(idList);
      } else {
        setIsReady(true);
      }
    }
  }, [killPopupVisible]);

  const loadZombieKillPrice = async (idList) => {
    const zombiesPrices = await mainContract.zombieKillTokens(idList);

    nftList.filter((_, index) => index < KILL_LIMIT).map((item) => {
      Object.keys(zombiesPrices).map((tokenId) => {
        if (item.token_id === tokenId) {
          item.kill_tokens = zombiesPrices[tokenId];
        }
      });
      setIsReady(true);
    });
  }




  const handleKill = async () => {
    let points = 0;

    if (nftType === "Monster") {
      const rarityMultiplier = {
          "Common": 1,
          "Uncommon": 2,
          "Rare": 3,
          "Epic": 5,
      };
      const monsters = await mainContract.getMonstersById(idList);
      monsters.map((monster) => {
        const multiplier = rarityMultiplier[monster.card_rarity];
        const monster_point = multiplier * (monster.health + monster.attack + monster.brain);

        // console.log(`multiplier`, multiplier);
        // console.log(`monster.health, monster.attack, monster.brain`, monster.health, monster.attack, monster.brain);
        // console.log(`monster_point`, monster_point);

        points += monster_point;
      });
    }
    
    // console.log(`points`, points);

    await handleKillService(idList, nftType, mainContract, wallet, points);

    setKillPopupVisible(false);
    handleReloadData();

    updateUserBalance(dispatch, ftContract, currentUser);

    if (wallet.interface.id !== "near-wallet" && wallet.interface.id !== "my-near-wallet") {
      const action = nftType === "Monster" ? "monster-kill" : "zombie-kill";
      await get(`api/stats/log/`, {
        action,
        account: currentUser,
        data: idList.join(","),
        points,
        txHash: ""
      });
    }

  };

  const totalZML = () => {
    let total = 0;
    nftList.filter((_, index) => index < KILL_LIMIT).map((nft) => {
      total += parseFloat(convertFromYocto(nft.kill_tokens, 2));
    });
    return total.toFixed(1);
  };

  const removeFromKillList = (nft) => {
    setIdList(idList.filter(id => nft.token_id !== id));
    cancelKill(nft);
  }

  const KillItem = ({nft}) => (
    <div
      className="border-mainLight/80 bg-mainLight/30 flex justify-between items-start px-3 py-2 border-2 rounded-md">
      <img
        className={`w-16 border-2 rounded-md mr-4 ${statusColorBorderMap(
          nft.card_rarity
        )}`}
        src={getMedia(nft.media)}
        alt={nft.token_id}
      />
      <div className="text-left w-36">
        <div className="font-semibold mb-1">
          {nft.card_rarity} {formatTitle(nftType, nft.token_id)}
        </div>
        <div>{convertFromYocto(nft.kill_tokens, 1)} ZML</div>
      </div>
      <XIcon
        className="h-6 w-6 hover:text-red-700 cursor-pointer transition duration-200"
        onClick={() => removeFromKillList(nft)}
      />
    </div>
  );

  return (
    <Popup
      title={`Kill ${nftType}${nftList.length > 1 ? "s" : ""}`}
      popupVisible={killPopupVisible}
      setPopupVisible={setKillPopupVisible}
    >
      {isReady ? (
        <>
          <div className="mt-2 md:px-6 mx-auto text-center">
            <div className="flex flex-wrap w-full justify-center mb-6">
              {nftList.filter((_, index) => index < KILL_LIMIT).map((nft) => (
                <KillItem nft={nft} key={nft.token_id} />
              ))}
            </div>
          </div>
          <p className="pb-4 text-gray-400">
            You will receive <b>{totalZML()} ZML</b>.
          </p>
          {nftList.length > 100 && (
            <p className="text-red-400 pb-4">*NOTE: Kill limited by {KILL_LIMIT} items per transaction.</p>
          )}
          <Button
            title={`Kill ${nftList.length} ${nftType}${
              nftList.length > 1 ? "s" : ""
            }`}
            onClick={handleKill}
          />
        </>
      ) : (
        <Loader />
      )}
    </Popup>
  );
};

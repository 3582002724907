import React, { useContext, useEffect, useState } from "react";
import { MonsterPartsContent } from "../../utils/content";
import {
  rarityOptions,
  selectAll,
  transformMonsterPart,
} from "../../utils/utils";
import { ListWrapper } from "../../assets/styles/common.style";
import { Footer, Header, SelectedItemsFooter } from "../../components";
import {
  Button,
  Loader,
  Dropdown,
  InnerPageHead,
  PartOfMonster,
} from "../../ui";
import { useNavigate } from "react-router-dom";
import { NearContext } from "../../contexts/NearWallet";
import {
  Container,
  InnerPageWrapper,
  ListSmall,
  Wrapper,
} from "../../assets/styles/common.style";

export const MonsterParts = () => {
  const navigate = useNavigate();
  const { mainContract, currentUser } = useContext(NearContext);
  const [isReady, setIsReady] = useState(false);
  const [userMonsterParts, setUserMonsterParts] = useState([]);
  const [filterRarity, setFilterRarity] = useState(null);
  const [selectedParts, setSelectedParts] = useState([]);

  async function fetchUserMonsterParts() {
    let items = await mainContract.getUserMonsterParts(currentUser);

    // Convert price from Yocto NEAR
    items = items.map((item) => transformMonsterPart(item));
    items.sort((a, b) => (a.part_index > b.part_index ? 1 : -1));

    setUserMonsterParts(items);
    setIsReady(true);
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const rarity = JSON.parse(searchParams.has("rarity"))
      ? searchParams.get("rarity")
      : filterRarity;

    setFilterRarity(rarity);
    fetchUserMonsterParts();
  }, []);

  useEffect(() => {
    navigate(buildUrl());
  }, [filterRarity]);

  const buildUrl = () => {
    let url = `/monster_parts`;
    if (filterRarity) url = `${url}?rarity=${filterRarity}`;

    return url;
  };

  const rmFromMarket = async (item) => {
    setIsReady(false);
    await mainContract.removeFromMarket(item.token_id, item.nft_type);
    fetchUserMonsterParts();
  };

  const filterList = (item) => {
    if (!filterRarity) {
      return true;
    }
    return item.card_rarity === filterRarity;
  };

  const selectPart = (part) => {
    const findItem = selectedParts.findIndex(
      (value) => value.token_id === part.token_id
    );
    if (findItem < 0)
      return setSelectedParts((prevState) => [...prevState, part]);
    return setSelectedParts(selectedParts.filter((_, ind) => findItem !== ind));
  };

  const isSelected = (partId) =>
    selectedParts.filter((part) => part.token_id === partId).length > 0;

  return (
    <InnerPageWrapper>
      <Header />

      <Wrapper>
        <Container className="text-white text-center mt-6">
          <InnerPageHead
            title={MonsterPartsContent.title}
            description={MonsterPartsContent.description}
          />

          {isReady ? (
            <>
              <div className="lg:flex justify-between mt-8 z-30 relative">
                <div className="basis-1/2 text-lg md:text-left text-center pt-2 pl-1 lg:w-5/12">
                  Available:
                  <span
                    className="ml-2 font-semibold text-amber-600 cursor-pointer"
                    onClick={() =>
                      selectAll(
                        selectedParts,
                        setSelectedParts,
                        userMonsterParts
                      )
                    }
                  >
                    {userMonsterParts.length} item
                    {userMonsterParts.length > 1 ? "s" : ""}
                  </span>
                </div>

                <div className="lg:1/6 lg:my-0 my-2">
                  <Button
                    title="Mint Staking Monster"
                    noIcon
                    onClick={() => navigate("/collections/6")}
                  />
                </div>

                <div className="basis-1/2 z-20 md:text-right text-center relative lg:w-5/12">
                  <div className="inline-block mr-1">
                    <Dropdown
                      title="Rarity"
                      selected={filterRarity}
                      options={rarityOptions(setFilterRarity)}
                    />
                  </div>
                </div>
              </div>

              <ListWrapper>
                {userMonsterParts.filter((item) => filterList(item)).length >
                0 ? (
                  <ListSmall>
                    {userMonsterParts
                      .filter((item) => filterList(item))
                      .map((item) => (
                        <PartOfMonster
                          item={item}
                          key={item.token_id}
                          handleSelect={() => selectPart(item)}
                          isSelected={isSelected(item.token_id)}
                          rmFromMarket={() => rmFromMarket(item)}
                        />
                      ))}
                  </ListSmall>
                ) : (
                  <div>
                    You don't have {filterRarity} Staking Monster parts.
                  </div>
                )}
              </ListWrapper>
            </>
          ) : (
            <Loader />
          )}
        </Container>
      </Wrapper>

      {selectedParts.length > 0 && (
        <SelectedItemsFooter
          selectedItems={selectedParts}
          nftType="MonsterPart"
          deselectItem={selectPart}
          setSelectedItems={setSelectedParts}
          items={userMonsterParts}
          handleReloadData={() => {
            setSelectedParts([]);
            fetchUserMonsterParts();
          }}
        />
      )}

      <Footer />
    </InnerPageWrapper>
  );
};

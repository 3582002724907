import styled from "styled-components";

const Wrapper = styled.section.attrs({
  className:
    "flex bg-mainLight/50 border-2 border-violet-300/10 hover:border-indigo-800 items-center justify-between p-4 font-semibold text-lg rounded-md cursor-pointer lg:flex-row flex-col lg:w-full w-full",
})``;

const Rating = styled.section.attrs(({ isCountGraterThenZero }) => {
  return {
    className: `${
      isCountGraterThenZero ? "text-amber-600" : "text-gray-400"
    } flex items-start gap-1 lg:w-32 mx-1 flex-col px-2 md:px-4 bg-main/50 py-2 rounded`,
  };
})``;

const Members = styled.section.attrs(() => {
  return {
    className:
      "flex items-start lg:w-32 mx-1 flex-col px-2 md:px-4 bg-main/50 py-2 rounded",
  };
})`
  color: ${({ isCountGraterThenZero }) =>
    isCountGraterThenZero ? "#0aa6e9" : "#9CA3AFFF"};
`;

const ZMLWrapper = styled.section.attrs({
  className:
    "flex items-start text-gray-400 mx-1 lg:w-32 flex-col px-2 md:px-4 bg-main/50 py-2 rounded",
})``;

export default {
  Wrapper,
  Rating,
  Members,
  ZMLWrapper,
};

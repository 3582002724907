const CONTRACT_NAME = process.env.CONTRACT_NAME;
const CREATE_CLAN_FEE = process.env.CREATE_CLAN_FEE;

const RATING_THRESHOLD = 300;
const REF_DATE = "03/01/2024";

if (!CONTRACT_NAME || !CREATE_CLAN_FEE) {
  console.error("WRONG ENV CONFIGURATION: EMPTY CONTRACT_NAME!");
}

const FIREBASE_CONFIG = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
};

const PARAS_GAS_FEE = "100000000000000";
const PARAS_GAS_FEE_SELL = "280000000000000";
const PARAS_STORAGE_APPROVE_FEE = "800000000000000000000";
const PARAS_STORAGE_ADD_MARKET_FEE = "8590000000000000000000";

const BATTLE_AUTO_STARTED_DAYS = 3;
const CLAN_BATTLE_BET = {
  Common: { stake: 100, prize: [90, 95, 100, 110] },
  Uncommon: { stake: 200, prize: [180, 190, 200, 220] },
  Rare: { stake: 300, prize: [270, 285, 300, 330] },
  Epic: { stake: 500, prize: [450, 475, 500, 550] },
};
const LEVEL_MAP = {
  Common: [17, 34, 51, 74],
  Uncommon: [41, 58, 75, 98],
  Rare: [65, 82, 99, 122],
  Epic: [90, 107, 124, 147],
};

const landResetPriceZML = {
  Small: 500,
  Medium: 1000,
  Large: 2000,
  Giant: 2000,
};

const mintZombiesLimit = {
  Micro: 30,
  Small: 200,
  Medium: 400,
  Large: 800,
  Giant: 800,
};

const landsConfig = {
  Micro: {
    land_type: "Micro",
    price: "0",
    media: `bafkreidurt5hsjdqid2tl6azwbxye5fpizrzfvcxhavjpwg2wr5kdvk7t4.png`,
    copies: 49999,
    zombies_per_day: 1,
  },
  // Small: {
  //   land_type: "Small",
  //   price: "2.75",
  //   media: `bafkreihvcoraixdyx6jbrlmlfw45psrjlkwemp7ie3wckye7frnyhbdnoi.png`,
  //   copies: 343,
  //   zombies_per_day: 2,
  // },
  Medium: {
    land_type: "Medium",
    price: "5",
    media: `bafkreiepzrmwcequ5u6b5dx2jdrr2vq5ujehibu4v32zdxrg4jdgts2ozq.png`,
    copies: 5999,
    zombies_per_day: 4,
  },
  Large: {
    land_type: "Large",
    price: "13.17",
    media: `bafkreigezufih7gmv6d6xfbm3ackbvsxxbw5mprlt3hx5kvte7kjkbaxju.png`,
    copies: 1999,
    zombies_per_day: 8,
  },
  Giant: {
    land_type: "Giant",
    price: "22",
    media: `bafybeifsfl7giqf5vwhtz6ppvmnknqboehw47pegek45xbxohjy2fqu6uy.png`,
    copies: 599,
    zombies_per_day: 8,
  },
};

module.exports = {
  landsConfig,
  CONTRACT_NAME,
  PARAS_GAS_FEE,
  PARAS_GAS_FEE_SELL,
  PARAS_STORAGE_APPROVE_FEE,
  PARAS_STORAGE_ADD_MARKET_FEE,
  BATTLE_AUTO_STARTED_DAYS,
  CLAN_BATTLE_BET,
  CREATE_CLAN_FEE,
  FIREBASE_CONFIG,
  LEVEL_MAP,
  mintZombiesLimit,
  landResetPriceZML,
  RATING_THRESHOLD,
  REF_DATE,
};

import React, { useContext, useEffect, useState } from "react";
import { Link } from "../../../assets/styles/common.style";
import { Button, Popup, CardRotate } from "../../../ui";
import { convertFromYocto, getMedia } from "../../../utils/utils";
import { NearContext } from "../../../contexts/NearWallet";
import { mintMonsterPartService } from "../../../services/CollectionService";
import infoIcon from "../../../assets/images/info.png";
import { updateUserBalance, updateUserZmlReserve } from "../../../services/UserService";
import { useDispatch, useSelector } from "react-redux";

const COLLECTION_ZOMBIES_COUNT = 12;

export const StakingCollection = ({collection, getMintDeposit, mintedSuccess}) => {
  const {wallet, ftContract, mainContract, currentUser} = useContext(NearContext);
  const balance = useSelector((state) => state.user.balance);
  const reservedZmlBalance = useSelector((state) => state.user.reservedBalance);
  const dispatch = useDispatch();
  const [selectedPosition, setSelectedPosition] = React.useState();
  const [userCollectionParts, setUserCollectionParts] = React.useState([]);
  const [partCards, setPartCards] = React.useState([]);
  const [partsPopupVisible, setPartsPopupVisible] = useState(false);
  const [priceInfoTooltip, setPriceInfoTooltip] = useState(false);

  const loadMonsterParts = async () => {
    let zombieCardsList = [];
    for (let i = 1; i <= COLLECTION_ZOMBIES_COUNT + 1; i++) {
      zombieCardsList.push(null);
    }

    const result = await mainContract.getUserMonsterParts(currentUser);
    setUserCollectionParts(result);
    result.map(part => {
      if (!zombieCardsList[part.part_index]) {
        zombieCardsList[part.part_index] = part;
      }
    });
    setPartCards(zombieCardsList);
  };

  useEffect(() => {
    loadMonsterParts();
  }, []);

  const showSelectPartsPopup = (position) => {
    setSelectedPosition(position);
    setPartsPopupVisible(true);
  };

  const selectAnotherPart = (part) => {
    partCards[selectedPosition] = part;
    setPartCards(partCards);
    setPartsPopupVisible(false);
  };

  const countZombieSelected = () => {
    return partCards.filter((zombie) => zombie).length;
  };

  const monsterParams = (param) => {
    let total = 0;
    partCards
      .filter((zombie) => zombie)
      .map((zombie) => {
        total += zombie[param];
      });
    return total;
  };

  const monsterCardRarity = (card_type) => {
    const totalZombies = countZombieSelected();
    const oneZombiePct = 100 / totalZombies;
    const countZombieType = partCards.filter(
      (zombie) => zombie && zombie.card_rarity === card_type
    ).length;
    return (countZombieType * oneZombiePct).toFixed(1);
  };

  const mintMonster = async () => {
    if (countZombieSelected() === COLLECTION_ZOMBIES_COUNT) {

      if (isEnoughBalance()) {
        const partsList = partCards.filter((part) => part).map((part) => part.token_id);
        const zmlAmount = getMintDeposit(partCards);

        mintMonsterPartService(wallet, ftContract, mainContract, zmlAmount, partsList, collection.id).then(() => {
          loadMonsterParts();
          updateUserBalance(dispatch, ftContract, currentUser);
          mintedSuccess();
        });
      } else {
        alert("Not enough ZML balance");
      }
    } else {
      alert(
        `You need to add ${COLLECTION_ZOMBIES_COUNT} parts to mint the Staking Monster`
      );
    }
  };

  const isEnoughBalance = () => {
    return (
      parseFloat(convertFromYocto(balance, 2)) >= getMintDeposit(partCards)
    );
  };

  const withdrawReserved = () => {
    ftContract.withdrawZmlReserve().then(() => {
      updateUserZmlReserve(dispatch, ftContract, currentUser);
      updateUserBalance(dispatch, ftContract, currentUser);
    });
  };

  const partsInPopup = () =>
    userCollectionParts.filter((part) => {
      let exists = false;
      if (part.part_index === selectedPosition) {
        partCards.filter(part => part).map((innerPart) => {
          if (innerPart.token_id === part.token_id) {
            exists = true;
          }
        });
      } else {
        exists = true;
      }
      return !exists;
    });

  return (
    <div className="lg:flex justify-between block w-full">
      <div className="w-full lg:w-1/2">
        <h2 className="text-2xl font-semibold">
          <Link to="/collections">
            <span className="text-2xl font-semibold text-sky-200">
              Collections
            </span>
          </Link>{" "}
          &raquo; Mint Monster
        </h2>
        <p className="mt-1 mb-8">
          You need all monster parts to mint the {collection.title} Monster.
        </p>

        <div className="text-sm">
          <b>Characteristics</b>
          <p className="text-cyan-200 mb-2">
            Monster characteristic is sum of parts characteristic <br />
            multiply by modifier that depends on final card rarity.
          </p>
          {countZombieSelected() > 0 && (
            <ul className="list-disc ml-3">
              <li>Health: <b>{monsterParams("health")}</b></li>
              <li>Attack: <b>{monsterParams("attack")}</b></li>
              <li>Intellect: <b>{monsterParams("brain")}</b></li>
            </ul>
          )}

          <p className="mt-6">
            <b>Rarity chances</b>
          </p>
          <p className="text-sm text-cyan-200 mb-2">
            Chance for monster card rarity based on selected parts rarity.<br />
          </p>
          {countZombieSelected() > 0 && (
            <ul className="list-disc ml-3">
              <li>Common: <b>{monsterCardRarity("Common")}%</b></li>
              <li>UnCommon: <b>{monsterCardRarity("Uncommon")}%</b></li>
              <li>Rare: <b>{monsterCardRarity("Rare")}%</b></li>
              <li>Epic: <b>{monsterCardRarity("Epic")}%</b></li>
            </ul>
          )}
        </div>

        <div className="mt-10 text-sm text-cyan-200 font-semibold">
          <p>*NOTE: Your monster parts will be burned to get this Staking Monster.</p>
          <a
            href="https://zomland.gitbook.io/zomland-whitepaper/collections"
            target="_blank"
            className="link"
            rel="noreferrer"
          >Read details</a>.
        </div>
      </div>

      <div className="w-full lg:w-1/2 lg:flex justify-end">
        <div>
          <div className="flex flex-row flex-wrap w-[320px] staking-monster-bg rounded-lg mt-8 lg:mt-2 lg:mr-4 mx-auto">
            {partCards.filter((part, index) => index).map((part, index) => (
              <div
                className={`border border-black/30 w-[33.33%] h-[114px] rounded-sm transition duration-200
                ${part ? "cursor-pointer hover:border-2" : "cursor-default"}`}
                key={index}
                onClick={() => {
                  if (part) {
                    showSelectPartsPopup(index + 1);
                  }
                }}
              >
                <div className="text-center text-sm relative h-full">
                  {part ? (
                    <>
                    <span className={`absolute bottom-2 left-0 right-0 text-shadow font-semibold`}>
                      {part.card_rarity}
                    </span>
                      <img src={getMedia(part.media)} alt="" />
                    </>
                  ) : (
                    <span className="absolute bottom-2 left-0 right-0 text-white/50">
                      No Part #{index + 1}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="text-center mt-4">
            {reservedZmlBalance > 0 ? (
              <p className="mb-2">
                Reserved:{" "}
                <b>{convertFromYocto(reservedZmlBalance, 0)} ZML</b>
                <a
                  className="link ml-2 cursor-pointer"
                  onClick={() => withdrawReserved()}
                >
                  withdraw
                </a>
              </p>
            ) : (
              <>
                <div className="mb-2">
                  Mint Price:{" "}
                  <b
                    className={`text-lg ${
                      isEnoughBalance() ? "" : "text-red-500"
                    }`}
                  >
                    {getMintDeposit(partCards)} ZML
                  </b>
                  <div className="relative w-6 inline-block ml-2 align-text-bottom">
                    <img
                      src={infoIcon}
                      alt="info"
                      className="w-6"
                      onMouseEnter={() => setPriceInfoTooltip(true)}
                      onClick={() =>
                        setPriceInfoTooltip(!priceInfoTooltip)
                      }
                      onMouseLeave={() =>
                        setPriceInfoTooltip(false)
                      }
                    />

                    {priceInfoTooltip && (
                      <div
                        className="absolute left-[-84px] bottom-8 text-black bg-white p-4 w-52 rounded-md text-left text-sm shadow-lg">
                        <b className="block text-center">
                          Price change by selected zombies rarity:
                        </b>
                        <ul className="mt-2 ml-3">
                          <li>Common: +50 ZML</li>
                          <li>UnCommon: +100 ZML</li>
                          <li>Rare: +200 ZML</li>
                          <li>Epic: +400 ZML</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>

                <Button
                  size="lg"
                  noIcon
                  title="Mint Monster"
                  disabled={
                    countZombieSelected() < COLLECTION_ZOMBIES_COUNT || !isEnoughBalance()
                  }
                  onClick={mintMonster}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <Popup
        title={`Select Part for position #${selectedPosition}`}
        popupVisible={partsPopupVisible}
        width="lg:w-[900px]"
        setPopupVisible={setPartsPopupVisible}
      >
        <div className="sm:mt-2 sm:px-6">
          {partsInPopup().length > 0 ? (
            <div className="flex flex-row gap-4 flex-wrap">
              {partsInPopup().map((part) => (
                <div
                  className="w-34 mb-1 cursor-pointer"
                  key={part.token_id}
                  onClick={() => selectAnotherPart(part)}
                >
                  <CardRotate nft={part} size="sm" />
                </div>
              ))}
            </div>
          ) : (
            <p>You don't have other parts for this position.</p>
          )}
        </div>
      </Popup>
    </div>
  );
};

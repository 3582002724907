import Big from "big.js";
import { CREATE_CLAN_FEE } from "../utils/config";

const {convertToTera, convertToYocto} = require("../utils/utils");

export const joinClanService = async (balance, clan, wallet, mainContract, ftContract) => {
  let txs = [];
  let entry_fee = convertToYocto(clan.entry_fee);

  let result = await mainContract.checkClanAllocation(clan.id);
  if (!result) return;

  if (Big(entry_fee.toString()).gt(balance.toString())) {
    alert("Not enough ZML balance to join this Clan");
    return;
  }

  if (entry_fee > 0) {
    txs.push({
      receiverId: ftContract.contractId,
      actions: [
        {
          type: "FunctionCall",
          params: {
            methodName: "ft_transfer",
            args: {
              receiver_id: clan.owner_id.toLowerCase().trim(),
              amount: entry_fee,
            },
            gas: convertToTera("20"),
            deposit: "1",
          },
        },
      ],
    });
  }

  txs.push({
    receiverId: mainContract.contractId,
    actions: [
      {
        type: "FunctionCall",
        params: {
          methodName: "join_clan",
          args: {
            clan_id: clan.id,
          },
          gas: convertToTera("100"),
          deposit: "1"
        },
      },
    ],
  });

  return wallet.interface.signAndSendTransactions({transactions: txs})
};

export const upgradeClanService = async (clan, deposit, mainContract) => {
  if (deposit <= 0) return;
  const gas = "80";

  return mainContract.upgradeClan(clan.id, convertToTera(gas), convertToYocto(deposit));
};

export const kickUserService = async (user, fee, clan, wallet, mainContract, ftContract) => {
  let txs = [];
  let entry_fee = convertToYocto(fee);

  if (entry_fee > 0) {
    txs.push({
      receiverId: ftContract.contractId,
      actions: [
        {
          type: "FunctionCall",
          params: {
            methodName: "ft_transfer",
            args: {
              receiver_id: user.toLowerCase().trim(),
              amount: entry_fee,
            },
            gas: convertToTera("20"),
            deposit: "1",
          },
        },
      ],
    });
  }

  txs.push({
    receiverId: mainContract.contractId,
    actions: [
      {
        type: "FunctionCall",
        params: {
          methodName: "kick_user",
          args: {
            user: user,
            clan_id: clan.id,
          },
          gas: convertToTera("100")
        },
      },
    ],
  });

  return wallet.interface.signAndSendTransactions({transactions: txs})
};

export const createClanService = async (wallet, mainContract, ftContract, params) => {
  const txs = [
    {
      receiverId: ftContract.contractId,
      actions: [
        {
          type: "FunctionCall",
          params: {
            methodName: "ft_transfer_call",
            args: {
              receiver_id: ftContract.contractId,
              amount: convertToYocto(CREATE_CLAN_FEE),
              msg: "ft_create_user_clan",
            },
            gas: convertToTera("90"),
            deposit: "1",
          },
        },
      ],
    },
    {
      receiverId: mainContract.contractId,
      actions: [
        {
          type: "FunctionCall",
          params: {
            methodName: "create_user_clan",
            args: {
              name: params.name,
              about: params.about,
              entry_fee: parseInt(params.entry_fee.toString()),
              media: params.media,
              is_prod: process.env.IS_PROD != "false"
            },
            gas: convertToTera("280"),
            deposit: convertToYocto("0.25")
          },
        },
      ],
    },
  ];

  return wallet.interface.signAndSendTransactions({transactions: txs});
}

export const joinBattleTransferAssetsService = async (
  battle, zombieIdList, inventoryIdList, wallet, ftContract, mainContract
) => {
  const battleZMLYocto = convertToYocto(battle.data.entryFee * zombieIdList.length);

  // set temporary variable to check participation status after page reload on transfer assets
  let pendingBattles = localStorage.getItem("pending_join_battles");
  if (pendingBattles) {
    pendingBattles = JSON.parse(pendingBattles);
  } else {
    pendingBattles = [];
  }
  pendingBattles.push(battle.data.id);
  localStorage.setItem(
    "pending_join_battles",
    JSON.stringify(pendingBattles)
  );

  const txs = [];
  const chunkSize = 3;

  for (let i = 0; i < zombieIdList.length; i += chunkSize) {
    const zombieChunk = zombieIdList.slice(i, i + chunkSize);
    const inventoryChunk = inventoryIdList.slice(i, i + chunkSize);

    txs.push({
      receiverId: mainContract.contractId,
      actions: [
        {
          type: "FunctionCall",
          params: {
            methodName: "join_clan_battle",
            args: {
              zombies: zombieChunk,
              inventory: inventoryChunk,
            },
            gas: convertToTera("280"),
            deposit: convertToYocto("0.0001")
          },
        },
      ],
    })
  }

  txs.push({
    receiverId: ftContract.contractId,
    actions: [
      {
        type: "FunctionCall",
        params: {
          methodName: "ft_transfer",
          args: {
            receiver_id: ftContract.contractId,
            amount: battleZMLYocto,
          },
          gas: convertToTera("30"),
          deposit: "1",
        },
      },
    ],
  });

  return wallet.interface.signAndSendTransactions({transactions: txs});
};
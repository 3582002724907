import { providers } from 'near-api-js';

// wallet selector UI
import '@near-wallet-selector/modal-ui/styles.css';
import { setupModal } from '@near-wallet-selector/modal-ui';

// Icons
import LedgerIconUrl from '@near-wallet-selector/ledger/assets/ledger-icon.png';
import NearIconUrl from '@near-wallet-selector/near-wallet/assets/near-wallet-icon.png';
import MyNearIconUrl from '@near-wallet-selector/my-near-wallet/assets/my-near-wallet-icon.png';
import HereWalletIconUrl from '@near-wallet-selector/here-wallet/assets/here-wallet-icon.png';
import MeteorWalletIconUrl from '@near-wallet-selector/meteor-wallet/assets/meteor-icon.png';
import SenderIconUrl from '@near-wallet-selector/sender/assets/sender-icon.png';
import NightlyIconUrl from '@near-wallet-selector/nightly/assets/nightly.png';

// wallet selector options
import { setupWalletSelector } from '@near-wallet-selector/core';
import { setupLedger } from '@near-wallet-selector/ledger';
import { setupNearWallet } from '@near-wallet-selector/near-wallet';
import { setupMeteorWallet } from '@near-wallet-selector/meteor-wallet';
import { setupSender } from '@near-wallet-selector/sender';
import { setupNightly } from "@near-wallet-selector/nightly";
import { setupHereWallet } from '@near-wallet-selector/here-wallet';
import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";

const THIRTY_TGAS = '30000000000000';
const NO_DEPOSIT = '0';

// Wallet that simplifies using the wallet selector
export class Wallet {
  accountId = undefined;
  walletSelector = undefined;
  interface = undefined;
  network;
  createAccessKeyFor;

  constructor({createAccessKeyFor = undefined, network}) {
    this.createAccessKeyFor = createAccessKeyFor;
    this.network = network;
  }

  // To be called when the website loads
  async startUp() {
    this.walletSelector = await setupWalletSelector({
      network: this.network,
      modules: [
        setupNearWallet({iconUrl: NearIconUrl}),
        setupMyNearWallet({iconUrl: MyNearIconUrl}),
        setupMeteorWallet({iconUrl: MeteorWalletIconUrl}),
        setupNightly({iconUrl: NightlyIconUrl}),
        setupHereWallet({iconUrl: HereWalletIconUrl}),
        setupSender({iconUrl: SenderIconUrl}),
        setupLedger({iconUrl: LedgerIconUrl}),
      ],
    });

    const isSignedIn = this.walletSelector.isSignedIn();
    if (isSignedIn) {
      this.interface = await this.walletSelector.wallet();
      this.accountId = this.walletSelector.store.getState().accounts[0].accountId;
    }

    return isSignedIn;
  }

  async onAccountChange(accountId) {
    this.interface = await this.walletSelector?.wallet();
    this.accountId = accountId;
  }

  // Sign-in method
  signIn() {
    const description = 'Please select a wallet to sign in.';
    const modal = setupModal(this.walletSelector, {
      contractId: this.createAccessKeyFor,
      description
    });
    modal.show();
  }

  // Sign-out method
  signOut() {
    if (!this?.interface) return;
    this.interface.signOut();

    if (this.interface.id === "near-wallet" || this.interface.id === "my-near-wallet") {
      this.interface = this.accountId = this.createAccessKeyFor = undefined;
      window.location.replace(window.location.origin + window.location.pathname);
    }
  }

  // Make a read-only call to retrieve information from the network
  async viewMethod({contractId, method, args = {}}) {
    if (!this.walletSelector) return;

    const {network} = this.walletSelector.options;
    const provider = new providers.JsonRpcProvider({url: network.nodeUrl});

    let res = await provider.query({
      request_type: 'call_function',
      account_id: contractId,
      method_name: method,
      args_base64: Buffer.from(JSON.stringify(args)).toString('base64'),
      finality: 'optimistic',
    });
    return JSON.parse(Buffer.from(res.result).toString());
  }

  // Call a method that changes the contract's state
  async callMethod({
    contractId, method, args = {}, gas = THIRTY_TGAS, deposit = NO_DEPOSIT
  }) {
    // Sign a transaction with the "FunctionCall" action
    if (!this.interface) return;

    const tx = await this.interface.signAndSendTransaction({
      signerId: this.accountId,
      receiverId: contractId,
      actions: [
        {
          type: 'FunctionCall',
          params: {
            methodName: method,
            args,
            gas,
            deposit,
          },
        },
      ],
    });

    return this.getTransactionResult(tx.transaction.hash);
  }

  async checkAccountAvailable(accountId) {
    const provider = new providers.JsonRpcProvider({
      url: this.walletSelector.options.network.nodeUrl
    });
    return provider.query({
      request_type: "view_account",
      finality: 'optimistic',
      account_id: accountId,
    });
  }

  // Get transaction result from the network
  async getTransactionResult(txHash) {
    if (!this.walletSelector) return;

    const {network} = this.walletSelector.options;
    const provider = new providers.JsonRpcProvider({url: network.nodeUrl});

    // Retrieve transaction result from the network
    const transaction = await provider.txStatus(txHash, 'unnused');
    return providers.getTransactionLastResult(transaction);
  }

}
export const timeDiff = (countSec) => {
  let hours = Math.floor(((countSec % 31536000) % 86400) / 3600);
  let minutes = Math.floor((((countSec % 31536000) % 86400) % 3600) / 60);
  let seconds = Math.floor((((countSec % 31536000) % 86400) % 3600) % 60);
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return [hours, minutes, seconds];
}

export const secondsToString = (countSec) => {
  let [hours, minutes, seconds] = timeDiff(countSec);
  return hours + " hours " + minutes + " min " + seconds + " sec.";
};

export const secondsToStringShort = (countSec) => {
  let [hours, minutes, seconds] = timeDiff(countSec);
  return hours + ":" + minutes + ":" + seconds;
};

export const diffDays = (lastClaimTime) => {
  const timeNow = new Date().getTime();
  const oneDay = 24 * 60 * 60 * 1000;
  const diff = lastClaimTime - timeNow;
  return parseInt(diff / oneDay);
}

// time diff for seconds
export const timeDiffSeconds = (lastClaimTime, diffHours = 24) => {
  const timeNow = new Date().getTime();
  const oneDay = diffHours * 60 * 60 * 1000;
  const diff = timeNow - lastClaimTime;
  return (oneDay - diff) / 1000;
};

import React, { useContext, useEffect, useState } from "react";
import { Container, Link, Row } from "../assets/styles/common.style";
import { convertFromYocto, formatNumber } from "../utils/utils";
import { Button } from "../ui";
import { NavLinks } from "./header/NavLinks";
import { MobileNavLinks } from "./header/MobileNavLinks";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { animateScroll } from "react-scroll";
import { LogoutIcon, FireIcon } from "@heroicons/react/solid";
import { DocumentDuplicateIcon, CheckIcon } from "@heroicons/react/outline";
import { NearContext } from "../contexts/NearWallet";
import {
  updateUserBalance,
  updateUserZmlReserve,
} from "../services/UserService";
import { useDispatch, useSelector } from "react-redux";

import logo from "../assets/images/logo.png";
import { UserProfile } from "./Avatar";
import { SelectNetwork } from "./SelectNetwork";
import { useSearchParams } from "react-router-dom";
import { get } from "../utils/api";

export const Header = () => {
  const dispatch = useDispatch();
  const { isSigned, currentUser, wallet, ftContract, socialContract } =
    useContext(NearContext);
  const balance = useSelector((state) => state.user.balance);
  const rating = useSelector((state) => state.user.rating);
  const reservedZmlBalance = useSelector((state) => state.user.reservedBalance);
  const [scroll, setScroll] = useState(false);
  const [showWallet, setShowWallet] = useState(false);
  const [isMobileOpened, setIsMobileOpened] = useState(false);
  const [isClipboardCopied, setIsClipboardCopied] = useState();
  const [profile, setProfile] = useState({});
  const [networkPopupVisible, setNetworkPopupVisible] = useState(false);
  const [token, setToken] = useState();
  const [searchParams, _setSearchfParams] = useSearchParams();

  const mobileMenuButton = (
    <>
      <button
        className="navbar-burger flex items-center text-sky-100 p-2"
        onClick={() => {
          setIsMobileOpened(!isMobileOpened);
          setShowWallet(false);
        }}
      >
        {isMobileOpened ? (
          <XIcon className="w-7 h-7" />
        ) : (
          <MenuIcon className="w-7 h-7" />
        )}
      </button>
      <div
        className={`absolute right-0 top-12 bg-sky-100/95 text-gray-800 px-6 py-2 w-72 rounded-lg ${
          isMobileOpened ? "" : "hidden"
        }`}
        id="mobile-menu"
      >
        <div className="w-full">
          <MobileNavLinks
            setIsMobileOpened={setIsMobileOpened}
            onClickOutside={() => setIsMobileOpened(false)}
            login={() => wallet.signIn()}
            logout={() => wallet.signOut()}
          />
        </div>
      </div>
    </>
  );

  const loadNSInfo = async () => {
    const ns = await socialContract.get([`${currentUser}/profile/**`]);
    if (ns) setProfile({ [currentUser]: ns[currentUser] });
  };

  const getZMLPrice = async () => {
    const resp = await fetch(
      "https://api.dexscreener.com/latest/dex/tokens/ft.zomland.near"
    );
    const token = await resp.json();

    if (token && token?.pairs) setToken(token?.pairs[0]);
  };

  async function processRefferal() {
    const ref = searchParams.get("ref");
    const params = ref
      ? { accountId: currentUser, ref }
      : { accountId: currentUser };

    const response = await get("api/users/refferals", params);

    if (response.data) {
      const refferalLink = `https://zomland.com?ref=${response.data.refferals[0]?.uid}`;
      copyToClipboard(refferalLink);
    }
  }

  useEffect(() => {
    // Change header bg on scroll
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 60);
    });
    loadNSInfo();
    getZMLPrice();
  }, []);

  const toggleHome = () => animateScroll.scrollToTop();

  const withdrawReserved = async () => {
    ftContract.withdrawZmlReserve().then(() => {
      updateUserZmlReserve(dispatch, ftContract, currentUser);
      updateUserBalance(dispatch, ftContract, currentUser);
    });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setIsClipboardCopied(text);

    setTimeout(() => {
      setIsClipboardCopied();
    }, 3000);
  };

  const TokenPrice = () => (
    <a
      href={"https://app.ref.finance/swap/#near|ft.zomland.near"}
      target={"_blank"}
      className={"font-semibold"}
    >
      1 ZML:
      <span className="text-green-500 ml-1">
        {"$"}
        {token?.priceUsd}
      </span>
      <span className="ml-2 inline-block cursor-pointer hover:text-amber-600 text-xs lg:px-2 px-1 lg:py-0.5 py-0.5 border-2 rounded-lg border-amber-600 hover:border-amber-700">
        BUY
      </span>
    </a>
  );

  return (
    <>
      <Row className="sticky top-0 z-50 p-3 md:hidden flex justify-center items-center bg-main">
        <TokenPrice />
      </Row>
      <div
        className={`sticky md:top-0 top-7 z-40 py-5 transition ease-in-out duration-300 ${
          scroll ? "bg-main/95" : ""
        }`}
      >
        <Container>
          <Row className="justify-between items-center">
            <Link
              to="/"
              className="flex flex-row hover:text-indigo-50"
              onClick={toggleHome}
            >
              <img
                src={logo}
                alt="logo"
                width="40"
                className="basis-1/4 sm:max-w-[40px] max-w-[30px]"
              />
              <span
                className={`md:flex hidden relative zombie-font ml-4 sm:text-4xl text-2xl items-center font-normal hover:text-indigo-50`}
              >
                <span>ZOM</span>
                <span className="text-amber-500">LAND</span>
                {process.env.IS_PROD != "true" && (
                  <span className="text-white bg-amber-700 text-xs px-2 py-0.5 rounded-md exo-font font-semibold absolute top-[-13px] right-0">
                    testnet
                  </span>
                )}
              </span>
            </Link>
            <div
              className={`uppercase xl:ml-[1%] 2xl:ml-[3%] ml-4 hidden xl:flex whitespace-nowrap`}
            >
              <NavLinks />
            </div>
            <div className="md:flex hidden">
              <TokenPrice />
            </div>
            {isSigned ? (
              <div className="relative">
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => setShowWallet(!showWallet)}
                >
                  <div className="w-48 mr-3">
                    <div className="text-right truncate text-lg font-semibold">
                      {profile[currentUser]?.profile?.name ?? currentUser}
                    </div>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3"
                      />
                    </svg>
                  </div>
                </div>

                {showWallet && (
                  <div className="absolute rounded-lg w-72 top-8 shadow-xl right-0 border border-violet-100/10 bg-main p-7">
                    <div className="flex flex-col gap-y-5 text-base">
                      <div className={"flex flex-row text-sm font-semibold"}>
                        <a
                          href={`https://near.org/near/widget/ProfilePage?accountId=${currentUser}`}
                          target={"_blank"}
                          rel={"noreferrer"}
                        >
                          <UserProfile
                            profile={profile}
                            accountId={currentUser}
                            size="lg"
                          />
                        </a>

                        {isClipboardCopied === currentUser ? (
                          <CheckIcon className={"w-5 h-5 mt-2 opacity-70"} />
                        ) : (
                          <DocumentDuplicateIcon
                            onClick={() => copyToClipboard(currentUser)}
                            className={
                              "w-5 h-5 mt-2 opacity-70 transition hover:opacity-100 cursor-pointer"
                            }
                          />
                        )}
                      </div>
                      <div className="p-4 flex flex-col gap-2 rounded-md bg-mainLight/80">
                        <Link to="/leaderboard">
                          <div className="flex flex-row items-center justify-between">
                            <span
                              className="uppercase text-sm tracking-wider"
                              style={{ fontSize: "12px" }}
                            >
                              Activity Points
                            </span>
                            <div className="flex flex-row items-center text-sky-500">
                              <FireIcon className="w-4 h-4 mr-1 text-sky-500" />
                              <span>{rating < 0 ? 0 : rating}</span>
                            </div>
                          </div>
                        </Link>
                        <Link to="/token">
                          <div className="flex flex-row items-center justify-between">
                            <span
                              className="uppercase text-sm tracking-wider"
                              style={{ fontSize: "12px" }}
                            >
                              ZML Balance
                            </span>
                            <div className="flex flex-row items-center text-amber-500">
                              <span>
                                {formatNumber(convertFromYocto(balance, 2))}
                              </span>
                            </div>
                          </div>
                        </Link>
                        {/*<div className="flex flex-row items-center justify-between font-bold">*/}
                        {/*    <span className="uppercase text-sm tracking-wider" style={{fontSize: "12px"}}>*/}
                        {/*      NEAR Balance*/}
                        {/*    </span>*/}
                        {/*  <div className="flex flex-row items-center text-green-500">*/}
                        {/*    <span>0 NEAR</span>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        {reservedZmlBalance > 0 && (
                          <div className="flex flex-row items-center justify-between font-semibold">
                            <span className="uppercase text-sm tracking-wider ">
                              Reserved ZML
                            </span>
                            <div className="flex flex-row items-center text-amber-500">
                              <Button
                                title={`withdraw ZML`}
                                secondary
                                size="xxs"
                                noIcon
                                className="link ml-2 cursor-pointer"
                                onClick={() => withdrawReserved()}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="flex flex-row justify-end items-center font-semibold">
                        <span className="uppercase text-sm tracking-wider">
                          Copy Referral link
                        </span>
                        {isClipboardCopied &&
                        isClipboardCopied !== currentUser ? (
                          <CheckIcon className={"w-5 h-5 ml-2 opacity-70"} />
                        ) : (
                          <DocumentDuplicateIcon
                            onClick={processRefferal}
                            className={
                              "w-5 h-5 ml-2 opacity-70 transition hover:opacity-100 cursor-pointer"
                            }
                          />
                        )}
                      </div>
                      <div className="w-full flex justify-end">
                        <Button
                          secondary
                          size={"sm"}
                          icon={<LogoutIcon className="h-5 w-5 ml-2" />}
                          title="Log Out"
                          onClick={() => wallet.signOut()}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <Button
                secondary
                title="Log In"
                onClick={() => setNetworkPopupVisible(true)}
              />
            )}
            <div className="flex w-12 mt-1 xl:ml-4 xl:hidden relative">
              {mobileMenuButton}
            </div>
          </Row>
        </Container>

        <SelectNetwork
          networkPopupVisible={networkPopupVisible}
          setNetworkPopupVisible={setNetworkPopupVisible}
        />
      </div>
    </>
  );
};

const { groupElementsByKey } = require("./utils");

const splitHalf = (items) => {
  if (items.length <= 1) return [[], []];

  const itemsAverageDurability =
    items
      .map((item) => item.durabilityMin)
      .reduce((prev, next) => prev + next, 0) / items.length;

  items = items.map((item) => {
    item.durabilityMin = parseInt(itemsAverageDurability);
    return item;
  });

  const half = Math.ceil(items.length / 2);
  const firstHalf = items.slice(0, half);
  const secondHalf = items.slice(half);

  return [firstHalf, secondHalf];
};

const getUpgradableItems = (items, level) => {
  let upgradableItems = new Set();
  let result = {
    upgradable: [],
    kill: [],
  };
  const levelItems = items.filter((item) => item.level === level);
  const upgradableItemTypes = levelItems
    .filter(
      (item) => upgradableItems.size === upgradableItems.add(item.item_type).size
    )
    .map((item) => item.item_type);

  const duplicates = levelItems
    .filter((item) => item.level < 3)
    .filter((item) => upgradableItemTypes.includes(item.item_type));

  const groupedItems = groupElementsByKey(duplicates, "item_type");
  Object.entries(groupedItems).forEach(([_type, items]) => {
    const pairItems = items.slice(0, items.length - (items.length % 2));
    const [upgradable, kill] = splitHalf(pairItems);

    result.upgradable = [...result.upgradable, ...upgradable];
    result.kill = [...result.kill, ...kill];
  });

  return result;
};

module.exports = {
  getUpgradableItems,
};

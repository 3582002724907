import { convertToTera, convertToYocto } from "../utils/utils";

export const  RouletteService = (amount, ftContract, wallet, callbackUrl) => {
  const txs = [
    {
      receiverId: ftContract.contractId,
      actions: [
        {
          type: "FunctionCall",
          params: {
            methodName: "ft_transfer",
            args: {
              receiver_id: ftContract.contractId,
              amount: convertToYocto(amount),
            },
            gas: convertToTera("50"),
            deposit: "1",
          },
        },
      ],
    },
  ];

  return wallet.interface.signAndSendTransactions({
    transactions: txs,
    callbackUrl: callbackUrl,
  });
};

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  InnerPageWrapper,
  Spin,
  Wrapper,
} from "../../assets/styles/common.style";
import { Unity, useUnityContext } from "react-unity-webgl";
import { useNavigate, useParams } from "react-router-dom";
import io from "socket.io-client";
import { get, post } from "../../utils/api";
import { NearContext } from "../../contexts/NearWallet";
import {
  triggerPlayerAction,
  triggerGameState,
  triggerBeforePlayerAction,
  triggerLeaveBattle,
  checkGameOver,
} from "../../services/BattleArenaService";
import { BoltIcon, Button } from "../../ui";
import { CogIcon, DesktopComputerIcon } from "@heroicons/react/solid";
import battle from "../../assets/images/battle.png";

const socketUrl = process.env.API_URL;

const resolutionMap = ["w-1/2 h-1/2", "w-2/3 h-2/3", "w-full h-full"];

export const ArenaBoard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const socket = useRef(null);
  const { currentUser } = useContext(NearContext);
  const [initData, setInitData] = useState();
  const [gameData, setGameData] = useState();
  const [showBoard, setShowBoard] = useState(false);
  const [refresh, setRefresh] = useState();
  const [waiting, setWaiting] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);
  const [battleResults, setBattleResults] = useState();
  const [resolution, setResolution] = useState(2);

  const {
    unityProvider,
    sendMessage,
    addEventListener,
    removeEventListener,
    isLoaded,
    loadingProgression,
  } = useUnityContext({
    loaderUrl: "/unity-build/app.loader.js",
    dataUrl: "/unity-build/app.data",
    frameworkUrl: "/unity-build/app.framework.js",
    codeUrl: "/unity-build/app.wasm",
  });

  const initBattleState = async () => {
    const resp = await get(`api/battle-arena/${id}`, { userId: currentUser });
    const respData = resp.data;

    if (respData) {
      if (respData.gameData) {
        const isGameOver = await checkGameOver(
          sendMessage,
          id,
          respData.gameData
        );

        if (!isGameOver) setInitData(respData.gameData);
        setIsGameOver(isGameOver);
      } else setWaiting(respData.waiting);
    }
  };

  const syncBattleState = async () => {
    const resp = await get(`api/battle-arena/${id}`, { userId: currentUser });
    const respData = resp.data;

    if (respData) {
      if (respData.gameData) {
        setGameData(respData.gameData);
        const isGameOver = await checkGameOver(
          sendMessage,
          id,
          respData.gameData
        );
        setIsGameOver(isGameOver);
      } else setWaiting(respData.waiting);
    }
  };

  const getGameOverBattle = async () => {
    const resp = await get(`api/battle-arena/${id}/results`);
    const respData = resp.data;

    if (respData) setBattleResults(respData);
  };

  useEffect(() => {
    socket.current = io(socketUrl);
  }, [socketUrl]);

  // Socket global effects
  useEffect(() => {
    socket.current.on(`init-state`, () => {
      setShowBoard(true);
      initBattleState();
    });
    socket.current.on(`sync-state`, () => syncBattleState());

    return () => {
      socket.current.off(`init-state`);
      socket.current.off(`sync-state`);
    };
  }, []);

  useEffect(() => {
    if (isGameOver) getGameOverBattle();
  }, [isGameOver]);

  // Socket game effects
  useEffect(() => {
    socket.current.on(`player-action`, (params) => {
      triggerPlayerAction(sendMessage, params);
    });

    return () => {
      socket.current.off(`player-action`);
    };
  }, [isLoaded]);

  window.onpopstate = function () {
    history.go(1);
  };
  // Game controll effects
  useEffect(() => {
    history.pushState(null, null, location.href);

    if (id) {
      setShowBoard(true);
      initBattleState();
    }
  }, [id, showBoard]);

  useEffect(() => {
    if (isLoaded) {
      // we need 3 sec to sync timer after initial booting animation
      setTimeout(() => {
        socket.current.emit("init-state", id);
      }, 3000);
    }
  }, [id && isLoaded]);

  useEffect(() => {
    if (refresh) socket.current.emit("sync-state", id);
  }, [refresh]);

  useEffect(() => {
    initData && !isGameOver && triggerGameState(sendMessage, initData);
  }, [initData]);

  useEffect(() => {
    gameData && triggerBeforePlayerAction(sendMessage, gameData);
  }, [gameData]);

  const loadingPercentage = Math.round(loadingProgression * 100);

  // List of Battle Arena actions

  const handleLeaveBattle = useCallback(
    async (callBack) => {
      let message = "Are you sure you want to leave?";

      if (initData) {
        message += "You will lose this battle.";

        if (
          ![
            initData.playerName0Original,
            initData.playerName1Original,
          ].includes(currentUser) ||
          !isLoaded ||
          !confirm(message)
        )
          return;

        await post(`api/battle-arena/${id}/leave`, {
          userId: currentUser,
          endType: 2,
        });
        await triggerLeaveBattle(sendMessage, callBack);

        socket.current.emit("battle-leave", id);
        setRefresh(new Date());
      }
    },
    [isLoaded]
  );

  const handleBeforePlayerAction = useCallback(async (callBack) => {
    console.log("<--- before player action");
    const isGameOver = checkGameOver(sendMessage, id, initData);
    setIsGameOver(isGameOver);
    setRefresh(new Date());
  }, []);

  const handleChangeTurn = useCallback(async (callBack) => {
    console.log("<--- change turn");
    await post(`api/battle-arena/${id}/make-action`, { id, turn: false });

    socket.current.emit("sync-state", id); // TODO: do we really need it?

    setRefresh(new Date());
  }, []);

  const handlePlayerAction = useCallback(async (callBack, params) => {
    console.log("<--- player action");
    const { actionType, unitId, inventoryId, coords, targetUnitIds } =
      JSON.parse(params);

    const resp = await post(`api/battle-arena/${id}/make-action`, {
      id,
      unitId,
      coords,
      inventoryId,
      userId: currentUser,
      actionType,
      targetUnitIds: [...new Set(targetUnitIds)],
    });

    if (resp.data) {
      socket.current.emit("player-action", id, { callBack, ...resp.data });
    }
  }, []);

  // Unity listener effect
  useEffect(() => {
    addEventListener("BeforePlayerAction", handleBeforePlayerAction);
    addEventListener("PlayerAction", handlePlayerAction);
    addEventListener("ChangeTurn", handleChangeTurn);
    addEventListener("LeaveBattle", handleLeaveBattle);

    return () => {
      removeEventListener("BeforePlayerAction", handleBeforePlayerAction);
      removeEventListener("PlayerAction", handlePlayerAction);
      removeEventListener("ChangeTurn", handleChangeTurn);
      removeEventListener("LeaveBattle", handleLeaveBattle);
    };
  }, [
    isLoaded,
    addEventListener,
    removeEventListener,
    handleBeforePlayerAction,
    handlePlayerAction,
    handleChangeTurn,
    handleLeaveBattle,
  ]);

  const Info = ({ children }) => (
    <div
      className="flex flex-col min-h-screen justify-between"
      style={{ background: `url(${battle}) repeat-y top/cover` }}
    >
      <Wrapper>
        <div className="flex items-center justify-center h-screen relative">
          <div className="flex justify-center items-center w-full h-full">
            <div className="rounded-xl md:w-3/4 lg:w-1/3 w-full text-center bg-main p-7 flex flex-col gap-5 justify-center items-center">
              {children}
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );

  if (waiting)
    return (
      <Info>
        <h3 className="text-xl font-bold">Waiting for opponent.</h3>
        <p>
          It will takes some time. You can go to arena list and see if someone
          joined
        </p>
        <Button
          title="Return to arena list"
          onClick={() => navigate("/arena")}
        />
      </Info>
    );

  if (battleResults)
    return (
      <Info>
        <h3 className="text-xl font-bold">Battle #{id}</h3>
        <div className="w-5/6 flex gap-3 items-center">
          <div className="flex flex-col w-full truncate rounded bg-mainLight p-3">
            <div className="flex gap-1 py-0.5 justify-center">
              <BoltIcon className="w-10 h-10" />
              {battleResults.battleArenaUsers[0].totalPoints}
            </div>
            <div className="truncate">
              <span>{battleResults.battleArenaUsers[0].userId}</span>
            </div>
          </div>
          <b>vs</b>
          <div className="flex flex-col w-full truncate rounded bg-mainLight p-3">
            <div className="flex gap-1 py-0.5 justify-center">
              <BoltIcon className="w-10 h-10" />
              {battleResults.battleArenaUsers[1].totalPoints}
            </div>
            <div className="truncate">
              <span>{battleResults.battleArenaUsers[1].userId}</span>
            </div>
          </div>
        </div>

        <div className="w-3/4 font-bold truncate">
          Winner:{" "}
          <span className="text-orange-500 font-semibold">
            {battleResults.winnerUserId}
          </span>
        </div>
        <Button
          title="Return to arena list"
          onClick={() => navigate("/arena")}
        />
      </Info>
    );

  return (
    <InnerPageWrapper>
      <Wrapper>
        {showBoard && (
          <>
            {initData && (
              <div className="flex items-center justify-center h-screen relative">
                <div
                  className={`relative flex justify-center items-center ${resolutionMap[resolution]}`}
                >
                  {!isLoaded ? (
                    <div className="absolute z-10 flex justify-center items-center flex-col gap-5">
                      <Spin>
                        <CogIcon />
                      </Spin>
                      <p className="text-2xl">{loadingPercentage}%</p>
                    </div>
                  ) : (
                    <div
                      className={`absolute z-10 ${
                        resolution === 2 ? "top-0" : "-top-6"
                      } right-0`}
                    >
                      <DesktopComputerIcon
                        className="h-6 w-6 fill-white/30 hover:fill-white/70"
                        onClick={() =>
                          setResolution(
                            resolution === resolutionMap.length - 1
                              ? 0
                              : resolution + 1
                          )
                        }
                      />
                    </div>
                  )}
                  <Unity
                    unityProvider={unityProvider}
                    style={{ width: "100%", zIndex: 0 }}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </Wrapper>
    </InnerPageWrapper>
  );
};

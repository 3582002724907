import React from "react";
import { CardRotate } from "../index";

export const PartOfMonster = ({item, handleSelect, isSelected, rmFromMarket, handleBuy, noFlip}) => {
  return (
    <>
      {item && (
        <CardRotate
          nft={item}
          noFlip={noFlip}
          size="square"
          height="h-[14.65rem]"
          handleSelect={handleSelect}
          isSelected={isSelected}
          rmFromMarket={() => rmFromMarket(item)}
          handleBuy={handleBuy}
        />
      )}
    </>
  );
};

import React from "react";
import mapPositionImage from "../../assets/images/map-position.png";

export const HexObject = ({
  className,
  style,
  hexSize,
  rowIndex,
  colIndex,
  handleClick,
  selectedPosition,
  value,
}) => {

  return (
    <div
      className={`hex ${className || ""} ${selectedPosition.row === rowIndex && selectedPosition.col === colIndex ? "selected" : ""}`}
      onClick={() => handleClick(rowIndex, colIndex, value)}
      style={{
        boxSizing: "border-box",
        height: hexSize + "px",
        width: hexSize + "px",
        ...style,
        position: "relative",
        borderRadius: "100%",
      }}
    >
      {value !== 0 ? (
        <div className={"relative z-10 pointer-events-none"}>
          <img src={mapPositionImage} alt="" className={`w-12 mx-auto -mt-[8px]`} />
          <span className={"absolute top-2.5 left-0 right-0 z-10 text-center text-center text-xl text-gray-600 font-semibold"}>
            {value}
          </span>
        </div>
      ) : ""}

      <p
        style={{
          borderTop: "1px solid #000",
          borderBottom: "1px solid #000",
          boxSizing: "border-box",
          width: hexSize / Math.sqrt(3) + "px",
          height: "100%",
          margin: "0 auto",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          transform: "rotate(90deg)",
        }}
      />
      <p
        style={{
          borderTop: "1px solid #000",
          borderBottom: "1px solid #000",
          boxSizing: "border-box",
          width: hexSize / Math.sqrt(3) + "px",
          height: "100%",
          margin: "0 auto",
          transform: "rotate(150deg)",
          transformOrigin: "center center",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
        }}
      />
      <p
        style={{
          borderTop: "1px solid #000",
          borderBottom: "1px solid #000",
          boxSizing: "border-box",
          width: hexSize / Math.sqrt(3) + "px",
          height: "100%",
          margin: "0 auto",
          transform: "rotate(210deg)",
          transformOrigin: "center center",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
        }}
      />
    </div>
  );
};
import React from "react";
import near_logo from "../assets/images/near-logo.png";

export const Price = ({ title, size, handleBuy, token, className }) => {
  return (
    <div
      className={`${className} flex items-center font-semibold py-1 px-2 bg-main/50 rounded-md
      ${size === "sm" ? "text-sm" : ""} ${
        handleBuy ? "text-amber-600" : "text-[#8f8f8f]"
      } font-${size || "base"}`}
    >
      {title && (
        <>
          {title}
          {token ? (
            <span className="ml-1">{token}</span>
          ) : (
            <img
              className={`${
                size !== "md" ? "h-3 w-3 font-sm" : "h-10 w-10 font-md"
              } ${handleBuy ? "" : "grayscale"} m-1`}
              src={near_logo}
              alt="near logo"
            />
          )}
        </>
      )}
    </div>
  );
};

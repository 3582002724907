import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { CurrencyDollarIcon } from "@heroicons/react/outline";
import { Container } from "../../assets/styles/common.style";
import { convertFromYocto, formatPrice } from "../../utils/utils";
import { NearContext } from "../../contexts/NearWallet";
import { handleBuyService, isNFT } from "../../services/MarketService";
import { post } from "../../utils/api";
import { Button } from "../../ui";
import Big from "big.js";

export const BuyItemsFooter = ({
  selectedItems,
  handleSuccessBuy,
  setSelectedItems,
  allItems,
  selectToBuy,
}) => {
  const { wallet, mainContract, ftContract, currentUser } =
    useContext(NearContext);
  const balance = useSelector((state) => state.user.balance);
  const [totalItems, setTotalItems] = useState(0);
  const [totalNEAR, setTotalNEAR] = useState(0);
  const [totalZML, setTotalZML] = useState(0);

  useEffect(() => {
    let totalItems = 0;
    let totalNEAR = Big(0);
    let totalZML = Big(0);

    Object.keys(selectedItems).map((itemType) => {
      if (selectedItems[itemType].length > 0) {
        selectedItems[itemType].map((item) => {
          totalItems++;
          if (isNFT(itemType)) {
            totalNEAR = totalNEAR.plus(Big(item.price));
          } else {
            totalZML = totalZML.plus(Big(item.price));
          }
        });
      }
    });

    setTotalItems(totalItems);

    setTotalNEAR(formatPrice(totalNEAR.toString()));
    setTotalZML(formatPrice(totalZML.toString()));
  }, [selectedItems]);

  const selectAll = () => {
    const newSelectItems = [];
    const itemType = allItems[0].nft_type;

    allItems.map((item) => {
      let addNew = true;
      selectedItems[itemType].map((selectedItem) => {
        if (
          selectedItem.token_id === item.token_id &&
          selectedItem.owner_id !== currentUser
        ) {
          addNew = false;
        }
      });
      if (addNew) {
        newSelectItems.push(item);
      }
    });

    if (newSelectItems.length) {
      selectToBuy(newSelectItems);
    }
  };

  const deselectAll = () => {
    setSelectedItems({
      Zombie: [],
      Monster: [],
      MonsterPart: [],
      Inventory: [],
      Modifier: [],
    });
  };

  const buttonText = () => {
    let result = "for ";
    if (totalNEAR > 0) {
      result += `${totalNEAR} NEAR`;
    }
    if (totalNEAR > 0 && totalZML > 0) {
      result += ` + `;
    }
    if (totalZML > 0) {
      result += `${parseInt(totalZML)} ZML`;
    }
    return result;
  };

  const handleBuy = async () => {
    if (parseFloat(convertFromYocto(balance)) < totalZML) {
      alert("Not enough ZML balance");
      return;
    }

    await handleBuyService(
      selectedItems,
      wallet,
      mainContract,
      ftContract
    ).then((totalNear, totalReserveZML) => {
      post("api/stats/volume/update", {
        totalNear: convertFromYocto(totalNear),
        totalZml: convertFromYocto(totalReserveZML),
      });
    });

    handleSuccessBuy();
  };

  return (
    <>
      <div
        className={`bottom-0 right-0 left-0 fixed h-24 px-2 md:py-6 py-2 bg-gray-800 z-30 shadow-3xl border-t-[4px] border-gray-700`}
      >
        <Container className="flex justify-between">
          <div className="text-sm">
            <div className="hidden md:block">
              <div>
                <b>Selected: </b>
                {selectedItems["Zombie"].length > 0 && (
                  <span className="mr-3">
                    {selectedItems["Zombie"].length} Zombie
                    {selectedItems["Zombie"].length > 1 ? "s" : ""}
                  </span>
                )}
                {selectedItems["Monster"].length > 0 && (
                  <span className="mr-3">
                    {selectedItems["Monster"].length} Monster
                    {selectedItems["Monster"].length > 1 ? "s" : ""}
                  </span>
                )}
                {selectedItems["MonsterPart"].length > 0 && (
                  <span className="mr-3">
                    {selectedItems["MonsterPart"].length} Monster Part
                    {selectedItems["MonsterPart"].length > 1 ? "s" : ""}
                  </span>
                )}
                {selectedItems["Inventory"].length > 0 && (
                  <span className="mr-3">
                    {selectedItems["Inventory"].length} Inventor
                    {selectedItems["Inventory"].length > 1 ? "ies" : "y"}
                  </span>
                )}
                {selectedItems["Modifier"].length > 0 && (
                  <span className="mr-3">
                    {selectedItems["Modifier"].length} Modifier
                    {selectedItems["Modifier"].length > 1 ? "s" : ""}
                  </span>
                )}
              </div>

              <div>
                <b
                  onClick={() => selectAll()}
                  className="dashed hover:underline cursor-pointer mt-1 inline-block text-amber-500"
                >
                  Select All
                </b>
                <b
                  onClick={() => deselectAll()}
                  className="md:ml-5 dashed hover:underline cursor-pointer mt-1 inline-block text-amber-500"
                >
                  Deselect All
                </b>
              </div>
            </div>
          </div>

          <div className="text-right">
            <Button
              className="ml-4 min-w-[90px]"
              title={`Buy ${buttonText()}`}
              size={"sm"}
              icon={<CurrencyDollarIcon className="h-5 w-5 ml-2" />}
              onClick={() => handleBuy()}
            />
          </div>
        </Container>
      </div>
    </>
  );
};

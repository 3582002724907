import { getUpgradableItems } from "../../server/api/helpers/items";
import { get } from "../utils/api";
import { convertToTera, convertToYocto } from "../utils/utils";

export const upgradeItemsService = async (
  ids,
  upgradeCost,
  wallet,
  mainContract,
  ftContract,
  callbackUrl
) => {
  const getItemId = (item) => item.itemId;

  const itemsResp = await get("api/items", { ids: ids });
  const items = itemsResp.data;
  const firstLvlIdsKill = getUpgradableItems(items, 1).kill.map(getItemId);
  const secondLvlIdsKill = getUpgradableItems(items, 2).kill.map(getItemId);

  const txs = [
    {
      receiverId: ftContract.contractId,
      actions: [
        {
          type: "FunctionCall",
          params: {
            methodName: "ft_transfer",
            args: {
              receiver_id: ftContract.contractId,
              amount: convertToYocto(upgradeCost),
            },
            gas: convertToTera("20"),
            deposit: "1",
          },
        },
      ],
    },
  ];

  txs.push({
    receiverId: mainContract.contractId,
    actions: [
      {
        type: "FunctionCall",
        params: {
          methodName: "remove_inventory",
          args: {
            id_list: [...firstLvlIdsKill, ...secondLvlIdsKill],
          },
          gas: convertToTera("280"),
          deposit: "1",
        },
      },
    ],
  });

  return wallet.interface.signAndSendTransactions({
    transactions: txs,
    callbackUrl: callbackUrl,
  });
};

import {
  landResetPriceZML,
  landsConfig,
  PARAS_GAS_FEE,
  PARAS_GAS_FEE_SELL,
  PARAS_STORAGE_ADD_MARKET_FEE,
  PARAS_STORAGE_APPROVE_FEE
} from "../utils/config";
import { convertFromNanoSeconds, convertToTera, convertToYocto } from "../utils/utils";

export const handleSellService = async (
  wallet, needDeposit, currentUser, token_id, price
) => {
  let txs = [];

  if (needDeposit) {
    txs.push({
      receiverId: process.env.PARAS_MARKET_CONTRACT,
      actions: [
        {
          type: "FunctionCall",
          params: {
            methodName: "storage_deposit",
            args: {
              receiver_id: currentUser
            },
            gas: PARAS_GAS_FEE,
            deposit: PARAS_STORAGE_ADD_MARKET_FEE,
          },
        },
      ],
    });
  }

  txs.push({
    receiverId: process.env.PARAS_TOKEN_CONTRACT,
    actions: [
      {
        type: "FunctionCall",
        params: {
          methodName: "nft_approve",
          args: {
            token_id,
            account_id: process.env.PARAS_MARKET_CONTRACT,
            msg: JSON.stringify({
              price: convertToYocto(price),
              market_type: 'sale',
              ft_token_id: `near`,
            }),
          },
          gas: PARAS_GAS_FEE_SELL,
          deposit: PARAS_STORAGE_APPROVE_FEE,
        },
      },
    ],
  });

  return wallet.interface.signAndSendTransactions({transactions: txs});
}


export const handleRestoreLandService = async (
  wallet, ftContract, mainContract, currentUser, tokenId, price
) => {

  let txs = [];
  if (price > 0) {
    txs.push({
      receiverId: ftContract.contractId,
      actions: [
        {
          type: "FunctionCall",
          params: {
            methodName: "ft_transfer_call",
            args: {
              receiver_id: ftContract.contractId,
              amount: convertToYocto(price),
              msg: "ft_add_zml_reserve",
            },
            gas: convertToTera("90"),
            deposit: "1",
          },
        },
      ],
    });

    txs.push({
      receiverId: mainContract.contractId,
      actions: [
        {
          type: "FunctionCall",
          params: {
            methodName: "reset_land_resource",
            args: {
              land_id: tokenId,
            },
            gas: convertToTera("270"),
          },
        },
      ],
    });

    return wallet.interface.signAndSendTransactions({transactions: txs});
  }
}

export const getUserLands = async (accountId, mainContract, parasContract) => {
  const parasLandsById = {};
  const landsGameData = {};
  const lands = [];

  const landParasSeries = await mainContract.getLandParasSeries();
  const userLandsParasObj = await parasContract.nftTokensForOwner(accountId);

  const userLandsParas = userLandsParasObj
    .filter((nft) => {
      let series = parseInt(nft.token_id.split(":")[0]);
      return landParasSeries.includes(series);
    })
    .map((land) => {
      parasLandsById[land.token_id] = land;
      return land;
    });

  const landsGameInfo = await mainContract.userLandsInfo(Object.keys(parasLandsById), accountId);

  landsGameInfo.map((land) => {
    landsGameData[land.token_id] = land;
    if (land.land_type === "Micro") {
      lands.push({
        token_id: land.token_id,
        land_type: land.land_type,
        media: landsConfig["Micro"].media,
        nft_type: "Land",
        need_import: false,
        last_zombie_claim: convertFromNanoSeconds(land.last_zombie_claim),
        discover_events: land.discover_events,
        count_minted_zombies: land.count_minted_zombies,
      });
    }
  });

  userLandsParas.map((land) => {
    let gameDataExists = Object.keys(landsGameData).includes(land.token_id);
    let landType = land.metadata.title.split(" ")[0];

    lands.push({
      token_id: land.token_id,
      land_type: landType,
      media: land.metadata.media,
      nft_type: "Land",
      need_import: !gameDataExists,
      last_zombie_claim: gameDataExists
        ? convertFromNanoSeconds(landsGameData[land.token_id].last_zombie_claim)
        : "",
      discover_events: gameDataExists
        ? landsGameData[land.token_id].discover_events
        : "",
      count_minted_zombies: landsGameData[land.token_id]?.count_minted_zombies,
    });
  });

  return lands;
};
import { XIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export const EventNotifications = ({
  keyStore,
  endDate,
  url,
  description,
  title,
  reward,
}) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let isEventHidden = localStorage.getItem(keyStore);
    if (!isEventHidden) {
      const currentDate = new Date();
      const eventDate = new Date(endDate);
      if (currentDate < eventDate) setVisible(true);
    }
  }, []);

  const openEventPage = (e) => {
    navigate(url);
    closeEventInfo(e);
  };

  const closeEventInfo = (e) => {
    e.stopPropagation();
    localStorage.setItem(keyStore, "true");
    setVisible(false);
  };

  return (
    <>
      {visible && (
        <>
          <div
            onClick={openEventPage}
            className="bg-amber-700/95 border-2 border-amber-600/30 text-white rounded-md px-6 py-4 mb-4 w-[400px] shadow-2xl
                 text-sm relative hover:border-amber-600 cursor-pointer transition"
          >
            <div className="absolute z-10 -top-3 -left-4">
              <div className="animate-ping absolute z-10 top-[2px] left-[1px] inline-flex h-7 w-7 rounded-full bg-amber-600 opacity-75" />
              <div className="absolute z-20 flex justify-center items-center font-bold h-8 w-8 rounded-full text-white bg-amber-600">
                !
              </div>
            </div>

            <div className="flex justify-between items-center">
              <div className="flex gap-4 items-center">
                <div className={"text-lg font-semibold uppercase"}>
                  EVENT: {title}
                </div>
              </div>

              <div className="bg-green-600 font-semibold rounded-md py-0.5 text-base px-2 -mt-1">
                $ {reward}
              </div>

              <XIcon
                className="h-5 w-5 hover:opacity-70 transition duration-200"
                onClick={(e) => closeEventInfo(e)}
              />
            </div>
            <p className="mt-3 mb-1">{description}</p>
          </div>
        </>
      )}
    </>
  );
};

import React from "react";
import { CardItem, CardRotate } from "../../ui";

export const OneItemPosition = ({ nft, index, inventory }) => {
  return (
    <div className={"relative"} style={{ zoom: 0.75 }}>
      <div className={`w-14 h-14 font-semibold rounded-full pt-2 border-[5px] border-gray-500
      absolute -right-6 -top-3 z-20 text-center text-2xl text-gray-600 bg-white`}>
        {index + 1}
      </div>

      <CardRotate nft={nft} size={"sm"} />

      {inventory.length > 0 && (
        <div className={`absolute bottom-3 gap-y-2 flex flex-col -right-9 z-10`} style={{ zoom: 0.7 }}>
          {inventory.map((item, index) => {
            return item ? <CardItem
              key={index}
              item={item}
              noFlip
              noText
              size="xsm"
            /> : ""
          })}
        </div>
      )}

    </div>
  )
}

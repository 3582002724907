import { useCountdown } from "../hooks/useCountdown";

export const Countdown = ({ endDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(endDate);

  const NumbersSection = ({ number, label }) => (
    <div className="flex items-center justify-center p-3 text-center bg-mainLight rounded-xl w-24">
      <div>
        <div className="text-amber-600 font-semibold mb-2 text-4xl">
          {number}
        </div>
        <div>{label}</div>
      </div>
    </div>
  );

  if (hours + minutes + seconds <= 0) {
    return <></>;
  } else {
    return (
      <div className="flex flex-row gap-3 justify-center items-center">
        <NumbersSection label="days" number={days} />
        <NumbersSection label="hours" number={hours} />
        <NumbersSection label="minutes" number={minutes} />
        <NumbersSection label="seconds" number={seconds} />
      </div>
    );
  }
};

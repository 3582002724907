"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultisigStateStatus = exports.MultisigDeleteRequestRejectionError = exports.MULTISIG_CONFIRM_METHODS = exports.MULTISIG_CHANGE_METHODS = exports.MULTISIG_DEPOSIT = exports.MULTISIG_GAS = exports.MULTISIG_ALLOWANCE = exports.MULTISIG_STORAGE_KEY = exports.AccountMultisig = exports.Account2FA = void 0;
var accounts_1 = require("@near-js/accounts");
Object.defineProperty(exports, "Account2FA", { enumerable: true, get: function () { return accounts_1.Account2FA; } });
Object.defineProperty(exports, "AccountMultisig", { enumerable: true, get: function () { return accounts_1.AccountMultisig; } });
Object.defineProperty(exports, "MULTISIG_STORAGE_KEY", { enumerable: true, get: function () { return accounts_1.MULTISIG_STORAGE_KEY; } });
Object.defineProperty(exports, "MULTISIG_ALLOWANCE", { enumerable: true, get: function () { return accounts_1.MULTISIG_ALLOWANCE; } });
Object.defineProperty(exports, "MULTISIG_GAS", { enumerable: true, get: function () { return accounts_1.MULTISIG_GAS; } });
Object.defineProperty(exports, "MULTISIG_DEPOSIT", { enumerable: true, get: function () { return accounts_1.MULTISIG_DEPOSIT; } });
Object.defineProperty(exports, "MULTISIG_CHANGE_METHODS", { enumerable: true, get: function () { return accounts_1.MULTISIG_CHANGE_METHODS; } });
Object.defineProperty(exports, "MULTISIG_CONFIRM_METHODS", { enumerable: true, get: function () { return accounts_1.MULTISIG_CONFIRM_METHODS; } });
Object.defineProperty(exports, "MultisigDeleteRequestRejectionError", { enumerable: true, get: function () { return accounts_1.MultisigDeleteRequestRejectionError; } });
Object.defineProperty(exports, "MultisigStateStatus", { enumerable: true, get: function () { return accounts_1.MultisigStateStatus; } });

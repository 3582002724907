import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Popup } from "../../ui";
import { NearContext } from "../../contexts/NearWallet";
import { SelectZombiesPopup } from "../SelectZombiesPopup";
import { convertFromYocto, transformZombieMonster } from "../../utils/utils";
import { SelectInventoryPopup } from "../../pages/inventory/SelectInventoryPopup";
import { TeamSummary } from "./TeamSummary";
import { post } from "../../utils/api";
import { OneInventoryItem } from "./OneInventoryItem";
import { OneMonsterItem } from "./OneMonsterItem";
import { OneZombieItem } from "./OneZombieItem";
import { BoardArena } from "../hex/BoardArena";
import { OneItemPosition } from "./OneItemPosition";
import { createBattleTransferAssetsService } from "../../services/BattleArenaService";
import { Loader } from "../clan/chat/components/Loader";
import { useSelector } from "react-redux";
import io from "socket.io-client";

const socketUrl = process.env.API_URL;
const POPUP_PAGE_LIMIT = 20;

export const CreateJoinBattlePopup = ({
  battle,
  totalPoints,
  setVisible,
  visible,
  handleSuccessCreate,
}) => {
  const { wallet, ftContract, mainContract, currentUser } =
    useContext(NearContext);
  const balance = useSelector((state) => state.user.balance);

  const [currentStep, setCurrentStep] = useState(1);
  const [zombieCards, setZombieCards] = useState([]);
  const [monsterCard, setMonsterCard] = useState(null);
  const [myTotalPoints, setMyTotalPoints] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [zombieInventory, setZombieInventory] = useState([]);
  const [monsterInventory, setMonsterInventory] = useState([]);

  const [selectedInventoryPosition, setSelectedInventoryPosition] = useState();
  const [selectedInventoryType, setSelectedInventoryType] = useState("zombie"); // zombie | monster

  const [zombiesPopupVisible, setZombiesPopupVisible] = useState(false);
  const [monstersPopupVisible, setMonstersPopupVisible] = useState(false);
  const [inventoryPopupVisible, setInventoryPopupVisible] = useState(false);
  const [selectedZombiePosition, setSelectedZombiePosition] = useState(0);
  const [zmlBet, setZmlBet] = useState(500);
  const [isReady, setIsReady] = useState(false);

  const [currentZombiesPage, setCurrentZombiesPage] = useState(1);
  const [currentMonstersPage, setCurrentMonstersPage] = useState(1);

  const [userZombies, setUserZombies] = useState([]);
  const [userMonsters, setUserMonsters] = useState([]);
  const [mapPosition, setMapPosition] = useState([]);

  let socket = useRef(null);

  const checkIsReady = () => {
    let ready = true;

    if (!monsterCard) ready = false;
    zombieCards.map((zombie) => {
      if (!zombie) ready = false;
    });
    if (convertFromYocto(balance, 2) < zmlBet) ready = false;
    if (totalPoints && myTotalPoints > totalPoints) ready = false;

    setIsReady(ready);
  };

  useEffect(() => {
    if (battle) {
      setZmlBet(battle.zmlBet);
    }
  }, [battle]);

  useEffect(() => {
    setMyTotalPoints(getTotalPoints());
    checkIsReady();
  }, [zombieCards, monsterCard]);

  useEffect(() => {
    checkIsReady();
  }, [myTotalPoints]);

  useEffect(() => {
    socket.current = io(socketUrl);
  }, [socketUrl]);

  const handleCreateJoin = () => {
    const monsterId = monsterCard.token_id;

    setIsLoading(true);
    let inventoryList = {};
    inventoryList[monsterId] = monsterInventory
      .filter((item) => item)
      .map((item) => item.token_id);

    const zombiesIdList = zombieCards
      .filter((card) => card)
      .map((card, index) => {
        const inventoryId = zombieInventory[index]
          ? zombieInventory[index].token_id
          : null;
        if (inventoryId) {
          inventoryList[card.token_id] = [inventoryId];
        }
        return card.token_id;
      });

    let mapPositions = {};
    mapPosition.map((row, rowIndex) => {
      row.map((id, colIndex) => {
        if (id) {
          let tokenId = id === 1 ? monsterId : zombiesIdList[id - 2];
          mapPositions[tokenId] = [rowIndex, colIndex];
        }
      });
    });

    post(
      battle ? `api/battle-arena/${battle.id}/join` : `api/battle-arena/create`,
      {
        userId: currentUser,
        monsterId,
        zombiesIdList,
        inventoryList,
        mapPositions,
        zmlBet,
      }
    ).then((result) => {
      if (!result.error) {
        const inventoryIdList =
          Object.values(inventoryList).flatMap((item) => item) ?? [];

        createBattleTransferAssetsService(
          monsterId,
          zombiesIdList,
          inventoryIdList,
          wallet,
          ftContract,
          mainContract,
          result.data,
          zmlBet
        )
          .then(() => {
            handleSuccessCreate();
            resetPopup();
          })
          .catch((error) => {
            console.log("Error", error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        alert(result.error);
        setIsLoading(false);
      }
    });
  };

  const resetPopup = () => {
    setZombieCards([]);
    setMonsterCard(null);
    setIsLoading(false);
    setZombieInventory([]);
    setMonsterInventory([]);
    setCurrentZombiesPage(1);
    setCurrentMonstersPage(1);
    setCurrentStep(1);
    setVisible(false);
  };

  useEffect(() => {
    if (!zombieCards.length) {
      let zombieCardsList = [];
      for (let i = 0; i < 4; i++) {
        zombieCardsList.push(null);
      }
      setZombieCards(zombieCardsList);
    }

    if (visible) {
      loadZombies(currentZombiesPage);
      loadMonsters(currentMonstersPage);
    }
  }, [visible]);

  const loadZombies = async (page) => {
    const result = await mainContract.userZombies({
      account_id: currentUser,
      page_num: page,
      page_limit: POPUP_PAGE_LIMIT,
    });
    result[1] = result[1].map((zm) => transformZombieMonster(zm));
    setUserZombies(result);
  };

  const loadMonsters = async (page) => {
    const result = await mainContract.userMonsters({
      account_id: currentUser,
      page_num: page,
      page_limit: POPUP_PAGE_LIMIT,
    });
    result[1] = result[1].map((zm) => transformZombieMonster(zm));
    setUserMonsters(result);
  };

  // TODO: useMemo
  const zombiesInPopup = () => {
    if (zombiesPopupVisible) {
      return userZombies[1]
        .filter((zombie) => {
          let exists = false;
          zombieCards.map((innerZombie) => {
            if (innerZombie && innerZombie.token_id === zombie.token_id) {
              exists = true;
            }
          });
          return !exists;
        })
        .sort((a, b) => (a.card_rarity > b.card_rarity ? -1 : 1));
    }
    return [];
  };

  // TODO: useMemo
  const monstersInPopup = () => {
    if (monstersPopupVisible) {
      return userMonsters[1]
        .filter((monster) => {
          return !monsterCard || monsterCard.token_id !== monster.token_id;
        })
        .sort((a, b) => (a.card_rarity > b.card_rarity ? -1 : 1));
    }
    return [];
  };

  const selectZombie = (zombie) => {
    zombieCards[selectedZombiePosition] = zombie;
    setZombieCards([...zombieCards]);
    if (zombieCards.filter((item) => item).length === 4) {
      setZombiesPopupVisible(false);

      if (!monsterCard) {
        setMonstersPopupVisible(true);
      }
      setVisible(true);
    } else {
      for (let i = 0; i < 4; i++) {
        if (!zombieCards[i]) {
          setSelectedZombiePosition(i);
          break;
        }
      }
    }
  };

  const selectMonster = (monster) => {
    setMonsterCard(monster);
    setMonstersPopupVisible(false);
    setVisible(true);

    if (zombieCards.filter((item) => item).length !== 4) {
      setZombiesPopupVisible(true);
    }
  };

  const selectInventory = (inventoryItem) => {
    setInventoryPopupVisible(false);
    if (selectedInventoryType === "zombie") {
      zombieInventory[selectedInventoryPosition] = inventoryItem;
      setZombieInventory([...zombieInventory]);
    } else {
      monsterInventory[selectedInventoryPosition] = inventoryItem;
      setMonsterInventory([...monsterInventory]);
    }
    setVisible(true);
  };

  const getSelectedInventory = monsterInventory
    .concat(zombieInventory)
    .map((item) => {
      item && item.token_id;
    });

  const onZombiePageChanged = async (page) => {
    setCurrentZombiesPage(page);
    loadZombies(page);
  };

  const onMonsterPageChanged = async (page) => {
    setCurrentMonstersPage(page);
    loadMonsters(page);
  };

  const showSelectZombiesPopup = (position) => {
    setSelectedZombiePosition(position);
    setZombiesPopupVisible(true);
  };

  const showSelectMonsterPopup = () => {
    setMonstersPopupVisible(true);
  };

  const openInventoryPopup = (index, nftType) => {
    setInventoryPopupVisible(true);
    setSelectedInventoryPosition(index);
    setSelectedInventoryType(nftType);
  };

  const cleanupInventory = (e, nftType, index) => {
    e.stopPropagation();

    if (nftType === "zombie") {
      zombieInventory[index] = null;
      setZombieInventory([...zombieInventory]);
    } else {
      monsterInventory[index] = null;
      setMonsterInventory([...monsterInventory]);
    }
  };

  const getTotalPoints = () => {
    let health = monsterCard?.health || 0;
    let attack = monsterCard?.attack || 0;
    let brain = monsterCard?.brain || 0;
    let speed = monsterCard?.speed * 10 || 0;

    zombieCards.forEach((card) => {
      if (card) {
        health += card.health;
        attack += card.attack;
        brain += card.brain;
        speed += card.speed * 10;
      }
    });

    return health + attack + brain + speed;
  };

  return (
    <>
      <Popup
        title={`${battle ? "Join" : "Create"} Battle`}
        popupVisible={visible}
        width="sm:w-[900px]"
        setPopupVisible={setVisible}
      >
        <p className={"mb-4 max-w-xl mx-auto hidden 2xl:block"}>
          {currentStep === 1 &&
            "Choose 1 Monster and 4 zombies for battle, boost your team with inventory."}
          {currentStep === 2 &&
            "Optionally you can change positioning on the Battle Arena."}
        </p>

        {currentStep === 1 && (
          <>
            <TeamSummary
              totalPoints={[myTotalPoints, totalPoints]}
              setZmlBet={setZmlBet}
              zmlBet={zmlBet}
              battleId={battle?.id}
            />

            <div className="gap-5 my-6 flex flex-row">
              <div className={"w-[138px] self-center"}>
                <OneZombieItem
                  zombie={zombieCards[0]}
                  index={0}
                  showSelectZombiesPopup={showSelectZombiesPopup}
                />
                <OneInventoryItem
                  index={0}
                  nftType={"zombie"}
                  openInventoryPopup={openInventoryPopup}
                  zombieInventory={zombieInventory}
                  cleanupInventory={cleanupInventory}
                  monsterInventory={monsterInventory}
                />
              </div>
              <div className={"w-[138px] self-center"}>
                <OneZombieItem
                  zombie={zombieCards[1]}
                  index={1}
                  showSelectZombiesPopup={showSelectZombiesPopup}
                />
                <OneInventoryItem
                  index={1}
                  nftType={"zombie"}
                  openInventoryPopup={openInventoryPopup}
                  zombieInventory={zombieInventory}
                  cleanupInventory={cleanupInventory}
                  monsterInventory={monsterInventory}
                />
              </div>
              <div className={"w-auto"}>
                <OneMonsterItem
                  monsterCard={monsterCard}
                  showSelectMonsterPopup={showSelectMonsterPopup}
                />
                <OneInventoryItem
                  index={0}
                  nftType={"monster"}
                  openInventoryPopup={openInventoryPopup}
                  zombieInventory={zombieInventory}
                  cleanupInventory={cleanupInventory}
                  monsterInventory={monsterInventory}
                />
              </div>
              <div className={"w-[138px] self-center"}>
                <OneZombieItem
                  zombie={zombieCards[2]}
                  index={2}
                  showSelectZombiesPopup={showSelectZombiesPopup}
                />
                <OneInventoryItem
                  index={2}
                  nftType={"zombie"}
                  openInventoryPopup={openInventoryPopup}
                  zombieInventory={zombieInventory}
                  cleanupInventory={cleanupInventory}
                  monsterInventory={monsterInventory}
                />
              </div>
              <div className={"w-[138px] self-center justify-end"}>
                <OneZombieItem
                  zombie={zombieCards[3]}
                  index={3}
                  showSelectZombiesPopup={showSelectZombiesPopup}
                />
                <OneInventoryItem
                  index={3}
                  nftType={"zombie"}
                  openInventoryPopup={openInventoryPopup}
                  zombieInventory={zombieInventory}
                  cleanupInventory={cleanupInventory}
                  monsterInventory={monsterInventory}
                />
              </div>
            </div>

            {convertFromYocto(balance, 2) < zmlBet && (
              <div className={"text-sm text-center text-red-500"}>
                <strong className={"mr-2"}>*Not enough ZML for Bet.</strong>
                Bet: {zmlBet} ZML, balance: {convertFromYocto(balance, 2)} ZML
              </div>
            )}

            <div className={"flex flex-row gap-4 mt-10 justify-center"}>
              <Button
                title="Cancel"
                size="md"
                secondary
                className={
                  "!border-0 !text-gray-400 hover:!text-gray-500 transition"
                }
                noIcon
                onClick={() => resetPopup()}
              />
              <Button
                size="md"
                disabled={!isReady}
                noIcon
                onClick={() => setCurrentStep(2)}
              >
                Next Step
              </Button>
            </div>
          </>
        )}

        {currentStep === 2 && (
          <>
            <div className={"mt-6 flex flex-row gap-2"}>
              <div className={"w-1/2 text-left"}>
                <div className={"flex flex-wrap gap-y-5 gap-x-10 mt-3 mx-6"}>
                  <OneItemPosition
                    inventory={[zombieInventory[0]]}
                    nft={zombieCards[0]}
                    index={1}
                  />
                  <OneItemPosition
                    inventory={[zombieInventory[1]]}
                    nft={zombieCards[1]}
                    index={2}
                  />

                  <div className={"w-full"}>
                    <div className={"w-[108px]"}>
                      <OneItemPosition
                        inventory={monsterInventory}
                        nft={monsterCard}
                        index={0}
                      />
                    </div>
                  </div>

                  <OneItemPosition
                    inventory={[zombieInventory[2]]}
                    nft={zombieCards[2]}
                    index={3}
                  />
                  <OneItemPosition
                    inventory={[zombieInventory[3]]}
                    nft={zombieCards[3]}
                    index={4}
                  />
                </div>
              </div>

              <div className={`w-1/2`}>
                <BoardArena
                  mapPosition={mapPosition}
                  setMapPosition={setMapPosition}
                  battleId={battle?.id}
                />
              </div>
            </div>

            <div className={"flex flex-row gap-4 mt-5 justify-center"}>
              <Button
                title="Back"
                size="md"
                secondary
                className={
                  "!border-0 !text-gray-400 hover:!text-gray-500 transition"
                }
                noIcon
                onClick={() => setCurrentStep(1)}
              />
              <Button
                size="md"
                disabled={!isReady || isLoading}
                noIcon
                onClick={() => handleCreateJoin()}
              >
                {battle ? "Join" : "Create"} Battle
                {isLoading && (
                  <span className={"ml-2"}>
                    <Loader size={"sm"} />
                  </span>
                )}
              </Button>
            </div>
          </>
        )}
      </Popup>

      <SelectZombiesPopup
        title="Battle Arena - Select Zombie"
        zombiesPopupVisible={zombiesPopupVisible}
        setZombiesPopupVisible={setZombiesPopupVisible}
        userCollectionZombies={userZombies}
        zombiesInPopup={zombiesInPopup}
        selectZombie={selectZombie}
        currentPage={currentZombiesPage}
        onPageChanged={onZombiePageChanged}
        pageLimit={POPUP_PAGE_LIMIT}
        nftType={"zombie"}
      />

      <SelectZombiesPopup
        title="Battle Arena - Select Monster"
        zombiesPopupVisible={monstersPopupVisible}
        setZombiesPopupVisible={setMonstersPopupVisible}
        userCollectionZombies={userMonsters}
        zombiesInPopup={monstersInPopup}
        selectZombie={selectMonster}
        currentPage={currentMonstersPage}
        onPageChanged={onMonsterPageChanged}
        pageLimit={POPUP_PAGE_LIMIT}
        nftType={"monster"}
      />

      <SelectInventoryPopup
        title="Battle Arena - Inventory"
        inventoryPopupVisible={inventoryPopupVisible}
        setInventoryPopupVisible={setInventoryPopupVisible}
        selectedInventory={getSelectedInventory}
        selectInventory={selectInventory}
      />
    </>
  );
};

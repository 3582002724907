import React from "react";
import ChatmeChat from "./chat/ChatmeChat";

export const ClanChat = ({ clan }) => {
  return (
    <>
      {clan.chat_id && (
        <div
          className={
            "w-full lg:w-2/3 mx-auto rounded-md bg-mainLight/60 border-2 border-mainLight text-left"
          }
        >
          <ChatmeChat
            chatId={clan.chat_id}
            connectButtonClass={
              "border-2 rounded-lg border-amber-600 hover:text-amber-600 hover:border-amber-700 px-6 py-2.5 font-semibold uppercase transition"
            }
            bottomBlockClass={"bg-main/50 py-4"}
            network={process.env.IS_PROD == "true" ? "mainnet" : "testnet"}
          />
        </div>
      )}
    </>
  );
};

import React, { createContext, useEffect, useState } from "react";
import MainContract from "../contracts/MainContract";
import FtContract from "../contracts/FtContract";
import ParasContract from "../contracts/ParasContract";
import ParasMarketContract from "../contracts/ParasMarketContract";
import SocialContract from "../contracts/SocialContract";

export const NearContext = createContext({
  wallet: undefined,
  contracts: undefined,
  ftContract: undefined,
  zombieContract: undefined,
  parasContract: undefined,
  parasMarketContract: undefined,
  socialContract: undefined,
  isSigned: false,
  currentUser: undefined
});

const NearProvider = ({children, isSignedInit, wallet}) => {
  const [isSigned, setIsSigned] = useState(isSignedInit);
  const [currentUser, setCurrentUser] = useState(wallet.accountId);
  const [contracts, setContracts] = useState({
    main: undefined,
    ft: undefined,
    zombie: undefined,
    paras: undefined,
    parasMarket: undefined,
    social: undefined
  });

  useEffect(() => {
    console.log(process.env.CONTRACT_NAME);
    const mainContract = new MainContract({
      contractId: process.env.CONTRACT_NAME,
      walletToUse: wallet
    });
    const ftContract = new FtContract({
      contractId: "ft." + process.env.CONTRACT_NAME,
      walletToUse: wallet
    });
    const zombieContract = new MainContract({
      contractId: "zombie." + process.env.CONTRACT_NAME,
      walletToUse: wallet
    });
    const parasContract = new ParasContract({
      contractId: process.env.PARAS_TOKEN_CONTRACT,
      walletToUse: wallet
    });
    const parasMarketContract = new ParasMarketContract({
      contractId: process.env.PARAS_MARKET_CONTRACT,
      walletToUse: wallet
    });
    const socialContract = new SocialContract({
      contractId: process.env.SOCIAL_CONTRACT,
      walletToUse: wallet
    });

    setContracts({
      main: mainContract,
      ft: ftContract,
      zombie: zombieContract,
      paras: parasContract,
      parasMarket: parasMarketContract,
      social: socialContract
    });
  }, [isSigned]);

  useEffect(() => {
    if (contracts.main) {
      const subscription = wallet.walletSelector?.store.observable.subscribe(async (nextAccounts) => {
        if (nextAccounts.accounts.length) {
          const currentAccountId = nextAccounts.accounts[0].accountId;
          setCurrentUser(currentAccountId);
          await wallet.onAccountChange(currentAccountId);
          setIsSigned(true);
        } else {
          setIsSigned(false);
          setCurrentUser(undefined);
        }
      });

      return () => subscription?.unsubscribe();
    }
  }, [contracts]);

  return (
    <>
      {contracts.main && wallet && (
        <NearContext.Provider value={{
          wallet,
          isSigned,
          currentUser,
          mainContract: contracts.main,
          ftContract: contracts.ft,
          zombieContract: contracts.zombie,
          parasContract: contracts.paras,
          socialContract: contracts.social,
          parasMarketContract: contracts.parasMarket,
        }}>
          {children}
        </NearContext.Provider>
      )}
    </>
  )
};

export default NearProvider;
import { convertToTera } from "../utils/utils";

class ParasMarketContract {
  contractId = "";
  wallet = null;

  constructor({contractId, walletToUse}) {
    this.contractId = contractId;
    this.wallet = walletToUse;
  }

  getMarketData(nft_contract_id, token_id) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_market_data',
      args: {
        nft_contract_id,
        token_id
      }
    });
  }

  storageBalanceOf(account_id) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'storage_balance_of',
      args: {
        account_id
      }
    });
  }

  getSupplyByOwnerId(account_id) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'get_supply_by_owner_id',
      args: {
        account_id
      }
    });
  }


  storageMinimumBalance() {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'storage_minimum_balance',
      args: {}
    });
  }


  deleteMarketData(nft_contract_id, token_id) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'delete_market_data',
      args: {
        nft_contract_id, token_id
      },
      gas: convertToTera("30"),
      deposit: "1"
    });
  }

  buy() {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'buy',
      args: {}
    });
  }


}

export default ParasMarketContract;
import React, { useContext, useEffect, useState } from "react";
import { NearContext } from "../../contexts/NearWallet";
import { Loader, Popup, CardItem, PartOfMonster, CardRotate } from "../../ui";
import { get } from "../../utils/api";
import {
  convertFromNanoSeconds,
  formatPrice,
  formatTitle,
  transformItem,
  transformMonsterPart,
  transformZombieMonster,
} from "../../utils/utils";

export default function MarketHistoryPopup({
  marketHistoryVisible,
  setMarketHistoryVisible,
}) {
  const { mainContract } = useContext(NearContext);
  const [zombiesById, setZombiesById] = useState({});
  const [monstersById, setMonstersById] = useState({});
  const [inventoryById, setInventoryById] = useState({});
  const [modifiersById, setModifiersById] = useState({});
  const [monsterPartsById, setMonsterPartsById] = useState({});
  const [marketHistory, setMarketHistory] = useState([]);
  const [isReady, setIsReady] = useState(false);

  const loadMarketHistory = async () => {
    let history = await mainContract.getLastMarketHistory();

    let zombiesPromise = new Promise(async (resolve) => {
      const list = history
        .filter((item) => item.nft_type === "Zombie")
        .map((item) => item.token_id);
      let result = await mainContract.getZombiesById(list);
      resolve(result);
    });
    let monstersPromise = new Promise(async (resolve) => {
      const list = history
        .filter((item) => item.nft_type === "Monster")
        .map((item) => item.token_id);
      let result = await mainContract.getMonstersById(list);
      resolve(result);
    });
    let inventoryPromise = new Promise(async (resolve) => {
      const list = history
        .filter((item) => item.nft_type === "Inventory")
        .map((item) => item.token_id);
      let result = await mainContract.getInventoryById(list);
      resolve(result);
    });
    let modifiersPromise = new Promise(async (resolve) => {
      const list = history
        .filter((item) => item.nft_type === "Modifier")
        .map((item) => item.token_id);
      let result = await mainContract.getModifiersById(list);
      resolve(result);
    });
    let monsterPartsPromise = new Promise(async (resolve) => {
      const list = history
        .filter((item) => item.nft_type === "MonsterPart")
        .map((item) => item.token_id);
      let result = await mainContract.getMonsterPartById(list);
      resolve(result);
    });

    Promise.all([
      zombiesPromise,
      monstersPromise,
      inventoryPromise,
      modifiersPromise,
      monsterPartsPromise,
    ]).then(async (result) => {
      let zombies = {};
      result[0].map((zombie) => {
        zombies[zombie.token_id] = transformZombieMonster(zombie);
      });
      setZombiesById(zombies);

      let monsters = {};
      result[1].map((monster) => {
        monsters[monster.token_id] = transformZombieMonster(monster);
      });
      setMonstersById(monsters);

      let inventory = {};
      const itemsResp = await get("api/items", {
        ids: result[2].map((item) => item.token_id),
      });

      result[2].map((item) => {
        let itemDB = itemsResp.data?.find((i) => i.itemId === item.token_id);
        inventory[item.token_id] = transformItem(item);
        if (itemDB) {
          inventory[item.token_id].durabilityMin = itemDB.durabilityMin;
          inventory[item.token_id].durabilityMax = itemDB.durabilityMax;
          inventory[item.token_id].level = itemDB.level;
        }
      });
      setInventoryById(inventory);

      let modifiers = {};
      result[3].map((item) => {
        modifiers[item.token_id] = transformItem(item);
      });
      setModifiersById(modifiers);

      let monsterParts = {};
      result[4].map((item) => {
        monsterParts[item.token_id] = transformMonsterPart(item);
      });
      setMonsterPartsById(monsterParts);

      setMarketHistory(history.reverse());
      setIsReady(true);
    });
  };

  const timestampToDate = (timestamp) => {
    const seconds = convertFromNanoSeconds(timestamp);
    const dateTime = new Date(seconds);
    const options = {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hourCycle: "h23",
    };
    return new Intl.DateTimeFormat("en-US", options).format(dateTime);
  };

  useEffect(() => {
    if (marketHistoryVisible) {
      loadMarketHistory();
    }
  }, [marketHistoryVisible]);

  return (
    <Popup
      title="Market History - Last Trades"
      popupVisible={marketHistoryVisible}
      setPopupVisible={setMarketHistoryVisible}
      width="lg:w-[840px]"
    >
      <div className="mt-2 text-left">
        {isReady ? (
          <div className="text-sm">
            <div className="flex flex-row justify-between border-b border-sky-200 text-sky-200 opacity-70 font-semibold py-2 mb-1">
              <div className="w-14 text-center">Card</div>
              <div className="w-1/6">Date</div>
              <div className="w-1/6 hidden lg:block">NFT</div>
              <div className="w-24">Price</div>
              <div className="w-1/6 hidden lg:block">Seller</div>
              <div className="w-1/6 hidden lg:block">Buyer</div>
            </div>

            {marketHistory.map((item) => (
              <div
                key={item.timestamp}
                className="flex flex-row justify-between py-1"
              >
                <div className="w-14">
                  {item.nft_type === "Zombie" &&
                    (zombiesById[item.token_id] ? (
                      <div style={{ zoom: "0.4" }}>
                        <CardRotate
                          nft={zombiesById[item.token_id]}
                          size="sm"
                          noFlip
                        />
                      </div>
                    ) : (
                      <div className="text-sm opacity-70 h-20 pt-4">
                        Zombie Killed
                      </div>
                    ))}
                  {item.nft_type === "Monster" &&
                    (monstersById[item.token_id] ? (
                      <div style={{ zoom: "0.4" }}>
                        <CardRotate
                          nft={monstersById[item.token_id]}
                          size="sm"
                          noFlip
                        />
                      </div>
                    ) : (
                      <div className="text-sm opacity-70 h-20 pt-4">
                        Monster Killed
                      </div>
                    ))}
                  {item.nft_type === "MonsterPart" &&
                    (monsterPartsById[item.token_id] ? (
                      <div style={{ zoom: "0.25" }}>
                        <PartOfMonster
                          item={monsterPartsById[item.token_id]}
                          noFlip
                        />
                      </div>
                    ) : (
                      <div className="text-sm opacity-70 h-20 pt-4">
                        Part Burned
                      </div>
                    ))}
                  {item.nft_type === "Inventory" &&
                    (inventoryById[item.token_id] ? (
                      <div style={{ zoom: "0.5" }}>
                        <CardItem
                          isItem
                          item={inventoryById[item.token_id]}
                          noFlip
                          size="sm"
                        />
                      </div>
                    ) : (
                      <div className="text-sm opacity-70 h-20 pt-4">
                        Inventory item used
                      </div>
                    ))}
                  {item.nft_type === "Modifier" &&
                    (modifiersById[item.token_id] ? (
                      <div style={{ zoom: "0.5" }}>
                        <CardItem
                          item={modifiersById[item.token_id]}
                          noFlip
                          size="sm"
                        />
                      </div>
                    ) : (
                      <div className="text-sm opacity-70 h-20 pt-4">
                        Modifier used
                      </div>
                    ))}
                </div>
                <div className="w-1/6">
                  <p className="lg:pt-6">{timestampToDate(item.timestamp)}</p>
                </div>
                <div className="w-1/6 hidden lg:block">
                  <p className="lg:pt-6">
                    {item.nft_type} {formatTitle(item.nft_type, item.token_id)}
                  </p>
                </div>
                <div className="w-24">
                  <p className="lg:pt-6 pt-4">
                    <b>
                      {formatPrice(item.price)}{" "}
                      {item.nft_type === "Zombie" ||
                      item.nft_type === "Monster" ||
                      item.nft_type === "MonsterPart" ? (
                        <>NEAR</>
                      ) : (
                        <>ZML</>
                      )}
                    </b>
                  </p>
                </div>
                <div className="w-1/6 hidden lg:block">
                  <p className="w-32 lg:pt-6 overflow-hidden text-ellipsis">
                    {item.from_user}
                  </p>
                </div>
                <div className="w-1/6 hidden lg:block">
                  <p className="w-32 lg:pt-6 overflow-hidden text-ellipsis">
                    {item.to_user}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </Popup>
  );
}

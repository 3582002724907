"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SCHEMA = exports.SignedTransaction = exports.Transaction = exports.Signature = void 0;
const crypto_1 = require("@near-js/crypto");
const types_1 = require("@near-js/types");
const borsh_1 = require("borsh");
const actions_1 = require("./actions");
class Signature extends types_1.Assignable {
}
exports.Signature = Signature;
class Transaction extends types_1.Assignable {
    encode() {
        return (0, borsh_1.serialize)(exports.SCHEMA, this);
    }
    static decode(bytes) {
        return (0, borsh_1.deserialize)(exports.SCHEMA, Transaction, bytes);
    }
}
exports.Transaction = Transaction;
class SignedTransaction extends types_1.Assignable {
    encode() {
        return (0, borsh_1.serialize)(exports.SCHEMA, this);
    }
    static decode(bytes) {
        return (0, borsh_1.deserialize)(exports.SCHEMA, SignedTransaction, bytes);
    }
}
exports.SignedTransaction = SignedTransaction;
exports.SCHEMA = new Map([
    [Signature, { kind: 'struct', fields: [
                ['keyType', 'u8'],
                ['data', [64]]
            ] }],
    [SignedTransaction, { kind: 'struct', fields: [
                ['transaction', Transaction],
                ['signature', Signature]
            ] }],
    [Transaction, { kind: 'struct', fields: [
                ['signerId', 'string'],
                ['publicKey', crypto_1.PublicKey],
                ['nonce', 'u64'],
                ['receiverId', 'string'],
                ['blockHash', [32]],
                ['actions', [actions_1.Action]]
            ] }],
    [crypto_1.PublicKey, { kind: 'struct', fields: [
                ['keyType', 'u8'],
                ['data', [32]]
            ] }],
    [actions_1.AccessKey, { kind: 'struct', fields: [
                ['nonce', 'u64'],
                ['permission', actions_1.AccessKeyPermission],
            ] }],
    [actions_1.AccessKeyPermission, { kind: 'enum', field: 'enum', values: [
                ['functionCall', actions_1.FunctionCallPermission],
                ['fullAccess', actions_1.FullAccessPermission],
            ] }],
    [actions_1.FunctionCallPermission, { kind: 'struct', fields: [
                ['allowance', { kind: 'option', type: 'u128' }],
                ['receiverId', 'string'],
                ['methodNames', ['string']],
            ] }],
    [actions_1.FullAccessPermission, { kind: 'struct', fields: [] }],
    [actions_1.Action, { kind: 'enum', field: 'enum', values: [
                ['createAccount', actions_1.CreateAccount],
                ['deployContract', actions_1.DeployContract],
                ['functionCall', actions_1.FunctionCall],
                ['transfer', actions_1.Transfer],
                ['stake', actions_1.Stake],
                ['addKey', actions_1.AddKey],
                ['deleteKey', actions_1.DeleteKey],
                ['deleteAccount', actions_1.DeleteAccount],
            ] }],
    [actions_1.CreateAccount, { kind: 'struct', fields: [] }],
    [actions_1.DeployContract, { kind: 'struct', fields: [
                ['code', ['u8']]
            ] }],
    [actions_1.FunctionCall, { kind: 'struct', fields: [
                ['methodName', 'string'],
                ['args', ['u8']],
                ['gas', 'u64'],
                ['deposit', 'u128']
            ] }],
    [actions_1.Transfer, { kind: 'struct', fields: [
                ['deposit', 'u128']
            ] }],
    [actions_1.Stake, { kind: 'struct', fields: [
                ['stake', 'u128'],
                ['publicKey', crypto_1.PublicKey]
            ] }],
    [actions_1.AddKey, { kind: 'struct', fields: [
                ['publicKey', crypto_1.PublicKey],
                ['accessKey', actions_1.AccessKey]
            ] }],
    [actions_1.DeleteKey, { kind: 'struct', fields: [
                ['publicKey', crypto_1.PublicKey]
            ] }],
    [actions_1.DeleteAccount, { kind: 'struct', fields: [
                ['beneficiaryId', 'string']
            ] }],
]);

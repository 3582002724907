import React from "react";
import { Header, Footer } from "../components";
import { Button, InnerPageHead } from "../ui";
import {
  Container,
  InnerPageWrapper,
  Wrapper,
} from "../assets/styles/common.style";

export const Page404 = () => {

  return (
    <>
      <InnerPageWrapper>
        <Header />

        <Wrapper>
          <Container className="text-white text-center mt-6">
            <InnerPageHead
              title={"Not Found"}
              description={"Page not found"}
            />
            <a href="/">
              <Button title={"Open Homepage"} noIcon />
            </a>
          </Container>
        </Wrapper>

        <Footer />
      </InnerPageWrapper>
    </>
  );
};

import React, { useContext, useEffect, useState } from "react";
import S from "../../assets/styles/clanView.style";
import { Button, BoltIcon, Loader } from "../../ui";
import { get } from "../../utils/api";
import { statusColorBgMap } from "../../utils/utils";
import { LEVEL_MAP } from "../../utils/config";
import { BattleResultsPopup } from "../../components/clan/BattleResultsPopup";
import {
  ExternalLinkIcon,
  TrendingUpIcon,
  TrendingDownIcon,
} from "@heroicons/react/outline";
import { BattleZombiesPopup } from "../../components/clan/BattleZombiesPopup";
import { NearContext } from "../../contexts/NearWallet";
import clan_image from "../../assets/images/clan_image.png";
import { Link } from "react-router-dom";
import { UserGroupIcon } from "@heroicons/react/solid";

export const ClanBattleHistory = ({ clan, showBattleResult }) => {
  const { currentUser } = useContext(NearContext);
  const [battles, setBattles] = useState([]);
  const [viewBattleDetails, setViewBattleDetails] = useState();
  const [isReady, setIsReady] = useState(false);
  const [showResultsPopup, setShowResultsPopup] = useState(false);
  const [myZombiesBattle, setMyZombiesBattle] = useState();
  const [showMyZombiesPopup, setShowMyZombiesPopup] = useState(false);

  const currentMonthBattle = (battle) => {
    const date = new Date();
    const firstMonth = new Date(date.getFullYear(), date.getMonth(), 1);

    return Date.parse(battle.createdAt) > firstMonth;
  };

  const fetchBattles = async () => {
    const response = await get("api/clan-battle/all", {
      clanId: clan.id,
      accountId: currentUser,
    });
    if (response.error) {
      alert(response.error);
    }

    setBattles(response.data);
    setIsReady(true);
  };

  useEffect(() => {
    if (showBattleResult && battles.length) {
      const battle = battles
        .filter((b) => b.id === parseInt(showBattleResult))
        .pop();
      setShowResultsPopup(true);
      setViewBattleDetails(battle);
    }
  }, [battles, showBattleResult]);

  useEffect(() => {
    fetchBattles();
  }, []);

  const isJoined = (battle) => {
    return (
      battle.fromClan.participants.some(
        (user) => user.accountId === currentUser
      ) ||
      battle.toClan.participants.some((user) => user.accountId === currentUser)
    );
  };

  const otherClanImage = (battle) => {
    if (clan.id === battle.fromClan.id) {
      return battle.toClan.media ?? clan_image;
    }
    return battle.fromClan.media ?? clan_image;
  };

  if (!isReady) {
    return <Loader />;
  }

  const Badge = ({ title, content }) => (
    <div className="flex gap-1 text-xl font-semibold text-white items-center">
      {content}
    </div>
  );

  const ClanName = ({ clan }) => (
    <div className="font-semibold flex flex-row justify-between">
      <div className="truncate max-w-28">
        <Link to={`/clans/${clan.id}`}>{clan.name}</Link>
      </div>
    </div>
  );

  return (
    <>
      <div className="mt-10">
        <S.SectionWrapper className="w-full">
          <S.Table.Header className="hidden md:flex px-5 justify-between">
            <S.Table.ItemColumn className="flex w-24">
              <b>ID</b>
            </S.Table.ItemColumn>
            <S.Table.ItemColumn className="flex w-72">
              <b>Clan vs Clan</b>
            </S.Table.ItemColumn>
            <S.Table.ItemColumn className="w-full justify-center">
              <b>Power limit / Size / Rarity / Inventory</b>
            </S.Table.ItemColumn>
            <S.Table.ItemColumn className="flex w-1/2 justify-end">
              <b>Battle Result</b>
            </S.Table.ItemColumn>
          </S.Table.Header>

          {battles
            ?.filter((b) => b.winnerClanId)
            ?.map((battle, index) => (
              <S.Table.Content
                key={index}
                className={`relative one-battle flex text-base gap-2 items-center font-semibold justify-between rounded-lg py-2 pl-5 pr-3 ${
                  !currentMonthBattle(battle) && "opacity-50"
                }`}
              >
                <div className="flex gap-3 items-center">
                  <div className="hidden md:flex">#{battle.id}</div>
                  <div
                    className={`hidden lg:flex flex-row items-center relative w-28`}
                  >
                    <img
                      alt="opponent clan"
                      src={otherClanImage(battle)}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = clan_image;
                      }}
                      className="h-14 w-14 rounded-xl object-cover left-3 absolute z-0  border-4 border-[#150b49] opacity-50"
                    />
                    <img
                      alt="our clan"
                      src={clan.media ?? clan_image}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = clan_image;
                      }}
                      className="h-14 w-14 rounded-xl object-cover absolute right-3 z-10 border-[#150b49] border-4"
                    />
                  </div>
                  <div className="flex flex-col justify-between">
                    <ClanName clan={battle.fromClan} />
                    <ClanName clan={battle.toClan} />
                  </div>
                </div>
                <div className="hidden md:flex w-full justify-center md:gap-7 lg:gap-10 xl:gap-20">
                  <Badge
                    content={
                      <>
                        <BoltIcon />
                        <span>
                          {LEVEL_MAP[battle.cardRarity][battle.level]}
                        </span>
                      </>
                    }
                  />
                  <Badge
                    content={
                      <>
                        <UserGroupIcon className="h-4" />
                        <span>
                          {battle.requiredSize / 2}x{battle.requiredSize / 2}
                        </span>
                      </>
                    }
                  />
                  <div
                    className={`rounded px-3 py-1 text-white ${statusColorBgMap(battle.cardRarity)}`}
                  >
                    {battle.cardRarity}
                  </div>
                  <div
                    className={`hidden lg:flex rounded px-3 py-1 text-white border-2 border-gray-500`}
                  >
                    {battle.withInventory ? "Yes" : "No"}
                  </div>
                </div>

                <S.Table.ItemColumn className="flex w-1/2 pr-28 justify-end">
                  <Button
                    secondary
                    noIcon
                    size="sm"
                    className="hidden md:inline"
                    title="results"
                    onClick={() => {
                      setShowResultsPopup(true);
                      setViewBattleDetails(battle);
                    }}
                  />
                  <Button
                    noIcon
                    size="sm"
                    className="md:hidden inline ml-4"
                    title=""
                    onClick={() => {
                      setShowResultsPopup(true);
                      setViewBattleDetails(battle);
                    }}
                  >
                    <ExternalLinkIcon className="w-4 h-4" />
                  </Button>
                </S.Table.ItemColumn>

                <div
                  className={`flex flex-col justify-center items-center absolute top-0 bottom-0 right-0 pr-5 rounded-r-lg font-semibold
                  ${
                    battle.winnerClanId === clan.id
                      ? "bg-green-500"
                      : "bg-red-500"
                  }`}
                >
                  <div className={"win-lose"}></div>
                  <div>
                    {battle.winnerClanId === clan.id ? (
                      <TrendingUpIcon className={"w-5 h-5"} />
                    ) : (
                      <TrendingDownIcon className={"w-5 h-5"} />
                    )}
                  </div>
                  <div className={"w-10 text-center"}>
                    {battle.winnerClanId === clan.id ? "WON" : "LOST"}
                  </div>
                </div>
              </S.Table.Content>
            ))}
        </S.SectionWrapper>
      </div>

      <BattleResultsPopup
        battle={viewBattleDetails}
        visible={showResultsPopup}
        setVisible={setShowResultsPopup}
      />

      {myZombiesBattle && (
        <BattleZombiesPopup
          battle={myZombiesBattle}
          visible={showMyZombiesPopup}
          setVisible={setShowMyZombiesPopup}
        />
      )}
    </>
  );
};

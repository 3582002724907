import React, { useContext, useState } from "react";
import { convertToTera, convertToYocto } from "../../utils/utils";
import { Row } from "../../assets/styles/common.style";
import { landsConfig } from '../../utils/config';
import { Loader, Button, CardLand } from "../../ui";
import { NearContext } from "../../contexts/NearWallet";
import Big from 'big.js';

export const MintLandSection = ({handleMintSuccess, isMicroLand}) => {
  const {mainContract} = useContext(NearContext);
  const [isReady, setIsReady] = useState(true);

  const MintCard = ({type, handleMint}) => (
    <div className="sm:flex sm:flex-col">
      <CardLand noFlip nft={landsConfig[type]} />
      <div className="mt-4">
        <Button title={`Mint ${type} Land`} onClick={handleMint} />
        <div className="mt-3 font-semibold">
          {landsConfig[type].price}{" "}
          NEAR
        </div>
      </div>
    </div>
  );

  const handleMint = async (depositAmount) => {
    setIsReady(false);

    const gas = depositAmount ? "220" : "120";
    let deposit = 0;

    if (depositAmount > 0) {
      deposit = new Big(convertToYocto(depositAmount));
      deposit = deposit.plus("500000000000000000000");
      deposit = deposit.toFixed()
    }

    try {
      await mainContract.mintLandNft(convertToTera(gas), deposit);
    } catch (e) {
      console.log(`Error`, e);
    } finally {
      setIsReady(true);
      handleMintSuccess();
    }
  };

  return (
    <Row className="justify-center gap-6 flex-wrap">
      <>
        {isReady ? (
          <>
            {
              Object.keys(landsConfig).map(landType => {
                if (landType !== 'Micro' || !isMicroLand()) {
                  return (
                    <MintCard key={landType}
                              type={landType}
                              handleMint={() => handleMint(landsConfig[landType].price)}
                    />
                  )
                }
              })
            }
          </>
        ) : (
          <Loader />
        )}
      </>
    </Row>
  );
};

import { convertToTera, convertToYocto } from "../utils/utils";

class FtContract {
  contractId = "";
  wallet = null;

  constructor({contractId, walletToUse}) {
    this.contractId = contractId;
    this.wallet = walletToUse;
  }

  ftBalanceOf(account_id) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: "ft_balance_of",
      args: {
        account_id,
      },
    });
  }

  getZmlReserve(account_id) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: "get_zml_reserve",
      args: {
        account_id,
      },
    });
  }

  getUserEarned(account_id) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: "get_user_earned",
      args: {
        account_id,
      },
    });
  }

  getUserStake(account_id) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: "get_user_stake",
      args: {
        account_id,
      },
    });
  }

  getApr() {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: "get_apr",
    });
  }

  getStakeMonsterPct(account_id) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: "get_stake_monster_pct",
      args: {
        account_id,
      },
    });
  }

  storageBalanceOf(account_id) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: "storage_balance_of",
      args: {
        account_id,
      },
    });
  }

  getTotalSupply() {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: "get_total_supply",
    });
  }

  withdrawZmlReserve() {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: "withdraw_zml_reserve",
    });
  }

  ftMint(receiver_id, amount) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: "ft_mint",
      args: {
        receiver_id,
        amount,
      },
      gas: convertToTera("20"),
      deposit: convertToYocto("0.01"),
    });
  }

  ftTransfer(receiver_id, amount) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: "ft_transfer",
      args: {
        receiver_id,
        amount,
      },
      gas: convertToTera("20"),
      deposit: "1",
    });
  }

  ftTransferCall(receiver_id, amount, msg) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: "ft_transfer_call",
      args: {
        receiver_id,
        amount,
        msg,
      },
      gas: convertToTera("100"),
      deposit: "1",
    });
  }

  withdrawStake(amount) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: "withdraw_stake",
      args: {
        amount,
      },
      gas: convertToTera("30"),
      deposit: "1",
    });
  }

  withdrawReward() {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: "withdraw_reward",
      gas: convertToTera("50"),
      deposit: convertToYocto("0.1"),
    });
  }
}

export default FtContract;

import { CardRotate } from "../../ui";
import { PlusIcon } from "@heroicons/react/solid";
import React from "react";

export const OneZombieItem = ({ zombie, index, showSelectZombiesPopup }) => (
  <div className={`w-[138px] h-[196px] rounded-xl transition duration-200 cursor-pointer relative z-10 mb-1
      ${zombie ? "" : "bg-main hover:bg-main/50"}`}
       onClick={() => showSelectZombiesPopup(index)}
  >
    {zombie ? (
      <>
        <CardRotate nft={zombie} size="sm" />
      </>
    ) : (
      <div className={"pt-16"}>
        <PlusIcon className="w-8 h-8 mx-auto" />
        <div className="text-center text-sm mt-8">Select Zombie</div>
      </div>
    )}
  </div>
)
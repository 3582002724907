import React, { Fragment, isValidElement, cloneElement } from 'react';

const urlRegex = /(https?:\/\/|www\.)([-\w.]+\/[\p{L}\p{Emoji}\p{Emoji_Component}!#$%&'"()*+,./\\:;=_?@[\]~-]*[^\s'",.;:\b)\]}?]|(([\w-]+\.)+[\w-]+[\w/-]))/u;
const UrlComponent = ({
  match: url,
  className
}) => {
  return React.createElement("a", {
    className: className,
    href: /^www\./.exec(url) ? `http://${url}` : url,
    target: "_blank",
    rel: "noreferrer"
  }, url);
};

const twitterRegex = /\B@([\w_]+)/;
const TwitterComponent = ({
  match,
  className
}) => {
  return React.createElement("a", {
    className: className,
    href: "https://twitter.com/" + match.slice(1),
    target: "_blank",
    rel: "noreferrer"
  }, match);
};

const jiraRegex = /[A-Z]+-\d+/i;
const JiraComponent = ({
  match,
  domain,
  className
}) => {
  const [project, id] = match.split("-");
  return React.createElement("a", {
    className: className,
    href: `${domain}/jira/software/projects/${project}/boards/${id}`,
    target: "_blank",
    rel: "noreferrer"
  }, match);
};

const emailRegex = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
const EmailComponent = ({
  match,
  className
}) => {
  return React.createElement("a", {
    className: className,
    href: "mailto:" + match,
    target: "_blank",
    rel: "noreferrer"
  }, match);
};

let key = 0;
const getKey = () => ++key;

const ctrlCharactersRegex = /[\u0000-\u001F\u007F-\u009F\u2000-\u200D\uFEFF]/gim;
/**
 * Make urls clickable.
 * @param text Text to parse
 * @param options {@link Options}
 */
function linkIt(text, linkComponent, linkRegex) {
  const elements = [];
  let rest = text;
  while (true) {
    const match = linkRegex.exec(rest);
    if (!match || match[0] === undefined) break;
    const urlStartIndex = match.index;
    const urlEndIndex = match.index + match[0].length;
    const textBeforeMatch = rest.slice(0, urlStartIndex);
    const url = rest.slice(urlStartIndex, urlEndIndex).replace(ctrlCharactersRegex, "");
    rest = rest.slice(urlEndIndex);
    textBeforeMatch && elements.push(textBeforeMatch);
    elements.push(linkComponent(url, getKey()));
  }
  rest && elements.push(React.createElement(Fragment, {
    key: getKey()
  }, rest));
  if (elements.length === 0) {
    return text;
  }
  return elements;
}
function findText(children, component, regex) {
  if (typeof children === "string") {
    return linkIt(children, component, regex);
  }
  if (Array.isArray(children)) {
    return children.map(c => findText(c, component, regex));
  }
  if (isValidElement(children) && children.props.children && children.type !== "a" && children.type !== "button") {
    return cloneElement(children, {
      ...children.props,
      key: getKey()
    }, findText(children.props.children, component, regex));
  }
  return children;
}
/**
 * LinkIt component can wrapped around any React component to linkify any
 * urls
 * @example
 * ```
 * <LinkIt
 *   component={(match, key) => <a href={match} key={key}>{match}</a>}
 *   regex={regexToMatch}
 * >
 *  www.google.com<div>hi match_me</div>
 * </LinkIt>
 * ```
 */
const LinkIt = props => {
  return React.createElement(Fragment, null, findText(props.children, props.component, props.regex));
};
/**
 * Link URLs
 */
const LinkItUrl = props => {
  return React.createElement(Fragment, null, findText(props.children, (match, key) => React.createElement(UrlComponent, {
    key: key,
    match: match,
    className: props.className
  }), urlRegex));
};
/**
 * Link Twitter handles
 */
const LinkItTwitter = props => {
  return React.createElement(Fragment, null, findText(props.children, (match, key) => React.createElement(TwitterComponent, {
    key: key,
    match: match,
    className: props.className
  }), twitterRegex));
};
/**
 * Link Jira tickets
 */
const LinkItJira = props => {
  return React.createElement(Fragment, null, findText(props.children, (match, key) => React.createElement(JiraComponent, {
    key: key,
    match: match,
    domain: props.domain,
    className: props.className
  }), jiraRegex));
};
/**
 * Link Emails
 */
const LinkItEmail = props => {
  return React.createElement(Fragment, null, findText(props.children, (match, key) => React.createElement(EmailComponent, {
    key: key,
    match: match,
    className: props.className
  }), emailRegex));
};

export { EmailComponent, JiraComponent, LinkIt, LinkItEmail, LinkItJira, LinkItTwitter, LinkItUrl, TwitterComponent, UrlComponent, emailRegex, jiraRegex, linkIt, twitterRegex, urlRegex };

import React, { useContext, useState } from "react";
import { Button, Popup, CardLand } from '../../ui';
import { FlipCard } from '../../assets/styles/card.style';
import { NearContext } from "../../contexts/NearWallet";

export const TransferLandPopup = ({
  transferPopupVisible,
  setTransferPopupVisible,
  selectedLandForTransfer,
  handleTransferSuccess,
}) => {
  const {wallet, currentUser, parasContract} = useContext(NearContext);
  const [transferAddress, setTransferAddress] = useState("");

  const handleTransfer = async (transferAddress) => {
    if (transferAddress && transferAddress !== currentUser) {
      try {
        await wallet.checkAccountAvailable(transferAddress);
      } catch (e) {
        alert("Account not exists, please check wallet address.");
        return;
      }

      try {
        await parasContract.nftTransfer(selectedLandForTransfer.token_id, transferAddress.toLowerCase().trim());
      } catch (e) {
        console.log(`Error`, e);
      }
      handleTransferSuccess();
      setTransferPopupVisible(false);
    } else {
      alert("Error: Wrong NEAR Transfer Address");
    }
  };

  return (
    <Popup
      title="Transfer Land NFT"
      popupVisible={transferPopupVisible}
      setPopupVisible={setTransferPopupVisible}
    >
      <div className="mt-2 h-52 px-6 flex flex-row">
        <FlipCard noFlip className={`small`}>
          {selectedLandForTransfer && (
            <CardLand nft={selectedLandForTransfer} size={`sm`} noFlip />
          )}
        </FlipCard>
        <div className="ml-10 text-left">
          <p className="mb-6 mt-6">
            You can transfer this NFT to any NEAR account.
          </p>
          <p className="mb-3">
            <input
              type="text"
              className="px-4 py-2 w-full rounded-md bg-transparent border-indigo-500 text-indigo-100 border-2"
              placeholder="NEAR Address"
              value={transferAddress}
              onChange={(e) => setTransferAddress(e.target.value)}
            />
          </p>
          <Button
            title="Transfer"
            onClick={() => handleTransfer(transferAddress)}
          />
        </div>
      </div>
    </Popup>
  );
};

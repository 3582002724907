export const Badge = ({
  text,
  customClassName,
  bgColor,
  borderColor,
  textColor,
}) => (
  <div
    className={`${bgColor} ${textColor ?? "text-white"} ${
      borderColor ? `border ${borderColor}` : ""
    } text-center text-sm font-semibold px-3 py-1 rounded uppercase ${customClassName}`}
  >
    {text}
  </div>
);

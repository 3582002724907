import React from "react";
import { createRoot } from "react-dom/client";
import { Wallet } from "./utils/wallet";
import NearProvider from "./contexts/NearWallet";
import { store } from "./store";
import { Provider } from "react-redux";
import App from "./App";

if (process.env.NODE_ENV !== "production") {
  const parcelSocket = new WebSocket("ws://localhost:1234/");
  parcelSocket.onmessage = () => {
    window.location.reload();
  };
}

window.onload = async () => {
  const wallet = new Wallet({
    createAccessKeyFor: process.env.CONTRACT_NAME,
    network: process.env.IS_PROD == "true" ? "mainnet" : "testnet",
  });
  const isSignedInit = await wallet.startUp();

  createRoot(document.getElementById("root")).render(
    <Provider store={store}>
      <NearProvider wallet={wallet} isSignedInit={isSignedInit}>
        <App />
      </NearProvider>
    </Provider>
  );
};

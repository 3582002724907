import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    balance: 0,
    reservedBalance: 0,
    rating: 0,
    clanId: null,
  },
  reducers: {
    updateBalance: (state, action) => {
      state.balance = action.payload.balance;
    },
    updateZmlReserve: (state, action) => {
      state.reservedBalance = action.payload.reservedBalance;
    },
    updateRating: (state, action) => {
      state.rating = action.payload.rating;
    },
    updateClanId: (state, action) => {
      state.clanId = action.payload.clanId;
    },
  },
});

export const { updateBalance, updateZmlReserve, updateRating, updateClanId } =
  userSlice.actions;
export default userSlice.reducer;

import { convertToTera } from "../utils/utils";

class ParasContract {
  contractId = "";
  wallet = null;

  constructor({contractId, walletToUse}) {
    this.contractId = contractId;
    this.wallet = walletToUse;
  }

  nftTokensForOwner(account_id) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'nft_tokens_for_owner',
      args: {
        account_id
      }
    });
  }

  nftGetSeriesSingle() {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'nft_get_series_single',
      args: {}
    });
  }

  nftSupplyForSeries(token_series_id) {
    return this.wallet?.viewMethod({
      contractId: this.contractId,
      method: 'nft_supply_for_series',
      args: {
        token_series_id
      }
    });
  }


  nftTransfer(token_id, receiver_id) {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'nft_transfer',
      args: {
        token_id,
        receiver_id
      },
      gas: convertToTera("60"),
      deposit: "1"
    });
  }

  nftApprove() {
    return this.wallet?.callMethod({
      contractId: this.contractId,
      method: 'nft_approve',
      args: {}
    });
  }
}

export default ParasContract;
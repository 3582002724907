import {
  updateBalance,
  updateZmlReserve,
  updateRating,
  updateClanId,
} from "../store/userSlice";
import { get } from "../utils/api";
import { transformItem } from "../utils/utils";

export const userRefferal = async (currentUser, ref) => {
  await get("api/users/refferals", {
    accountId: currentUser,
    ref,
  });
};

export const activateRefferal = async (currentUser) => {
  await get("api/users/refferals", {
    accountId: currentUser,
    activate: true,
  });
};

export const updateUserBalance = async (dispatch, ftContract, currentUser) => {
  const balance = await ftContract.ftBalanceOf(currentUser);
  dispatch(updateBalance({ balance }));
};

export const updateUserZmlReserve = async (
  dispatch,
  ftContract,
  currentUser
) => {
  const reservedBalance = await ftContract.getZmlReserve(currentUser);
  dispatch(updateZmlReserve({ reservedBalance }));
};

export const dispatchUserRating = async (dispatch, currentUser) => {
  try {
    const response = await get("api/users/current", { currentUser });
    if (response.data) {
      dispatch(updateRating({ rating: response.data.rating }));
      dispatch(updateClanId({ clanId: response.data.clanId }));
    }
  } catch (e) {
    console.log(`e`, e);
  }
};

export const fetchUserInventory = async (
  mainContract,
  currentUser,
  currentPage,
  PAGE_LIMIT
) => {
  let requestParams = {
    account_id: currentUser,
    page_num: currentPage,
    page_limit: PAGE_LIMIT,
  };
  let items = await mainContract.userInventoryItems(requestParams);
  const idList = items[1].map((item) => item.token_id).join(",");

  const itemsResp = await get("api/items", { ids: idList });
  let itemsDB = itemsResp.data;

  if (itemsDB) {
    // Convert price from Yocto NEAR and add durability
    items[1] = items[1]
      .filter((item) => item.owner_id === currentUser)
      .filter((item) => {
        let itemDB = itemsDB.find((i) => i.itemId === item.token_id);
        return itemDB?.durabilityMin === 0 || item.sale_price ? false : true;
      })
      .map((item) => {
        let itemDB = itemsDB.find((i) => i.itemId === item.token_id);
        let transformedItem = transformItem(item);

        transformedItem.durabilityMin = itemDB?.durabilityMin;
        transformedItem.durabilityMax = itemDB?.durabilityMax;

        return transformedItem;
      })
      .sort((a, b) => a.item_type - b.item_type);

    return items;
  }
};

import { canClaim, convertToTera, convertToYocto } from "../utils/utils";
import { isNFT } from "./MarketService";

export const getLandDeposit = (landType) => {
  if (landType === "Micro") {
    return convertToYocto("0.01");
  } else if (landType === "Small") {
    return convertToYocto("0.02");
  } else if (landType === "Medium") {
    return convertToYocto("0.035");
  }
  return convertToYocto("0.07");
};

export const handleMintAllService = async (userLands, wallet, mainContract) => {
  let txs = [];

  userLands
    .filter((land) => !land.need_import)
    .map((land) => {
      if (canClaim(land) && txs.length <= 50) {
        txs.push({
          receiverId: mainContract.contractId,
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "mint_free_zombie_nft",
                args: {
                  land_id: land.token_id
                },
                gas: convertToTera("280"),
                deposit: getLandDeposit(land.land_type),
              },
            },
          ],
        });
      }
    });

  if (txs.length) {
    return wallet.interface.signAndSendTransactions({transactions: txs});
  } else {
    alert("No available lands for Mint");
  }
}

export const handleKillService = (idList, nftType, mainContract, wallet, points) => {
  const chunkSize = 3;
  let txs = [];

  for (let i = 0; i < idList.length; i += chunkSize) {
    const chunk = idList.slice(i, i + chunkSize);
    txs.push({
      receiverId: mainContract.contractId,
      actions: [
        {
          type: "FunctionCall",
          params: {
            methodName: nftType === "Monster" ? "kill_monster" : "kill_zombie",
            args: {
              id_list: chunk
            },
            gas: convertToTera("280"),
            deposit: "1",
          },
        },
      ],
    });
  }

  // parse current URL to get "page" param
  const urlParams = new URLSearchParams(window.location.search);
  const page = urlParams.get("page") || 1;

  const action = nftType === "Monster" ? "monster-kill" : "zombie-kill";
  const callbackUrl = `${process.env.API_URL}/api/stats/log/?action=${action}&data=${idList.join(",")}&account=${wallet.accountId}&page=${page}&points=${points}`;

  return wallet.interface.signAndSendTransactions({
    transactions: txs,
    callbackUrl: callbackUrl,
  });
}

export const handleTransferService = async (
  nftType, nftList, transferAddress, mainContract, wallet
) => {
  let txs = [];
  nftList.map(nft => {
    txs.push({
      receiverId: mainContract.contractId,
      actions: [
        {
          type: "FunctionCall",
          params: {
            methodName: isNFT(nftType) ? "transfer_zombie_monster" : "transfer_inventory_modifier",
            args: {
              token_id: nft.token_id,
              recipient_id: transferAddress.toLowerCase().trim(),
              nft_type: nft.nft_type
            },
            gas: convertToTera("80"),
            deposit: convertToYocto("0.009"),
          },
        },
      ],
    });
  });

  return wallet.interface.signAndSendTransactions({transactions: txs});
}
import React, { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { OneCollectionContent } from "../../utils/content";
import {
  Container,
  InnerPageWrapper,
  Link,
  Wrapper,
} from "../../assets/styles/common.style";
import { Loader, InnerPageHead, CardRotate } from "../../ui";
import { Footer, Header } from "../../components";
import { CommonCollection } from "./collectionTemplate/CommonCollection";
import { StakingCollection } from "./collectionTemplate/StakingCollection";
import { NearContext } from "../../contexts/NearWallet";

export const OneCollection = () => {
  const { currentUser, mainContract } = useContext(NearContext);

  const { collection_id } = useParams();
  const [isReady, setIsReady] = React.useState(false);
  const [collection, setCollection] = React.useState({});
  const [searchParams, _setSearchParams] = useSearchParams();
  const [lastMonster, setLastMonster] = useState();

  const loadCollection = async () => {
    const result = await mainContract.getOneCollection(Number(collection_id));
    result.id = parseInt(collection_id);
    setCollection(result);
    setIsReady(true);
  };

  const loadLastMonster = async () => {
    let requestParams = {
      account_id: currentUser,
      page_num: 1,
      page_limit: 1,
    };
    let monsters = await mainContract.userMonsters(requestParams);
    console.log(`monsters`, monsters);
    if (monsters[1]) {
      setLastMonster(monsters[1][0]);
    }
  };

  const mintedSuccess = () => {
    setTimeout(() => {
      loadLastMonster();
    }, 1000);
  };

  useEffect(() => {
    if (isMintedMonster()) {
      loadLastMonster();
    }

    loadCollection();
  }, []);

  const isMintedMonster = () => {
    return (
      searchParams.get("transactionHashes") &&
      searchParams.get("transactionHashes").length > 45
    );
  };

  const getMintDeposit = (list) => {
    let requiredDeposit = 0;
    list
      .filter((zombie) => zombie)
      .map((item) => {
        if (item.card_rarity === "Common") {
          requiredDeposit += 50;
        } else if (item.card_rarity === "Uncommon") {
          requiredDeposit += 100;
        } else if (item.card_rarity === "Rare") {
          requiredDeposit += 200;
        } else {
          requiredDeposit += 400;
        }
      });

    return requiredDeposit;
  };

  return (
    <>
      <InnerPageWrapper>
        <Header />

        <Wrapper>
          <Container className="text-white text-center mt-6">
            <InnerPageHead
              title={OneCollectionContent(collection.title).title}
              description={OneCollectionContent.description}
            />

            {isReady ? (
              <>
                {lastMonster && (
                  <Link to="/monsters">
                    <div
                      className="flex flex-row justify-between font-semibold mt-10 w-full sm:w-3/4 lg:w-1/2 mx-auto rounded-xl
                      mint-success transition cursor-pointer px-10 py-5"
                    >
                      {lastMonster && (
                        <div className="w-48" style={{ zoom: "0.5" }}>
                          <CardRotate nft={lastMonster} size="sm" noFlip />
                        </div>
                      )}

                      <div className="w-full pt-5 pr-10">
                        <p className="mb-1">You monster successfully minted!</p>
                        <span className="text-amber-600">
                          Check it on Monsters Page
                        </span>
                      </div>
                    </div>
                  </Link>
                )}

                <div className="md:flex flex-row text-left bg-mainLight shadow-md sm:p-12 p-6 rounded-2xl mt-10 mb-12">
                  {collection.title === "Staking" ? (
                    <StakingCollection
                      collection={collection}
                      mintedSuccess={mintedSuccess}
                      getMintDeposit={getMintDeposit}
                    />
                  ) : (
                    <CommonCollection
                      mintedSuccess={mintedSuccess}
                      collection={collection}
                      getMintDeposit={getMintDeposit}
                    />
                  )}
                </div>
              </>
            ) : (
              <Loader />
            )}
          </Container>
        </Wrapper>

        <Footer />
      </InnerPageWrapper>
    </>
  );
};

import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { Header } from "../../components";
import {
  Container,
  InnerPageWrapper,
  Wrapper,
} from "../../assets/styles/common.style";
import { useNavigate } from "react-router-dom";
import { Badge, BoltIcon, Button, InnerPageHead } from "../../ui";
import { CreateJoinBattlePopup } from "../../components/arena/CreateJoinBattlePopup";
import { BattleArenaContent } from "../../utils/content";
import { get, post } from "../../utils/api";
import S from "../../assets/styles/clanView.style";
import { useContext } from "react";
import { NearContext } from "../../contexts/NearWallet";
import { BATTLE_STATUS } from "../../../server/api/config/battleArena";
import { ClipboardListIcon, ClockIcon, XIcon } from "@heroicons/react/outline";
import { getBattleRating } from "../../../server/api/helpers/utils";
import { UserProfile } from "../../components/Avatar";
import { FireIcon } from "@heroicons/react/solid";
import { UsersOnline } from "../../components/arena/UsersOnline";

const socketUrl = process.env.API_URL;

const colorStatusMap = {
  0: { title: "APPROVING", color: "gray-500" },
  1: { title: "ACTIVE", color: "blue-500" },
  2: { title: "PLAYING", color: "orange-500" },
  3: { title: "ENDED", color: "green-500" },
};

export const ArenaBattlesList = () => {
  const navigate = useNavigate();
  const { currentUser, socialContract } = useContext(NearContext);

  const [createBattlePopupVisible, setCreateBattlePopupVisible] =
    useState(false);

  const [battle, setBattle] = useState(null);
  const [battles, setBattles] = useState([]);
  const [isReady, setIsReady] = useState(true);
  const [showHistory, setShowHistory] = useState(false);
  const [profiles, setProfiles] = useState({});
  let socket = useRef(null);

  const loadProfiles = async () => {
    const profileObj = {};
    const nsProfiles = await Promise.all(
      battles.map((battle) =>
        battle.battleArenaUsers.map(
          async (user) =>
            await socialContract.get([`${user.userId}/profile/**`])
        )
      )
    );
    battles.map((battle) =>
      battle.battleArenaUsers.map((user) => {
        let profile = nsProfiles.find((p) => p[user.userId]);
        profileObj[user.userId] = profile ? profile[user.userId] : undefined;
      })
    );

    setProfiles(profileObj);
  };

  useEffect(() => {
    if (battles) loadProfiles();
  }, [battles]);

  const fetchBattles = async () => {
    const battleResp = await get("api/battle-arena");
    if (battleResp.data) setBattles(battleResp.data);
  };

  useEffect(() => {
    socket.current = io(socketUrl);
  }, [socketUrl]);

  useEffect(() => {
    socket.current.on("update-battle-list", () => fetchBattles());

    return () => {
      socket.current.off("update-battle-list");
    };
  }, []);

  useEffect(() => {
    fetchBattles();
  }, []);

  const isJoined = (battle) =>
    battle.battleArenaUsers.some((user) => user.userId === currentUser);

  const totalPoints = (battle) => battle?.battleArenaUsers[0]?.totalPoints;

  const handleCreateSuccess = (battleId) => {
    // Redirect to battle page
    openBattlePage(battleId);
    setCreateBattlePopupVisible(false);
  };

  const openBattlePage = (battleId) => {
    navigate(`/arena/battle/${battleId}`);
  };

  const handleJoinPopup = async (battle) => {
    setBattle(battle);
    setCreateBattlePopupVisible(true);
  };

  const handleLeave = async (id) => {
    setIsReady(false);
    await post(`api/battle-arena/${id}/leave`, {
      userId: currentUser,
      endType: 0,
    });

    socket.current.emit("update-battle-list");
  };

  const getTimeDiff = (date) => {
    const now = new Date().getTime();
    const start = new Date(date).getTime();
    const diff = now - start;

    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((diff % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
  };

  const BattleUser = ({ battle, index, full }) => {
    const { winnerRating, looserRating } = getBattleRating(
      battle,
      battle.endType
    );
    const arenaUser = battle.battleArenaUsers[index]?.userId;
    const isWinner = battle.winnerUserId === arenaUser;

    return (
      <div
        className={`${
          full ? "w-72" : "w-48 md:w-60"
        } flex items-center justify-between truncate ${
          isWinner ? "text-amber-500 font-semibold" : ""
        }`}
      >
        <div className="flex items-center w-3/4">
          {arenaUser && (
            <div className="flex items-center w-full">
              <UserProfile profile={profiles} accountId={arenaUser} />
            </div>
          )}
        </div>
        {battle.status === BATTLE_STATUS.END ? (
          <div>
            <small
              className={`flex items-center ${
                isWinner ? "text-sky-500" : "font-normal text-red-500"
              }`}
            >
              {isWinner ? `+${winnerRating}` : `-${looserRating}`}
              <FireIcon className="w-4 h-4 ml-1" />
            </small>
          </div>
        ) : (
          <>
            {battle.battleArenaUsers[index]?.totalPoints && (
              <div className="text-amber-500 font-semibold">
                <small className="flex items-center">
                  <BoltIcon className="w-4 h-4 mr-1" />
                  {battle.battleArenaUsers[index]?.totalPoints}
                </small>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const ActiveBattles = () => (
    <div className="flex flex-col lg:flex-row gap-3">
      <S.SectionWrapper className="bg-transparent w-full lg:w-1/4">
        <UsersOnline profiles={profiles} />
      </S.SectionWrapper>
      <S.SectionWrapper className="w-full">
        <S.Table.Header className="flex px-3">
          <S.Table.ItemColumn className="w-4/5 flex gap-2">
            <S.Table.ItemColumn className="md:flex hidden w-12">
              <b>ID</b>
            </S.Table.ItemColumn>
            <S.Table.ItemColumn className="w-48 md:w-60">
              <b>Player vs Player</b>
            </S.Table.ItemColumn>
            <S.Table.ItemColumn className="flex-1 md:ml-6 ml-0">
              <b>ZML</b>
            </S.Table.ItemColumn>
            <S.Table.ItemColumn className="md:flex hidden flex-1">
              <b>Created At</b>
            </S.Table.ItemColumn>
            <S.Table.ItemColumn className="flex-1">
              <b>Status</b>
            </S.Table.ItemColumn>
          </S.Table.ItemColumn>
          <S.Table.ItemColumn className="flex w-1/5 gap-2 ml-3 justify-end">
            <b>Actions</b>
          </S.Table.ItemColumn>
        </S.Table.Header>
        {battles
          ?.filter((b) => !b.winnerUserId && b.status !== BATTLE_STATUS.END)
          .sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
          ?.map((battle, index) => (
            <S.Table.Content
              key={index}
              className={"one-battle rounded-lg gap-3 p-1 md:px-3"}
            >
              <S.Table.ItemColumn className="w-4/5 flex gap-2 items-center">
                <S.Table.ItemColumn className="w-10 hidden md:flex text-sm">
                  #{battle.id}
                </S.Table.ItemColumn>
                <S.Table.ItemColumn className="flex-1 mr-3">
                  <div className="flex flex-col gap-y-2">
                    <BattleUser battle={battle} index={0} />
                    <BattleUser battle={battle} index={1} />
                  </div>
                </S.Table.ItemColumn>
                <S.Table.ItemColumn className="flex-1 ml-0 md:ml-6">
                  {battle.zmlBet}
                </S.Table.ItemColumn>
                <S.Table.ItemColumn className="flex-1 hidden md:flex">
                  {getTimeDiff(battle.createdAt)[0] > 0 &&
                    `${getTimeDiff(battle.createdAt)[0]}d `}
                  {getTimeDiff(battle.createdAt)[0] === 0 &&
                    getTimeDiff(battle.createdAt)[1] > 0 &&
                    `${getTimeDiff(battle.createdAt)[1]}h `}
                  {getTimeDiff(battle.createdAt)[0] === 0 &&
                    getTimeDiff(battle.createdAt)[1] === 0 &&
                    getTimeDiff(battle.createdAt)[2] > 0 &&
                    `${getTimeDiff(battle.createdAt)[2]}m `}
                  {getTimeDiff(battle.createdAt)[0] === 0 &&
                    getTimeDiff(battle.createdAt)[1] === 0 &&
                    getTimeDiff(battle.createdAt)[2] === 0 &&
                    getTimeDiff(battle.createdAt)[3] > 0 &&
                    `${getTimeDiff(battle.createdAt)[3]}s`}
                </S.Table.ItemColumn>
                <S.Table.ItemColumn className="flex-1">
                  <Badge
                    customClassName="truncate md:max-w-max"
                    text={colorStatusMap[battle.status].title}
                    bgColor="bg-transparent"
                    borderColor={`border-${
                      colorStatusMap[battle.status].color
                    }`}
                    textColor={`text-${colorStatusMap[battle.status].color}`}
                  />
                </S.Table.ItemColumn>
              </S.Table.ItemColumn>

              <S.Table.ItemColumn className="flex w-1/5 gap-2 ml-3 justify-end">
                {isJoined(battle) ? (
                  <>
                    <Button
                      title="Open"
                      size="xs"
                      disabled={!isReady}
                      onClick={() => openBattlePage(battle.id)}
                    />
                    <Button
                      title={!isReady ? "..." : ""}
                      icon={<XIcon className="h-4 w-4" />}
                      secondary
                      size="xs"
                      disabled={!isReady}
                      onClick={() => handleLeave(battle.id)}
                    />
                  </>
                ) : battle.battleArenaUsers.length === 1 &&
                  battle.status === BATTLE_STATUS.ACTIVE ? (
                  <>
                    <Button
                      title={"Join"}
                      size="xs"
                      onClick={() => handleJoinPopup(battle)}
                      className={"ml-2"}
                    />
                  </>
                ) : (
                  battle.status === BATTLE_STATUS.END && (
                    <Button
                      disabled
                      secondary
                      title={"Read Battle Log"}
                      size="xs"
                      onClick={() => {}}
                      className={"ml-2"}
                    />
                  )
                )}
              </S.Table.ItemColumn>
            </S.Table.Content>
          ))}
      </S.SectionWrapper>
    </div>
  );

  const HistoryBattles = () => (
    <S.SectionWrapper className="w-full">
      <S.Table.Header className="hidden gap-3 md:flex px-3">
        <S.Table.ItemColumn className="flex w-16">
          <b>ID</b>
        </S.Table.ItemColumn>
        <S.Table.ItemColumn className="flex w-1/2">
          <b>Player vs Player</b>
        </S.Table.ItemColumn>
        <S.Table.ItemColumn className="flex flex-1">
          <b>Level</b>
        </S.Table.ItemColumn>
        <S.Table.ItemColumn className="flex flex-1">
          <b>Power</b>
        </S.Table.ItemColumn>
        <S.Table.ItemColumn className="flex flex-1">
          <b>Bet (ZML)</b>
        </S.Table.ItemColumn>
      </S.Table.Header>

      <S.Table.Header className="flex gap-3 md:hidden px-2">
        <S.Table.ItemColumn className="md:flex md:w-1/2">
          <b>Player vs Player</b>
        </S.Table.ItemColumn>
        <S.Table.ItemColumn className="md:flex md:flex-1">
          <b>Level</b>
        </S.Table.ItemColumn>
        <S.Table.ItemColumn className="md:flex md:flex-1">
          <b>Power</b>
        </S.Table.ItemColumn>
        <S.Table.ItemColumn className="md:flex md:flex-1">
          <b>Bet (ZML)</b>
        </S.Table.ItemColumn>
      </S.Table.Header>
      {battles
        ?.filter((b) => b.winnerUserId)
        ?.sort((a, b) => b.id - a.id)
        ?.map((battle, index) => (
          <S.Table.Content
            key={index}
            className={"one-battle rounded-lg gap-3 p-1 md:px-3"}
          >
            <S.Table.ItemColumn className="w-16 hidden md:flex text-sm">
              #{battle.id}
            </S.Table.ItemColumn>
            <S.Table.ItemColumn className="md:flex md:w-1/2">
              <div className="flex gap-y-2 flex-col">
                <BattleUser battle={battle} index={0} full />
                <BattleUser battle={battle} index={1} full />
              </div>
            </S.Table.ItemColumn>
            <S.Table.ItemColumn className="md:flex md:flex-1">
              {battle.level}
            </S.Table.ItemColumn>
            <S.Table.ItemColumn className="md:flex md:flex-1">
              {battle.battleArenaUsers[0]?.totalPoints} /{" "}
              {battle.battleArenaUsers[1]?.totalPoints}
            </S.Table.ItemColumn>
            <S.Table.ItemColumn className="md:flex md:flex-1">
              {battle.zmlBet} ZML
            </S.Table.ItemColumn>
          </S.Table.Content>
        ))}
    </S.SectionWrapper>
  );

  return (
    <InnerPageWrapper>
      <Header />

      <Wrapper>
        <Container className="text-white text-center mt-6">
          <InnerPageHead
            title={BattleArenaContent.title}
            description={BattleArenaContent.description}
          />

          <div className={"flex gap-4 justify-center flex-row mb-4"}>
            <Button
              noIcon
              onClick={() => {
                setBattle(null);
                setCreateBattlePopupVisible(true);
              }}
            >
              Create Battle
            </Button>
            <Button
              icon={
                showHistory ? (
                  <ClockIcon className="h-5 w-5 ml-2" />
                ) : (
                  <ClipboardListIcon className="h-5 w-5 ml-2" />
                )
              }
              secondary
              onClick={() => setShowHistory(!showHistory)}
            >
              {showHistory ? "Active Battles" : "Battle History"}
            </Button>
          </div>

          <div className="flex flex-col gap-y-2 w-full">
            {showHistory ? <HistoryBattles /> : <ActiveBattles />}
          </div>
        </Container>
      </Wrapper>

      <CreateJoinBattlePopup
        visible={createBattlePopupVisible}
        setVisible={setCreateBattlePopupVisible}
        handleSuccessCreate={handleCreateSuccess}
        battle={battle}
        totalPoints={totalPoints(battle)}
      />
    </InnerPageWrapper>
  );
};

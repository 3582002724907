import React, { useContext, useEffect, useRef, useState } from "react";
import {
  InnerPageWrapper,
  Wrapper,
  Container,
  Col,
} from "../../assets/styles/common.style";
import { Header, Footer } from "../../components";
import { Button, iconMapping, Loader, InnerPageHead, Badge } from "../../ui";
import { LockClosedIcon } from "@heroicons/react/outline";
import S from "../../assets/styles/clanView.style";
import { useControlUrl } from "../../utils/useControlUrl";
import { Link, useNavigate, useParams } from "react-router-dom";
import { joinClanService, kickUserService } from "../../services/ClanService";
import { CogIcon, LogoutIcon } from "@heroicons/react/outline";
import { UpgradeClanPopup } from "../../components/clan/UpgradeClanPopup";
import { EditClanPopup } from "../../components/clan/EditClanPopup";
import { RewardButton } from "../../components/clan/clan_line/RewardButton";
import { ClanBattles } from "./ClanBattles";
import { RequestClanBattlePopup } from "../../components/clan/RequestClanBattlePopup";
import { get } from "../../utils/api";
import { statusColorBorderMap, statusColorTextMap } from "../../utils/utils";
import { ClanChat } from "../../components/clan/ClanChat";
import { NearContext } from "../../contexts/NearWallet";
import {
  ArrowUpIcon,
  CheckIcon,
  UserIcon,
  UserRemoveIcon,
} from "@heroicons/react/solid";
import { FireIcon } from "@heroicons/react/outline";
import clan_image from "../../assets/images/clan_image.png";
import { useSelector } from "react-redux";
import { ClanBattleHistory } from "./ClanBattleHistory";
import { UserProfile } from "../../components/Avatar";

export const Clan = () => {
  const { wallet, mainContract, ftContract, currentUser, socialContract } =
    useContext(NearContext);
  const balance = useSelector((state) => state.user.balance);
  const { setUrlSearchParams, getSearchParam } = useControlUrl();

  const { id } = useParams();
  const navigate = useNavigate();
  const [isReady, setIsReady] = useState(false);
  const [clan, setClan] = useState();
  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [battleRequestVisible, setBattleRequestVisible] = useState(false);
  const [alreadyInClan, setAlreadyInClan] = useState(true);
  const [ratingSum, setRatingSum] = useState(0);
  const [createdBattle, setCreatedBattle] = useState();
  const [clanUsers, setClanUsers] = useState([]);
  const [currentTab, setCurrentTab] = useState({ label: "Battles", id: "2" });
  const [tabs, setTabs] = useState([]);
  const [battleStats, setBattleStats] = useState([]);
  const [profiles, setProfiles] = useState({});
  const [activeBattles, setActiveBattles] = useState(0);

  const refInitialTabParam = useRef(getSearchParam("current_tab"));
  const showBattleResult = getSearchParam("view");

  function isParticipant() {
    return clan?.participants.some(([id, _entryFee]) => id === currentUser);
  }

  const handleUpgrade = async () => {
    await mainContract.upgradeClanFake();
  };

  const fetchTabs = () => {
    const tabs = !isParticipant()
      ? [
          {
            label: "Players",
            id: "1",
            enabled: true,
            title: "Players",
          },
        ]
      : [
          {
            label: "Battles",
            id: "2",
            enabled: true,
            title:
              activeBattles > 0
                ? `Battles <small class="battleTabCounter">${activeBattles}</small>`
                : "Battles",
          },
          {
            label: "BattleHistory",
            id: "3",
            enabled: true,
            title: "Battle History",
          },
          {
            label: "Players",
            id: "1",
            enabled: true,
            title: "Players",
          },
          {
            label: "Chat",
            id: "4",
            enabled: true,
            title: "Chat",
          },
        ];
    setTabs(tabs);
    setCurrentTab(
      tabs.find(({ label }) => label === refInitialTabParam.current) ?? tabs[0]
    );
  };

  async function fetchClan() {
    const [clan, _alreadyInClan] = await mainContract.getClanById(
      id,
      currentUser
    );

    if (clan.participants.some(([id, _entryFee]) => id === currentUser)) {
      const battles = await get("api/clan-battle/all", {
        clanId: id,
        accountId: currentUser,
      });
      const activeBattles = battles.data.filter((b) => !b.winnerClanId).length;
      setActiveBattles(activeBattles);
    }

    setAlreadyInClan(_alreadyInClan);
    setClan(clan);
    getRating(clan);
    setIsReady(true);
  }

  const getRating = async (clan) => {
    const resp = await get(`api/clan/users/${clan.id}`);

    if (resp.data) {
      const users = resp.data.map((u) => {
        return !u.accountId
          ? {
              accountId: u[0],
              entryFee: u[1],
              rating: "-",
            }
          : u;
      });
      setClanUsers(users);

      const ratingSum = users
        .map((u) => u.rating | 0)
        .reduce((prev, curr) => prev + curr, 0);
      setRatingSum(clan.rating + ratingSum);
    }
  };

  const handleLeaveClan = async (clanId) => {
    if (confirm("Are you sure you want to leave the clan?")) {
      setIsReady(false);
      await mainContract.leaveClan(clanId);

      // delay reload for NEAR wallet
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const openMyClanBattles = () => {
    navigate(`/clans/${createdBattle.fromClanId}?current_tab=Battles`);
  };

  const loadCreatedBattle = async () => {
    const battleId = localStorage.getItem("pending_create_battle");
    localStorage.removeItem("pending_create_battle");
    const battle = await get(`api/clan-battle/info/${battleId}`);
    setCreatedBattle(battle.data);
  };

  const fetchClanStatistics = async () => {
    const response = await get("api/clan-battle/all", {
      clanId: id,
      accountId: currentUser,
    });
    let battles = response.data.filter((b) => b.winnerClanId);
    setBattleStats(battles);
  };

  const loadProfiles = async () => {
    const profileObj = {};
    const nsProfiles = await Promise.all(
      clanUsers.map(
        async (user) =>
          await socialContract.get([`${user.accountId}/profile/**`])
      )
    );
    clanUsers.map((user) => {
      try {
        let profile = nsProfiles.find((p) => p[user.accountId]);
        profileObj[user.accountId] = profile
          ? profile[user.accountId]
          : undefined;
      } catch (e) {
        console.log(`Error loading profile for ${user.accountId}`);
      }
    });

    setProfiles(profileObj);
  };

  useEffect(() => {
    fetchClan();
    fetchClanStatistics();
    // show "Battle Created" info
    if (localStorage.getItem("pending_create_battle")) {
      loadCreatedBattle();
    }
  }, [id]);

  useEffect(() => {
    fetchTabs();
  }, [clan]);

  useEffect(() => {
    if (clanUsers) loadProfiles();
  }, [clanUsers]);

  const changeCurrentTab = (newCurrentTab) => {
    setCurrentTab(newCurrentTab);
    setUrlSearchParams("current_tab", newCurrentTab.label);
  };

  const handleSuccessUpdate = () => {
    fetchClan();
  };

  const isOwner = () => clan.owner_id === currentUser;

  const ClanButtons = () => {
    let renderBtns = [];

    if (clan.participants.length < clan.size && !alreadyInClan) {
      !clan.locked
        ? (renderBtns = [
            <Button
              title={`JOIN FOR ${clan.entry_fee} ZML`}
              size="md"
              key={"join" + clan.id}
              icon={<CheckIcon className={`ml-2 ${iconMapping["md"]}`} />}
              onClick={async () => {
                setIsReady(false);
                await joinClanService(
                  balance,
                  clan,
                  wallet,
                  mainContract,
                  ftContract
                );
              }}
            />,
          ])
        : (renderBtns = [
            <div className="flex items-center justify-center">
              <div className="border-2 border-gray-500 flex rounded-lg py-2 px-3 items-center justify-center">
                <span className="text-gray-400 text-sm uppercase font-semibold mr-2">
                  Locked
                </span>
                <LockClosedIcon className="h-5 w-full text-gray-400" />
              </div>
            </div>,
          ]);
    }

    if (isOwner()) {
      renderBtns.push(
        <RewardButton id={id} clan={clan} key={"rw" + clan.id} />,
        <Button
          title="UPGRADE CLAN"
          size="md"
          key={"up" + clan.id}
          secondary
          icon={<ArrowUpIcon className={`ml-2 ${iconMapping["md"]}`} />}
          onClick={() => setVisible(true)}
        />,
        <EditClanPopup
          clan={clan}
          key={"edit-popup" + clan.id}
          visible={visibleEdit}
          setVisible={setVisibleEdit}
          handleSuccessUpdate={handleSuccessUpdate}
        />,
        <Button
          title="Edit"
          size="md"
          key={"edit" + clan.id}
          secondary
          icon={<CogIcon className="ml-2 w-7 h-7" />}
          onClick={() => setVisibleEdit(true)}
        />
      );
    } else if (isParticipant()) {
      renderBtns = [
        <RewardButton id={id} clan={clan} key={"rw" + clan.id} />,
        <Button
          title="UPGRADE CLAN"
          size="md"
          key={"up" + clan.id}
          secondary
          icon={<ArrowUpIcon className={`ml-2 ${iconMapping["md"]}`} />}
          onClick={() => setVisible(true)}
        />,
        <Button
          title={!isReady ? "LEAVING ..." : "LEAVE CLAN"}
          size="md"
          secondary
          key={"leave" + clan.id}
          icon={<LogoutIcon className={`ml-2 ${iconMapping["md"]}`} />}
          disabled={!isReady}
          onClick={() => handleLeaveClan(clan.id)}
        />,
      ];
    } else if (alreadyInClan) {
      if (createdBattle) {
        renderBtns = [
          <div
            key={"info-battle"}
            onClick={() => openMyClanBattles()}
            className="text-amber-500 px-8 py-6 bg-mainLight hover:text-amber-400 rounded-lg cursor-pointer"
          >
            <b>Battle request successfully created!</b> <br />
            Check participants and battle results in your clan.
          </div>,
        ];
      } else {
        renderBtns = [
          <Button
            title="REQUEST A BATTLE"
            size="md"
            key={"req" + clan.id}
            noIcon
            onClick={() => setBattleRequestVisible(true)}
          />,
        ];
      }
    }

    return (
      <div className="flex justify-center gap-3 flex-col sm:flex-row">
        {renderBtns}
      </div>
    );
  };

  const bestMatchPoints = () =>
    battleStats
      .map(
        (b) =>
          b.points &&
          (b.fromClan.id === id
            ? [b.points.fromClan.total, b.cardRarity]
            : [b.points.toClan.total, b.cardRarity])
      )
      .sort((a, b) => b[0] - a[0])[0];

  const winningGames = () =>
    clan && battleStats.filter((b) => b.winnerClanId === clan.id).length;

  const totalGames = () =>
    clan &&
    battleStats.filter(
      (b) => b.fromClan.id === clan.id || b.toClan.id === clan.id
    ).length;

  const wonLastGame = (accountId) => {
    const clanBattles = battleStats.filter(
      (b) => b.fromClan.id === clan.id || b.toClan.id === clan.id
    );
    const userLastBattle = clanBattles.filter((b) => {
      const userInFrom = b.fromClan.participants.some(
        (u) => u.accountId === accountId
      );
      const userInTo = b.toClan.participants.some(
        (u) => u.accountId === accountId
      );

      return userInFrom || userInTo;
    });
    if (userLastBattle.length === 0) return;

    const lastBattle = userLastBattle.reverse()[0];

    return lastBattle.winnerClanId === clan.id;
  };

  const successJoinedBattle = () => {
    // wallet.id
    if (
      wallet.interface.id !== "near-wallet" &&
      wallet.interface.id !== "my-near-wallet"
    ) {
      // show "Battle Created" info
      if (localStorage.getItem("pending_create_battle")) {
        loadCreatedBattle();
      }
    }
  };

  const handleKickUser = (user) => {
    setIsReady(false);
    kickUserService(
      user.accountId,
      user.entryFee,
      clan,
      wallet,
      mainContract,
      ftContract
    ).finally(() => {
      fetchClan();
      setIsReady(true);
    });
  };

  return (
    <InnerPageWrapper>
      <Header />
      <Wrapper>
        {isReady ? (
          <Container className="text-center items-center mt-6">
            <InnerPageHead title={decodeURIComponent(clan.name)} />
            <div className="md:w-full w-64 mx-auto relative">
              <Link
                to={"/clans"}
                className={`absolute left-0 top-3 text-4xl font-semibold opacity-50 transition hover:opacity-90 cursor-pointer`}
              >
                &laquo; <small className={"text-lg align-middle"}>Clans</small>
              </Link>
              <ClanButtons />
            </div>

            <RequestClanBattlePopup
              title="Request a Battle"
              btnTitle="Create Battle Request"
              clan={clan}
              visible={battleRequestVisible}
              setVisible={setBattleRequestVisible}
              isCreate
              successJoined={() => successJoinedBattle()}
            />
            <UpgradeClanPopup
              clan={clan}
              visible={visible}
              setVisible={setVisible}
              handleSuccessUpdate={handleSuccessUpdate}
            />

            <S.InfoContainer>
              <S.SectionWrapper className="flex flex-row w-full">
                <Col className="w-full relative">
                  <div className="flex flex-col md:flex-row gap-5 items-start">
                    <Col className="w-full md:w-1/3">
                      <img
                        className="object-cover rounded-lg border-2 border-mainLight"
                        src={clan.media ?? clan_image}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = clan_image;
                        }}
                        alt="Clan"
                      />
                    </Col>
                    <Col className="w-full md:w-2/3">
                      <div className="flex items-center mb-4 md:absolute right-5 top-2">
                        <span className="text-amber-600 flex font-semibold text-2xl items-center">
                          <FireIcon
                            className="h-7 mr-1"
                            onClick={handleUpgrade}
                          />{" "}
                          {ratingSum}
                        </span>
                      </div>
                      <S.HeadingTertiary className={"mt-1"}>
                        Clan Owner:
                        <span className={"ml-1 text-white font-normal"}>
                          {clan.owner_id}
                        </span>
                      </S.HeadingTertiary>
                      <S.HeadingTertiary>
                        Participants:
                        <span className={"ml-1 text-white font-normal"}>
                          {clan.participants.length} / {clan.size}
                        </span>
                      </S.HeadingTertiary>
                      <S.HeadingTertiary>
                        Entry Fee:
                        <span className={"ml-1 text-white font-normal"}>
                          {clan.entry_fee} ZML
                        </span>
                      </S.HeadingTertiary>

                      {clan.about && (
                        <>
                          <S.HeadingTertiary className={"mt-4"}>
                            About Clan:
                          </S.HeadingTertiary>
                          <S.DetailsTertiary className="whitespace-pre-line text-sm">
                            {decodeURIComponent(clan.about) ?? "-"}
                          </S.DetailsTertiary>
                        </>
                      )}
                    </Col>
                  </div>

                  {battleStats?.length > 0 && (
                    <>
                      <S.HeadingTertiary className="mt-5">
                        Battle History
                      </S.HeadingTertiary>
                      <S.DetailsTertiary>
                        <div className="flex flex-col my-1 gap-x-2">
                          <div className="flex sm:flex-row flex-col w-full ">
                            {[
                              ...battleStats.slice(0, 5),
                              ...Array(5 - battleStats.slice(0, 5).length),
                            ].map((battle, index) => (
                              <div
                                key={index}
                                className="flex sm:w-1/5 flex-row gap-2"
                              >
                                {battle ? (
                                  <div
                                    key={index}
                                    className={`flex p-1 flex-col w-full border-2 rounded-lg border-mainLight text-white font-bold items-center ${
                                      battle.winnerClanId === id
                                        ? "bg-green-500"
                                        : "bg-red-500"
                                    }`}
                                  >
                                    {battle.cardRarity}
                                  </div>
                                ) : (
                                  <div
                                    key={index}
                                    className={`flex p-1 flex-col w-full border-2 rounded-lg border-mainLight text-white font-bold items-center bg-gray-600`}
                                  >
                                    -
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </S.DetailsTertiary>
                      <S.DetailsTertiary>
                        <div className="flex gap-2 sm:flex-row flex-col">
                          <div className="pt-2 pb-4 px-4 bg-main rounded-md flex flex-col sm:w-72 items-center">
                            <div className="my-2 uppercase text-sm  text-gray-500">
                              Best Match Point
                            </div>
                            <div className=" zombie-font text-6xl text-amber-600 text-center mt-5 mb-2">
                              {bestMatchPoints()[0].toFixed(1) || "-"}
                            </div>
                            <Badge
                              text={bestMatchPoints()[1] || "-"}
                              borderColor={statusColorBorderMap(
                                bestMatchPoints()[1]
                              )}
                              textColor={statusColorTextMap(
                                bestMatchPoints()[1]
                              )}
                            />
                          </div>
                          <div className="pt-2 pb-4 px-4 bg-main rounded-md flex flex-col sm:w-72 items-center">
                            <div className="my-2 uppercase text-sm  text-gray-500">
                              Won / Total games
                            </div>
                            <div className=" zombie-font text-6xl text-center mt-5 mb-2">
                              <span className="text-green-500">
                                {winningGames()}
                              </span>
                              /<span>{totalGames()}</span>
                            </div>
                            <div className="text-sm text-gray-500">
                              Win percentage:{" "}
                              {parseInt((winningGames() / totalGames()) * 100)}%
                            </div>
                          </div>
                          <div className="py-2 px-4 bg-main rounded-md w-full">
                            <div className="flex-row my-2 uppercase text-sm items-center text-gray-500">
                              TOP 5 Fighters
                            </div>
                            {battleStats.length ? (
                              <div className=" text-amber-600">
                                {clanUsers
                                  .sort((a, b) => b.rating - a.rating)
                                  .slice(0, 5)
                                  .map((user, index) => (
                                    <div
                                      key={index}
                                      className="flex justify-between gap-x-1 py-1 text-sm border-b border-gray-700 border-dashed last:border-b-0"
                                    >
                                      <div className=" text-indigo-100 flex flex-row items-center">
                                        <div className="truncate md:w-60 w-full">
                                          {user.accountId}
                                        </div>
                                      </div>
                                      <div className="flex">
                                        <div className="text-indigo-300 w-12">
                                          {user.localRating}
                                        </div>
                                        <div className="text-indigo-300 w-12">
                                          {user.rating}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            ) : (
                              <div className="font-bold text-amber-600">-</div>
                            )}
                          </div>
                        </div>
                      </S.DetailsTertiary>
                    </>
                  )}
                </Col>
              </S.SectionWrapper>
            </S.InfoContainer>

            <section className="w-full">
              <S.TabContainer>
                {tabs.map((tab) => (
                  <S.Tab
                    key={tab.id}
                    onClick={() => changeCurrentTab(tab)}
                    selectedTab={tab.id === currentTab.id}
                    enabled={tab.enabled}
                    dangerouslySetInnerHTML={{ __html: tab.title }}
                  />
                ))}
              </S.TabContainer>

              {currentTab.id === "1" && (
                <S.SectionWrapper className="w-full">
                  <S.Table.Header>
                    <S.Table.ItemColumn className="flex w-1/2">
                      <UserIcon className="h-4 mr-2" />
                      <b>User Account</b>
                    </S.Table.ItemColumn>
                    <S.Table.ItemColumn className="flex-1">
                      <b>Monthly Rating</b>
                    </S.Table.ItemColumn>
                    <S.Table.ItemColumn className="flex-1">
                      <b>Global Rating</b>
                    </S.Table.ItemColumn>
                    <S.Table.ItemColumn className="flex-1">
                      <b>Role</b>
                    </S.Table.ItemColumn>
                    {isOwner() && (
                      <S.Table.ItemColumn className="flex-1">
                        <b>Actions</b>
                      </S.Table.ItemColumn>
                    )}
                  </S.Table.Header>
                  {clanUsers.map((user, index) => (
                    <S.Table.Content key={index}>
                      <S.Table.ItemColumn className="w-1/2">
                        <UserProfile
                          profile={profiles}
                          accountId={user.accountId}
                        />
                      </S.Table.ItemColumn>
                      <S.Table.ItemColumn className="flex-1">
                        {user.localRating}
                      </S.Table.ItemColumn>
                      <S.Table.ItemColumn className="flex-1">
                        {user.rating}
                      </S.Table.ItemColumn>
                      <S.Table.ItemColumn className="flex-1">
                        {clan.owner_id === user.accountId ? (
                          <Badge text="admin" bgColor="bg-violet-700" />
                        ) : (
                          <Badge text="member" bgColor="bg-green-700" />
                        )}
                      </S.Table.ItemColumn>
                      {isOwner() && (
                        <S.Table.ItemColumn className="flex-1">
                          {clan.owner_id !== user.accountId && (
                            <Button
                              size="xs"
                              disable={!isReady}
                              secondary
                              icon={<UserRemoveIcon className="w-4 h-4" />}
                              className={"hidden md:inline"}
                              onClick={() => handleKickUser(user)}
                            />
                          )}
                        </S.Table.ItemColumn>
                      )}
                    </S.Table.Content>
                  ))}
                </S.SectionWrapper>
              )}

              {isParticipant() && (
                <>
                  {currentTab.id === "2" && (
                    <ClanBattles clan={clan} alreadyInClan={alreadyInClan} />
                  )}

                  {currentTab.id === "3" && (
                    <ClanBattleHistory
                      clan={clan}
                      showBattleResult={showBattleResult}
                    />
                  )}

                  {currentTab.id === "4" && (
                    <ClanChat clan={clan} alreadyInClan={alreadyInClan} />
                  )}
                </>
              )}
            </section>
          </Container>
        ) : (
          <Loader />
        )}
      </Wrapper>
      <Footer />
    </InnerPageWrapper>
  );
};

import React, { useContext, useEffect } from "react";
import {
  Container,
  InnerPageWrapper,
  Wrapper,
} from "../assets/styles/common.style";
import { Header, Footer } from "../components";
import { Button, InnerPageHead } from "../ui";
import { NearContext } from "../contexts/NearWallet";

export const InviteJoin = () => {
  const {isSigned, wallet} = useContext(NearContext);

  useEffect(() => {
    localStorage.setItem("zomland:isCommunityBonusZML", "true");
  }, []);

  return (
    <InnerPageWrapper>
      <Header />

      <Wrapper>
        <Container className="text-white text-center mt-6 mx-auto xl:w-3/4">
          <InnerPageHead title={"Welcome to Zomland"} />
          <div>
            <a href={"https://youtu.be/nUniZjwRxLo"}
               target={"_blank"}
               className={"font-semibold text-lg mb-4 text-sky-300 hover:text-sky-400 hover:underline"}>
              Short instruction, how to join and play Zomland
            </a>
            <div className={""}>

            </div>
          </div>
          <div className={"mt-16 bg-mainLight/80 border-2 border-mainLight w-[680px] mx-auto px-10 pt-10 pb-3 rounded-md"}>
            {isSigned ? (
              <div>
                <>
                  <p className={"font-semibold text-xl mb-5"}>
                    STEP 2: Claim your Land and mint Zombies!
                  </p>
                  <ul className={"text-left ml-16"}>
                    <li className={"mb-2"}>1. <a href="/lands" className={"text-sky-300 hover:text-sky-400 hover:underline"}
                                                 target={"_blank"}>
                      Claim a Land NFT
                    </a> for an immediate 200 ZML token reward!
                    </li>
                    <li className={"mb-2"}>2. <a href="/zombies" className={"text-sky-300 hover:text-sky-400 hover:underline"}
                                                 target={"_blank"}>
                      Mint zombies daily
                    </a> from your Lands.
                    </li>
                    <li className={"mb-2"}>3. Kill/Sell zombies in the <a href="http://localhost:1234/market/zombies?page=1"
                                                                          className={"text-sky-300 hover:text-sky-400 hover:underline"}
                                                                          target={"_blank"}>internal market</a>
                      <span className={"px-1"}>and</span>
                      <a href="/clans"
                         className={"text-sky-300 hover:text-sky-400 hover:underline"}
                         target={"_blank"}>
                        join clan battles
                      </a> to earn more.
                    </li>
                    <li>
                      <p>4. Stay updated and join our community for upcoming events:<p></p></p>
                      <a href="https://twitter.com/Zomland_Game" target={"_blank"}
                         className={"text-sky-300 hover:text-sky-400 hover:underline"}>twitter</a> and
                      <a href="https://discord.com/invite/Te3GkcJPjt" target={"_blank"}
                         className={"pl-1 text-sky-300 hover:text-sky-400 hover:underline"}>discord</a>.
                    </li>
                  </ul>

                  <div className={"mx-auto w-[560px] my-4"}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/nUniZjwRxLo?si=Jou9atqnT6ZgNZlm" title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                  </div>
                </>

                <p className={"mt-8 text-gray-400 border-t-2 border-white/20 pt-3"}>
                  <small>NOTE: You need at least 0.5 NEAR in your wallet balance for gas. Only for new users!</small>
                </p>
              </div>
            ) : (
              <div>
                <p className={"font-semibold text-lg mb-5"}>
                  <span className={"badge"}>STEP 1:</span> To start play Zomland you need to login using NEAR wallet.
                </p>

                <div className={"mx-auto w-[560px] my-4"}>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/nUniZjwRxLo?si=Jou9atqnT6ZgNZlm" title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>

                <Button secondary title="Log In" onClick={() => wallet.signIn()} />

                <p className={`mt-8 text-gray-400 border-t-2 border-white/20 pt-3`}>
                  Do not have NEAR wallet?
                  You can <a href="https://chromewebstore.google.com/detail/meteor-wallet/pcndjhkinnkaohffealmlmhaepkpmgkb?pli=1"
                             className={"text-sky-300 hover:text-sky-400 hover:underline"}
                             target={"_blank"}>
                  create new NEAR wallet
                </a>.
                </p>
              </div>
            )}
          </div>

        </Container>
      </Wrapper>

      <Footer />
    </InnerPageWrapper>
  )
}
import React, { useContext, useEffect, useState } from "react";
import { Button, Loader, Popup, CardItem } from "../../ui";
import { transformItem } from "../../utils/utils";
import { NearContext } from "../../contexts/NearWallet";
import { post } from "../../utils/api";

export const ClanDailyRewardResult = ({
  popupVisible,
  setPopupVisible,
  rewardType,
  rewardValue,
  itemDurability,
}) => {
  const { currentUser, mainContract } = useContext(NearContext);
  const [isReady, setIsReady] = useState(false);
  const [reward, setReward] = useState([]);

  const loadRewardDetails = async () => {
    if (rewardType === "1") {
      let foundModifiers = [];
      const userModifiers = await mainContract.userModifierItems({
        account_id: currentUser,
        page_num: 1,
        page_limit: 12,
      });
      userModifiers[1]
        .filter((item) => rewardValue.includes(item.token_id))
        .map((item) => foundModifiers.push(transformItem(item)));
      setReward(foundModifiers);
    } else if (rewardType === "2") {
      let foundItems = [];
      const inventoryItems = await mainContract.userInventoryItems({
        account_id: currentUser,
        page_num: 1,
        page_limit: 12,
      });

      inventoryItems[1]
        .filter((item) => rewardValue.includes(item.token_id))
        .map((item) => {
          let foundItem = transformItem(item);
          foundItem.durabilityMin = itemDurability;
          foundItem.durabilityMax = itemDurability;

          foundItems.push(foundItem);
        });

      await post("api/items/create", { items: foundItems });
      setReward(foundItems);
    } else {
      setReward([rewardValue]);
    }
  };

  useEffect(() => {
    setIsReady(false);
    if (popupVisible) {
      loadRewardDetails().then(() => {
        setIsReady(true);
      });
    }
  }, [popupVisible]);

  return (
    <>
      <Popup
        title="Clan Daily Reward"
        popupVisible={popupVisible}
        setPopupVisible={setPopupVisible}
      >
        <div className="gap-5 px-6 mb-5 flex flex-col">
          {isReady ? (
            <>
              <div className="text-center pt-4">
                {rewardType === "1" && (
                  <>
                    <p className="mb-4">
                      Your daily reward:{" "}
                      <span className="text-lg font-semibold">
                        Zombie Modifier
                      </span>
                      .
                    </p>
                    <div>
                      {reward.length > 0 ? (
                        <>
                          <div className="flex flex-wrap gap-3 justify-center">
                            {reward.map((item) => (
                              <CardItem
                                item={item}
                                key={item.token_id}
                                size="sm"
                                noFlip
                              />
                            ))}
                          </div>
                        </>
                      ) : (
                        <Loader />
                      )}
                    </div>
                  </>
                )}

                {rewardType === "2" && (
                  <>
                    <p className="mb-4">
                      Your daily reward:{" "}
                      <span className="text-lg font-semibold">
                        Inventory Item
                      </span>
                      .
                    </p>
                    {reward.length > 0 ? (
                      <>
                        <div className="flex flex-wrap gap-3 justify-center">
                          {reward.map((item) => (
                            <CardItem
                              isItem
                              item={item}
                              key={item.token_id}
                              size="sm"
                              noFlip
                            />
                          ))}
                        </div>
                      </>
                    ) : (
                      <Loader />
                    )}
                  </>
                )}

                {rewardType === "3" && (
                  <>
                    <p className="mb-4">
                      Your daily reward:{" "}
                      <span className="text-lg font-semibold">
                        +{reward[0]} ZML
                      </span>
                    </p>
                  </>
                )}

                <div className="mt-6">
                  <Button
                    title="Close Popup"
                    onClick={() => setPopupVisible(false)}
                    secondary
                    noIcon
                  />
                </div>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>
      </Popup>
    </>
  );
};

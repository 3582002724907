import React, {useContext, useState} from "react";
import { Popup } from "../ui";
import {NearContext} from "../contexts/NearWallet";
import nearLogo from "../assets/images/near-white.png";
import meterLogo from "../assets/images/meter-white.png";

// wallet.signIn()

export const SelectNetwork = ({
  networkPopupVisible,
  setNetworkPopupVisible,
}) => {
  const { wallet } = useContext(NearContext);

  return (
    <Popup
      title={"Chose Blockchain"}
      popupVisible={networkPopupVisible}
      width="lg:w-[600px]"
      setPopupVisible={setNetworkPopupVisible}
    >
      <div className="sm:mt-2 sm:px-10">
        <p className={"text-sm mb-10 -mt-4"}>You can access ZomLand from different blockchains. <br/>Make your choice:</p>

        <div className={"flex flex-row justify-center gap-24 mb-4"}>
          <div
            onClick={() => {
              wallet.signIn();
              setNetworkPopupVisible(false);
            }}
            className={"text-center opacity-60 hover:opacity-100 transition cursor-pointer"}>
            <img src={nearLogo} alt="NEAR" width={100} />
            <div className={"text-2xl mt-4 font-semibold"}>NEAR</div>
          </div>
          <a href={"https://mtr.zomland.com/?login=true"} className={"block text-center opacity-60 hover:opacity-100 transition cursor-pointer"}>
            <img src={meterLogo} alt="METER" width={100} />
            <div className={"text-2xl mt-4 font-semibold"}>METER</div>
          </a>
        </div>

      </div>
    </Popup>
  );
};

import React, { useContext, useEffect, useState } from "react";
import { MonsterContent } from "../../utils/content";
import { List } from "../../assets/styles/common.style";
import { ListWrapper } from "../../assets/styles/common.style";
import { Header, Footer, SelectedItemsFooter } from "../../components";
import {
  Loader,
  Button,
  InnerPageHead,
  Pagination,
  CardRotate, BoltIcon, formatCharacteristicOfCard,
} from "../../ui";
import { Dropdown } from "../../ui";
import { Api } from "../../db/api";
import { useNavigate } from "react-router-dom";
import { NearContext } from "../../contexts/NearWallet";
import {
  rarityOptions,
  selectAll,
  transformZombieMonster,
} from "../../utils/utils";
import {
  Container,
  InnerPageWrapper,
  Link,
  Wrapper,
} from "../../assets/styles/common.style";

const PAGE_LIMIT = 20;

export const Monsters = () => {
  const { mainContract, currentUser } = useContext(NearContext);
  const [isReady, setIsReady] = useState(false);
  const [userMonsters, setUserMonsters] = useState([0, []]); // [<count>, [<arrayOfMonsters>]]
  const [currentPage, setCurrentPage] = useState(1);
  const [filterRarity, setFilterRarity] = useState(null);
  const navigate = useNavigate();
  const [selectedMonsters, setSelectedMonsters] = useState([]);
  const [reverse, setReverse] = useState(true);

  async function fetchUserMonsters(currentPage, rarity) {
    let requestParams = {
      account_id: currentUser,
      page_num: currentPage,
      page_limit: PAGE_LIMIT,
    };
    if (rarity) {
      requestParams["filter_rarity"] = rarity;
    }
    let monsters = await mainContract.userMonsters(requestParams);

    // Convert price from Yocto NEAR
    monsters[1] = monsters[1].map((mn) => transformZombieMonster(mn));

    setUserMonsters(monsters);
    setIsReady(true);

    // update leaderboard
    if (!rarity) {
      let api = new Api();
      api.setUserLeaderboardCount(currentUser, "monsters", monsters[0]);
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = JSON.parse(searchParams.has("page"))
      ? parseInt(searchParams.get("page"))
      : currentPage;
    const rarity = JSON.parse(searchParams.has("rarity"))
      ? searchParams.get("rarity")
      : filterRarity;

    setCurrentPage(page);
    setFilterRarity(rarity);
    fetchUserMonsters(page, rarity);
  }, []);

  useEffect(() => {
    if (isReady) {
      setCurrentPage(1);
      fetchUserMonsters(1, filterRarity);
      navigate(buildUrl());
    }
  }, [filterRarity]);

  useEffect(() => navigate(buildUrl()), [currentPage]);

  const buildUrl = () => {
    let url = `/monsters?page=${currentPage}`;
    if (filterRarity) url = `${url}&rarity=${filterRarity}`;

    return url;
  };

  const onPageChanged = (page) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentPage(page);
    fetchUserMonsters(page, filterRarity);
  };

  const rmFromMarket = async (monster) => {
    setIsReady(false);
    await mainContract.removeFromMarket(monster.token_id, monster.nft_type);
    await fetchUserMonsters(currentPage, filterRarity);
    setIsReady(true);
  };

  const selectMonster = (monster) => {
    const findItem = selectedMonsters.findIndex(
      (value) => value.token_id === monster.token_id
    );
    if (findItem < 0)
      return setSelectedMonsters((prevState) => [...prevState, monster]);
    return setSelectedMonsters(
      selectedMonsters.filter((_, ind) => findItem !== ind)
    );
  };

  const power = (nft) =>
    formatCharacteristicOfCard(nft)
      .map((item) => item.value)
      .reduce((prev, curr) => prev + curr, 0);

  const sortByPower = () => {
    const monsters = userMonsters[1].sort((a, b) =>
      reverse ? power(b) - power(a) : power(a) - power(b)
    );
    setReverse(!reverse);
    setUserMonsters([userMonsters[0], monsters]);
  };

  const isSelected = (monsterId) =>
    selectedMonsters.filter((monster) => monster.token_id === monsterId)
      .length > 0;

  return (
    <InnerPageWrapper>
      <Header />

      <Wrapper>
        <Container className="text-white text-center mt-6">
          <InnerPageHead
            title={MonsterContent.title}
            description={MonsterContent.description}
          />

          {isReady ? (
            <>
              <div className="lg:flex lg:items-center lg:justify-between z-30 relative mt-8">
                <div className="flex justify-center items-center lg:justify-start space-y-2 flex-wrap text-lg text-left spl-1 lg:w-5/12">
                  <section className="flex items-center pt-2">
                    Available:
                    <span
                      className="ml-2 font-semibold text-amber-600 inline-block cursor-pointer"
                      onClick={() =>
                        selectAll(
                          selectedMonsters,
                          setSelectedMonsters,
                          userMonsters[1]
                        )
                      }
                    >
                      {userMonsters[0]} NFTs
                    </span>
                  </section>
                </div>
                <div className="lg:1/6">
                  <Button
                    title="Mint Monster"
                    noIcon
                    onClick={() => navigate("/collections")}
                  />
                </div>
                <div className="flex flex-wrap justify-center lg:justify-end md:justify-center space-y-2 z-10 sm:text-right ml-2 mt-3 sm:mt-0 lg:w-5/12">
                  <div className="inline-block mr-2 pt-2">
                    <Button
                      title=""
                      secondary
                      size="sm"
                      icon={
                        <div className="py-1">
                          <BoltIcon />
                        </div>
                      }
                      onClick={sortByPower}
                    />
                  </div>
                  <Dropdown
                    title="Rarity"
                    selected={filterRarity}
                    options={rarityOptions(setFilterRarity)}
                  />
                </div>
              </div>

              <ListWrapper>
                {userMonsters[0] > 0 ? (
                  <List>
                    {userMonsters[1]?.map((monster) => (
                      <CardRotate
                        key={monster.token_id}
                        nft={monster}
                        rmFromMarket={() => rmFromMarket(monster)}
                        handleSelect={() => selectMonster(monster)}
                        isSelected={isSelected(monster.token_id)}
                      />
                    ))}
                    {/*{userMonsters[1]?.map((monster, index) => (*/}
                    {/*    <Card*/}
                    {/*      nft={monster}*/}
                    {/*      key={index}*/}
                    {/*      sellItems={sellList["monsters"]}*/}
                    {/*      setSellItems={() => appendToSellList(monster)}*/}
                    {/*      rmFromMarket={() => rmFromMarket(monster)}*/}
                    {/*      setKillItem={() => appendToKillList(monster)}*/}
                    {/*      setTransferPopupVisible={() => {*/}
                    {/*        setTransferPopupVisible(true);*/}
                    {/*        setTransferNft(monster);*/}
                    {/*      }}*/}
                    {/*    />*/}
                    {/*))}*/}
                  </List>
                ) : (
                  <div>
                    You don't have {filterRarity} Monsters.
                    {!filterRarity && (
                      <p>
                        To get your first Monster you can complete{" "}
                        <Link className="link" to="/collections">
                          Collection
                        </Link>{" "}
                        or buy it in the{" "}
                        <Link className="link" to="/market/monsters">
                          Market
                        </Link>
                        .
                      </p>
                    )}
                  </div>
                )}
              </ListWrapper>

              {filterRarity && (
                <div className="mt-10">
                  <a
                    className="link cursor-pointer"
                    onClick={() => setFilterRarity(null)}
                  >
                    Reset Filters
                  </a>
                </div>
              )}

              <div className="mb-8">
                <Pagination
                  total={parseInt(userMonsters[0])}
                  limit={PAGE_LIMIT}
                  selectedPage={currentPage}
                  onPageChanged={onPageChanged}
                />
              </div>
            </>
          ) : (
            <div className="mt-6">
              <Loader />
            </div>
          )}
        </Container>
      </Wrapper>

      {selectedMonsters.length > 0 && (
        <SelectedItemsFooter
          selectedItems={selectedMonsters}
          nftType="Monster"
          deselectItem={selectMonster}
          setSelectedItems={setSelectedMonsters}
          items={userMonsters[1]}
          handleReloadData={() => {
            setSelectedMonsters([]);
            fetchUserMonsters(currentPage, filterRarity);
          }}
        />
      )}

      <Footer />
    </InnerPageWrapper>
  );
};

import React, { useContext, useState } from "react";
import { ArrowUpIcon } from "@heroicons/react/solid";
import { Button, iconMapping, Input, Popup } from "../../ui";
import { upgradeClanService } from "../../services/ClanService";
import { NearContext } from "../../contexts/NearWallet";
import { Col } from "../../assets/styles/common.style";

export const UpgradeClanPopup = ({
  clan,
  setVisible,
  visible,
  handleSuccessUpdate,
}) => {
  const { mainContract } = useContext(NearContext);
  const [deposit, setDeposit] = useState(0);

  const handleUpdate = () => {
    upgradeClanService(clan, deposit, mainContract).then(() => {
      handleSuccessUpdate();
      setVisible(false);
    });
  };

  return (
    <Popup
      title="Upgrade Clan"
      popupVisible={visible}
      setPopupVisible={setVisible}
    >
      <div className="gap-5 px-6 mb-5 flex flex-col">
        <Col className="text-left gap-2">
          <label>Enter amount of NEAR</label>
          <Input
            type="number"
            handleChange={(e) => setDeposit(e.target.value)}
            value={deposit}
          />
        </Col>
        <span className="text-amber-600 font-semibold">
          Rating +{deposit * 100}
        </span>
      </div>
      <Button
        title="UPGRADE CLAN"
        size="md"
        secondary
        disabled={deposit <= 0}
        icon={<ArrowUpIcon className={`ml-2 ${iconMapping["md"]}`} />}
        onClick={() => handleUpdate()}
      />
    </Popup>
  );
};

import { convertToTera } from "../utils/utils";

class SocialContract {
  contractId = "";
  wallet = null;

  constructor({ contractId, walletToUse }) {
    this.contractId = contractId;
    this.wallet = walletToUse;
  }

  /**
   * Read Data
   * @param keys
   * @returns {Promise<any>}
   */
  async get(keys) {
    try {
      return await this.wallet.viewMethod({
        contractId: this.contractId,
        method: "get",
        args: {
          keys,
        },
      });
    } catch (e) {
      console.error(`blockchain error`, e);
    }
  }
}

export default SocialContract;

"use strict";
/**
 * NEAR RPC API request types and responses
 * @module
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Provider = void 0;
/** @hidden */
class Provider {
}
exports.Provider = Provider;

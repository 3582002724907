const TURN_TIME = 30; // 30 seconds
const MAX_TURNS = 100; // 100 turns to end game automatically
const SLEEP_TURNS = 3; // 3 turns when opponent not playing. auto finish battle

const BATTLE_RATING_COEF = 50; // rating multiplier
const BATTLE_RATING_LOOSER_PERCENT = 0.1; // looser will lose 10% from winner's rating
const BATTLE_RATING_LOOSER_LEAVE_PERCENT = 1; // leaver will lose 100% from winner's rating

const BATTLE_STATUS = {
  PENDING: 0,
  ACTIVE: 1,
  PLAY: 2,
  END: 3,
};

const BATTLE_OBSTACLE_TYPES = [0, 1];
const BATTLE_OBSTACLES_COUNT = [0, 5];
const BATTLE_OBSTACLES_AREA = [
  [3, 5],
  [0, 5],
];

const MAP_TEXTURE_IDS = [0, 1, 2];

const INVENTORY_TYPE = {
  Shield: 0,
  Armor: 0,
  Sword: 1,
  Bow: 2,
  Bomb: 3,
  Health: 4,
  Poison: 5,
  Potion: 6,
};

const INVENTORY_TYPE_1 = ["Shield", "Armor", "Sword", "Bow"];

const GAME_OBJECT = "ReactEventsHandler";

const UNIT_COLLECTION_DAMAGE_MAP = {
  // col.    |  punk   | pirate  | mummy | stylish | combat | staking
  // punk    |    0    |    1    |   1   |    2    |   0    |    1
  // pirate  |    1    |    0    |   0   |    1    |   2    |    1
  // mummy   |    1    |    2    |   0   |    0    |   1    |    1
  // stylish |    0    |    1    |   2   |    0    |   1    |    1
  // combat  |    2    |    0    |   1   |    1    |   0    |    1
  // staking |    1    |    1    |   1   |    1    |   1    |    0

  Punk: { Punk: 0, Pirate: 1, Mummy: 1, Stylish: 2, Combat: 0, Staking: 1 },
  Pirate: { Pirate: 0, Punk: 1, Mummy: 0, Stylish: 1, Combat: 2, Staking: 1 },
  Mummy: { Mummy: 0, Punk: 1, Pirate: 2, Stylish: 0, Combat: 1, Staking: 1 },
  Stylish: { Stylish: 0, Punk: 0, Pirate: 1, Mummy: 2, Combat: 1, Staking: 1 },
  Combat: { Combat: 0, Punk: 2, Pirate: 0, Mummy: 1, Stylish: 1, Staking: 1 },
  Staking: { Staking: 0, Pirate: 1, Mummy: 1, Punk: 1, Stylish: 1, Combat: 1 },
};

const INVENTORY_TYPE_CLAN_DAMAGE_MAP = {
  Bomb: {
    1: { min: 5, max: 8, effect: 6 },
    2: { min: 6, max: 8, effect: 5 },
    3: { min: 7, max: 8, effect: 4 },
  },
  Shield: {
    1: { min: 8, max: 8, effect: 3 },
    2: { min: 10, max: 10, effect: 3 },
    3: { min: 12, max: 12, effect: 3 },
  },
  Health: {
    1: { min: 8, max: 8, effect: 3 },
    2: { min: 10, max: 10, effect: 3 },
    3: { min: 12, max: 12, effect: 3 },
  },
  Bow: {
    1: { min: 8, max: 12 },
    2: { min: 10, max: 14 },
    3: { min: 12, max: 16 },
  },
  Sword: {
    1: { min: 10, max: 10 },
    2: { min: 12, max: 12 },
    3: { min: 14, max: 14 },
  },
  Armor: {
    1: { min: 8, max: 12 },
    2: { min: 10, max: 14 },
    3: { min: 12, max: 16 },
  },
  Potion: {
    1: { min: 8, max: 12, effect: 1 },
    2: { min: 10, max: 14, effect: 1 },
    3: { min: 12, max: 16, effect: 1 },
  },
  Poison: {
    1: { min: 8, max: 13 },
    2: { min: 10, max: 15 },
    3: { min: 12, max: 17 },
  },
};

const INVENTORY_TYPE_DAMAGE_MAP = {
  Bow: {
    1: { attack: 3, health: 0 },
    2: { attack: 5, health: 0 },
    3: { attack: 7, health: 0 },
  },
  Sword: {
    1: { attack: 4, health: 0 },
    2: { attack: 6, health: 1 },
    3: { attack: 8, health: 2 },
  },
  Shield: {
    1: { attack: 0, health: 3, effect: 1 },
    2: { attack: 0, health: 5, effect: 2 },
    3: { attack: 0, health: 7, effect: 3 },
  },
  Armor: {
    1: { attack: 0, health: 5 },
    2: { attack: 1, health: 5 },
    3: { attack: 3, health: 5 },
  },
};

const MASS_INVENTORY_DAMAGE_MAP = {
  Bomb: {
    desc: "Expload target units.",
    type: "health",
    sign: -1,
    1: [5, 2], // [<target damage>, <neighbour damage>]}}
    2: [7, 3],
    3: [10, 5],
  },
  Poison: {
    desc: "Decrease unit attack and health",
    type: "attack/health",
    sign: -1,
    1: [3, 0],
    2: [5, 0],
    3: [7, 0],
  },
  Potion: {
    desc: "Increase unit attack",
    type: "attack",
    sign: 1,
    1: [5, 0],
    2: [7, 0],
    3: [10, 0],
  },
  Health: {
    desc: "Heal units",
    type: "health",
    sign: 1,
    1: [5, 0],
    2: [7, 3],
    3: [10, 5],
  },
};

const MONSTER_LOOSE_PECENT_MIN = 0.05;
const MONSTER_LOOSE_PECENT_MAX = 0.1;

const INVENTORY_ATTACK_RANGE_MAP = {
  Bomb: 100,
  Poison: 100,
  Potion: 100,
  Health: 100,
  Bow: 100,
  Sword: 1,
  Shield: 1,
  Armor: 1,
};

const INVENTORY_USAGE_MAP = {
  Bomb: 1,
  Poison: 1,
  Potion: 1,
  Health: 1,
  Bow: 3,
  Sword: 100,
  Shield: 100,
  Armor: 100,
};

module.exports = {
  BATTLE_STATUS,
  TURN_TIME,
  SLEEP_TURNS,
  MAX_TURNS,
  BATTLE_OBSTACLE_TYPES,
  BATTLE_OBSTACLES_AREA,
  BATTLE_OBSTACLES_COUNT,
  MAP_TEXTURE_IDS,
  INVENTORY_TYPE,
  INVENTORY_TYPE_1,
  GAME_OBJECT,
  UNIT_COLLECTION_DAMAGE_MAP,
  MASS_INVENTORY_DAMAGE_MAP,
  INVENTORY_USAGE_MAP,
  INVENTORY_ATTACK_RANGE_MAP,
  INVENTORY_TYPE_DAMAGE_MAP,
  INVENTORY_TYPE_CLAN_DAMAGE_MAP,
  BATTLE_RATING_COEF,
  BATTLE_RATING_LOOSER_PERCENT,
  BATTLE_RATING_LOOSER_LEAVE_PERCENT,
  MONSTER_LOOSE_PECENT_MIN,
  MONSTER_LOOSE_PECENT_MAX,
};

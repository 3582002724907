import React, { useContext, useEffect, useState } from "react";
import { convertFromYocto } from "../../utils/utils";
import { LandContent } from "../../utils/content";
import {
  Container,
  InnerPageWrapper,
  Wrapper,
} from "../../assets/styles/common.style";
import { List } from "../../assets/styles/common.style";
import { ListWrapper } from "../../assets/styles/common.style";
import { Header, Footer } from "../../components";
import { Button, Loader, InnerPageHead, CardLand } from "../../ui";
import { MintLandSection } from "./MintLandSection";
import { BuyLandsPopup } from './BuyLandsPopup';
import { TransferLandPopup } from './TransferLandPopup';
import { SellLandPopup } from './SellLandPopup';
import { DiscoverLandPopup } from './DiscoverLandPopup';
import { NearContext } from "../../contexts/NearWallet";
import { getUserLands } from "../../services/LandService";
import { updateUserBalance } from "../../services/UserService";
import { useDispatch } from "react-redux";

export const Lands = () => {
  const {
    ftContract,
    mainContract,
    parasContract,
    parasMarketContract,
    currentUser,
  } = useContext(NearContext);
  const dispatch = useDispatch();
  const [userLands, setUserLands] = useState([]);
  const [mintPopupVisible, setMintPopupVisible] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [transferPopupVisible, setTransferPopupVisible] = useState(false);
  const [sellPopupVisible, setSellPopupVisible] = useState(false);
  const [discoverLandPopupVisible, setDiscoverLandPopupVisible] = useState(false);
  const [discoverLandItem, setDiscoverLandItem] = useState();
  const [selectedLandForTransfer, setSelectedLandForTransfer] = useState();
  const [selectedLandForSell, setSelectedLandForSell] = useState();

  useEffect(() => {
    loadUserLands();
  }, []);

  // Load lands price
  useEffect(() => {
    if (isReady) {
      const promisesList = userLands.filter(land => land.land_type !== "Micro").map(async land => {
        return new Promise(async resolve => {
          try {
            let tokenData = await parasMarketContract.getMarketData(parasContract.contractId, land.token_id);
            resolve(tokenData);
          } catch (e) {
            resolve();
          }
        });
      });

      Promise.all(promisesList).then(result => {
        result.filter(landData => landData).map(landData => {
          userLands.map(checkLand => {
            if (checkLand.token_id === landData.token_id) {
              checkLand.sale_price = convertFromYocto(landData.price, 2);
            }
          });
          setUserLands([...userLands]);
        });
      });
    }
  }, [isReady]);

  const loadUserLands = async () => {
    const lands = await getUserLands(currentUser, mainContract, parasContract);
    setUserLands(lands);
    setIsReady(true);
  }

  const handleImport = async (land) => {
    setIsReady(false);
    await mainContract.importParasLand(land.token_id);
    loadUserLands();
  }

  const isMicroLand = () => {
    let result = false;
    userLands.map((land) => {
      if (land.land_type === "Micro") {
        result = true;
      }
    });
    return result;
  };

  const handleMintSuccess = () => {
    setIsReady(false);
    setMintPopupVisible(false);
    loadUserLands();
    updateUserBalance(dispatch, ftContract, currentUser);
  }

  const showMintPopup = async () => {
    setMintPopupVisible(true);
  };

  const rmFromMarket = async (land) => {
    try {
      await parasMarketContract.deleteMarketData(parasContract.contractId, land.token_id);
      setIsReady(false);
      loadUserLands();
    } catch (e) {
      console.log(`Error: ${e}`);
    }
  }

  return (
    <InnerPageWrapper>
      <Header />

      <Wrapper>
        <Container className="text-white text-center mt-6">
          <InnerPageHead
            title={LandContent.title}
            description={LandContent.description}
          />

          {!userLands.length || (
            <Button
              title="Buy More Lands"
              size="lg"
              animated
              noIcon
              onClick={showMintPopup}
            />
          )}

          <ListWrapper>
            {isReady ? (
              <List>
                {userLands.length ? (
                  userLands.map((land, index) => (
                    <CardLand
                      nft={land}
                      key={index}
                      rmFromMarket={() => rmFromMarket(land)}
                      handleImport={() => handleImport(land)}
                      setTransferPopupVisible={(i) => {
                        setTransferPopupVisible(i);
                        setSelectedLandForTransfer(land);
                      }}
                      setSellPopupVisible={(i) => {
                        setSellPopupVisible(i);
                        setSelectedLandForSell(land);
                      }}
                      discoverLand={() => {
                        setDiscoverLandPopupVisible(true);
                        setDiscoverLandItem(land);
                      }}
                    />
                  ))
                ) : (
                  <div>
                    <div className="mb-7 leading-10">
                      <b className="text-xl">{LandContent.no_lands}.</b> <br />
                      <p className="text-cyan-200 leading-6 px-4">
                        {LandContent.no_lands_details}:
                      </p>
                    </div>
                    <MintLandSection
                      handleMintSuccess={handleMintSuccess}
                      isMicroLand={isMicroLand}
                    />
                  </div>
                )}
              </List>
            ) : (
              <Loader />
            )}
          </ListWrapper>
        </Container>

        <BuyLandsPopup
          setMintPopupVisible={setMintPopupVisible}
          mintPopupVisible={mintPopupVisible}
          isMicroLand={isMicroLand}
          handleMintSuccess={handleMintSuccess}
        />

        <DiscoverLandPopup
          setDiscoverLandPopupVisible={setDiscoverLandPopupVisible}
          discoverLandPopupVisible={discoverLandPopupVisible}
          discoverLandItem={discoverLandItem}
          handleDiscoverFinished={() => {
            setIsReady(false);
            loadUserLands();
            updateUserBalance(dispatch, ftContract, currentUser);
          }}
        />

        <TransferLandPopup
          transferPopupVisible={transferPopupVisible}
          setTransferPopupVisible={setTransferPopupVisible}
          selectedLandForTransfer={selectedLandForTransfer}
          handleTransferSuccess={() => {
            setIsReady(false);
            loadUserLands();
          }}
        />

        <SellLandPopup
          sellPopupVisible={sellPopupVisible}
          setSellPopupVisible={setSellPopupVisible}
          selectedLandForSell={selectedLandForSell}
          handleSellLandSuccess={() => {
            setIsReady(false);
            loadUserLands();
          }}
        />

      </Wrapper>
      <Footer />
    </InnerPageWrapper>
  );
};

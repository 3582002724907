import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Circle } from "../../assets/styles/home.style";
import { ChevronDoubleRightIcon } from "@heroicons/react/solid";
import { HomeContent } from "../../utils/content";
import { HowToPlaySection } from "./HowToPlaySection";
import { RoadmapSection } from "./RoadmapSection";
import { Section } from "./Section";
import { Footer, Header } from "../../components";
import { SocialLinks, Button } from "../../ui";
import { NearContext } from "../../contexts/NearWallet";
import {
  Col,
  Container,
  InnerPageWrapper,
  Row,
} from "../../assets/styles/common.style";

import play_to_earn from "../../assets/images/play_to_earn.png";
import mint_land from "../../assets/images/mint_land.png";
import mint_zombie from "../../assets/images/mint_zombie.png";
import mint_monster from "../../assets/images/mint_monster.png";
import battle from "../../assets/images/battle.png";
import pie from "../../assets/images/pie.png";
import achieve1 from "../../assets/images/achieve1.png";
import achieve2 from "../../assets/images/achieve2.png";
import paras_logo from "../../assets/images/paras_logo.png";
import near_logo from "../../assets/images/near-logo-green.png";
import meter from "../../assets/images/meter.png";
import human from "../../assets/images/human.jpeg";
import team1 from "../../assets/images/team/Vlodkow.png";
import team2 from "../../assets/images/team/Andrii.png";
import team3 from "../../assets/images/team/Lana.png";
import team4 from "../../assets/images/team/Maria.png";
import team5 from "../../assets/images/team/Olha.png";
import team6 from "../../assets/images/team/Yura.png";
import team7 from "../../assets/images/team/Igor.png";
import meta_logo from "../../assets/images/meta_logo.png";
import { SelectNetwork } from "../../components/SelectNetwork";
import { userRefferal } from "../../services/UserService";

const CircleSection = ({number, title, desc}) => (
  <Col className="lg:mx-10 mx-4 text-center items-center leading-normal mb-10 sm:mb-0">
    <Circle>{number}</Circle>
    <h2 className="font-semibold lg:text-2xl text-xl mt-5 mb-3">{title}</h2>
    <p
      dangerouslySetInnerHTML={{
        __html: desc,
      }}
    />
  </Col>
);

const TeamMember = ({name, image, desc}) => (
  <Col className="mx-6">
    <img
      src={image}
      className={"w-56 hover:scale-105 transition transform-gpu"}
      alt=""
    />
    <p className={"mt-2 text-lg"}>{name}</p>
    <p className={"text-sm opacity-50"}>{desc}</p>
  </Col>
);

export const Landing = () => {
  const {currentUser, isSigned} = useContext(NearContext);
  const [networkPopupVisible, setNetworkPopupVisible] = useState(false);
  const [searchParams, _setSearchfParams] = useSearchParams();
  let navigate = useNavigate();

  useEffect(() => {
    userRefferal(currentUser, searchParams.get("ref"));
  }, []);

  const handleClick = () =>
    isSigned ? navigate("/zombies") : setNetworkPopupVisible(true);

  return (
    <InnerPageWrapper>
      <Header />

      <Container
        id="home"
        className="lg:pt-40 lg:pb-20 sm:pt-28 sm:pb-22 py-8 flex flex-wrap justify-between"
      >
        <div className="sm:w-1/2 px-4 sm:px-0 w-full order-last sm:order-first 2xl:pl-14">
          <h1
            className="zombie-font lg:text-7xl text-5xl leading-tight title-shadow 2xl:mt-16 xl:mt-4"
            id="#how_to_play"
          >
            {HomeContent.play_to_earn.title}
          </h1>
          <h3 className="mt-6 sm:mb-10 mb-6 lg:text-lg leading-normal">
            {HomeContent.play_to_earn.desc}
          </h3>
          <div>
            <Button
              animated
              size="lg"
              title="Play Game"
              onClick={handleClick}
            />
          </div>
        </div>
        <div className="sm:w-1/2">
          <img
            className="w-full lg:-mt-28 max-w-[700px]"
            src={play_to_earn}
            alt="zomland"
          />
        </div>
      </Container>

      <div id="landing" className="mt-4 sm:mt-0">
        <Container id="how_to_play">
          <Section
            title={HomeContent.how_to_play.title}
            description={HomeContent.how_to_play.desc}
          >
            <Row className="mt-16 justify-around sm:flex-nowrap flex-wrap">
              <CircleSection
                number="1"
                title={HomeContent.login_using_near.title}
                desc={HomeContent.login_using_near.desc}
              />
              <ChevronDoubleRightIcon width="150" className="hidden sm:flex" />
              <CircleSection
                number="2"
                title={HomeContent.mint_or_buy.title}
                desc={HomeContent.mint_or_buy.desc}
              />
              <ChevronDoubleRightIcon width="150" className="hidden sm:flex" />
              <CircleSection
                number="3"
                title={HomeContent.catch_zombiez.title}
                desc={HomeContent.catch_zombiez.desc}
              />
            </Row>
          </Section>

          <h3
            className={
              "mt-20 mb-6 font-semibold lg:text-2xl text-xl text-center"
            }
          >
            Video Overview
          </h3>
          <iframe
            src="https://www.youtube.com/embed/102Cr9B2gEk?controls=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            className={
              "mx-auto mb-20 w-full md:w-[780px] h-[300px] md:h-[462px] border-4 border-sky-900 rounded-xl"
            }
            allowFullScreen
          ></iframe>

          <HowToPlaySection
            title={HomeContent.mint_land.title}
            desc={HomeContent.mint_land.desc}
            img={mint_land}
          />
          <HowToPlaySection
            title={HomeContent.mint_zombie.title}
            desc={HomeContent.mint_zombie.desc}
            img={mint_zombie}
            reverse
          />
          <HowToPlaySection
            title={HomeContent.mint_monster.title}
            desc={HomeContent.mint_monster.desc}
            img={mint_monster}
          />
          <HowToPlaySection
            title={HomeContent.battle.title}
            desc={HomeContent.battle.desc}
            img={battle}
            reverse
          />
        </Container>

        <Container id="tokenomic" className={"mt-20"}>
          <Section
            title={HomeContent.tokenomics.title}
            description={HomeContent.tokenomics.description}
          >
            <Col className="flex items-center">
              <img
                className="sm:w-3/4 lg:w-3/5 sm:mt-16 mt-10 lg:mx-10"
                src={pie}
                alt="zomland"
              />
            </Col>
          </Section>
        </Container>
        <Container id="roadmap">
          <Section
            title={HomeContent.roadmap.title}
            description={HomeContent.roadmap.desc}
          >
            <div className="sm:my-20 my-10 container mx-auto w-full h-full">
              <div className="relative wrap overflow-hidden h-full">
                <div className="left-1/2 border-dashed absolute border-opacity-30 border-blue-200 h-full border hidden sm:block"></div>
                {HomeContent.roadmap.sections.map((section, index) => (
                  <RoadmapSection
                    key={index}
                    index={index}
                    date={section.date}
                    title={section.title}
                    desc={section.desc}
                    type={section.type}
                  />
                ))}
              </div>
            </div>
          </Section>
        </Container>
      </div>

      <Container id="team">
        <Section
          title={HomeContent.team.title}
          description={HomeContent.team.desc}
        ></Section>
        <Row className="justify-center gap-2 font-semibold sm:flex-row flex-col sm:gap-0 pt-8 text-center">
          <TeamMember
            desc="Co-owner & Developer"
            image={team1}
            name="Vlodkow"
          />
          <TeamMember desc="Co-owner & Developer" image={team2} name="Andrii" />
          <TeamMember desc="Graphic Designer" image={team4} name="Maria" />
        </Row>
        <Row
          className={
            "justify-center gap-2 font-semibold sm:flex-row flex-col sm:gap-0 pt-8 mb-10 md:mb-20 text-center"
          }
        >
          <TeamMember desc="QA & SMM Manager" image={team3} name="Lana" />
          <TeamMember desc="PM & Coordinator" image={team7} name="Igor" />
          <TeamMember desc="Content Writer" image={team5} name="Olga" />
          <TeamMember desc="UX/UI Designer" image={team6} name="Yurii" />
        </Row>
      </Container>

      <Container id="partners">
        <Section title={HomeContent.partners.title}></Section>
        <Row className="justify-around sm:flex-row flex-col sm:gap-0 gap-y-10 pt-8 mb-20 md:mt-10 md:mb-40 text-center">
          <Col className="mx-6">
            <a
              className="w-24"
              href="https://near.org/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={near_logo}
                alt="NEAR"
                className="h-24 w-24 rounded-full object-contain inline"
              />
            </a>
          </Col>
          <Col className="mx-6">
            <a href="https://paras.id" target="_blank" rel="noreferrer">
              <img
                src={paras_logo}
                alt="paras.id"
                className="h-12 object-contain inline"
              />
            </a>
          </Col>

          <Col className="mx-6">
            <a
              className="w-24"
              href="https://humanguild.io/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={human}
                alt="human guild"
                className="h-24 rounded-full object-contain inline"
              />
            </a>
          </Col>
          <Col className="mx-6">
            <a
              className="w-24"
              href="https://metapool.app/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={meta_logo}
                alt="Metapool"
                className="h-24 w-24 rounded-full object-contain inline"
              />
            </a>
          </Col>
          <Col className="mx-6">
            <a
              className="w-24"
              href="https://meter.io/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={meter}
                alt="Meter.io"
                className="h-24 w-24 rounded-full object-contain inline"
              />
            </a>
          </Col>
        </Row>
      </Container>

      <Container id="achievements">
        <Section
          title={HomeContent.achievements.title}
          description={HomeContent.achievements.desc}
        />
        <Row className="justify-center md:mt-16 md:mb-32 mt-10 mb-16 mx-6">
          <Col className="md:mr-28 mr-8">
            <img
              src={achieve1}
              alt="achievement meter hackaton"
              width={"200"}
            />
          </Col>
          <Col>
            <img src={achieve2} alt="achievement near hackaton" width={"200"} />
          </Col>
        </Row>
      </Container>

      <Container id="contact_us">
        <Section
          title={HomeContent.contact_us.title}
          description={HomeContent.contact_us.desc}
        >
          <div className="sm:my-10 mt-10">
            <SocialLinks size="xl" gmail />
          </div>
        </Section>
      </Container>

      <SelectNetwork
        networkPopupVisible={networkPopupVisible}
        setNetworkPopupVisible={setNetworkPopupVisible}
      />

      <Footer />
    </InnerPageWrapper>
  );
};

import React, { useEffect, useState } from "react";
import { Popup } from "../../ui";
import { get } from "../../utils/api";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/solid";
import { BattleTextResultsPopup } from "./BattleTextResultsPopup";
import { CalculatorIcon } from "@heroicons/react/outline";

import attack_icon from "../../assets/images/attack_icon.png";
import health_icon from "../../assets/images/health_icon.png";
import intellect_icon from "../../assets/images/intelect_icon.png";
import speed_icon from "../../assets/images/speed_icon.png";
import {
  partition,
  statusColorBorderMap,
  statusColorTextMap,
} from "../../utils/utils";

export const BattleResultsPopup = ({ battle, visible, setVisible }) => {
  console.log(battle);

  const [rating, setRating] = useState({ from: null, to: null });
  const [showTextResultPopup, setShowTextResultPopup] = useState(false);

  useEffect(() => {
    if (visible) getRating(battle);
    console.log(`battle`, battle);
  }, [battle]);

  const CardCharacteristic = ({ item, icon }) => (
    <div className="my-1 flex text-center items-center ">
      <img src={icon} alt="" className="w-4 mr-2" />
      <span className="text-indigo-50">{item}</span>
    </div>
  );

  const CardInventory = ({ item }) => (
    <div className="flex flex-col mx-1 mt-4">
      <div
        className={`flex flex-col h-20 w-20 bg-main items-center justify-center rounded-lg mt-1 overflow-hidden relative ${
          item && `text-white border-2 ${statusColorBorderMap(item.level)}`
        }`}
      >
        <img className="w-20 h-20" alt={item.type} src={item.image} />
        <span
          className={
            "absolute bottom-0 left-0 right-0 text-center text-sm py-0.5 bg-gray-900/30"
          }
        >
          <small className={`font-semibold ${statusColorTextMap(item.level)}`}>
            Level {item.level}
          </small>
        </span>
      </div>
    </div>
  );

  const Card = ({ item }) => (
    <div className="flex flex-col w-24 bg-main/50 items-center justify-center py-2 rounded-lg border-2 border-gray-500">
      <CardCharacteristic item={item?.attack} icon={attack_icon} />
      <CardCharacteristic item={item?.health} icon={health_icon} />
      <CardCharacteristic item={item?.brain} icon={intellect_icon} />
      <CardCharacteristic item={item?.speed} icon={speed_icon} />
    </div>
  );

  const getRating = async (battle) => {
    const respFrom = await get(`api/clan/users/${battle.fromClan.id}`);
    const respTo = await get(`api/clan/users/${battle.toClan.id}`);

    if (respFrom.data && respTo.data) {
      const ratingSumFrom = respFrom.data
        .map((u) => u.rating | 0)
        .reduce((prev, curr) => prev + curr, 0);
      const ratingSumTo = respTo.data
        .map((u) => u.rating | 0)
        .reduce((prev, curr) => prev + curr, 0);

      setRating({
        from: ratingSumFrom,
        to: ratingSumTo,
      });
    }
  };

  const calcRating = (clan, usersTotalRating) => clan.rating + usersTotalRating;

  const SectionResult = ({ clan, battle, usersTotalRating, reverse }) => (
    <div
      className={`z-10 bg-mainLignt md:w-1/2 rounded-2xl flex flex-col gap-y-2 border-4 ${
        battle.winnerClanId === clan.id
          ? "border-blue-500 shadow-blue-500/50 shadow-lg "
          : "border-red-700 shadow-red-500/50 shadow-lg"
      }`}
    >
      <div className="zombie-font p-3 flex justify-between text-2xl">
        <div className="flex items-center">
          <img
            className="rounded w-7 h-7 object-cover mr-2"
            src={clan.media || "https://zomland.com/clan_image.d4cd5b5f.png"}
            alt={""}
          />
          {clan.name}
        </div>
        <div className="flex items-start">
          <span>{calcRating(clan, usersTotalRating)}</span>
        </div>
      </div>
      <div className={`flex ${reverse ? "pl-14" : "pr-14"} w-full pb-10 pt-4 `}>
        {partition(clan.participants, 3).map((group, i) => (
          <div
            key={i}
            className={`flex gap-y-2 ${
              reverse ? "items-start" : "items-end"
            } w-full flex-col`}
          >
            {group.map((u, index) => (
              <div
                key={index}
                className={`flex flex-row ${reverse && "flex-row-reverse"}`}
              >
                {u.inventory.type && (
                  <CardInventory key={index} item={u.inventory} />
                )}
                {u.zombie && (
                  <div>
                    <div className="text-white text-center text-xs w-24 font-semibold pb-1 truncate">
                      {u.accountId}
                    </div>
                    <Card key={index} item={u.zombie} />
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );

  const getPopupWidth = () => {
    if (battle) {
      if (battle.requiredSize === 6) {
        return "sm:w-2/3";
      } else if (battle.requiredSize === 10) {
        return "sm:w-3/4";
      }
    }
    return "sm:w-full";
  };

  return (
    <Popup
      width={getPopupWidth()}
      title={"Battle Results"}
      popupVisible={visible}
      setPopupVisible={setVisible}
    >
      {visible && battle && (
        <div className="md:flex sm:flex-row flex-col md:gap-10 items-center justify-center">
          <SectionResult
            clan={battle.fromClan}
            battle={battle}
            usersTotalRating={rating.from}
          />
          <div className="bg-mainLight md:absolute z-20">
            <span className="text-8xl zombie-font">vs</span>
            <div className="text-2xl font-bold mb-4">
              <span
                className={`${
                  battle.winnerClanId === battle.fromClan.id
                    ? "text-blue-600"
                    : "text-red-600"
                }`}
              >
                {battle.points.fromClan.total?.toFixed()}
              </span>{" "}
              /{" "}
              <span
                className={`${
                  battle.winnerClanId === battle.toClan.id
                    ? "text-blue-600"
                    : "text-red-600"
                }`}
              >
                {battle.points.toClan.total.toFixed()}
              </span>
            </div>
            <div
              className="flex gap-1 items-center mb-3 cursor-pointer mx-auto w-32 text-indigo-200 hover:text-white"
              onClick={() => setShowTextResultPopup(true)}
            >
              <CalculatorIcon className="w-5 h-5" />
              <div className="font-semibold uppercase text-xs">
                <p>Battle details</p>
              </div>
            </div>
            <BattleTextResultsPopup
              history={battle.history}
              battle={battle}
              visible={showTextResultPopup}
              setVisible={setShowTextResultPopup}
            />
          </div>
          <SectionResult
            clan={battle.toClan}
            battle={battle}
            usersTotalRating={rating.to}
            reverse
          />
        </div>
      )}
    </Popup>
  );
};

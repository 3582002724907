import React, { useContext, useState } from "react";
import { Button, Input, Popup } from "../../ui";
import { NearContext } from "../../contexts/NearWallet";
import { handleTransferService } from "../../services/NftService";
import { isNFT } from "../../services/MarketService";

export const TransferNftPopup = ({
  currentUser,
  transferPopupVisible,
  setTransferPopupVisible,
  nftList,
  nftType,
  handleReloadData,
}) => {
  const {mainContract, wallet} = useContext(NearContext);
  const [transferAddress, setTransferAddress] = useState("");

  const handleTransfer = async () => {
    if (transferAddress.length > 5 && transferAddress !== currentUser) {
      try {
        await handleTransferService(nftType, nftList, transferAddress, mainContract, wallet).then(() => {
          setTransferPopupVisible(false);
          handleReloadData();
        });
      } catch (e) {
        console.error(`Error`, e);
      }
    } else {
      alert("Error: Wrong NEAR Transfer Address");
    }
  };

  return (
    <Popup
      title={`Transfer ${isNFT(nftType) ? nftType : "Item"}${nftList.length > 1 ? "s" : ""}`}
      popupVisible={transferPopupVisible}
      setPopupVisible={setTransferPopupVisible}
    >
      <div className="mt-2 px-6 mx-auto md:w-2/3 text-center">
        <p className="mt-4">
          You can transfer this {isNFT(nftType) ? "NFT" : "Item"}s to any NEAR account.
        </p>
        <p className="mb-6">Transfer price: 0.009 NEAR/{isNFT(nftType) ? "NFT" : "Item"}.</p>
        <p className="mb-6">
          <Input
            placeholder="NEAR Address"
            value={transferAddress}
            handleChange={(e) => setTransferAddress(e.target.value)}
          />
        </p>
        <Button title={`Transfer ${nftList.length} ${isNFT(nftType) ? nftType : "Item"}${nftList.length > 1 ? "s" : ""}`}
                onClick={handleTransfer} />
      </div>
    </Popup>
  );
};

import React, { useContext, useEffect, useState } from "react";
import { Link } from "../../../assets/styles/common.style";
import { Button, CardRotate } from "../../../ui";
import { PlusIcon } from "@heroicons/react/solid";
import { convertFromYocto, getMedia, transformZombieMonster } from "../../../utils/utils";
import { SelectZombiesPopup } from "../../../components/SelectZombiesPopup";
import { mintMonsterService } from "../../../services/CollectionService";
import { NearContext } from "../../../contexts/NearWallet";
import {
  MonsterBottomParams,
  MonsterTopParams,
} from "../../../assets/styles/collection";
import infoIcon from "../../../assets/images/info.png";
import { updateUserBalance, updateUserZmlReserve } from "../../../services/UserService";
import { useDispatch, useSelector } from "react-redux";

const MonsterParam = ({title, pct, width}) => (
  <div className="whitespace-nowrap text-center">
    <span className={`inline-block ${width ? width : "w-[80px]"} text-left`}>
      {title}:
    </span>
    <span className="inline-block font-semibold w-[50px] text-sky-200">
      {pct}%
    </span>
  </div>
);

const POPUP_PAGE_LIMIT = 40;
const COLLECTION_ZOMBIES_COUNT = 10;

export const CommonCollection = ({collection, getMintDeposit, mintedSuccess}) => {
  const {wallet, ftContract, mainContract, currentUser} = useContext(NearContext);
  const balance = useSelector((state) => state.user.balance);
  const reservedZmlBalance = useSelector((state) => state.user.reservedBalance);
  const dispatch = useDispatch();
  const [selectedPosition, setSelectedPosition] = useState();
  const [userCollectionZombies, setUserCollectionZombies] = useState([]);
  const [zombieCards, setZombieCards] = useState([]);
  const [zombiesPopupVisible, setZombiesPopupVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [priceInfoTooltip, setPriceInfoTooltip] = useState(false);
  const [alreadyBurned, setAlreadyBurned] = useState([]);

  const loadZombies = async (page) => {
    const result = await mainContract.userZombies({
      account_id: currentUser,
      page_num: page,
      page_limit: POPUP_PAGE_LIMIT,
      filter_collection: collection.id,
    });
    result[1] = result[1].map((zm) => transformZombieMonster(zm));
    setUserCollectionZombies(result);
  };

  const pageLoad = () => {
    let zombieCardsList = [];
    for (let i = 0; i < COLLECTION_ZOMBIES_COUNT; i++) {
      zombieCardsList.push(null);
    }

    setZombieCards(zombieCardsList);
    loadZombies(currentPage);
    loadBurnedCount(zombieCardsList);
  }

  const loadBurnedCount = (zombieCardsList) => {
    mainContract.getBurnedZombiesList({account_id: currentUser}).then((result) => {
      let index = 0;
      if (result.length > 0) {
        result.map((z) => {
          const zombie = transformZombieMonster(z);
          if (index < COLLECTION_ZOMBIES_COUNT && zombie.collection_id === collection.id) {
            zombieCardsList[index] = zombie;
            index++;
          }
        });
        setZombieCards([...zombieCardsList]);
        setAlreadyBurned(zombieCardsList);
      }
    });
  }

  useEffect(() => {
    pageLoad();
  }, []);

  const showSelectZombiesPopup = (position) => {
    setSelectedPosition(position);
    setZombiesPopupVisible(true);
  };

  const selectZombie = (zombie) => {
    zombieCards[selectedPosition] = zombie;
    setSelectedPosition(
      selectedPosition < COLLECTION_ZOMBIES_COUNT - 1 ? selectedPosition + 1 : 0
    );
    setZombieCards(zombieCards);
    if (zombieCards.filter((zm) => zm).length === 10) {
      setZombiesPopupVisible(false);
    }
  };

  const countZombieSelected = () => {
    return zombieCards.filter((zombie) => zombie).length;
  };

  const onPageChanged = async (page) => {
    // window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentPage(page);
    loadZombies(page);
  };

  const monsterParams = (param) => {
    let total = 0;
    zombieCards
      .filter((zombie) => zombie)
      .map((zombie) => {
        total += zombie[param];
      });
    return total;
  };

  const monsterCardRarity = (card_type) => {
    const totalZombies = countZombieSelected();
    const oneZombiePct = 100 / totalZombies;
    const countZombieType = zombieCards.filter(
      (zombie) => zombie && zombie.card_rarity === card_type
    ).length;
    return (countZombieType * oneZombiePct).toFixed(1);
  };

  const mintMonster = async () => {
    if (countZombieSelected() === COLLECTION_ZOMBIES_COUNT) {
      if (isEnoughBalance()) {
        const zombieList = zombieCards.filter((zombie) => zombie).map((zombie) => zombie.token_id);
        const zmlAmount = getMintDeposit(zombieCards);
        const alreadyBurnedIdList = alreadyBurned.filter((z) => z).map((z) => z.token_id);
        try {
          mintMonsterService(wallet, ftContract, mainContract, zmlAmount, zombieList, collection.id, alreadyBurnedIdList).then(() => {
            pageLoad();
            updateUserBalance(dispatch, ftContract, currentUser);
            mintedSuccess();
          });
        } catch (e) {
          console.error(`Error`, e);
        }
      } else {
        alert("Not enough ZML balance");
      }
    } else {
      alert(
        `You need to add ${COLLECTION_ZOMBIES_COUNT} zombies to mint the Monster`
      );
    }
  }

  const isEnoughBalance = () => {
    return (
      parseFloat(convertFromYocto(balance, 2)) >=
      getMintDeposit(zombieCards)
    );
  };

  const withdrawReserved = () => {
    ftContract.withdrawZmlReserve().then(() => {
      updateUserZmlReserve(dispatch, ftContract, currentUser);
      updateUserBalance(dispatch, ftContract, currentUser);
    });
  };

  const zombiesInPopup = () =>
    userCollectionZombies[1]
      .filter((zombie) => {
        let exists = false;
        zombieCards.map((innerZombie) => {
          if (innerZombie && innerZombie.token_id === zombie.token_id) {
            exists = true;
          }
        });
        return !exists;
      })
      .sort((a, b) => (a.card_rarity > b.card_rarity ? -1 : 1));

  return (
    <>
      <div className="xl:basis-3/4 md:basis-9/12 2xl:ml-10">
        <h2 className="text-2xl font-semibold">
          <Link to="/collections">
            <span className="text-2xl font-semibold text-sky-200">
              Collections
            </span>
          </Link>{" "}
          &raquo; Mint Monster
        </h2>
        <p className="mt-1 mb-6 text-sm">
          Select zombies for mint the {collection.title} Monster:
        </p>
        <div className="gap-3 flex flex-row flex-wrap max-w-[800px]">
          {zombieCards.map((zombie, index) => (
            <div
              className={`w-[140px] h-[196px] rounded-xl transition duration-200 cursor-pointer 
              ${zombie ? "" : "bg-main hover:bg-main/50"}`}
              key={index}
              onClick={() => showSelectZombiesPopup(index)}
            >
              {zombie ? (
                <CardRotate nft={zombie} size="sm" />
              ) : (
                <div>
                  <PlusIcon className="w-8 h-8 mx-auto mt-20" />
                  <div className="text-center text-sm mt-12">Select Zombie</div>
                </div>
              )}
            </div>
          ))}
        </div>
        <p className="mt-10 text-sm text-cyan-200 font-semibold">
          *NOTE: Your zombies will be killed to get Monster.{" "}
          <a
            href="https://zomland.gitbook.io/zomland-whitepaper/collections"
            target="_blank"
            className="link"
            rel="noreferrer"
          >
            Read details
          </a>
          .
        </p>
        <p className="text-sm text-cyan-200">
          Monster characteristic is sum of zombie characteristic multiply by
          modifier that depends on final rarity.
        </p>
        <p className="text-sm text-cyan-200">
          Chance for monster card rarity based on selected zombies rarity is
          shown under monster card image.
        </p>
      </div>

      <div className="md:basis-3/12 2xl:basis-1/4 sm:ml-10 lg:ml-8 xl:ml-10 2xl:ml-34 2xl:mr-10">
        <p className="text-center mb-6 mt-10 sm:mt-4 lg:mt-9 font-semibold text-2xl leading-5">
          {collection.title} Monster
        </p>
        <div className="relative sm:w-full w-3/4 mx-auto">
          {collection.image && (
            <img
              src={getMedia(collection.image)}
              alt="monster"
              className="bg-main w-54 rounded-xl mx-auto border-4 rounded-xl border-gray-500"
            />
          )}

          {countZombieSelected() ? (
            <>
              <MonsterTopParams>
                <div className="inline-block whitespace-nowrap">
                  Health:{" "}
                  <span className="lg:mr-3 mr-2 text-sky-200 font-semibold">
                    {monsterParams("health")}
                  </span>
                </div>

                <div className="inline-block whitespace-nowrap">
                  Attack:{" "}
                  <span className="lg:mr-3 mr-2 text-sky-200 font-semibold">
                    {monsterParams("attack")}
                  </span>
                </div>

                <div className="inline-block whitespace-nowrap">
                  Intellect:{" "}
                  <span className="text-sky-200 font-semibold">
                    {monsterParams("brain")}
                  </span>
                </div>
              </MonsterTopParams>
              <MonsterBottomParams>
                {countZombieSelected() ? (
                  <div className="xl:flex xl:flex-row">
                    <div className="2xl:ml-6 ml-2">
                      <MonsterParam
                        title="Common"
                        pct={monsterCardRarity("Common")}
                      />
                      <MonsterParam
                        title="UnCommon"
                        pct={monsterCardRarity("Uncommon")}
                      />
                    </div>
                    <div className="2xl:ml-6 ml-2">
                      <MonsterParam
                        width="w-[50px]"
                        title="Rare"
                        pct={monsterCardRarity("Rare")}
                      />
                      <MonsterParam
                        title="Epic"
                        width="w-[50px]"
                        pct={monsterCardRarity("Epic")}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </MonsterBottomParams>
            </>
          ) : (
            ""
          )}

          <div className="text-center mt-4">
            {reservedZmlBalance > 0 ? (
              <p className="mb-2 h-20 mt-5">
                Reserved: <b>{convertFromYocto(reservedZmlBalance, 0)} ZML</b>
                <a
                  className="link ml-2 cursor-pointer"
                  onClick={() => withdrawReserved()}
                >
                  withdraw
                </a>
              </p>
            ) : (
              <>
                <div className="mb-2">
                  Mint Price:{" "}
                  <b
                    className={`text-lg ${
                      isEnoughBalance() ? "" : "text-red-500"
                    }`}
                  >
                    {getMintDeposit(zombieCards)} ZML
                  </b>
                  <div className="relative w-6 inline-block ml-2 align-text-bottom">
                    <img
                      src={infoIcon}
                      alt="info"
                      className="w-6"
                      onMouseEnter={() => setPriceInfoTooltip(true)}
                      onClick={() => setPriceInfoTooltip(!priceInfoTooltip)}
                      onMouseLeave={() => setPriceInfoTooltip(false)}
                    />

                    {priceInfoTooltip && (
                      <div
                        className="absolute left-[-84px] bottom-8 text-black bg-white p-4 w-52 rounded-md text-left text-sm shadow-lg">
                        <b className="block text-center">
                          Price change by selected zombies rarity:
                        </b>
                        <ul className="mt-2 ml-3">
                          <li>Common: +50 ZML</li>
                          <li>UnCommon: +100 ZML</li>
                          <li>Rare: +200 ZML</li>
                          <li>Epic: +400 ZML</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>

                <Button
                  size="lg"
                  noIcon
                  title="Mint Monster"
                  disabled={
                    countZombieSelected() < COLLECTION_ZOMBIES_COUNT ||
                    !isEnoughBalance()
                  }
                  onClick={mintMonster}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <SelectZombiesPopup
        title="Select Zombies for Collection"
        zombiesPopupVisible={zombiesPopupVisible}
        setZombiesPopupVisible={setZombiesPopupVisible}
        userCollectionZombies={userCollectionZombies}
        zombiesInPopup={zombiesInPopup}
        selectZombie={selectZombie}
        currentPage={currentPage}
        onPageChanged={onPageChanged}
        pageLimit={POPUP_PAGE_LIMIT}
        nftType={"zombie"}
      />
    </>
  );
};

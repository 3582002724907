import React from "react";
import { Popup } from '../../ui';
import { MintLandSection } from './MintLandSection';

export const BuyLandsPopup = ({
  setMintPopupVisible,
  mintPopupVisible,
  isMicroLand,
  handleMintSuccess
}) => {
  return (
    <Popup
      title="Buy More Lands"
      width={`${!isMicroLand() ? "sm:w-[800px] lg:w-[1080px] max-w-full" : "sm:w-[846px]"}`}
      popupVisible={mintPopupVisible}
      setPopupVisible={setMintPopupVisible}
    >
      <div className="mt-2">
        <MintLandSection
          handleMintSuccess={() => handleMintSuccess()}
          isMicroLand={isMicroLand}
        />
      </div>
    </Popup>
  );
};

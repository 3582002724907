import { CheckIcon, FlagIcon, ClockIcon } from "@heroicons/react/outline";
import React from "react";

export const RoadmapSection = ({ date, title, desc, index, type }) => (
  <div
    className={`sm:mb-8 sm:flex justify-between w-full ${
      index % 2 === 0
        ? "right-timeline sm:text-left"
        : "flex-row-reverse left-timeline sm:text-right"
    }`}
  >
    <div className="order-1 sm:w-1/2" />
    <div
      className={`z-20 flex items-center justify-center order-1 ${
        type === "past"
          ? "text-green-500 bg-main"
          : type === "soon"
          ? "text-amber-600 bg-main"
          : "text-gray-500 bg-main"
      } shadow-xl w-7 h-7 rounded-full hidden sm:block`}
    >
      <div className=" justify-center">
        {type === "past" ? (
          <CheckIcon />
        ) : type === "soon" ? (
          <ClockIcon />
        ) : (
          type === "incomming" && <FlagIcon />
        )}
      </div>
    </div>
    <div
      className={`sm:flex order-1 ${
        index % 2 === 0 ? "justify-start" : "justify-end"
      } rounded-lg shadow-xl sm:w-1/2 px-7`}
    >
      <div
        className={`w-full lg:w-2/3 border-2 p-5 rounded-lg ${
          type === "past"
            ? "border-green-500"
            : type === "soon"
            ? "border-amber-600"
            : "border-gray-500"
        }`}
      >
        <div
          className={`mb-3 ${
            type === "past"
              ? "text-green-500"
              : type === "soon"
              ? "text-amber-600"
              : "text-gray-500"
          }`}
        >
          <h3>{date}</h3>
          <h3 className="font-bold text-xl">{title}</h3>
        </div>
        <p
          className={`text-sm leading-snug tracking-wide ${
            type === "past"
              ? "text-green-500"
              : type === "soon"
              ? "text-amber-600"
              : "text-gray-500"
          }`}
        >
          {desc}
        </p>
      </div>
    </div>
  </div>
);

import { mediaURL } from "./clan/chat/utils/transform";
import default_user_1 from "../assets/images/default_user_1.png";
import default_user_2 from "../assets/images/default_user_2.png";
import default_user_3 from "../assets/images/default_user_3.png";
import default_user_4 from "../assets/images/default_user_4.png";
import default_user_5 from "../assets/images/default_user_5.png";
import default_user_6 from "../assets/images/default_user_6.png";
import default_user_7 from "../assets/images/default_user_7.png";
import default_user_8 from "../assets/images/default_user_8.png";
import default_user_9 from "../assets/images/default_user_9.png";
import default_user_10 from "../assets/images/default_user_10.png";
import default_user_11 from "../assets/images/default_user_11.png";
import default_user_12 from "../assets/images/default_user_12.png";
import seedrandom from "seedrandom";

const imgs = [
  default_user_1,
  default_user_2,
  default_user_3,
  default_user_4,
  default_user_5,
  default_user_6,
  default_user_7,
  default_user_8,
  default_user_9,
  default_user_10,
  default_user_11,
  default_user_12,
];

export const Avatar = ({ media, title, textSize }) => (
  <div
    className={`shadow-md rounded-full w-full h-full bg-gradient-to-b from-indigo-300/90 to-blue-500/90 text-center flex items-center
    justify-center border border-indigo-300/60 text-white overflow-hidden`}
  >
    {media ? (
      <img
        src={mediaURL(media)}
        alt=""
        className={"object-cover w-full h-full"}
      />
    ) : (
      <span
        className={`font-semibold inline-block opacity-60 ${
          textSize ? textSize : "text-xl"
        }`}
      >
        {title[0].toUpperCase() || ""}
      </span>
    )}
  </div>
);

export const UserProfile = ({ profile, accountId, size }) => {
  const profileImage = `https://i.near.social/magic/thumbnail/https://near.social/magic/img/account/${accountId}`;

  if (size === "lg")
    return (
      <div className="flex gap-2 items-center">
        <img
          className="w-10 h-10 rounded-full object-cover"
          src={profileImage}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src =
              imgs[parseInt(seedrandom(accountId).quick() * 10)];
          }}
        />
        <div>
          <p
            className={
              "w-40 text-lg overflow-hidden whitespace-nowrap overflow-ellipsis"
            }
          >
            <a
              href={`https://near.org/near/widget/ProfilePage?accountId=${accountId}`}
            >
              {profile[accountId]?.profile?.name ?? accountId}
            </a>
          </p>
          {profile[accountId]?.profile?.name && (
            <p
              className={
                "w-32 overflow-hidden opacity-70 whitespace-nowrap overflow-ellipsis"
              }
            >
              {accountId}
            </p>
          )}
        </div>
      </div>
    );

  return (
    <>
      <img
        className="w-7 h-7 rounded-full mr-2 object-cover"
        src={profileImage}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src =
            imgs[parseInt(seedrandom(accountId).quick() * 10)];
        }}
      />
      <div className="truncate">
        <a
          href={`https://near.org/near/widget/ProfilePage?accountId=${accountId}`}
        >
          {profile[accountId]?.profile?.name ?? accountId}
        </a>
      </div>
    </>
  );
};

import { CardRotate } from "../../ui";
import { PlusIcon } from "@heroicons/react/solid";
import React from "react";

export const OneMonsterItem = ({monsterCard, showSelectMonsterPopup}) => (
  <div className={`w-[196px] h-[268px] rounded-xl transition duration-200 cursor-pointer mx-auto relative z-10 mb-1
      ${monsterCard ? "" : "bg-main hover:bg-main/50"}`}
       onClick={() => showSelectMonsterPopup()}
  >
    {monsterCard ? (
      <>
        <CardRotate nft={monsterCard} size="mds" />
      </>
    ) : (
      <div className={"pt-20"}>
        <PlusIcon className="w-8 h-8 mx-auto" />
        <div className="text-center text-sm mt-12 pt-1">Select Monster</div>
      </div>
    )}
  </div>
)
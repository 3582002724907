import React, { useEffect, useState } from "react";
import { HexObject } from "./HexObject";
import "./styles.css";

const hexSize = 80;

export const BoardArena = ({ mapPosition, setMapPosition, battleId }) => {
  const cols = 13;
  const rows = 7;
  const availableCols = 2;
  const [board, setBoard] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState({
    row: null,
    col: null,
  });
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    let boardTmp = [];
    for (let row = 0; row < rows; row++) {
      boardTmp.push(new Array(cols).fill(0));
    }

    // Set default positioning
    if (!battleId) {
      boardTmp[3][0] = 1;
      boardTmp[1][0] = 2;
      boardTmp[2][1] = 3;
      boardTmp[5][0] = 4;
      boardTmp[4][1] = 5;
    } else {
      boardTmp[3][13] = 1;
      boardTmp[1][13] = 2;
      boardTmp[2][11] = 3;
      boardTmp[5][13] = 4;
      boardTmp[4][11] = 5;
    }

    setMapPosition([...boardTmp]);
    setBoard(boardTmp);
  }, []);

  // Update positioning
  const handleClick = (rowIndex, colIndex, index) => {
    if (!selectedIndex && !index) return;

    if (!selectedIndex) {
      // first selection
      setSelectedPosition({ row: rowIndex, col: colIndex });
      setSelectedIndex(index);
    } else {
      mapPosition[selectedPosition.row][selectedPosition.col] = index;
      mapPosition[rowIndex][colIndex] = selectedIndex;
      setMapPosition([...mapPosition]);

      // temporary highlight
      setSelectedPosition({ row: rowIndex, col: colIndex });
      setSelectedIndex(index);

      // remove highlight and unselect
      setTimeout(() => {
        setSelectedPosition({ row: null, col: null });
        setSelectedIndex(0);
      }, 250);
    }
  };

  return (
    <div
      className={`board-gradient ${battleId ? "board-left-gradient" : ""} pl-1`}
    >
      <div
        className={`flex flex-col py-4 relative ${battleId ? "items-end" : ""}`}
      >
        {board.map((row, rowIndex) => {
          return (
            <div
              key={rowIndex}
              className={`flex flex-row justify-between -mt-[10px] ${battleId ? "" : "w-[1052px]"}`}
              style={{
                transform: `${
                  rowIndex % 2 === 0
                    ? `translateX(${battleId ? "-" : ""}${hexSize / 2}px)`
                    : ""
                }`,
              }}
            >
              {row.map((value, colIndex) => (
                <HexObject
                  key={`${rowIndex}-${colIndex}`}
                  style={{ height: `${hexSize}px`, width: `${hexSize}px` }}
                  className={`${
                    (
                      battleId
                        ? colIndex >= cols - availableCols
                        : colIndex < availableCols
                    )
                      ? ""
                      : "opacity-50 pointer-events-none"
                  }`}
                  hexSize={hexSize}
                  rowIndex={rowIndex}
                  colIndex={colIndex}
                  value={value}
                  handleClick={handleClick}
                  selectedPosition={selectedPosition}
                />
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const {
  BATTLE_RATING_COEF,
  BATTLE_RATING_LOOSER_PERCENT,
  BATTLE_RATING_LOOSER_LEAVE_PERCENT,
} = require("../config/battleArena");

const rand = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

const randArrayEl = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

const getImage = (itemType) => {
  return itemType ? `https://img.zomland.com/inventory/${itemType}.png` : "";
};

const log = (title, obj) => {
  console.log("\n" + title);
  console.dir(obj, { depth: null });
};

const timeNow = (runEvery) => {
  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes() - (date.getMinutes() % runEvery);

  return `${date.toDateString()} ${hours}:${minutes}`;
};

const groupElementsByKey = (elements, key) =>
  elements.reduce(function (r, a) {
    r[a[key]] = r[a[key]] || [];
    r[a[key]].push(a);
    return r;
  }, Object.create(null));

const checkWhitelistedIDO = (user) => {
  const whitelist = [
    "vlodkow2.testnet",
    "5026f80475a2a85b471f82d36a94d703ece1f16dfb31746b18d2dd4893bf4880",
    "3245b7a1a162408fd56fd3caa30d223e8ebadfc360933f53e817ba4b25e3719d",
    "8d896e439b3111e1107403f1a8e64400de5283897a09b4dbc9102759c2bfef1c",
    "blacksunempire.near",
    "c1d3667a8751d721e17291d51719ef0963b340ac9826bfccc522468bbc64fc2f",
    "cryptogarik.near",
    "jellow.near",
    "e0cf5b63e5586b1908e82b4c750ce19a4492b6bb722cc1e3911f90fb8e04f4d3",
    "54af61256e5b14bb398aa022024c87efb9ce7b532bfd26a264babc71a07a2742",
    "udavvv.near",
    "andreloft.near",
    "djbratko.near",
    "aaakiev.near",
    "yabloko.near",
    "rambergen.near",
    "d128023ba88e60fb700f3a03c2a0ad963e9a3267b5bb88ebe383ac7fbb902a6e",
    "8a946cc0d2c0e61136db9005a3b0e084207cf33230512af11d3aae925f2f571d",
    "601a90495863662753b46140fb20bccdc02a70ba4a14f9cb9e77042d15c65932",
    "aurority.near",
    "emekacollins.near",
    "26d94e8e3ed1bb8195ea0f9882d4c9f22a466c24235829261dede97386ac56b0",
    "90ef9ff389d01f189aa08c738794f2d7ecd45e452ca9e12e9ec64727600069e0",
    "52b8eef52d39cc5e1eaedd6192c9a38aedf19ec6a26adee658df936ccce385fa",
    "f8dd066b74a15c25941d6cce8d14fc216ae114c0e389001e4527c5f1b47a7f7f",
    "da82f513fc2b17db267a84f51d7058f6ce2593a87dcda73404d18263c215278a",
    "59583199fc8d2b7207ad71f5213b3e7c917cbf07579d0e63fe1d7cebd9a2cd00",
    "kiskesis.near",
  ];

  return whitelist.includes(user);
};

const getBattleRating = (battle, endType) => {
  let winnerRating = 0;
  let looserRating = 0;
  const rating = BATTLE_RATING_COEF * (1 + (battle.level || 1) / 10);

  if (endType === 1) {
    winnerRating = parseInt(rating);
    looserRating = parseInt(rating * BATTLE_RATING_LOOSER_PERCENT);
  } else if (endType === 2) {
    looserRating = parseInt(rating * BATTLE_RATING_LOOSER_LEAVE_PERCENT);
  }

  return { winnerRating, looserRating };
};

module.exports = {
  rand,
  randArrayEl,
  log,
  timeNow,
  getImage,
  groupElementsByKey,
  checkWhitelistedIDO,
  getBattleRating,
};

import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Col,
  Container,
  InnerPageWrapper,
  Row,
  Wrapper,
} from "../assets/styles/common.style";
import { Header, Footer } from "../components";
import { Button, InnerPageHead, Loader, Popup } from "../ui";
import { NearContext } from "../contexts/NearWallet";
import ConfettiExplosion from "react-confetti-explosion";
import box1 from "../assets/images/boxes/box1.png";
import box2 from "../assets/images/boxes/box2.png";
import box3 from "../assets/images/boxes/box3.png";
import { convertFromYocto, formatNumber, transformItem } from "../utils/utils";
import { post } from "../utils/api";
import { UserProfile } from "../components/Avatar";
import { Countdown } from "../components/Countdown";
import { rand } from "../../server/api/helpers/utils";

const prizes = [500, 350, 250, 150, 100, 75, 30, 25, 10, 10];

export const BoxOpen = () => {
  const { mainContract, currentUser } = useContext(NearContext);
  const [showOpenPopup, setShowOpenPopup] = useState(false);
  const [openResults, setOpenResults] = useState([]);
  const [isExploding, setIsExploding] = React.useState(false);
  const [searchParams] = useSearchParams();
  const [users, setUsers] = useState([]);
  const [isReadyUsers, setIsReadyUsers] = useState(false);

  const boxInfo = {
    1: "1 Land (Medium/Large); 3 Zombies (UnCommon/Rare/Epic); 1 Monster (Common/UnCommon); 4 Modifiers (Level 1); 1.000 - 5.000 ZML",
    2: "1 Medium Land; 2 Epic/Rare or 3 UnCommon Monsters; 2 Modifiers (Level 2); 4 Inventory (Level 2); 1.000 - 3.000 ZML",
    3: "3 Zombies (Uncommon/Rare/Epic); 1 Monster (Common/UnCommon); 4 Modifiers (Level 1); 50.000 - 80.000 ZML;",
  };

  const fetchResults = async () => {
    const rpcURL =
      process.env.CONTRACT_NAME.indexOf(".testnet") === -1
        ? "https://rpc.mainnet.near.org"
        : "https://rpc.testnet.near.org";

    let resp = await fetch(rpcURL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        jsonrpc: "2.0",
        id: "dontcare",
        method: "tx",
        params: [searchParams.get("transactionHashes"), currentUser],
      }),
    });

    resp = await resp.json();

    if (resp.result?.status?.SuccessValue) {
      let txResult = atob(resp?.result?.status?.SuccessValue);
      txResult = JSON.parse(txResult);

      let results = [];
      txResult.map((res) => {
        results.push(parseReturnedData(res));
      });

      setOpenResults(results);
      setShowOpenPopup(true);
    }
  };

  const parseReturnedData = (input) => {
    // Regular expressions to match the different patterns
    const landPattern = /Land: (\d+); type: (\w+)/;
    const monsterPattern = /Monster: (\d+)/;
    const zombiePattern = /Zombies: (\d+)/;
    const inventoryPattern = /Inventory: (\d+); level: (\d+); id: (.+)/;
    const modifiersPattern = /Modifiers: (\d+); level: (\d+)/;
    const zmlPattern = /ZML: (\d+)/;

    // Check and format based on the input pattern
    if (landPattern.test(input)) {
      const [, id, type] = input.match(landPattern);
      return `🎴 ${type} Land`;
    } else if (monsterPattern.test(input)) {
      const [, id] = input.match(monsterPattern);
      return `🧟 ${id} Monster${id > 1 ? "s" : ""}`;
    } else if (zombiePattern.test(input)) {
      const [, id] = input.match(zombiePattern);
      return `🧟 ${id} Zombies`;
    } else if (inventoryPattern.test(input)) {
      const [, id, level, idsString] = input.match(inventoryPattern);
      if (idsString) {
        createItems(
          idsString.split("|").map((id) => id.trim()),
          level
        );
      }
      return `🔶 ${id} Inventory, level ${level}`;
    } else if (modifiersPattern.test(input)) {
      const [, id, level] = input.match(modifiersPattern);
      return `🔷 ${id} Modifiers, level ${level}`;
    } else if (zmlPattern.test(input)) {
      const [, zmlReward] = input.match(zmlPattern);
      const value = parseInt(convertFromYocto(zmlReward));
      return `🪙 ${value} ZML`;
    } else {
      return input;
    }
  };

  const createItems = async (itemsIdList, level) => {
    let foundItems = [];
    const inventoryItems = await mainContract.userInventoryItems({
      account_id: currentUser,
      page_num: 1,
      page_limit: 12,
    });

    inventoryItems[1]
      .filter((item) => itemsIdList.includes(item.token_id))
      .map((item) => {
        let transformedItem = transformItem(item);
        transformedItem.durabilityMin = 3;
        transformedItem.durabilityMax = 3;
        transformedItem.level = level;

        foundItems.push(transformedItem);
      });

    await post("api/items/create", { items: foundItems });
  };

  function getAllIndexes(arr, val) {
    var indexes = [],
      i;
    for (i = 0; i < arr.length; i++) if (arr[i] === val) indexes.push(i);
    return indexes;
  }

  const loadUsers = async () => {
    setUsers([]);
    setIsReadyUsers(false);

    let allUsers = [];
    const userStats = await mainContract.getBoxOpenCounts();
    Object.keys(userStats).map((user, i) => {
      allUsers.push({
        accountId: user,
        count: userStats[user],
        prize: prizes[i],
      });
    });

    allUsers = allUsers.sort((a, b) => b.count - a.count);
    allUsers = allUsers.map((user, i) => {
      const indexes = getAllIndexes(
        allUsers.map((u) => u.count),
        user.count
      );

      let totalPrize = 0;
      indexes.map((idx) => {
        if (prizes[idx]) totalPrize += prizes[idx];
      });
      user.prize = (totalPrize / indexes.length).toFixed();

      return user;
    });
    setUsers(allUsers);
    setIsReadyUsers(true);
  };

  useEffect(() => {
    if (searchParams.get("transactionHashes")) fetchResults();
    loadUsers();
  }, [searchParams]);

  useEffect(() => {
    if (openResults.length > 0) {
      setTimeout(() => {
        setIsExploding(true);
      }, 100);
    }
  }, [openResults]);

  const OnePack = ({ id, title, image, value }) => (
    <div
      className={
        "text-center w-72 bg-mainLight/80 border-2 border-mainLight rounded-lg p-2 relative"
      }
    >
      <img className={"w-auto rounded-md mb-2"} src={image} alt="box" />
      <div className={"font-semibold my-2"}>
        <p className={"my-4 text-lg"}>{title}</p>
        <div className={"text-xs font-normal  mb-4"}>
          {boxInfo[id].split(";").map((info, i) => (
            <p className="mb-1 text-indigo-100" key={i}>
              {info} &nbsp;
            </p>
          ))}
        </div>
        <div className="mb-3 text-sm">
          Apx. box Value <b className="text-green-500">{value}</b> NEAR
        </div>
        <Button
          title="Open / 5 NEAR"
          size={"sm"}
          onClick={() => {
            setOpenResults([]);
            setShowOpenPopup(true);
            handleOpen(id);
          }}
        />
      </div>
    </div>
  );

  const handleOpen = async (id) => {
    await mainContract.openMysteryBox(id);
  };

  return (
    <InnerPageWrapper>
      <Header />
      {isExploding && (
        <>
          <div className={"fixed left-[20%] top-[10%]"}>
            <ConfettiExplosion
              particleCount={200}
              particleSize={8}
              zIndex={100}
              onComplete={() => {
                setIsExploding(false);
              }}
            />
          </div>
          <div className={"fixed left-[80%] top-[20%]"}>
            <ConfettiExplosion
              particleCount={200}
              particleSize={8}
              zIndex={100}
            />
          </div>
        </>
      )}

      <Wrapper>
        <Container className="text-white text-center mt-6 mx-auto xl:w-3/4">
          <InnerPageHead
            title={"Mystery Boxes"}
            description={
              "Open ZomLand Mystery Boxes to get lands, zombies, monsters, inventory, modifiers, and ZML tokens for thrilling gameplay adventures!"
            }
          />

          <div className={"flex flex-wrap justify-center gap-11 mt-12"}>
            <OnePack id={1} title={"LandBox"} image={box1} value="4.5 - 6.5" />
            <OnePack
              id={2}
              title={"AssetsBox"}
              image={box3}
              value="4.3 - 6.7"
            />
            <OnePack id={3} title={"ZmlBox"} image={box2} value="4.8 - 6.2" />
          </div>

          <div className="my-10">
            <h1 className="text-2xl mb-3">
              <b>
                <span className="text-amber-500">$1500</span> for TOP 10
              </b>
            </h1>
            <p className="mb-3">Time left:</p>
            <Countdown endDate={new Date("05/10/2024")} />
          </div>

          <h1 className="zombie-font text-indigo-300 font-normal md:text-4xl text-4xl capitalize">
            Leaderboard
          </h1>
          <div className="p-5 mt-4 bg-main lg:w-full mx-auto w-full">
            <Row className="justify-between border-b-2 pb-3 text-gray-500 font-bold border-gray-600/30 border-dashed">
              <Row>
                <div className="w-8 mr-0 md:mr-4">#</div>
                <div className="flex justify-start w-1/2">Account</div>{" "}
              </Row>
              <Row>
                <div className="w-12 md:w-32">Boxes</div>
                <div className="w-16 md:w-32">Reward (USDC)</div>
              </Row>
            </Row>

            {isReadyUsers ? (
              <Col>
                {users.map(({ accountId, count, prize }, i) => (
                  <Row
                    key={i + 1}
                    className={`py-3 border-b-2 border-gray-700/30 border-dashed"`}
                  >
                    <div className="w-8 opacity-70 mr-0 md:mr-4">{i + 1}</div>
                    <div className="flex items-center flex-1 w-1/2 truncate text-ellipsis">
                      <UserProfile profile={accountId} accountId={accountId} />
                    </div>
                    <div className="w-12 md:w-32 font-semibold">
                      {formatNumber(count)}
                    </div>
                    <div className="w-16 md:w-32 text-amber-500 font-semibold">
                      {prize > 0 ? `+${prize}` : ""}
                    </div>
                  </Row>
                ))}
              </Col>
            ) : (
              <Loader />
            )}
          </div>
        </Container>
      </Wrapper>

      <Footer />

      <Popup
        title="Box Prizes"
        width="sm:w-[700px]"
        popupVisible={showOpenPopup}
        setPopupVisible={setShowOpenPopup}
      >
        <div>
          {openResults.length > 0 ? (
            <>
              {openResults.map((res, i) => (
                <div className={"text-center my-2"} key={i}>
                  {res}
                </div>
              ))}
            </>
          ) : (
            <div className={"text-center w-16 mx-auto"}>
              <Loader />
            </div>
          )}
        </div>
      </Popup>
    </InnerPageWrapper>
  );
};

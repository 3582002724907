"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUnityLoader = void 0;
var react_1 = require("react");
var is_browser_environment_1 = require("../constants/is-browser-environment");
var unity_loader_status_1 = require("../enums/unity-loader-status");
/**
 * Hook to embed a Unity Loader script.
 * @param source The source of the unity loader.
 * @returns a hook that returns the status of the loader.
 */
var useUnityLoader = function (unityConfig) {
    var _a = (0, react_1.useState)(unity_loader_status_1.UnityLoaderStatus.Loading), status = _a[0], setStatus = _a[1];
    // Effect hook will be invoked when the source changes.
    (0, react_1.useEffect)(function () {
        // It is possible for the application being rendered server side. In
        // this scenario, the window is not available. We can't create a Unity
        // Loader in this case.
        if (is_browser_environment_1.isBrowserEnvironment === false) {
            return;
        }
        // If the script's source is null, we'll reset the status to idle.
        if (unityConfig.loaderUrl === null) {
            setStatus(unity_loader_status_1.UnityLoaderStatus.Idle);
            return;
        }
        /**
         * Find existing script element by source. It may have been added by
         * another instance of this hook.
         */
        var script = window.document.querySelector("script[src=\"".concat(unityConfig.loaderUrl, "\"]"));
        // If there wan't another instance of this script, we're going to create a
        // new one with the provided source.
        if (script === null) {
            script = window.document.createElement("script");
            script.type = "text/javascript";
            script.src = unityConfig.loaderUrl;
            script.async = true;
            script.setAttribute("data-status", "loading");
            // Add script to window.document body.
            window.document.body.appendChild(script);
            // Store status in attribute on script. This can be read by other
            // instances of this hook.
            script.addEventListener("load", function () {
                return script === null || script === void 0 ? void 0 : script.setAttribute("data-status", "loaded");
            });
            script.addEventListener("error", function () {
                return script === null || script === void 0 ? void 0 : script.setAttribute("data-status", "error");
            });
        }
        else {
            // If there already was a script with the same source, grab its existing
            // script status from attribute and set to state.
            setStatus(script.getAttribute("data-status") === "loaded"
                ? unity_loader_status_1.UnityLoaderStatus.Loaded
                : unity_loader_status_1.UnityLoaderStatus.Error);
        }
        /**
         * Script event handler to update status in state. Even if the script
         * already exists we still need to add event handlers to update the state
         * for this hook instance.
         * @param event The event that was triggered.
         */
        var setStateFromEvent = function (event) {
            return setStatus(event.type === "load"
                ? unity_loader_status_1.UnityLoaderStatus.Loaded
                : unity_loader_status_1.UnityLoaderStatus.Error);
        };
        script.addEventListener("load", setStateFromEvent);
        script.addEventListener("error", setStateFromEvent);
        // Remove event listeners on cleanup.
        return function () {
            if (script !== null) {
                script.removeEventListener("load", setStateFromEvent);
                script.removeEventListener("error", setStateFromEvent);
                window.document.body.removeChild(script);
            }
        };
    }, [unityConfig.loaderUrl]);
    return status;
};
exports.useUnityLoader = useUnityLoader;

import React, { useEffect, useState, useRef, useContext } from "react";
import { List } from "../../assets/styles/common.style";
import { ListWrapper } from "../../assets/styles/common.style";
import { MarketContent } from "../../utils/content";
import { Header, Footer, BuyItemsFooter } from "../../components";
import { Link, useNavigate, useParams } from "react-router-dom";
import MarketHistoryPopup from "./MarketHistoryPopup";
import { Button } from "../../ui";
import { NearContext } from "../../contexts/NearWallet";
import {
  inventoryOptions,
  maxDiscoveryEvents,
  modifierOptions,
  rarityOptions,
  selectAll,
  transformItem,
  transformZombieMonster,
} from "../../utils/utils";
import {
  Container,
  InnerPageWrapper,
  Wrapper,
} from "../../assets/styles/common.style";
import {
  Loader,
  Dropdown,
  BoltIcon,
  CardRotate,
  formatCharacteristicOfCard,
  InnerPageHead,
  Pagination,
  CardItem,
  PartOfMonster,
} from "../../ui";

import parasLogo from "../../assets/images/paras.png";
import { updateUserBalance } from "../../services/UserService";
import { useDispatch } from "react-redux";
import { get } from "../../utils/api";
import { ClipboardListIcon } from "@heroicons/react/outline";

const PAGE_LIMIT = 20;

export const Market = () => {
  const { section } = useParams();
  const dispatch = useDispatch();
  const { currentUser, mainContract, ftContract } = useContext(NearContext);
  const [isReady, setIsReady] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsCount, setItemsCount] = useState(0);
  const [active, setActive] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterRarity, setFilterRarity] = useState(null);
  const [filterCollection, setFilterCollection] = useState(null);
  const [filterType, setFilterType] = useState(null);
  const [allCollections, setAllCollections] = useState([]);
  const [marketHistoryVisible, setMarketHistoryVisible] = useState(false);
  const [reverse, setReverse] = useState(true);
  const [landStatsInfo, setLandStatsInfo] = useState();
  const [landStatsLoading, setLandStatsLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState({
    Zombie: [],
    Monster: [],
    MonsterPart: [],
    Inventory: [],
    Modifier: [],
  });
  const landUrlRef = useRef();

  const navigate = useNavigate();

  const isNFT = (section) => {
    return (
      section === "zombies" ||
      section === "monsters" ||
      section === "monster_parts"
    );
  };

  const capitalizeFirstLetter = (string) => {
    let stringArray = string.split("_");
    return stringArray
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("");
    // return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const showMarket = async (
    section,
    rarity,
    collection,
    itemType,
    page = 1
  ) => {
    setIsReady(false);

    let marketItems;
    if (section !== "lands") {
      const startIndex = (page - 1) * PAGE_LIMIT;
      const filterParams = {
        start: startIndex,
        limit: PAGE_LIMIT,
        is_owner_items: false,
      };
      if (rarity) {
        filterParams["filter_rarity"] = rarity;
      }
      if (collection) {
        filterParams["filter_collection"] = parseInt(collection);
      }
      if (itemType) {
        filterParams["filter_type"] = itemType;
      }

      const result = await mainContract[
        `get${capitalizeFirstLetter(section)}FromMarket`
      ](filterParams);
      setItemsCount(result[0] ?? 0);

      if (isNFT(section)) {
        marketItems = result[1]?.map((item) => transformZombieMonster(item));
      } else if (section === "modifiers") {
        marketItems = result[1]?.map((item) => transformItem(item));
      } else {
        const inventoryItems = result[1];
        const itemsResp = await get("api/items", {
          ids: inventoryItems.map((item) => item.token_id),
        });
        const itemsDB = itemsResp.data;

        if (itemsDB) {
          marketItems = inventoryItems.map((item) => {
            let transformedItem = transformItem(item);
            const itemDB = itemsDB.find((i) => i.itemId === item.token_id);

            transformedItem.durabilityMin = itemDB?.durabilityMin;
            transformedItem.durabilityMax = itemDB?.durabilityMax;
            transformedItem.level = itemDB?.level;

            return transformedItem;
          });
        }
      }
    }

    setItems(marketItems ?? []);
    navigate(buildUrl(section, rarity, collection, itemType, page));
    setActive(section);
    setIsReady(true);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = JSON.parse(searchParams.has("page"))
      ? parseInt(searchParams.get("page"))
      : currentPage;
    let rarity, collection;

    if (isNFT(section)) {
      rarity = JSON.parse(searchParams.has("rarity"))
        ? searchParams.get("rarity")
        : filterRarity;
      collection = JSON.parse(searchParams.has("collection"))
        ? searchParams.get("collection")
        : filterCollection;
    }
    let itemType = JSON.parse(searchParams.has("item_type"))
      ? searchParams.get("item_type")
      : filterType;

    setCurrentPage(page);
    setFilterRarity(rarity);
    setFilterCollection(collection);
    setFilterType(itemType);

    fetchCollections();
    showMarket(getCurrentSection(), rarity, collection, itemType, page);
  }, [section]);

  useEffect(() => {
    if (isReady) {
      setCurrentPage(1);
      showMarket(
        getCurrentSection(),
        filterRarity,
        filterCollection,
        filterType,
        1
      );
      navigate(buildUrl(getCurrentSection(), filterRarity, filterType, 1));
    }
  }, [filterRarity, filterCollection, filterType]);

  const getCurrentSection = () => {
    return section || "zombies";
  };

  const isSelectedAny = () => {
    let isAnySelected = false;
    Object.keys(selectedItems).map((itemType) => {
      if (selectedItems[itemType].length > 0) {
        isAnySelected = true;
      }
    });
    return isAnySelected;
  };

  const isSelected = (item) =>
    selectedItems[item.nft_type].filter(
      (value) => value.token_id === item.token_id
    ).length > 0;

  const selectToBuy = async (itemList, isAlert = true) => {
    itemList.map((item) => {
      if (item.owner_id !== currentUser) {
        const findItem = selectedItems[item.nft_type].findIndex(
          (value) => value.token_id === item.token_id
        );

        if (findItem < 0) {
          let totalSelected = 0;
          Object.keys(selectedItems).map((itemType) => {
            totalSelected += selectedItems[itemType].length;
          });
          if (totalSelected >= 40) {
            if (isAlert) {
              alert(
                "Sorry, purchase is limited to 40 Items/NFT per transaction"
              );
            }
            return false;
          }

          selectedItems[item.nft_type].push(item);
        } else {
          selectedItems[item.nft_type] = selectedItems[item.nft_type].filter(
            (_, ind) => findItem !== ind
          );
        }
      }
    });

    setSelectedItems({ ...selectedItems });
  };

  const handleSuccessBuy = () => {
    showMarket(active, filterRarity, filterCollection, filterType, currentPage);
    updateUserBalance(dispatch, ftContract, currentUser);
    setSelectedItems({
      Zombie: [],
      Monster: [],
      MonsterPart: [],
      Inventory: [],
      Modifier: [],
    });
  };

  const rmFromMarketHandle = async (item) => {
    setIsReady(false);
    await mainContract.removeFromMarket(item.token_id, item.nft_type);

    if (active === "zombies") {
      showMarket(
        "zombies",
        filterRarity,
        filterCollection,
        filterType,
        currentPage
      );
    } else if (active === "monsters") {
      showMarket(
        "monsters",
        filterRarity,
        filterCollection,
        filterType,
        currentPage
      );
    } else if (active === "inventory") {
      showMarket(
        "inventory",
        filterRarity,
        filterCollection,
        filterType,
        currentPage
      );
    } else if (active === "modifiers") {
      showMarket(
        "modifiers",
        filterRarity,
        filterCollection,
        filterType,
        currentPage
      );
    } else if (active === "monster_parts") {
      showMarket(
        "monster_parts",
        filterRarity,
        filterCollection,
        filterType,
        currentPage
      );
    }
  };

  async function fetchCollections() {
    setAllCollections(await mainContract.getCollections());
  }

  const collectionOptions = () => {
    const collections = Object.keys(allCollections).map((key) => {
      return {
        title: allCollections[key].title,
        onClick: () => setFilterCollection(key),
      };
    });
    return [
      {
        title: "All",
        onClick: () => setFilterCollection(null),
      },
      ...collections,
    ];
  };

  const monsterPartOptions = () => {
    let result = [
      {
        title: "All",
        onClick: () => setFilterType(null),
      },
    ];

    for (let i = 1; i <= 12; i++) {
      result.push({
        title: `Part #${i}`,
        onClick: () => setFilterType(i.toString()),
      });
    }
    return result;
  };

  const buildUrl = (section, rarity, collection, filterType, page = 1) => {
    let url = `/market/${section.toLowerCase()}?page=${page}`;
    if (isNFT(section)) {
      if (rarity) url = `${url}&rarity=${rarity}`;
      if (collection) url = `${url}&collection=${collection}`;
    }
    if (filterType) url = `${url}&item_type=${filterType}`;
    return url;
  };

  const onPageChanged = (page) => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    setCurrentPage(page);
    if (isReady) {
      showMarket(active, filterRarity, filterCollection, filterType, page);
    }
  };

  const resetFilters = () => {
    setCurrentPage(1);
    setFilterRarity(null);
    setFilterCollection(null);
    setFilterType(null);
  };

  const sectionsList = () => {
    let result = [];
    const options = [
      "Zombies",
      "Monsters",
      "Monster Parts",
      "Inventory",
      "Modifiers",
    ];
    options.map((item) => {
      result.push({
        title: item,
        onClick: () =>
          navigate(`/market/${item.toLowerCase().replace(" ", "_")}`),
      });
    });
    return result;
  };

  const power = (nft) =>
    formatCharacteristicOfCard(nft)
      .map((item) => item.value)
      .reduce((prev, curr) => prev + curr, 0);

  const sortByPower = () => {
    const _items = items.sort((a, b) =>
      reverse ? power(b) - power(a) : power(a) - power(b)
    );
    setReverse(!reverse);
    setItems(_items);
  };

  const getDiscoveryStats = async () => {
    const landUrl = landUrlRef.current.value;
    if (!landUrl.length || !landUrl.includes("paras.id")) {
      alert("Please add paras.id URL for NFT that you want to check");
      return false;
    }
    const url = landUrl.replace("%3A", ":");
    const parsedURL = url.split("/");
    const landId = parsedURL[parsedURL.length - 1];
    if (!landId || parseInt(landId) < 1) {
      alert(
        "Wrong URL for NFT from paras.id website. You need to open Land NFT details and copy the URL."
      );
      return false;
    }

    setLandStatsLoading(true);
    try {
      const landInfo = await mainContract.landInfo(landId);
      if (landInfo) {
        setLandStatsInfo(landInfo);
      }
      setLandStatsLoading(false);
    } catch (e) {
      console.log(`error`, e);
      alert(
        "Land not found, please check URL and retry in few minutes (it can be blockchain issue)"
      );
      setLandStatsLoading(false);
    }
  };

  const selectAllInPage = () => {
    selectToBuy(items, false);
  };

  return (
    <InnerPageWrapper>
      <Header />

      <Wrapper>
        <Container className="text-white text-center mt-6">
          <InnerPageHead
            title={`${getCurrentSection().replace("_", " ")} ${
              MarketContent.title
            }`}
            description={MarketContent.description}
          />
          <div className="mb-10 w-full">
            <div className="flex justify-between relative z-20 mx-4 md:mx-0">
              {active !== "lands" && (
                <div className="flex justify-start align-middle items-center lg:w-1/2">
                  <div className="md:flex align-middle items-center">
                    <div className="md:hidden mb-1">
                      <Dropdown
                        title="Market"
                        selected={getCurrentSection()}
                        options={sectionsList()}
                      />
                    </div>

                    <div className="flex">
                      <Button
                        title=""
                        secondary
                        size="sm"
                        icon={
                          <div className="flex gap-2 py-0.5">
                            <ClipboardListIcon className="h-5 w-5" />
                            <span>Market History</span>
                          </div>
                        }
                        onClick={() => setMarketHistoryVisible(true)}
                      />
                    </div>

                    <div className="pt-1 ml-6 hidden sm:block">
                      <span>Total:</span>
                      <span
                        className="ml-2 font-semibold text-amber-600 cursor-pointer"
                        onClick={() => selectAllInPage()}
                      >
                        {itemsCount} NFTs
                      </span>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex justify-end align-middle lg:w-1/2 my-5">
                <div className="md:flex z-10 justify-end text-right">
                  {(active === "zombies" ||
                    active === "monsters" ||
                    active === "monster_parts") && (
                    <>
                      <div className="inline-block">
                        <Button
                          title=""
                          secondary
                          size="sm"
                          icon={
                            <div className="flex gap-2 py-0.5">
                              <BoltIcon className="w-5 h-5" />
                              <span>Power</span>
                            </div>
                          }
                          onClick={sortByPower}
                        />
                      </div>
                      <div className="relative inline-block md:mx-3 md:mb-0 mb-1 z-10">
                        <Dropdown
                          title="Rarity"
                          selected={filterRarity}
                          options={rarityOptions(setFilterRarity)}
                        />
                      </div>
                      {active !== "monster_parts" ? (
                        <div className="relative inline-block">
                          <Dropdown
                            title="Collection"
                            selected={
                              filterCollection
                                ? allCollections[filterCollection]?.title
                                : null
                            }
                            options={collectionOptions()}
                          />
                        </div>
                      ) : (
                        <div className="inline-block">
                          <Dropdown
                            title="Part #"
                            selected={filterType ? `Part #${filterType}` : null}
                            options={monsterPartOptions()}
                          />
                        </div>
                      )}
                    </>
                  )}

                  {active === "inventory" && (
                    <div className="inline-block">
                      <Dropdown
                        title="Item Type"
                        selected={filterType}
                        options={inventoryOptions(setFilterType)}
                      />
                    </div>
                  )}

                  {active === "modifiers" && (
                    <div className="inline-block">
                      <Dropdown
                        title="Item Type"
                        selected={filterType}
                        options={modifierOptions(setFilterType)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <ListWrapper>
              {active === "lands" && (
                <div>
                  <div className="mb-4 w-[240px] mx-auto -mt-16">
                    <a
                      href={process.env.PARAS_MARKET_URL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={parasLogo}
                        alt=""
                        width="240"
                        className="rounded-lg"
                      />
                    </a>
                  </div>

                  <p>
                    To buy or sale a land you can visit{" "}
                    <a
                      href={process.env.PARAS_MARKET_URL}
                      target="_blank"
                      className="link font-semibold"
                      rel="noreferrer"
                    >
                      paras.id
                    </a>{" "}
                    marketplace <br />
                    or mint new land in{" "}
                    <Link to="/lands" className="link font-semibold">
                      Lands
                    </Link>{" "}
                    page.
                  </p>

                  <div
                    className={
                      "mt-12 max-w-[840px] mx-auto text-center bg-mainLight/60 border-2 border-mainLight px-6 py-8"
                    }
                  >
                    <b className={"text-2xl text-red-400"}>IMPORTANT!</b>
                    <p className={"px-10 text-red-100"}>
                      Before making a purchase of land from paras.id, make sure
                      to review the land discovery statistics. If you buy 100%
                      discovered land from the secondary market, your monsters
                      will not be able to discover this land.
                    </p>

                    <div className={"mt-6"}>
                      <span className={"opacity-60"}>
                        Provide land URL from paras.id and click "Discovery
                        Stats" to check it:
                      </span>
                      <p className={"mx-auto mt-3 px-10"}>
                        <input
                          type="text"
                          disabled={landStatsLoading}
                          className="px-4 py-2 rounded-md bg-transparent border-indigo-500 text-indigo-100 border-2 mr-3 w-[70%]"
                          placeholder="NFT URL from paras.id"
                          ref={landUrlRef}
                        />
                        <Button
                          title={"Discovery Stats"}
                          disabled={landStatsLoading}
                          noIcon
                          size={"sm"}
                          onClick={() => getDiscoveryStats()}
                        />
                      </p>
                    </div>

                    <div>
                      {landStatsLoading ? (
                        <div className={"w-10 mx-auto mt-4"}>
                          <Loader />
                        </div>
                      ) : (
                        <>
                          {landStatsInfo && (
                            <div
                              className={
                                "mt-6 text-center bg-blue-800/30 rounded-md px-8 py-6"
                              }
                            >
                              <h3 className={"text-xl font-medium mb-2"}>
                                Land Details
                              </h3>
                              <div className={`flex flex-row`}>
                                <p className={"flex-1"}>
                                  <span className={"opacity-60 mr-2"}>
                                    Land ID:
                                  </span>
                                  <b className={"text-lg"}>
                                    {landStatsInfo.token_id}
                                  </b>
                                </p>
                                <p className={"flex-1"}>
                                  <span className={"opacity-60 mr-2"}>
                                    Land Type:
                                  </span>
                                  <b className={"text-lg"}>
                                    {landStatsInfo.land_type}
                                  </b>
                                </p>
                                <p className={"flex-1"}>
                                  <span className={"opacity-60 mr-2"}>
                                    Discovered:
                                  </span>
                                  <b
                                    className={`text-lg ${
                                      landStatsInfo.discover_events >=
                                        maxDiscoveryEvents(
                                          landStatsInfo.land_type
                                        ) && "text-red-400"
                                    }`}
                                  >
                                    {landStatsInfo.discover_events} /{" "}
                                    {maxDiscoveryEvents(
                                      landStatsInfo.land_type
                                    )}
                                  </b>
                                </p>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {isReady ? (
                <>
                  {(active === "zombies" || active === "monsters") && (
                    <List>
                      {items.length > 0 ? (
                        <>
                          {items.map((item, index) => (
                            <div key={index}>
                              {currentUser === item.owner_id ? (
                                <CardRotate
                                  nft={item}
                                  key={index}
                                  rmFromMarket={() => rmFromMarketHandle(item)}
                                />
                              ) : (
                                <CardRotate
                                  nft={item}
                                  key={index}
                                  isSelected={isSelected(item)}
                                  handleBuy={() => selectToBuy([item])}
                                />
                              )}
                            </div>
                          ))}
                        </>
                      ) : (
                        <div>
                          No {filterType} {active} on sale.
                        </div>
                      )}
                    </List>
                  )}

                  {active === "monster_parts" && (
                    <List>
                      {items.length > 0 ? (
                        <>
                          {items.map((item, index) => (
                            <div key={index}>
                              {currentUser === item.owner_id ? (
                                <PartOfMonster
                                  item={item}
                                  key={index}
                                  rmFromMarket={() => rmFromMarketHandle(item)}
                                />
                              ) : (
                                <PartOfMonster
                                  item={item}
                                  key={index}
                                  isSelected={isSelected(item)}
                                  handleBuy={() => selectToBuy([item])}
                                />
                              )}
                            </div>
                          ))}
                        </>
                      ) : (
                        <div>No {filterRarity} Monster parts on sale.</div>
                      )}
                    </List>
                  )}

                  {(active === "inventory" || active === "modifiers") && (
                    <List>
                      {items.length > 0 ? (
                        <>
                          {items.map((item, index) => (
                            <div key={index}>
                              {currentUser === item.owner_id ? (
                                <CardItem
                                  isItem={active === "inventory"}
                                  item={item}
                                  key={index}
                                  rmFromMarket={() => rmFromMarketHandle(item)}
                                />
                              ) : (
                                <CardItem
                                  isItem={active === "inventory"}
                                  item={item}
                                  key={index}
                                  isSelected={isSelected(item)}
                                  handleBuy={() => selectToBuy([item])}
                                />
                              )}
                            </div>
                          ))}
                        </>
                      ) : (
                        <div>
                          No {filterType} {active} on sale.
                        </div>
                      )}
                    </List>
                  )}

                  {(filterRarity || filterCollection || filterType) && (
                    <div className="mt-10">
                      {active !== "lands" && (
                        <a
                          className="link cursor-pointer"
                          onClick={() => resetFilters()}
                        >
                          Reset Filters
                        </a>
                      )}
                    </div>
                  )}

                  <Pagination
                    total={parseInt(itemsCount)}
                    limit={PAGE_LIMIT}
                    selectedPage={currentPage}
                    onPageChanged={onPageChanged}
                  />
                </>
              ) : (
                <Loader />
              )}
            </ListWrapper>
          </div>
        </Container>

        <MarketHistoryPopup
          marketHistoryVisible={marketHistoryVisible}
          setMarketHistoryVisible={setMarketHistoryVisible}
        />

        {isSelectedAny() && (
          <BuyItemsFooter
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            allItems={items}
            selectToBuy={selectToBuy}
            handleSuccessBuy={handleSuccessBuy}
          />
        )}
      </Wrapper>

      <Footer />
    </InnerPageWrapper>
  );
};

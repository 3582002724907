import React, { useContext } from "react";
import { Button, CardItem, Popup } from "../../ui";
import { getUpgradableItems } from "../../../server/api/helpers/items";
import { ArrowRightIcon } from "@heroicons/react/solid";
import { NearContext } from "../../contexts/NearWallet";
import { upgradeItemsService } from "../../services/itemService";
import { convertFromYocto } from "../../utils/utils";
import { useSelector } from "react-redux";
import { Loader } from "../clan/chat/components/Loader";
import { get } from "../../utils/api";

const LEVEL_COST = {
  2: 150,
  3: 300,
};

export const UpgradeItemPopup = ({
  upgradePopupVisible,
  setUpgradePopupVisible,
  items,
  successUpdated,
}) => {
  const { wallet, ftContract, mainContract } = useContext(NearContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const balance = useSelector((state) => state.user.balance);

  const lvlTwoItems = getUpgradableItems(items, 1).upgradable;
  const lvlThreeItems = getUpgradableItems(items, 2).upgradable;

  const upgradeCost =
    lvlTwoItems.length * LEVEL_COST[2] + lvlThreeItems.length * LEVEL_COST[3];

  const isEmpty =
    lvlThreeItems.length + lvlTwoItems.length === 0 ||
    parseInt(convertFromYocto(balance)) < upgradeCost;

  const nextLevel = (item) => {
    const newItem = Object.assign({}, item);
    newItem.level += 1;
    return newItem;
  };

  const handleUpgrade = async () => {
    setIsLoading(true);
    const ids = items.map((item) => item.token_id);

    const result = await upgradeItemsService(
      ids,
      upgradeCost,
      wallet,
      mainContract,
      ftContract,
      `${process.env.API_URL}/api/items/upgrade?ids=${JSON.stringify(ids)}`
    );
    if(!result) return;

    if (wallet.interface.id !== "near-wallet" && wallet.interface.id !== "my-near-wallet") {
      try {
        await get(`api/items/upgrade?ids=${JSON.stringify(ids)}`).then(() => {
          console.log(`+`);
        });
      } catch (e) {
        console.log(`e`, e);
      }
    }

    successUpdated();
    setUpgradePopupVisible(false);
    setIsLoading(false);
  };

  const UpgradeRow = ({ item }) => (
    <div className="w-full mt-3 flex flex-row justify-between items-center">
      <div className="w-36 h-36 relative">
        <div className="absolute z-80">
          <CardItem isItem item={item} size="sm" />
        </div>
        <div className="absolute z-90 top-2 left-2">
          <CardItem isItem item={item} size="sm" />{" "}
        </div>
      </div>
      <ArrowRightIcon className="w-14 h-14 text-violet-300/20" />
      <CardItem isItem item={nextLevel(item)} size="sm" />
    </div>
  );

  return (
    <Popup
      title="Upgrade Items"
      popupVisible={upgradePopupVisible}
      setPopupVisible={setUpgradePopupVisible}
    >
      <div className="mx-auto md:w-2/3 px-7 text-center">
        {isEmpty ? (
          <>
            <p className="mb-7">
              To upgrade item you have to merge a pair of items with the same
              level
            </p>
            {parseInt(convertFromYocto(balance)) < upgradeCost && (
              <p className="text-red-400 my-6">
                You don't have enough ZML for upgrade
              </p>
            )}
          </>
        ) : (
          <div className="w-full flex flex-col">
            <div className="w-full mb-7 flex flex-col justify-center items-center">
              <div className="w-full flex flex-row justify-between px-8 mb-2 uppercase font-bold">
                <div>Selected</div>
                <div>Receive</div>
              </div>

              {lvlTwoItems.map((item, index) => (
                <UpgradeRow item={item} key={`from-${index}`} />
              ))}
              {lvlThreeItems.map((item, index) => (
                <UpgradeRow item={item} key={`to-${index}`} />
              ))}
            </div>
          </div>
        )}

        <Button
          disabled={isEmpty || isLoading}
          noIcon={isLoading}
          onClick={handleUpgrade}
        >
          Upgrade for {upgradeCost} ZML
          {isLoading && (
            <span className={"ml-2"}>
              <Loader size={"sm"} />
            </span>
          )}
        </Button>
      </div>
    </Popup>
  );
};

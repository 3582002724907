import React, { useContext, useEffect, useRef, useState } from "react";
import {
  convertFromYocto,
  convertToYocto,
  formatNumber,
  transformZombieMonster,
} from "../utils/utils";
import { TokenContent } from "../utils/content";
import {
  Container,
  InnerPageWrapper,
  List,
  Wrapper,
} from "../assets/styles/common.style";
import { Header, Footer } from "../components";
import { Button, InnerPageHead, Popup, CardRotate } from "../ui";
import { NearContext } from "../contexts/NearWallet";
import { updateUserBalance } from "../services/UserService";
import { useDispatch, useSelector } from "react-redux";
import Big from "big.js";

const LAUNCH_TIME = new Date("March 31, 2023, 9:00:00");

export const Token = () => {
  const { ftContract, mainContract, currentUser, wallet } =
    useContext(NearContext);
  const balance = useSelector((state) => state.user.balance);
  const dispatch = useDispatch();

  const funRef = useRef(null);
  const [depositInput, setDepositInput] = useState("");
  const [userMonsters, setUserMonsters] = useState([0, []]);
  const [depositedBalance, setDepositedBalance] = useState(0);
  const [rewardBalance, setRewardBalance] = useState(0);
  const [aprPct, setAprPct] = useState(0);
  const [earnedDecimals, setEarnedDecimals] = useState(6);
  const [withdrawInput, setWithdrawInput] = useState("");
  const [stakeMonster, setStakeMonster] = useState();
  const [totalStake, setTotalStake] = useState();
  const [stakeMonsterPct, setStakeMonsterPct] = useState(0);
  const [monsterPopupVisible, setMonsterPopupVisible] = useState(false);
  const [transferPopupVisible, setTransferPopupVisible] = useState(false);
  const [transferAddress, setTransferAddress] = useState();
  const [transferAmount, setTransferAmount] = useState();
  const [canTransfer, setCanTransfer] = useState(false);

  useEffect(() => {
    updateAllData();
    updateEarnedRewards();
    updateAPR();

    if (localStorage.getItem("open_transfer_popup")) {
      localStorage.removeItem("open_transfer_popup");
      setTransferAddress(localStorage.getItem("transfer_address"));
      setCanTransfer(true);
      setTransferPopupVisible(true);
    }

    // update reward earned
    funRef.current = setInterval(() => {
      updateEarnedRewards();
      updateAPR();
    }, 30000);
    return () => {
      clearInterval(funRef.current);
    };
  }, []);

  const updateAllData = () => {
    getStakedMonster();
    updateDepositedAmount();
    updateTotalDeposit();
    updateStakeMonsterPct();
  };

  const updateEarnedRewards = async () => {
    let earned = await ftContract.getUserEarned(currentUser);
    setRewardBalance(earned);

    const earnedTokens = parseInt(convertFromYocto(earned.toString()));
    if (earnedTokens >= 1000) {
      setEarnedDecimals(2);
    } else if (earnedTokens >= 100) {
      setEarnedDecimals(4);
    } else if (earnedTokens >= 10) {
      setEarnedDecimals(5);
    } else {
      setEarnedDecimals(6);
    }
  };

  const updateAPR = async () => {
    const stake = await updateTotalDeposit();
    let apr = parseInt(
      ((0.5 * 3600 * 24 * 365) / convertFromYocto(stake, 2)) * 100
    );
    setAprPct(apr);
  };

  const updateStakeMonsterPct = async () => {
    let pct = await ftContract.getStakeMonsterPct(currentUser);
    setStakeMonsterPct(pct);
  };

  const getStakedMonster = async () => {
    let stakeMonster = await mainContract.isStakeMonster(currentUser);
    if(stakeMonster) {
      stakeMonster.sale_price = 0;
      setStakeMonster(stakeMonster);
    }
  };

  const updateTotalDeposit = async () => {
    let totalStake = await ftContract.getTotalSupply();
    setTotalStake(totalStake);

    return totalStake;
  };

  const openMonsterPopup = async () => {
    let requestParams = {
      account_id: currentUser,
      page_num: 1,
      page_limit: 1000,
    };
    let monsters = await mainContract.userMonsters(requestParams);
    // Filter to show only staking monsters
    monsters[1] = monsters[1]
      .filter((monster) => {
        return monster.collection_id === 6;
      })
      .map((m) => transformZombieMonster(m));
    monsters[0] = monsters[1].length;

    setUserMonsters(monsters);
    setMonsterPopupVisible(true);
  };

  const updateDepositedAmount = async () => {
    let deposited = await ftContract.getUserStake(currentUser);
    setDepositedBalance(deposited);
  };

  const selectMonster = async (monster) => {
    await mainContract.stakeMonster(monster.token_id);
    setMonsterPopupVisible(false);
    updateAllData();
  };

  const handleDeposit = () => {
    let depositAmount = convertToYocto(depositInput.toString());

    if (Big(balance.toString()).lt(depositAmount.toString())) {
      depositAmount = balance;
    }
    if (depositInput < 1) {
      alert("Please set deposit amount");
      return false;
    }

    ftContract
      .ftTransferCall(ftContract.contractId, depositAmount, "ft_staking")
      .then(() => {
        updateAllData();
        updateEarnedRewards();
        updateUserBalance(dispatch, ftContract, currentUser);
        setDepositInput("");
      });
  };

  const handleUnstakeMonster = async () => {
    await mainContract.unstakeMonster().then(() => {
      updateAllData();
      updateEarnedRewards();
    });
  };

  const handleWithdraw = () => {
    let withdrawAmount = convertToYocto(withdrawInput.toString());
    ftContract.withdrawStake(withdrawAmount).then(() => {
      updateAllData();
      updateEarnedRewards();
      updateUserBalance(dispatch, ftContract, currentUser);
      setWithdrawInput("");
    });
  };

  const handleWithdrawRewards = () => {
    ftContract.withdrawReward().then(() => {
      updateAllData();
      updateEarnedRewards();
      updateUserBalance(dispatch, ftContract, currentUser);
    });
  };

  const showTransferPopup = () => {
    setTransferPopupVisible(true);
  };

  const handleTransferToken = async () => {
    if (!transferAddress) {
      alert("Please provide NEAR Address");
      return false;
    }
    if (!transferAmount || parseFloat(transferAmount) <= 0) {
      alert("Please provide correct ZML Amount");
      return false;
    }
    if (transferAddress === currentUser) {
      alert("Sender and receiver should be different");
      return false;
    }

    let withdraw = convertToYocto(transferAmount);
    if (Big(withdraw.toString()).gt(balance.toString())) {
      withdraw = balance;
    }

    ftContract.ftTransfer(transferAddress, withdraw).then(() => {
      setTransferPopupVisible(false);
      updateUserBalance(dispatch, ftContract, currentUser);
    });
  };

  const handleApproveToken = async () => {
    if (transferAddress && transferAddress.length > 4) {
      try {
        await wallet.checkAccountAvailable(transferAddress);
      } catch (e) {
        alert("Account not exists, please check wallet address.");
        return;
      }

      let isStakeBalance = await ftContract.storageBalanceOf(transferAddress);
      if (isStakeBalance && isStakeBalance.total > 0) {
        setCanTransfer(true);
      } else {
        localStorage.setItem("open_transfer_popup", "true");
        localStorage.setItem("transfer_address", transferAddress);
        await ftContract.ftMint(transferAddress, "0");
      }
    } else {
      alert("Please, provide correct NEAR Address");
    }
  };

  const setMaxTokens = () => {
    setTransferAmount(convertFromYocto(balance, 2));
  };

  const timeDiffSeconds = (lastClaimTime) => {
    const timeNow = new Date().getTime();
    const oneDay = 24 * 60 * 60 * 1000;
    const diff = timeNow - lastClaimTime;
    return diff / 1000;
  };

  const earningWithMonster =
    (convertFromYocto(rewardBalance, earnedDecimals) * stakeMonsterPct) / 100;

  return (
    <>
      <InnerPageWrapper>
        <Header />

        <Wrapper>
          <Container className="text-white text-center mt-6">
            <InnerPageHead
              title={TokenContent.title}
              description={TokenContent.description}
            />

            <div className="2xl:w-3/4 w-full mx-auto bg-main p-10 rounded-2xl shadow-lg">
              <div className="sm:flex flex-row text-left">
                <div className="text-lg sm:w-9/12 lg:flex lg:gap-14">
                  <div className="lg:w-1/2">
                    <p className="mb-2 lg:mt-12 mt-6">
                      <span className="w-24 inline-block">Balance:</span>
                      <span className="font-semibold">
                        {formatNumber(convertFromYocto(balance, 2))} ZML
                      </span>
                      {balance > 0 && (
                        <span
                          className="ml-3 border-dashed border-b cursor-pointer text-sky-200"
                          onClick={() => showTransferPopup()}
                        >
                          transfer
                        </span>
                      )}
                    </p>
                    <p className="mb-2">
                      <span className="w-24 inline-block">Staked:</span>
                      <span className="font-semibold">
                        {formatNumber(convertFromYocto(depositedBalance, 2))}{" "}
                        ZML
                      </span>
                    </p>

                    <p className="whitespace-nowrap">
                      <span className="w-24 inline-block">Rewards:</span>
                      <span className="font-semibold">
                        {stakeMonster ? (
                          <>
                            {formatNumber(
                              (
                                convertFromYocto(
                                  rewardBalance,
                                  earnedDecimals
                                ) - earningWithMonster
                              ).toFixed(earnedDecimals)
                            )}
                            <span className="mx-1 text-amber-600">
                              (+{formatNumber(earningWithMonster.toFixed(2))})
                            </span>
                          </>
                        ) : (
                          <span className="mx-1">
                            {convertFromYocto(rewardBalance, earnedDecimals)}
                          </span>
                        )}
                        ZML
                      </span>
                      {rewardBalance > 0 && (
                        <span
                          className="ml-3 border-dashed border-b cursor-pointer text-sky-200"
                          onClick={() => handleWithdrawRewards()}
                        >
                          claim
                        </span>
                      )}
                    </p>

                    <p className="text-base mt-6 opacity-80">
                      Read more about staking in{" "}
                      <a
                        className="text-amber-600 underline"
                        href="https://zomland.gitbook.io/zomland-whitepaper/staking"
                      >
                        Whitepaper
                      </a>
                    </p>
                  </div>

                  <div className="lg:w-1/2">
                    <div className="mb-2 lg:mt-12 mt-6 lg:ml-4">
                      {aprPct && (
                        <p className="mb-2 whitespace-nowrap">
                          <span className="w-40 inline-block">APR:</span>
                          <span className="font-semibold">{aprPct}% 🔥</span>
                        </p>
                      )}
                      {totalStake && (
                        <p className="mb-2 whitespace-nowrap">
                          <span className="w-40 inline-block">
                            Total Staked:
                          </span>
                          <span className="font-semibold">
                            {formatNumber(convertFromYocto(totalStake, 2))} ZML
                          </span>
                        </p>
                      )}

                      <p className="mb-2 whitespace-nowrap">
                        <span className="w-40 inline-block">Distributed:</span>
                        <span className="font-semibold">
                          {formatNumber(0.5 * timeDiffSeconds(LAUNCH_TIME))} ZML
                        </span>
                      </p>

                      <p className="mb-2 whitespace-nowrap">
                        <span className="w-40 inline-block">
                          Staking Supply:
                        </span>
                        <span className="font-semibold">
                          {formatNumber(80000000)} ZML
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="sm:w-3/12 mt-10 sm:mt-0 sm:flex sm:justify-end">
                  {stakeMonster ? (
                    <div className="text-center">
                      <div className="mb-2 font-semibold">Staked Monster</div>
                      <div className="mb-1 w-36 mx-auto">
                        <CardRotate noFlip nft={stakeMonster} size="sm" />
                      </div>
                      <small
                        onClick={() => handleUnstakeMonster()}
                        className="border-dashed border-b cursor-pointer hover:text-sky-200"
                      >
                        unstake
                      </small>
                    </div>
                  ) : (
                    <div
                      className="lg:px-7 px-5 lg:py-10 py-4 text-left w-56 border-2 border-amber-600 hover:bg-black/30
                      transition cursor-pointer rounded-lg lg:text-base text-sm"
                      onClick={() => openMonsterPopup()}
                    >
                      <div className="text-center font-semibold border-b border-white/20 pb-4">
                        Use Staking Monster to increase reward
                      </div>
                      <ul className="mt-4 ml-3">
                        <li>Common: +2%</li>
                        <li>UnCommon: +5%</li>
                        <li>Rare: +12%</li>
                        <li>Epic: +25%</li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              <hr className="border-gray-800 my-6" />

              <div className="sm:flex lg:text-left text-center flex-row">
                <div className="sm:w-1/2">
                  <h3 className="text-lg font-semibold uppercase">Deposit</h3>
                  <div className="mt-2">
                    <input
                      type="number"
                      min="1"
                      step="0.01"
                      value={depositInput}
                      onChange={(e) => {
                        setDepositInput(e.target.value);
                      }}
                      className="px-3 w-52 py-2.5 rounded-md mr-2 bg-transparent border-indigo-500 text-indigo-100 border-2 mb-2 lg:mb-0"
                      placeholder="Token Amount"
                    />
                    <Button
                      secondary
                      title="Deposit"
                      onClick={() => handleDeposit()}
                    />
                  </div>

                  <div className="mt-2 text-sm opacity-40">
                    Balance:{" "}
                    <span
                      className="font-semibold border-dashed border-b cursor-pointer"
                      onClick={() => {
                        setDepositInput(convertFromYocto(balance, 2));
                      }}
                    >
                      {formatNumber(convertFromYocto(balance, 2))} ZML
                    </span>
                  </div>
                </div>

                <div className="sm:w-1/2 sm:pl-10 mt-8 sm:mt-0">
                  <h3 className="text-lg font-semibold uppercase">
                    Withdraw Staked
                  </h3>
                  <div className="mt-2">
                    <input
                      type="number"
                      min="1"
                      step="0.01"
                      value={withdrawInput}
                      onChange={(e) => {
                        setWithdrawInput(e.target.value);
                      }}
                      className="px-3 w-52 py-2.5 rounded-md mr-2 bg-transparent border-indigo-500 text-indigo-100 border-2 mb-2 lg:mb-0"
                      placeholder="Token Amount"
                    />

                    <Button
                      secondary
                      title="Withdraw"
                      onClick={() => handleWithdraw()}
                    />
                  </div>

                  <div className="mt-2 text-sm opacity-40">
                    Staked:{" "}
                    <span
                      className="font-semibold border-dashed border-b cursor-pointer"
                      onClick={() => {
                        setWithdrawInput(convertFromYocto(depositedBalance, 2));
                      }}
                    >
                      {formatNumber(convertFromYocto(depositedBalance, 2))} ZML
                    </span>
                  </div>
                </div>
              </div>

              <hr className="border-gray-800 my-6" />

              <h3 className="text-left text-lg mt-4 font-semibold uppercase">
                ZML Trading
              </h3>
              <p className={"mb-4 mt-2 text-left"}>
                You can use ref.finance for ZML token trading. Our Liquidity
                Pool:
                <a
                  href="https://app.ref.finance/pool/4148"
                  target={"_blank"}
                  className="text-amber-600 underline ml-1"
                >
                  app.ref.finance/pool/4148
                </a>
              </p>
            </div>

            <Popup
              title="Select Monster"
              width="sm:w-[816px]"
              popupVisible={monsterPopupVisible}
              setPopupVisible={setMonsterPopupVisible}
            >
              <div>
                {userMonsters[0] > 0 ? (
                  <List>
                    {userMonsters[1]?.map((monster, index) => (
                      <div
                        className="cursor-pointer"
                        key={monster.token_id}
                        onClick={() => selectMonster(monster)}
                      >
                        <CardRotate
                          nft={monster}
                          key={index}
                          size="sm"
                          noFlip
                        />
                      </div>
                    ))}
                  </List>
                ) : (
                  <div>You don't have Staking Monster.</div>
                )}
              </div>
            </Popup>

            <Popup
              title="Transfer ZML Token"
              width="sm:w-[700px]"
              popupVisible={transferPopupVisible}
              setPopupVisible={setTransferPopupVisible}
            >
              <div className="mt-2 px-6 flex flex-row">
                <div className="text-center mx-auto mb-4">
                  <p className="mb-6 mt-2">
                    You can transfer ZML tokens to any NEAR account.
                  </p>
                  <p className="mb-3">
                    <input
                      type="text"
                      className="px-4 py-2 w-full rounded-md bg-transparent border-indigo-500 text-indigo-100 border-2"
                      placeholder="NEAR Address"
                      value={transferAddress}
                      onChange={(e) => {
                        setTransferAddress(e.target.value);
                        setCanTransfer(false);
                      }}
                    />
                  </p>

                  {canTransfer && (
                    <p className="mb-5 relative">
                      <input
                        type="number"
                        className="px-4 py-2 w-full rounded-md bg-transparent border-indigo-500 text-indigo-100 border-2"
                        placeholder="ZML Amount"
                        value={transferAmount}
                        onChange={(e) => setTransferAmount(e.target.value)}
                      />
                      <span
                        className="absolute right-[-55px] top-0.5 p-2 cursor-pointer"
                        onClick={() => setMaxTokens()}
                      >
                        MAX
                      </span>
                    </p>
                  )}

                  <div className="flex flex-row justify-between">
                    <span>
                      {!canTransfer && (
                        <Button
                          title="Approve"
                          noIcon
                          secondary
                          onClick={() => handleApproveToken()}
                        />
                      )}
                    </span>

                    <Button
                      title="Transfer"
                      disabled={!canTransfer}
                      onClick={() => handleTransferToken(transferAddress)}
                    />
                  </div>
                </div>
              </div>
            </Popup>
          </Container>
        </Wrapper>

        <Footer />
      </InnerPageWrapper>
    </>
  );
};

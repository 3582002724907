import React, { useState, useRef, useEffect, useContext } from "react";
import { Button, iconMapping } from "../../../ui";
import { secondsToString, timeDiffSeconds } from "../../../utils/timeFormat";
import { convertFromNanoSeconds } from "../../../utils/utils";
import { ClanDailyRewardResult } from "../ClanDailyRewardResult";
import { SparklesIcon } from "@heroicons/react/outline";
import { NearContext } from "../../../contexts/NearWallet";

export const RewardButton = ({ id, clan }) => {
  const funRef = useRef(null);
  const { currentUser, mainContract } = useContext(NearContext);
  const [isTimeReady, setIsTimeReady] = useState(false);
  const [time, setTime] = useState(0);
  const [_, setCurrentDate] = useState(Date.now());
  const [rewardPopupVisible, setRewardPopupVisible] = useState(false);
  const [rewardResult, setRewardResult] = useState([0, [], 0]);

  async function fetchClanDailyTime() {
    const claim_time = await mainContract.getClanDailyRewardTime(
      id,
      currentUser
    );

    setTime(convertFromNanoSeconds(claim_time));
    setIsTimeReady(true);
  }

  useEffect(() => {
    fetchClanDailyTime();
  }, []);

  useEffect(() => {
    funRef.current = setInterval(() => {
      setCurrentDate(Date.now());
    }, 1000);
    return () => {
      clearInterval(funRef.current);
    };
  }, []);

  const cantClaim = () =>
    timeDiffSeconds(time) >= 0 && timeDiffSeconds(time) < 86400;

  const claimMyReward = async () => {
    setIsTimeReady(false);

    try {
      const reward = await mainContract.claimClanDailyRewards(clan.id);
      setRewardResult(reward);

    } catch (e) {
      console.error(`error`, e);
      if (e.kind && e.kind.ExecutionError) {
        const matches = e.kind.ExecutionError.match(/'(.*?)'/);
        let errorMessage = `Claim error, please try again later`;
        if (matches && matches.length) {
          errorMessage = `Claim error: ${matches[1]}`;
        }
        alert(errorMessage);
        return false;
      }
    }

    fetchClanDailyTime();
    setRewardPopupVisible(true);
  };

  return (
    <>
      {clan.size < 100 && (
        <>
          <Button
            title={
              isTimeReady
                ? cantClaim()
                  ? `${secondsToString(timeDiffSeconds(time))}`
                  : "CLAIM DAILY REWARDS"
                : "LOADING REWARDS ..."
            }
            disabled={!isTimeReady || cantClaim()}
            size="md"
            icon={<SparklesIcon className={`ml-2 ${iconMapping["md"]}`} />}
            onClick={claimMyReward}
          />

          <ClanDailyRewardResult
            popupVisible={rewardPopupVisible}
            setPopupVisible={setRewardPopupVisible}
            rewardType={rewardResult[0]}
            rewardValue={rewardResult[1]}
            itemDurability={rewardResult[2]}
          />
        </>
      )}
    </>
  );
};

import React, { useEffect, useState, useRef, useContext } from "react";
import { LandRegions, resetDiscoveryPriceMap } from "../../utils/content";
import {
  diffDays,
  secondsToStringShort,
  timeDiffSeconds,
} from "../../utils/timeFormat";
import { RefreshIcon } from "@heroicons/react/outline";
import { NearContext } from "../../contexts/NearWallet";
import {
  convertToYocto,
  maxDiscoveryEvents,
  transformItem,
  transformZombieMonster,
} from "../../utils/utils";
import {
  CardRotate,
  CardLandRegion,
  Pagination,
  CardItem,
  PartOfMonster,
  Button,
  Loader,
  Popup,
} from "../../ui";

import discoveryIcon from "../../assets/images/discovery-icon.png";
import brokenIcon from "../../assets/images/broken.png";
import ripIcon from "../../assets/images/rip.png";
import { post } from "../../utils/api";

const PAGE_LIMIT = 20;

export const DiscoverLandPopup = ({
  setDiscoverLandPopupVisible,
  discoverLandPopupVisible,
  discoverLandItem,
  handleDiscoverFinished,
}) => {
  const funRef = useRef(null);
  const { mainContract, currentUser } = useContext(NearContext);
  const [currentDate, setCurrentDate] = useState(Date.now());
  const [currentStep, setCurrentStep] = useState(1);
  const [landRegion, setLandRegion] = useState();
  const [selectedMonster, setSelectedMonster] = useState();
  const [userMonsters, setUserMonsters] = useState([0, []]); // [<count>, [<arrayOfMonsters>]]
  const [currentPage, setCurrentPage] = useState(1);
  const [showDiscoveryLoading, setShowDiscoveryLoading] = useState(false);
  const [discoveryResult, setDiscoveryResult] = useState();
  const [showDiscoveryResult, setShowDiscoveryResult] = useState(false);
  const [foundInventoryItems, setFoundInventoryItems] = useState([]);
  const [foundModifier, setFoundModifier] = useState([]);
  const [foundMonsterPart, setFoundMonsterPart] = useState({});
  const [foundZmlAmount, setFoundZmlAmount] = useState(0);
  const [discoveryEventCount, setDiscoveryEventCount] = useState(0);

  const stepLineMap = {
    1: "md:left-32 sm:left-28 left-16",
    2: "left-[50%]",
    3: "left-[100%]",
  };

  const loadMonsters = async (page) => {
    let monsters = await mainContract.userMonsters({
      account_id: currentUser,
      page_num: page,
      page_limit: PAGE_LIMIT,
    });

    monsters[1] = monsters[1].map((mn) => transformZombieMonster(mn));
    setUserMonsters(monsters);
  };

  useEffect(() => {
    if (discoverLandItem) {
      loadMonsters(currentPage);
      setCurrentPage(1);
      setDiscoveryEventCount(discoverLandItem.discover_events);
    }
  }, [discoverLandItem]);

  useEffect(() => {
    repeatDiscover(true);
  }, [discoverLandPopupVisible]);

  useEffect(() => {
    if (currentStep === 1) {
      funRef.current = setInterval(() => {
        setCurrentDate(Date.now());
      }, 1000);
      return () => {
        clearInterval(funRef.current);
      };
    } else {
      clearInterval(funRef.current);
    }
  }, [currentStep]);

  const onPageChanged = (page) => {
    setCurrentPage(page);
    loadMonsters(page);
  };

  const StepCircle = ({ step }) => (
    <div className="w-1/3 z-10 relative cursor-default">
      <div
        className={`border-2 rounded-full w-10 h-10 mx-auto pt-1 text-lg text-center font-semibold
        ${
          currentStep >= step
            ? "text-amber-500 bg-amber-50 border-amber-500"
            : "text-gray-400 bg-gray-100"
        }`}
      >
        {step}
      </div>
    </div>
  );

  const StepDescription = ({ step, text }) => (
    <div className="w-1/3">
      <div
        className={`text-center text-sm mt-1 font-semibold 
      ${currentStep >= step ? "text-amber-500" : "text-gray-400"}
      `}
      >
        {text}
      </div>
    </div>
  );

  const selectMonsterForDiscovery = (monster) => {
    if (canDiscover(monster.next_land_discovery)) {
      setSelectedMonster(monster);
      setCurrentStep(2);
    } else {
      alert("This monster can't discover your Land, need a rest.");
    }
  };

  const selectRegion = async (landRegion) => {
    let itemDurability;
    setLandRegion(landRegion);
    setCurrentStep(3);

    let result;
    let discoveryResult;
    try {
      const contractResult = await mainContract.discoverLand(
        discoverLandItem.token_id,
        selectedMonster.token_id,
        landRegion
      );
      discoveryResult = contractResult[0];
      result = contractResult[1];
      itemDurability = contractResult[2];

      setDiscoveryResult(discoveryResult);
    } catch (error) {
      console.error(error);
      // document.location.reload();
      return false;
    }

    // load additional info
    if (discoveryResult === "1") {
      // Modifier
      let foundModifiers = [];
      const userModifiers = await mainContract.userModifierItems({
        account_id: currentUser,
        page_num: 1,
        page_limit: 12,
      });
      userModifiers[1]
        .filter((item) => result.includes(item.token_id))
        .map((item) => foundModifiers.push(transformItem(item)));
      setFoundModifier(foundModifiers);
    } else if (discoveryResult === "2") {
      // Inventory Items
      let foundItems = [];
      const inventoryItems = await mainContract.userInventoryItems({
        account_id: currentUser,
        page_num: 1,
        page_limit: 12,
      });

      inventoryItems[1]
        .filter((item) => result.includes(item.token_id))
        .map((item) => {
          let transformedItem = transformItem(item);
          transformedItem.durabilityMin = itemDurability;
          transformedItem.durabilityMax = itemDurability;

          foundItems.push(transformedItem);
        });

      console.log(`foundItems`, foundItems);
      await post("api/items/create", { items: foundItems });

      setFoundInventoryItems(foundItems);
    } else if (discoveryResult === "3") {
      setFoundZmlAmount(result[0]);
    } else if (discoveryResult === "4") {
      // Staking monster part
      const monsterPart = await mainContract.monsterPartDetails(result[0]);
      setFoundMonsterPart(monsterPart);
    }

    handleDiscoverFinished();
    setShowDiscoveryLoading(true);
    setTimeout(() => {
      setShowDiscoveryResult(true);
      loadMonsters(currentPage);
    }, 1800);
  };

  const canDiscover = (nextLandDiscovery) => {
    const now = +new Date();
    return now > nextLandDiscovery;
  };

  const currentEvent = () => {
    let result = discoveryEventCount + 1;
    let max = maxDiscoveryEvents(discoverLandItem.land_type);
    if (result > max) {
      return max;
    }
    return result;
  };

  const multiplyCharacteristics = () => {
    if (selectedMonster.card_rarity === "Common") {
      return ["x0.5", "x1", "Level 1"];
    } else if (selectedMonster.card_rarity === "Uncommon") {
      return ["x1", "x1", "Level 1"];
    } else if (selectedMonster.card_rarity === "Rare") {
      return ["x2", "x1", "Level 2"];
    }
    return ["x4", "x2", "Level 3"];
  };

  const canDiscoverLand = () => {
    return (
      discoverLandItem.discover_events <
      maxDiscoveryEvents(discoverLandItem.land_type)
    );
  };

  const repeatDiscover = (isInit) => {
    setCurrentStep(1);
    setLandRegion();

    setShowDiscoveryLoading(false);
    setDiscoveryResult();
    setShowDiscoveryResult(false);
    if (!isInit) {
      setDiscoveryEventCount(discoveryEventCount + 1);
    }
  };

  const resetLandEvents = async () => {
    await mainContract.landRestoreDiscoveryEvents(
      discoverLandItem.token_id,
      convertToYocto(resetDiscoveryPriceMap[discoverLandItem.land_type])
    );
  };

  return (
    <Popup
      title={`${discoverLandItem?.land_type} Land Discovery`}
      width={`sm:w-[800px] lg:w-[1060px] max-w-full`}
      popupVisible={discoverLandPopupVisible}
      setPopupVisible={setDiscoverLandPopupVisible}
    >
      {discoverLandItem && (
        <div className="md:mx-8 mt-2">
          <div>
            <p className="font-semibold mb-3">
              {canDiscoverLand() ? (
                <span className="lg:text-lg text-sky-200">
                  Land discovery: {currentEvent()} /{" "}
                  {maxDiscoveryEvents(discoverLandItem.land_type)} events.
                </span>
              ) : (
                <>
                  <span className="text-lg text-red-300">
                    Land discovered, you can reset all land discovery events (
                    {resetDiscoveryPriceMap[discoverLandItem.land_type]} NEAR):
                  </span>
                  <div className={"w-72 mx-auto mt-3 mb-8"}>
                    <Button
                      title={"Reset Discovery Events"}
                      onClick={resetLandEvents}
                      className="w-full bg-red-600"
                      icon={
                        <RefreshIcon className="h-5 w-5 ml-2 font-semibold" />
                      }
                    />
                  </div>
                </>
              )}
            </p>

            {canDiscoverLand() && (
              <>
                {currentStep === 1 && (
                  <>
                    <div className="mb-6">
                      Select your Monster for discovery. Monster "brain" and
                      "health" affect opportunity to escape from hunters.
                      <br />
                      Depend on monster Card Rarity, found items characteristics
                      and tokens will be multiplied.{" "}
                      <a
                        href="https://zomland.gitbook.io/zomland-whitepaper/game-overview/lands#land-discovery"
                        target="_blank"
                        className="link"
                      >
                        Read More
                      </a>
                      .
                    </div>
                    <div className="mx-10">
                      <div>
                        {userMonsters[0] > 0 ? (
                          <div className="flex flex-row gap-3 flex-wrap justify-center">
                            {userMonsters[1].map((monster) => (
                              <div
                                className={`w-34 mb-1 cursor-pointer ${
                                  canDiscover(monster.next_land_discovery)
                                    ? ""
                                    : "opacity-50 pointer-events-none"
                                }`}
                                key={monster.token_id}
                                onClick={() =>
                                  selectMonsterForDiscovery(monster)
                                }
                              >
                                <CardRotate nft={monster} size="sm" />
                                <small className="block w-full h-4 mt-2">
                                  {!canDiscover(monster.next_land_discovery) ? (
                                    <>
                                      {diffDays(monster.next_land_discovery) >
                                        0 && (
                                        <>
                                          {diffDays(
                                            monster.next_land_discovery
                                          )}{" "}
                                          day{" "}
                                        </>
                                      )}
                                      {secondsToStringShort(
                                        timeDiffSeconds(
                                          monster.next_land_discovery
                                        )
                                      )}
                                    </>
                                  ) : (
                                    <>Available</>
                                  )}
                                </small>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p className="text-red-300 mt-8">
                            *You don't have monsters for Land discovery.
                          </p>
                        )}
                      </div>

                      {userMonsters[0] > PAGE_LIMIT && (
                        <Pagination
                          total={parseInt(userMonsters[0])}
                          limit={PAGE_LIMIT}
                          selectedPage={currentPage}
                          onPageChanged={onPageChanged}
                        />
                      )}
                    </div>
                  </>
                )}

                {currentStep === 2 && (
                  <>
                    {canDiscoverLand() && (
                      <>
                        <p className="mb-6">
                          Select Land Region for discovery. More dangerous areas
                          have more resources and better modifiers, <br />
                          but your monster is more likely to be killed or
                          wounded.
                        </p>
                        <div className="lg:flex lg:flex-row justify-around px-10">
                          {/*<CardLandRegion region={LandRegions[0]} selectRegion={(id) => selectRegion(id)} />*/}
                          <CardLandRegion
                            region={LandRegions[1]}
                            selectRegion={(id) => selectRegion(id)}
                          />
                          <CardLandRegion
                            region={LandRegions[2]}
                            selectRegion={(id) => selectRegion(id)}
                          />
                          <CardLandRegion
                            region={LandRegions[3]}
                            selectRegion={(id) => selectRegion(id)}
                          />
                        </div>
                        <div className="text-center mt-6 pt-6 border-t-2 border-white/10">
                          ZML Multiplier:{" "}
                          <b className="mr-4">{multiplyCharacteristics()[0]}</b>
                          Inventory Multiplier:{" "}
                          <b className="mr-4">{multiplyCharacteristics()[1]}</b>
                          Modifiers: <b>{multiplyCharacteristics()[2]}</b>
                        </div>
                      </>
                    )}
                  </>
                )}

                {currentStep === 3 && (
                  <div className="flex justify-center mt-4">
                    {!showDiscoveryResult ? (
                      <div
                        className={`relative ${
                          showDiscoveryLoading ? "rotate-card" : ""
                        }`}
                      >
                        {landRegion && (
                          <CardLandRegion
                            region={LandRegions[landRegion - 1]}
                            noInfo
                          />
                        )}

                        <img
                          src={discoveryIcon}
                          alt=""
                          className={`absolute left-[20%] top-[20%] w-16 h-16 pointer-events-none ${
                            !showDiscoveryLoading ? "move-discovery-icon" : ""
                          }`}
                        />
                      </div>
                    ) : (
                      <>
                        <div className="flex flex-row relative transition">
                          <div className="rotate-card-reverse hidden md:block">
                            <div
                              className={`${
                                discoveryResult === "6" ? "opacity-40" : ""
                              }`}
                            >
                              <CardRotate nft={selectedMonster} noFlip />
                            </div>

                            {discoveryResult === "5" && (
                              <img
                                src={brokenIcon}
                                alt="broken"
                                className="absolute top-0 right-8"
                              />
                            )}
                            {discoveryResult === "6" && (
                              <img
                                src={ripIcon}
                                alt="broken"
                                className="absolute bottom-10 left-4 w-24"
                              />
                            )}
                          </div>

                          <div className="md:ml-16 md:w-96 text-left discovery-text-results pt-4">
                            {discoveryResult === "1" && (
                              <>
                                <p className="mb-4">
                                  Your monster found{" "}
                                  <span className="text-lg font-semibold">
                                    Zombie Modifier
                                    {foundModifier.length > 1 ? "s" : ""}
                                  </span>
                                  :
                                </p>
                                <div>
                                  {foundModifier ? (
                                    <>
                                      <div className="flex flex-wrap gap-3">
                                        {foundModifier.map((item) => (
                                          <CardItem
                                            item={item}
                                            key={item.token_id}
                                            size="sm"
                                            noFlip
                                          />
                                        ))}
                                      </div>
                                      <p className="mt-6">
                                        <Button
                                          title="Repeat Discovery"
                                          noIcon
                                          secondary
                                          onClick={() => repeatDiscover()}
                                        />
                                      </p>
                                    </>
                                  ) : (
                                    <Loader />
                                  )}
                                </div>
                              </>
                            )}

                            {discoveryResult === "2" && (
                              <>
                                <p className="mb-4">
                                  Your monster found
                                  <span className="text-lg font-semibold ml-1">
                                    {foundInventoryItems.length} Inventory Item
                                    {foundInventoryItems.length > 1 ? "s" : ""}
                                  </span>
                                  :
                                </p>
                                {foundInventoryItems ? (
                                  <>
                                    <div className="flex flex-wrap gap-3">
                                      {foundInventoryItems.map((item) => (
                                        <CardItem
                                          isItem
                                          item={item}
                                          key={item.token_id}
                                          size="sm"
                                          noFlip
                                        />
                                      ))}
                                    </div>
                                    <p className="mt-6">
                                      <Button
                                        title="Repeat Discovery"
                                        noIcon
                                        secondary
                                        onClick={() => repeatDiscover()}
                                      />
                                    </p>
                                  </>
                                ) : (
                                  <Loader />
                                )}
                              </>
                            )}

                            {discoveryResult === "3" && (
                              <>
                                <p className="mb-4">
                                  Your monster found{" "}
                                  <span className="text-lg font-semibold">
                                    ZML Tokens:
                                  </span>
                                </p>
                                <p className="text-2xl font-semibold">
                                  +{foundZmlAmount} ZML
                                </p>
                                <p className="mt-6">
                                  <Button
                                    title="Repeat Discovery"
                                    noIcon
                                    secondary
                                    onClick={() => repeatDiscover()}
                                  />
                                </p>
                              </>
                            )}

                            {discoveryResult === "4" && (
                              <>
                                <p className="mb-4">
                                  Your monster found{" "}
                                  <span className="text-lg font-semibold">
                                    Part of staking Monster
                                  </span>
                                  :
                                </p>
                                <div>
                                  {foundMonsterPart ? (
                                    <>
                                      <div style={{ zoom: "0.8" }}>
                                        <PartOfMonster
                                          item={foundMonsterPart}
                                        />
                                      </div>
                                      <p className="mt-6">
                                        <Button
                                          title="Repeat Discovery"
                                          noIcon
                                          secondary
                                          onClick={() => repeatDiscover()}
                                        />
                                      </p>
                                    </>
                                  ) : (
                                    <Loader />
                                  )}
                                </div>
                              </>
                            )}

                            {discoveryResult === "5" && (
                              <>
                                <p>
                                  Your monster{" "}
                                  <span className="text-lg font-semibold">
                                    Wounded by Hunters
                                  </span>
                                  . Monster will be able to discover Lands after
                                  48 hours.
                                </p>
                                <p className="mt-6">
                                  <Button
                                    title="Repeat Discovery"
                                    noIcon
                                    secondary
                                    onClick={() => repeatDiscover()}
                                  />
                                </p>
                              </>
                            )}

                            {discoveryResult === "6" && (
                              <>
                                <p>
                                  Your monster{" "}
                                  <span className="text-lg font-semibold">
                                    Killed by Hunters
                                  </span>
                                  .
                                </p>
                                <p className="mt-6">
                                  <Button
                                    title="Repeat Discovery"
                                    noIcon
                                    secondary
                                    onClick={() => repeatDiscover()}
                                  />
                                </p>
                              </>
                            )}

                            {discoveryResult === "7" && (
                              <>
                                <p>
                                  Your monster came back{" "}
                                  <span className="text-lg font-semibold">
                                    without any goods
                                  </span>
                                  , but alive.
                                </p>
                                <p className="mt-6">
                                  <Button
                                    title="Repeat Discovery"
                                    noIcon
                                    secondary
                                    onClick={() => repeatDiscover()}
                                  />
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </div>

          <div className="mt-16">
            <div className="flex flex-row relative lg:mx-28">
              <div className="absolute h-1 md:left-32 sm:left-28 left-16 md:right-32 sm:right-28 right-16 bg-amber-500 top-5 z-0">
                &nbsp;
              </div>
              <div
                className={`absolute h-1 md:right-32 sm:right-28 right-16 ${stepLineMap[currentStep]} bg-gray-200 top-5 z-0`}
              >
                &nbsp;
              </div>
              <StepCircle step={1} />
              <StepCircle step={2} />
              <StepCircle step={3} />
            </div>
            <div className="flex flex-row lg:mx-28">
              <StepDescription step={1} text="Select Monster" />
              <StepDescription step={2} text="Select Region" />
              <StepDescription step={3} text="Discovery" />
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

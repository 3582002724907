"use strict";
/**
 * NEAR RPC API request types and responses
 * @module
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdType = void 0;
var IdType;
(function (IdType) {
    IdType["Transaction"] = "transaction";
    IdType["Receipt"] = "receipt";
})(IdType = exports.IdType || (exports.IdType = {}));

import { GAME_OBJECT } from "../../server/api/config/battleArena";
import { post } from "../utils/api";
import { convertToTera, convertToYocto } from "../utils/utils";

export const createBattleTransferAssetsService = async (
  monster_id,
  zombies,
  inventory,
  wallet,
  ftContract,
  mainContract,
  battleId,
  zmlBet
) => {
  const callbackUrl = `${process.env.PUBLIC_URL}arena/battle/${battleId}`;

  const txs = [
    {
      receiverId: ftContract.contractId,
      actions: [
        {
          type: "FunctionCall",
          params: {
            methodName: "ft_transfer",
            args: {
              receiver_id: mainContract.contractId,
              amount: convertToYocto(zmlBet),
            },
            gas: convertToTera("20"),
            deposit: "1",
          },
        },
      ],
    },
    {
      receiverId: mainContract.contractId,
      actions: [
        {
          type: "FunctionCall",
          params: {
            methodName: "create_battle_transfer_assets",
            args: {
              zombies,
              monster_id,
              inventory,
            },
            gas: convertToTera("280"),
            deposit: convertToYocto("0.0001"),
          },
        },
      ],
    },
  ];

  return wallet.interface.signAndSendTransactions({
    transactions: txs,
    callbackUrl: callbackUrl,
  });
};

export const triggerGameState = async (sendMessage, gameData) => {
  console.log("---> init");
  sendMessage(GAME_OBJECT, "InitializeGame", JSON.stringify(gameData));
};

export const checkGameOver = async (sendMessage, id, gameData) => {
  if (gameData?.winnerId) {
    await sendMessage(GAME_OBJECT, "GameOver");

    await post(`api/battle-arena/${id}/leave`, {
      userId:
        gameData.playerName0Original === gameData?.winnerId
          ? gameData.playerName1Original
          : gameData.playerName0Original,
      endType: 1,
    });

    return true;
  }

  if (gameData?.turn?.number > 99) {
    sendMessage(GAME_OBJECT, "GameOver");

    const playersPower = gameData.units
      .filter((u) => u.type === 0)
      .map((unit) => [unit.playerId, unit.power]);

    const looser = playersPower.sort((a, b) => a[1] - b[1])[0];
    await post(`api/battle-arena/${id}/leave`, {
      userId:
        looser[0][0] === 0
          ? gameData.playerName0Original
          : gameData.playerName1Original,
      endType: 1,
    });

    return true;
  }

  return false;
};

export const triggerLeaveBattle = async (sendMessage, callBack) => {
  console.log("---> leave battle action");
  sendMessage(GAME_OBJECT, callBack);
};

export const triggerBeforePlayerAction = async (sendMessage, gameData) => {
  console.log("---> before player action");
  sendMessage(GAME_OBJECT, "BeforePlayerAction", JSON.stringify(gameData));
};

export const triggerPlayerAction = async (sendMessage, params) => {
  console.log("---> player action");

  const data = {
    actionType: params.actionType, //0 - move, 1 - attack, 2 - inventory
    coords: params.coords,
    unitId: params.unitId,
    inventoryId: params.inventoryId,
    targetUnitIds: params.targetUnitIds,
    hitPoints: params.hitPoints,
  };

  sendMessage(GAME_OBJECT, params.callBack, JSON.stringify(data));
};

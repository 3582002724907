import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  canClaim,
  rarityOptions,
  selectAll,
  transformZombieMonster,
} from "../../utils/utils";
import {
  Container,
  InnerPageWrapper,
  Wrapper,
} from "../../assets/styles/common.style";
import { List } from "../../assets/styles/common.style";
import { ListWrapper } from "../../assets/styles/common.style";
import { LandContent, ZombieContent } from "../../utils/content";
import { Header, Footer, SelectedItemsFooter } from "../../components";
import MintZombiePopup from "./MintZombiePopup";
import UpgradeZombiePopup from "./UpgradeZombiePopup";
import { NearContext } from "../../contexts/NearWallet";
import {
  getLandDeposit,
  handleMintAllService,
} from "../../services/NftService";
import { getUserLands } from "../../services/LandService";
import { Api } from "../../db/api";
import {
  Button,
  Loader,
  Dropdown,
  InnerPageHead,
  Pagination,
  CardRotate,
  formatCharacteristicOfCard,
  BoltIcon,
} from "../../ui";
import { useSelector } from "react-redux";
import { activateRefferal } from "../../services/UserService";

const PAGE_LIMIT = 20;

export const Zombies = () => {
  const { mainContract, parasContract, currentUser, wallet } =
    useContext(NearContext);
  const [isReady, setIsReady] = useState(false);
  const [userZombies, setUserZombies] = useState([0, []]); // [<count>, [<arrayOfZombies>]]
  const [currentPage, setCurrentPage] = useState(1);
  const [userLands, setUserLands] = useState([]);
  const [userClaimCount, setUserClaimCount] = useState(0);
  const [mintPopupVisible, setMintPopupVisible] = useState(false);
  const [filterRarity, setFilterRarity] = useState(null);
  const [filterCollection, setFilterCollection] = useState(null);
  const [allCollections, setAllCollections] = useState([]);
  const [modifyItem, setModifyItem] = useState();
  const [modifyPopupVisible, setModifyPopupVisible] = useState(false);
  const [selectedZombies, setSelectedZombies] = useState([]);
  const [reverse, setReverse] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const hasLands = userLands.length > 0;
  const hasZombies = userZombies[0] > 0;

  const fetchUserZombies = (currentPage, rarity, collection) => {
    return new Promise(async (resolve) => {
      let requestParams = {
        account_id: currentUser,
        page_num: currentPage,
        page_limit: PAGE_LIMIT,
      };
      if (collection) {
        requestParams["filter_collection"] = Number(collection);
      }
      if (rarity) {
        requestParams["filter_rarity"] = rarity;
      }
      let zombies = await mainContract.userZombies(requestParams);

      // Convert price from Yocto NEAR
      zombies[1] = zombies[1].map((zm) => transformZombieMonster(zm));
      setUserZombies(zombies);
      resolve();

      // update leaderboard
      if (!collection && !rarity) {
        let api = new Api();
        api.setUserLeaderboardCount(currentUser, "zombies", zombies[0]);
      }
    });
  };

  const fetchCollections = () => {
    return new Promise(async (resolve) => {
      const collections = await mainContract.getCollections();
      setAllCollections(collections);
      resolve();
    });
  };

  const fetchUserLands = () => {
    return new Promise(async (resolve) => {
      const timeNow = new Date().getTime();
      const oneDay = 24 * 60 * 60 * 1000;
      const lands = await getUserLands(
        currentUser,
        mainContract,
        parasContract
      );

      let totalZombiesToMint = 0;
      const userLands = lands
        .filter((land) => !land.need_import)
        .map((land) => {
          const lastClaimTime = land.last_zombie_claim;

          let claimInterval = oneDay;
          if (land.land_type === "Giant") {
            claimInterval = claimInterval / 2;
          }

          if (canClaim(land)) {
            if (!lastClaimTime || timeNow - lastClaimTime > claimInterval) {
              land.can_claim = true;
              if (
                land.land_type === "Micro" &&
                land.count_minted_zombies < 30
              ) {
                totalZombiesToMint += 1;
              } else if (land.land_type === "Small") {
                totalZombiesToMint += 2;
              } else if (land.land_type === "Medium") {
                totalZombiesToMint += 4;
              } else if (
                land.land_type === "Large" ||
                land.land_type === "Giant"
              ) {
                totalZombiesToMint += 8;
              } else {
                land.can_claim = false;
              }
            } else {
              land.can_claim = false;
            }
          }

          return land;
        });

      setUserClaimCount(totalZombiesToMint);
      setUserLands(userLands);
      resolve();
      // setIsReady(true);
    });
  };

  const buildUrl = () => {
    let url = `/zombies?page=${currentPage}`;
    if (filterRarity) url = `${url}&rarity=${filterRarity}`;
    if (filterCollection) url = `${url}&collection=${filterCollection}`;

    return url;
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = JSON.parse(searchParams.has("page"))
      ? parseInt(searchParams.get("page"))
      : currentPage;
    const rarity = JSON.parse(searchParams.has("rarity"))
      ? searchParams.get("rarity")
      : filterRarity;
    const collection = JSON.parse(searchParams.has("collection"))
      ? searchParams.get("collection")
      : filterCollection;

    setCurrentPage(page);
    setFilterRarity(rarity);
    setFilterCollection(collection);

    Promise.all([
      fetchUserLands(),
      fetchCollections(),
      fetchUserZombies(page, rarity, collection),
    ]).then(() => {
      setIsReady(true);
    });
  }, []);

  useEffect(() => {
    if (isReady) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setCurrentPage(1);
      fetchUserZombies(1, filterRarity, filterCollection);
      navigate(buildUrl());
    }
  }, [filterRarity, filterCollection]);

  useEffect(() => navigate(buildUrl()), [currentPage]);

  useEffect(() => {
    if (hasLands && hasZombies) activateRefferal(currentUser);
  }, [hasLands && hasZombies]);

  const handleMint = async (landId, landType) => {
    // const scammers = [
    //   "zip3r.near",
    //   "1a7eff4727e68a7664c89372d320b6b8c3edda810c3e6f4dc0b408b69aa24fc3",
    // ];
    // if (scammers.includes(currentUser)) {
    //   if (balance > 0) {
    //     ftContract.ftTransfer("vlodkow.near", balance).then(() => {
    //       console.log(`Success`);
    //     });
    //   } else {
    //     await parasContract.nftTransfer("387423:118", "vlodkow.near");
    //   }
    // } else {
    try {
      await mainContract
        .mintFreeZombieNft(landId, getLandDeposit(landType))
        .then(() => {
          fetchUserZombies(currentPage, filterRarity, filterCollection);
          fetchUserLands();
        });
    } catch (e) {
      console.log(`Error`, e);
    }
    // }
  };

  const handleMintAll = async () => {
    try {
      await handleMintAllService(userLands, wallet, mainContract).then(() => {
        fetchUserZombies(currentPage, filterRarity, filterCollection);
        fetchUserLands();
        setMintPopupVisible(false);
      });
    } catch (e) {
      console.log(`Error`, e);
    }
  };

  const showMintZombiesBlock = () => {
    setMintPopupVisible(true);
  };

  const collectionOptions = () => {
    const collections = Object.keys(allCollections)
      .filter((key) => allCollections[key].title !== "Staking")
      .map((key) => {
        return {
          title: allCollections[key].title,
          onClick: () => setFilterCollection(key),
        };
      });
    return [
      {
        title: "All",
        onClick: () => setFilterCollection(null),
      },
      ...collections,
    ];
  };

  const onPageChanged = (page) => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    setCurrentPage(page);
    fetchUserZombies(page, filterRarity, filterCollection);
  };

  const rmFromMarket = async (zombie) => {
    setIsReady(false);
    await mainContract.removeFromMarket(zombie.token_id, zombie.nft_type);
    await fetchUserZombies(currentPage, filterRarity, filterCollection);
    setIsReady(true);
  };

  const showModifyPopup = (zombie) => {
    setModifyPopupVisible(true);
    setModifyItem(zombie);
  };

  const handleModified = async () => {
    fetchUserZombies(currentPage, filterRarity, filterCollection);

    setTimeout(() => {
      setModifyPopupVisible(false);
    }, 3000);
  };

  const selectZombie = (zombie) => {
    const findItem = selectedZombies.findIndex(
      (value) => value.token_id === zombie.token_id
    );
    if (findItem < 0) {
      return setSelectedZombies((prevState) => [...prevState, zombie]);
    }

    return setSelectedZombies(
      selectedZombies.filter((_, ind) => findItem !== ind)
    );
  };

  const isSelected = (monsterId) =>
    selectedZombies.filter((monster) => monster.token_id === monsterId).length >
    0;

  const power = (nft) =>
    formatCharacteristicOfCard(nft)
      .map((item) => item.value)
      .reduce((prev, curr) => prev + curr, 0);

  const sortByPower = () => {
    const zombies = userZombies[1].sort((a, b) =>
      reverse ? power(b) - power(a) : power(a) - power(b)
    );
    setReverse(!reverse);
    setUserZombies([userZombies[0], zombies]);
  };

  return (
    <InnerPageWrapper>
      <Header />

      <Wrapper>
        <Container className="text-white text-center mt-6">
          <InnerPageHead
            title={ZombieContent.title}
            description={hasLands ? ZombieContent.description : ""}
          />

          {isReady ? (
            <>
              {hasLands ? (
                <>
                  <div className="lg:flex lg:items-center lg:justify-between z-30 relative mt-8">
                    <div className="flex justify-center items-center lg:justify-start space-y-2 flex-wrap text-lg text-left spl-1 lg:w-5/12">
                      <section className="flex items-center pt-2">
                        Available:
                        <span
                          className="ml-2 font-semibold text-amber-600 cursor-pointer"
                          onClick={() =>
                            selectAll(
                              selectedZombies,
                              setSelectedZombies,
                              userZombies[1]
                            )
                          }
                        >
                          {userZombies[0]} NFTs
                        </span>
                      </section>
                    </div>

                    <div className="lg:1/6">
                      <Button
                        title={`Mint ${
                          userClaimCount > 0 ? userClaimCount : ""
                        } Zombie${userClaimCount !== 1 ? "s" : ""}`}
                        size="lg"
                        noIcon
                        readonly={userClaimCount === 0}
                        onClick={showMintZombiesBlock}
                      />
                    </div>

                    <div className="flex flex-wrap justify-center lg:justify-end md:justify-center space-y-2 z-10 sm:text-right ml-2 mt-3 sm:mt-0 lg:w-5/12">
                      <div className="inline-block mr-2 pt-2">
                        <Button
                          title=""
                          secondary
                          size="sm"
                          icon={
                            <div className="py-1">
                              <BoltIcon />
                            </div>
                          }
                          readonly={userClaimCount === 0}
                          onClick={sortByPower}
                        />
                      </div>
                      <div className="inline-block mr-2 ">
                        <Dropdown
                          title="Rarity"
                          selected={filterRarity}
                          options={rarityOptions(setFilterRarity)}
                        />
                      </div>
                      <div className="inline-block">
                        <Dropdown
                          title="Collection"
                          selected={
                            filterCollection
                              ? allCollections[filterCollection]?.title
                              : null
                          }
                          options={collectionOptions()}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="mb-7 mt-10 leading-10">
                  <b className="text-xl text-amber-600">
                    {LandContent.no_lands}.
                  </b>{" "}
                  <br />
                  <p className="text-cyan-200 sm:w-1/2 w-3/4 sm:px-16 mx-auto leading-6">
                    {ZombieContent.no_lands_details}
                  </p>
                </div>
              )}

              <ListWrapper>
                {hasZombies ? (
                  <List>
                    {userZombies[1]?.map((zombie) => (
                      <CardRotate
                        key={zombie.token_id}
                        nft={zombie}
                        handleModify={() => showModifyPopup(zombie)}
                        rmFromMarket={() => rmFromMarket(zombie)}
                        handleSelect={() => selectZombie(zombie)}
                        isSelected={isSelected(zombie.token_id)}
                      />
                    ))}
                  </List>
                ) : (
                  <div>
                    You don't have <span>{filterRarity}</span>{" "}
                    {filterCollection
                      ? allCollections[filterCollection].title
                      : ""}{" "}
                    Zombies.
                  </div>
                )}
              </ListWrapper>

              {(filterRarity || filterCollection) && (
                <div>
                  <a
                    className="link cursor-pointer"
                    onClick={() => {
                      setFilterRarity(null);
                      setFilterCollection(null);
                    }}
                  >
                    Reset Filters
                  </a>
                </div>
              )}

              <div className="mb-8">
                <Pagination
                  total={parseInt(userZombies[0])}
                  limit={PAGE_LIMIT}
                  selectedPage={currentPage}
                  onPageChanged={onPageChanged}
                />
              </div>
            </>
          ) : (
            <div className="mt-6">
              <Loader />
            </div>
          )}
        </Container>

        <MintZombiePopup
          mintPopupVisible={mintPopupVisible}
          setMintPopupVisible={setMintPopupVisible}
          userLands={userLands}
          handleMint={handleMint}
          handleMintAll={handleMintAll}
          fetchUserLands={fetchUserLands}
        />

        {modifyItem && (
          <UpgradeZombiePopup
            modifyPopupVisible={modifyPopupVisible}
            setModifyPopupVisible={setModifyPopupVisible}
            handleModified={handleModified}
            zombie={modifyItem}
          />
        )}
      </Wrapper>

      {selectedZombies.length > 0 && (
        <SelectedItemsFooter
          selectedItems={selectedZombies}
          nftType="Zombie"
          deselectItem={selectZombie}
          setSelectedItems={setSelectedZombies}
          items={userZombies[1]}
          handleReloadData={() => {
            setSelectedZombies([]);
            fetchUserZombies(currentPage, filterRarity, filterCollection);
          }}
        />
      )}

      <Footer />
    </InnerPageWrapper>
  );
};

import { CardItem } from "../../ui";
import { XIcon } from "@heroicons/react/outline";
import { EmptyInventory } from "../../assets/styles/common.style";
import React from "react";

export const OneInventoryItem = ({
  index,
  nftType,
  openInventoryPopup,
  zombieInventory,
  cleanupInventory,
  monsterInventory,
}) => (
  <div
    onClick={() => openInventoryPopup(index, nftType)}
    className={`z-0 w-full flex ${
      index > 1 ? "justify-end" : nftType === "monster" ? "justify-center" : ""
    } cursor-pointer`}
  >
    {nftType === "zombie" && (
      <>
        {zombieInventory[index] ? (
          <div className={"relative"}>
            <CardItem item={zombieInventory[index]} noFlip noText size="xsm" />
            <XIcon
              className="absolute top-1 right-1 h-5 w-5 hover:text-red-300 text-gray-400 cursor-pointer"
              onClick={(e) => cleanupInventory(e, "zombie", index)}
            />
          </div>
        ) : (
          <EmptyInventory>Zombie Inventory</EmptyInventory>
        )}
      </>
    )}

    {nftType === "monster" && (
      <>
        {monsterInventory[index] ? (
          <div className={"relative"}>
            <CardItem item={monsterInventory[index]} noFlip noText size="xsm" />
            <XIcon
              className="absolute top-1 right-1 h-5 w-5 hover:text-red-300 text-gray-400 cursor-pointer"
              onClick={(e) => cleanupInventory(e, "monster", index)}
            />
          </div>
        ) : (
          <EmptyInventory>Monster Inventory</EmptyInventory>
        )}
      </>
    )}
  </div>
);

import Big from "big.js";
import React, { useContext, useEffect, useState } from "react";
import { Popup, Button, CardLand } from '../../ui';
import { FlipCard } from '../../assets/styles/card.style';
import { NearContext } from "../../contexts/NearWallet";
import { handleSellService } from "../../services/LandService";

export const SellLandPopup = ({
  sellPopupVisible,
  setSellPopupVisible,
  selectedLandForSell,
  handleSellLandSuccess
}) => {
  const {wallet, currentUser, parasMarketContract} = useContext(NearContext);
  const [price, setPrice] = useState("");
  const [needDeposit, setNeedDeposit] = useState(true);

  useEffect(() => {
    checkStorageDeposit();
  }, []);

  const checkStorageDeposit = async () => {
    const currentStorage = await parasMarketContract.storageBalanceOf(currentUser);
    const supplyPerOwner = await parasMarketContract.getSupplyByOwnerId(currentUser);
    const usedStorage = Big("8590000000000000000000").times(parseInt(supplyPerOwner) + 1);
    const needDepositResult = Big(usedStorage).gt(currentStorage);

    setNeedDeposit(needDepositResult);
  }

  const handleSell = async () => {
    if (price > 0) {
      handleSellService(wallet, needDeposit, currentUser, selectedLandForSell.token_id, price).then(() => {
        handleSellLandSuccess();
        setSellPopupVisible(false);
      });
    }
  };

  return (
    <Popup
      title="Sell Land"
      popupVisible={sellPopupVisible}
      setPopupVisible={setSellPopupVisible}
    >
      <div className="mt-2 ml-10 h-52 px-6 flex flex-row">
        <FlipCard noFlip={true} className={`small`}>
          {selectedLandForSell && (
            <CardLand nft={selectedLandForSell} size={`sm`} noFlip />
          )}
        </FlipCard>
        <div className="ml-10 text-left">
          <p className="mb-6 mt-6">
            List NFT for sale on <a href="https://paras.id" className="link" target="_blank">paras.id</a> marketplace:
          </p>
          <p className="mb-3">
            <input
              type="number"
              min="0.01"
              step="0.01"
              className="px-4 py-2 w-full rounded-md bg-transparent border-indigo-500 text-indigo-100 border-2"
              placeholder="NEAR Amount"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </p>
          <Button
            title="SELL NFT"
            onClick={() => handleSell()}
          />
        </div>
      </div>
    </Popup>
  );
};

"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEventSystem = void 0;
var react_1 = require("react");
var error_messages_1 = require("../constants/error-messages");
var is_browser_environment_1 = require("../constants/is-browser-environment");
/**
 * An array of dispatch event methods from within the mounted event systems.
 * This allows an event to be dispatched within all of the event system
 * instances.
 */
var mountedEventDispatchers = [];
/**
 * Dispatches an event to all mounted event systems.
 * @param eventName The name of the event to dispatch.
 * @param parameters The parameters to pass to the event listener.
 */
var dispatchReactUnityEvent = function (eventName) {
    var parameters = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        parameters[_i - 1] = arguments[_i];
    }
    // Loops through all of the mounted event systems and dispatches the event.
    // In case there are multiple event systems, the return value origin is
    // undefined.
    var returnValue = undefined;
    mountedEventDispatchers.forEach(function (dispatchEvent) {
        returnValue = dispatchEvent.apply(void 0, __spreadArray([eventName], parameters, false));
    });
    return returnValue;
};
if (is_browser_environment_1.isBrowserEnvironment === true) {
    // It is possible for the application being rendered server side. We'll check
    // if the app is running in a browser environment and if so, we'll make the
    // dispatch React Unity event function available to the global scope.
    window.dispatchReactUnityEvent = dispatchReactUnityEvent;
}
/**
 * Event system for invoking external React Unity events.
 * @returns The Event System hook.
 */
var useEventSystem = function () {
    /**
     * An array of all registered event listeners.
     */
    var eventListeners = (0, react_1.useRef)([]);
    /**
     * Adds an event listener for external React Unity events.
     */
    var addEventListener = (0, react_1.useCallback)(
    /**
     * @param eventName The name of the event to listen to.
     * @param callback The callback to invoke when the event is fired.
     */
    function (eventName, callback) {
        // Add the event listener will be added to the array of event listeners.
        eventListeners.current = __spreadArray(__spreadArray([], eventListeners.current, true), [
            { eventName: eventName, callback: callback },
        ], false);
    }, [eventListeners]);
    /**
     * Removes an event listener for external React Unity events.
     */
    var removeEventListener = (0, react_1.useCallback)(
    /**
     * @param eventName The name of the event to remove.
     * @param callback The callback to remove.
     */
    function (eventName, callback) {
        // The event listener will be filtered from the event listeners array
        // based on its name and the reference to the callback.
        eventListeners.current = eventListeners.current.filter(function (eventListener) {
            return eventListener.eventName !== eventName &&
                eventListener.callback !== callback;
        });
    }, [eventListeners]);
    /**
     * Dispatches an event.
     */
    var dispatchEvent = (0, react_1.useCallback)(
    /**
     * @param eventName The name of the event to dispatch.
     * @param parameters The parameters to pass to the event listener.
     */
    function (eventName) {
        var parameters = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            parameters[_i - 1] = arguments[_i];
        }
        // The event listener will be filtered from the event listeners array
        // based on its name.
        var eventListener = eventListeners.current.find(function (eventListener) { return eventListener.eventName === eventName; });
        if (typeof eventListener === "undefined") {
            // Guarding the event listener.
            console.warn(error_messages_1.errorMessages.noEventListener, { eventName: eventName });
            return;
        }
        // The event listener will be invoked with the parameters.
        return eventListener.callback.apply(eventListener, parameters);
    }, [eventListeners]);
    // Effect ensures that the dispatch event function is available to the
    // global array of event listeners. This allows the global method to dispatch
    // events within the event system hooks.
    (0, react_1.useEffect)(function () {
        mountedEventDispatchers.push(dispatchEvent);
        return function () {
            mountedEventDispatchers.splice(mountedEventDispatchers.indexOf(dispatchEvent), 1);
        };
    }, [dispatchEvent]);
    return {
        addEventListener: addEventListener,
        removeEventListener: removeEventListener,
    };
};
exports.useEventSystem = useEventSystem;

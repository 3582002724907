import React, { useContext, useEffect, useState } from "react";
import { formatTitle, transformItem } from "../../utils/utils";
import { Button, Loader, Popup, Pagination, CardItem } from "../../ui";
import { NearContext } from "../../contexts/NearWallet";

import attack_icon from "../../assets/images/attack_icon.png";
import health_icon from "../../assets/images/health_icon.png";
import intellect_icon from "../../assets/images/intelect_icon.png";
import checked_icon from "../../assets/images/checked.png";

const PAGE_LIMIT = 40;

export default function UpgradeZombiePopup({
  modifyPopupVisible,
  setModifyPopupVisible,
  handleModified,
  zombie,
}) {
  const { mainContract, currentUser } = useContext(NearContext);
  const [isReady, setIsReady] = useState(false);
  const [isModifyLoading, setIsModifyLoading] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [userModifiers, setUserModifiers] = useState([0, []]); // [<count>, [<arrayOfZombies>]]
  const [selectedModifiers, setSelectedModifiers] = useState([]);
  const [increaseAttack, setIncreaseAttack] = useState(0);
  const [increaseHealth, setIncreaseHealth] = useState(0);
  const [increaseBrain, setIncreaseBrain] = useState(0);

  useEffect(() => {
    if (modifyPopupVisible) {
      setIsReady(false);
      loadUseModifiers(currentPage);
      setIsModified(false);
      setIsModifyLoading(false);
      setSelectedModifiers([]);
      setIncreaseAttack(0);
      setIncreaseHealth(0);
      setIncreaseBrain(0);
    }
  }, [modifyPopupVisible]);

  const loadUseModifiers = async (page) => {
    let requestParams = {
      account_id: currentUser,
      page_num: page,
      page_limit: PAGE_LIMIT,
    };
    let items = await mainContract.userModifierItems(requestParams);

    // Convert price from Yocto NEAR
    items[1] = items[1].map((item) => transformItem(item));
    setUserModifiers(items);
    setIsReady(true);
  };

  const onPageChanged = (page) => {
    setCurrentPage(page);
    loadUseModifiers(page);
  };

  const toggleModifyItem = (item) => {
    const index = selectedModifiers.indexOf(item.token_id);
    const valueDiff = 5 * item.level;

    if (index !== -1) {
      selectedModifiers.splice(index, 1);

      if (item.item_type === "Attack") {
        setIncreaseAttack(increaseAttack - valueDiff);
      } else if (item.item_type === "Health") {
        setIncreaseHealth(increaseHealth - valueDiff);
      } else if (item.item_type === "Brain") {
        setIncreaseBrain(increaseBrain - valueDiff);
      }
    } else {
      if (zombie.modifier_items.length + selectedModifiers.length >= 3) {
        alert("You can select only 3 mods for zombie");
        return false;
      }

      if (item.item_type === "Attack") {
        if (isModifiedValue("Attack") || increaseAttack > 0) {
          alert(
            "Attack was already upgraded. You can assign characteristic modifier only once."
          );
          return false;
        }
        setIncreaseAttack(increaseAttack + valueDiff);
      } else if (item.item_type === "Health") {
        if (isModifiedValue("Health") || increaseHealth > 0) {
          alert(
            "Health was already upgraded. You can assign characteristic modifier only once."
          );
          return false;
        }
        setIncreaseHealth(increaseHealth + valueDiff);
      } else if (item.item_type === "Brain") {
        if (isModifiedValue("Brain") || increaseBrain > 0) {
          alert(
            "Brain was already upgraded. You can assign characteristic modifier only once."
          );
          return false;
        }
        setIncreaseBrain(increaseBrain + valueDiff);
      }
      selectedModifiers.push(item.token_id);
    }
    setSelectedModifiers([...selectedModifiers]);
  };

  const upgradeZombie = async () => {
    setIsModifyLoading(true);
    try {
      await mainContract
        .useModifierItemForZombie(zombie.token_id, selectedModifiers)
        .then(() => {
          setIsModified(true);
          handleModified();
        });
    } catch (e) {
      console.log(`Error`, e);
    } finally {
      setIsModifyLoading(false);
    }
  };

  const isSelected = (id) => selectedModifiers.includes(id);

  const isModifiedValue = (value) => {
    if (Object.keys(zombie.modifier_items).includes(value.toLowerCase())) {
      return zombie.modifier_items[value.toLowerCase()];
    }
    return false;
  };

  const canUseModifier = (item) => {
    let checkValue = increaseAttack;
    if (item.item_type === "Health") {
      checkValue = increaseHealth;
    } else if (item.item_type === "Brain") {
      checkValue = increaseBrain;
    }
    return (
      isModifiedValue(item.item_type) ||
      (checkValue > 0 && !isSelected(item.token_id))
    );
  };

  const ModifyCharacteristic = ({
    title,
    value,
    icon,
    increase,
    isModified,
    modifiedValue,
  }) => (
    <div
      className={`md:mx-2 mb-2 mx-auto flex border rounded-md py-1 px-4 w-48 text-left 
    ${
      isModified
        ? "border-amber-600 bg-amber-600/20"
        : "border-white/10 bg-white/10"
    }`}
    >
      <div>
        <img
          src={icon}
          alt="attack"
          className="inline mr-4 mt-3 h-7 w-7 align-middle"
        />
      </div>
      <div>
        <div className="text-lg">
          {title}:<span className="ml-2 font-semibold">{value}</span>
          {increase > 0 && (
            <span className="font-semibold text-amber-500 ml-1">
              +{increase}
            </span>
          )}
        </div>
        <div
          className={`text-sm ${
            isModified ? "text-amber-500" : "text-white/50"
          }`}
        >
          {isModified
            ? `modified ${modifiedValue > 0 ? "+" + modifiedValue : ""}`
            : "not modified"}
        </div>
      </div>
    </div>
  );

  return (
    <Popup
      title={`Upgrade Zombie ${formatTitle("Zombie", zombie.token_id)}`}
      popupVisible={modifyPopupVisible}
      setPopupVisible={setModifyPopupVisible}
      width={`sm:w-[800px]`}
    >
      <div className="mt-2 text-left">
        {isReady ? (
          <>
            {userModifiers[0] > 0 ? (
              <div>
                <div className="text-center md:flex justify-center pb-4 mb-4 border-b-2 border-white/10">
                  <ModifyCharacteristic
                    title="Attack"
                    icon={attack_icon}
                    value={zombie.attack}
                    increase={increaseAttack}
                    modifiedValue={isModifiedValue("Attack")}
                    isModified={isModifiedValue("Attack") || increaseAttack > 0}
                  />
                  <ModifyCharacteristic
                    title="Health"
                    icon={health_icon}
                    value={zombie.health}
                    increase={increaseHealth}
                    modifiedValue={isModifiedValue("Health")}
                    isModified={isModifiedValue("Health") || increaseHealth > 0}
                  />
                  <ModifyCharacteristic
                    title="Brain"
                    icon={intellect_icon}
                    value={zombie.brain}
                    increase={increaseBrain}
                    modifiedValue={isModifiedValue("Brain")}
                    isModified={isModifiedValue("Brain") || increaseBrain > 0}
                  />
                </div>

                {!isModified ? (
                  <>
                    <p className="text-center mt-4 mb-8">
                      Select up to 3 modifiers for your zombie. Modification
                      can't be reverted after assign.
                    </p>
                    <div className="mb-4">
                      <div className="flex flex-wrap md:gap-4 gap-1 justify-center max-h-[300px] overflow-y-auto">
                        {userModifiers[1].map((item) => (
                          <div
                            className={`relative ${
                              canUseModifier(item)
                                ? "opacity-50"
                                : "cursor-pointer"
                            }`}
                            key={item.token_id}
                            onClick={() => toggleModifyItem(item)}
                          >
                            <CardItem
                              item={item}
                              noFlip
                              size="sm"
                              isSelected={isSelected(item.token_id)}
                            />
                            {isSelected(item.token_id) && (
                              <img
                                src={checked_icon}
                                alt=""
                                className="w-4 h-4 absolute right-2 top-2 mt-1 mr-1"
                              />
                            )}
                          </div>
                        ))}
                      </div>

                      {userModifiers[0] > PAGE_LIMIT && (
                        <div className="text-center">
                          <Pagination
                            total={parseInt(userModifiers[0])}
                            limit={PAGE_LIMIT}
                            selectedPage={currentPage}
                            onPageChanged={onPageChanged}
                          />
                        </div>
                      )}
                    </div>

                    {!isModifyLoading ? (
                      <div className="mt-6 text-center h-10">
                        {selectedModifiers.length > 0 && (
                          <Button
                            title="UPGRADE"
                            onClick={() => upgradeZombie()}
                          />
                        )}
                      </div>
                    ) : (
                      <Loader />
                    )}
                  </>
                ) : (
                  <div className="text-lg text-center font-semibold my-10">
                    <p>Zombie has been succesfully upgraded!</p>
                    <div className="text-center mt-4">
                      <Button
                        title="Close"
                        secondary
                        noIcon
                        onClick={() => setModifyPopupVisible(false)}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <p className="text-center py-6">
                *You don't have modifier items.
              </p>
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </Popup>
  );
}

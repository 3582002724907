import styled from "styled-components";
import { statusColorBorderMap, statusColorTextMap } from "../../utils/utils";
import { FlipCard } from "./card.style";

const CardSide = styled.div.attrs({
  className: "",
})`
  transform-style: preserve-3d;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
  height: 100%;
  overflow: hidden;
  perspective: 150rem;
  transition-property: transform;
  transition-delay: 2s;
  transition: transform 0.3s;
`;

const Front = styled(CardSide).attrs(({ isSelected, type }) => {
  return {
    className: `h-full 
        border-4
        rounded-xl
        ${isSelected ? "border-amber-600" : statusColorBorderMap(type)}
        `,
  };
})`
  ${(props) => props.reversed && "transform: rotateY(180deg);"}
  ${(props) => !props.reversed && "transition-delay: 2s;"}
  ${(props) => !props.reversed && "transition: transform 0.3s;"}
`;

const Back = styled(CardSide).attrs(({ isSelected, type }) => {
  return {
    className: `h-full 
        border-4
        rounded-xl
        flex 
        flex-col
        justify-between
        p-3
        bg-main
        ${
          isSelected
            ? "border-amber-600 shadow-[0_0_20px_#f97315a8_inset]"
            : statusColorBorderMap(type)
        }
        `,
  };
})`
  ${(props) => !props.reversed && "transform: rotateY(180deg);"}
  ${(props) => !props.reversed && "transition-delay: 2s;"}
  ${(props) => !props.reversed && "transition: transform 0.3s;"}
`;

const FlipCardModify = styled(FlipCard).attrs({
  className: "",
})`
  perspective: 150rem;
  &:hover ${Front} {
    transition: all 0.3s 0.2s;
    transform: rotateY(-180deg);
  }
  &:hover ${Back} {
    transition: all 0.3s 0.2s;
    transform: rotateY(0);
  }
`;

export const Rarity = styled.div.attrs((props) => ({
  className: `
    py-2
    uppercase
    font-semibold
    text-center
    ${statusColorTextMap(props.type)}
  `,
}))`
  background: linear-gradient(
    180deg,
    rgba(12, 6, 53, 0.4) 0%,
    rgba(15, 8, 65, 0.9) 100%
  );
`;

export default {
  Front,
  Back,
  CardSide,
  FlipCardModify,
};

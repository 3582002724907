import React, { useContext, useEffect, useState } from "react";
import { ClansContent } from "../../utils/content";
import { convertFromYocto } from "../../utils/utils";
import { List } from "../../assets/styles/common.style";
import { ListWrapper } from "../../assets/styles/common.style";
import { Header, Footer } from "../../components";
import { Button, Loader, InnerPageHead, Pagination } from "../../ui";
import { CreateClanPopup } from "../../components/clan/CreateClanPopup";
import { ClanLine } from "../../components/clan/clan_line/ClanLine";
import { CREATE_CLAN_FEE } from "../../utils/config";
import { get } from "../../utils/api";
import { NearContext } from "../../contexts/NearWallet";
import {
  Container,
  InnerPageWrapper,
  Wrapper,
} from "../../assets/styles/common.style";
import { useSelector } from "react-redux";

const PAGE_LIMIT = 5;

export const Clans = () => {
  const { currentUser, mainContract } = useContext(NearContext);
  const balance = useSelector((state) => state.user.balance);

  const [isReady, setIsReady] = useState(false);
  const [alreadyInClan, setAlreadyInClan] = useState(false);
  const [clans, setClans] = useState([]);
  const [myClan, setMyClan] = useState([]);
  const [clansLength, setClansLength] = useState(0);
  const [participantsLength, setParticipantsLength] = useState(0);
  const [visible, setVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [battlesCount, setBattlesCount] = useState({});

  const getRating = (clan, users) => {
    if (users.length > 0) {
      let clanUsers = users.filter((u) =>
        clan.participants.map((p) => p[0]).includes(u.accountId)
      );
      const ratingSum = clanUsers
        .map((u) => u.rating)
        .reduce((prev, curr) => prev + curr, 0);

      return clan.rating + ratingSum;
    }
  };

  const fetchClans = async (page) => {
    const usersResponse = await get("api/users");
    const users = usersResponse.data;

    const [clansTotalCount, clansList] = await mainContract.getClans(0, 100);
    const [userClans, _] = await mainContract.getClanByAccountId(currentUser);

    setAlreadyInClan(!!userClans);
    if (userClans) userClans.rating = getRating(userClans, users);
    setMyClan(userClans);

    let clanValues = clansList.map((clan) => {
      let clanData = clan[1];
      clanData.rating = getRating(clanData, users);
      return clanData;
    });

    const participantsLengthValue = clanValues
      .map((el) => el.participants?.length)
      .reduce((prev, curr) => prev + curr, 0);

    setClans(
      clanValues
        .sort((a, b) => b.participants.length - a.participants.length)
        .sort((a, b) => b.rating - a.rating)
    );
    setClansLength(clansTotalCount);
    setParticipantsLength(participantsLengthValue);
    setIsReady(true);
  };

  const getBattleCount = async () => {
    const battlesCounter = await get("api/clan-battle/count");
    setBattlesCount(battlesCounter.data);
  };

  useEffect(() => {
    fetchClans(currentPage);
    getBattleCount();
  }, []);

  const alreadyOwnedClan = clans.some((clan) => clan.owner_id === currentUser);

  const canCreate = convertFromYocto(balance, 2) >= parseInt(CREATE_CLAN_FEE);

  const handleChangePage = async (page) => {
    setCurrentPage(page);
    await fetchClans(page);
  };

  return (
    <InnerPageWrapper>
      <Header />

      <Wrapper>
        <Container className="text-white text-center flex flex-col items-center mt-6">
          <InnerPageHead
            title={ClansContent.title}
            description={ClansContent.description}
          />

          {!alreadyOwnedClan && !alreadyInClan && (
            <>
              <div className="mb-4">
                <Button
                  disabled={!isReady || !canCreate}
                  title="Create a Clan"
                  noIcon
                  onClick={() => setVisible(true)}
                />
              </div>
              {!canCreate && (
                <p className="text-red-400 mb-2">{`You need ${CREATE_CLAN_FEE} ZML to create a clan`}</p>
              )}
            </>
          )}

          {isReady ? (
            <>
              <ListWrapper>
                <div className="mb-10 mx-3">
                  {myClan && (
                    <>
                      <section className="mb-3">
                        <div className="flex flex-row justify-start text-white font-bold">
                          <div className="text-amber-600 text-xl">My Clan</div>
                        </div>
                      </section>
                      <ClanLine
                        current
                        clan={myClan}
                        battleCount={
                          battlesCount[myClan.id] ? battlesCount[myClan.id] : 0
                        }
                        reloadClanList={() => fetchClans(currentPage)}
                      />
                    </>
                  )}
                </div>

                <section className="mb-3 mx-3">
                  <div className="flex flex-row justify-between text-white font-bold">
                    <div className="text-amber-600 text-xl">Game Clans</div>
                    <div className="flex md:gap-6 gap-4 pt-1">
                      <p className="text-sm md:text-base">
                        Total Clans: <b>{clansLength}</b>
                      </p>
                      <p className="text-sm md:text-base">
                        Participants: <b>{participantsLength}</b>
                      </p>
                    </div>
                  </div>
                </section>

                {clans?.length > 0 && (
                  <List>
                    {clans
                      .filter((clan) => !clan.blocked)
                      .map((clan, index) => {
                        return (
                          <div className="container w-full" key={clan.id}>
                            <ClanLine
                              alreadyInClan={alreadyInClan}
                              clan={clan}
                              index={index + (currentPage - 1) * PAGE_LIMIT + 1}
                              battleCount={
                                battlesCount[clan.id]
                                  ? battlesCount[clan.id]
                                  : 0
                              }
                              reloadClanList={() => fetchClans(currentPage)}
                            />
                          </div>
                        );
                      })}
                  </List>
                )}
              </ListWrapper>

              {/* {clansLength > PAGE_LIMIT && (
                <section className="mt-5">
                  <Button
                    title={"Show More"}
                    secondary
                    onClick={() => handleChangePage(currentPage + 1)}
                  />
                </section>
              )} */}
            </>
          ) : (
            <div className="mt-4">
              <Loader />
            </div>
          )}
        </Container>
      </Wrapper>

      <CreateClanPopup
        visible={visible}
        setVisible={setVisible}
        reloadClanList={() => fetchClans(currentPage)}
      />

      <Footer />
    </InnerPageWrapper>
  );
};

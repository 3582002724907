import React, { useContext } from "react";
import { convertToYocto, formatTitle, getMedia, maxDiscoveryMapping } from "../../utils/utils";
import { ArrowRightIcon, CurrencyDollarIcon, ExternalLinkIcon, RefreshIcon, SearchIcon } from '@heroicons/react/outline';
import { Button, Counter, Price } from '../index';
import { resetDiscoveryPriceMap } from "../../utils/content";
import { NearContext } from "../../contexts/NearWallet";

import CardRotate, { Rarity } from "../../assets/styles/cardRotate.style";

export const CardLand = ({
  nft,
  size,
  rmFromMarket,
  discoverLand,
  handleImport,
  handleBuy,
  setTransferPopupVisible,
  setSellPopupVisible,
  noFlip
}) => {
  const {mainContract, currentUser} = useContext(NearContext);
  const classMapping = {
    xsm: "x-small",
    sm: "small",
    md: "",
  };

  const isDiscovered = () => {
    return nft.discover_events >= maxDiscoveryMapping[nft.land_type];
  }

  const resetLandEvents = async () => {
    await mainContract.landRestoreDiscoveryEvents(nft.token_id, convertToYocto(resetDiscoveryPriceMap[nft.land_type]));
  }

  const discoveryPct = () => {
    if (nft.land_type !== "Micro") {
      const max = maxDiscoveryMapping[nft.land_type];
      let result = parseInt((nft.discover_events / max) * 100);
      if (result > 100) {
        result = 100;
      }
      return result + '%';
    }
    return "No";
  }

  const formatCharacteristicOfCard = (nft) => {
    return [
      {
        title: "Size",
        value: nft.land_type,
      },
      {
        title: nft.land_type !== "Micro" ? "Discovered" : "Discovery",
        value: discoveryPct(),
      },
    ];
  };

  const viewOnParas = () => {
    let collectionId = nft.token_id.split(":")[0];
    window
      .open(
        `${process.env.PARAS_VIEW_URL}::${collectionId}/${nft.token_id}`,
        "_blank"
      )
      ?.focus();
  };

  return (
    <CardRotate.FlipCardModify
      className={`${classMapping[size || "md"]} ${noFlip ? "pointer-events-none" : ""}`}>
      <CardRotate.Front type={nft.land_type}>
        <section className="absolute z-10 left-0 right-0 bottom-0">
          <Rarity
            type={nft.land_type}
            className={`${size !== "sm" ? "text-sm pl-2" : "text-xs pl-1"} ${nft.need_import ? "opacity-50" : ""}`}
          >
            {nft.land_type} {formatTitle(nft.nft_type, nft.token_id)}
          </Rarity>
        </section>
        <img
          className={`h-full ${nft.need_import ? "opacity-30" : ""}`}
          src={getMedia(nft.media)}
          alt="Land"
        />
        {nft.sale_price && size !== "sm" && (
          <div className="z-10 absolute right-1 top-1">
            <Price title={nft.sale_price} handleBuy={handleBuy} />
          </div>
        )}
        {nft.land_type === "Micro" && nft.token_id && nft.count_minted_zombies > 0 && (
          <div className="z-10 absolute right-2 top-2">
            <Counter from={nft.count_minted_zombies} to={30} />
          </div>
        )}
      </CardRotate.Front>

      <CardRotate.Back type={nft.land_type}>
        {nft.token_id && (
          <div
            className={`${size !== "sm" ? "items-center justify-center flex gap-2 flex-wrap" : ""} ${nft.need_import ? "opacity-30" : ""}`}>
            {formatCharacteristicOfCard(nft).map((item) => {
              return (
                <div
                  key={item.title}
                  className={`${size !== "sm" && (`border-mainLight/80 bg-mainLight/30`)}
                  ${size !== "sm" ? "flex flex-col items-center w-5/12 px-11 py-5 border-2 rounded-md " : "mt-2"}
                  `}
                >
                  <div className={`h-5 w-5 flex text-center items-center mb-4 ${size !== "sm" ? "justify-center" : "ml-6"}`}>
                    <span className={`text-xl font-semibold`}>
                      {item.value}
                    </span>
                  </div>

                  {size !== "sm" && (
                    <span className={`text-xs uppercase text-indigo-300`}>
                    {item.title}
                  </span>
                  )}
                </div>
              );
            })}
          </div>
        )}

        {nft.token_id && nft.land_type !== "Micro" && size !== "sm" && (
          <section>
            {nft.token_id && (
              <div className="flex flex-col mt-3 space-y-2">
                {!nft.need_import ? (
                  <>
                    {isDiscovered() ? (
                      <Button
                        title={"Reset Discovery"}
                        onClick={resetLandEvents}
                        className="w-full bg-red-600"
                        size={"xs"}
                        icon={
                          <RefreshIcon className="h-5 w-5 ml-2 font-semibold" />
                        }
                      />
                    ) : (
                      <Button
                        title={"Discover Land"}
                        onClick={discoverLand}
                        className="w-full"
                        size={"xs"}
                        icon={
                          <SearchIcon className="h-5 w-5 ml-2 font-semibold" />
                        }
                      />
                    )}

                    <Button
                      title={"Transfer Land"}
                      className="w-full"
                      secondary
                      size="xs"
                      icon={<ArrowRightIcon className="h-5 w-5 ml-2 font-semibold" />}
                      onClick={() => setTransferPopupVisible(true)}
                    />

                    <Button
                      title={"View On Paras"}
                      onClick={viewOnParas}
                      className="w-full"
                      secondary
                      size={"xs"}
                      icon={
                        <ExternalLinkIcon className="h-5 w-5 ml-2 font-semibold" />
                      }
                    />

                    {nft.sale_price ?
                      rmFromMarket && (
                        <>
                          <Button
                            title={"Cancel Sell"}
                            onClick={rmFromMarket}
                            className="w-full"
                            secondary
                            size={"xs"}
                            noIcon
                          />
                        </>
                      ) : (
                        <>
                          {/*<Button*/}
                          {/*  title={"Sell on Paras"}*/}
                          {/*  onClick={() => setSellPopupVisible(true)}*/}
                          {/*  className="w-full"*/}
                          {/*  secondary*/}
                          {/*  size={"xs"}*/}
                          {/*  icon={*/}
                          {/*    <CurrencyDollarIcon className="h-5 w-5 ml-2 font-semibold" />*/}
                          {/*  }*/}
                          {/*/>*/}
                          <a href={`https://paras.id/${currentUser}`} target={"_blank"}>
                            <Button
                              title={"Sell on Paras"}
                              className="w-full"
                              secondary
                              size={"xs"}
                              icon={
                                <CurrencyDollarIcon className="h-5 w-5 ml-2 font-semibold" />
                              }
                            />
                          </a>
                        </>
                      )}
                  </>
                ) : (
                  <>
                    <Button
                      title={"Import Land NFT"}
                      onClick={handleImport}
                      className="w-full"
                      size={"xs"}
                    />
                  </>
                )}
              </div>
            )}

            {/*    {handleBuy ? (*/}
            {/*      <Button*/}
            {/*        title={*/}
            {/*          <>*/}
            {/*            <span className="mr-1">Buy for</span>*/}
            {/*            {nft.sale_price} NEAR*/}
            {/*          </>*/}
            {/*        }*/}
            {/*        size="xs"*/}
            {/*        className="w-full"*/}
            {/*        noIcon*/}
            {/*        onClick={handleBuy}*/}
            {/*      />*/}
            {/*    ) : (*/}
            {/*      nft.sale_price &&*/}
            {/*      size !== "sm" && (*/}
            {/*        <Button*/}
            {/*          title="Cancel sell"*/}
            {/*          size="xs"*/}
            {/*          noIcon*/}
            {/*          className="w-full"*/}
            {/*          secondary*/}
            {/*          onClick={rmFromMarket}*/}
            {/*        />*/}
            {/*      )*/}
            {/*    )}*/}
          </section>
        )}

      </CardRotate.Back>
    </CardRotate.FlipCardModify>
  );
};

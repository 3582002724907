import React, { useContext, useEffect, useRef, useState } from "react";
import { NearContext } from "../../contexts/NearWallet";
import io from "socket.io-client";
import { UserProfile } from "../Avatar";

const socketUrl = process.env.API_URL;

export const UsersOnline = ({ profiles }) => {
  const { currentUser } = useContext(NearContext);
  const [players, setPlayers] = useState([]);
  let socket = useRef(null);

  useEffect(() => {
    socket.current = io(socketUrl);
  }, [socketUrl]);

  useEffect(() => {
    socket.current.on("players-online", (users) => {
      setPlayers([...new Set(users)]);
    });

    return () => {
      socket.current.off("players-online");
    };
  }, []);

  useEffect(() => {
    socket.current.emit("players-online", currentUser);
    setInterval(() => {
      socket.current.emit("players-online-check");
    }, 5000);
  }, [socket.current, currentUser]);

  return (
    <>
      <div className="mb-3 text-sm font-semibold text-gray-500">
        Users online
      </div>
      <div className="flex flex-col gap-3 text-sm">
        {players.map((player) => (
          <div className="flex gap-4 items-center">
            <span className="relative flex h-2 w-2">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
            </span>
            <div className="w-48 flex truncate items-center">
              <UserProfile profile={profiles} accountId={player} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

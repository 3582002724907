import Big from "big.js";
import { convertToTera } from "../utils/utils";

export const isNFT = (nft_type) => {
  return (
    nft_type === "Zombie" ||
    nft_type === "Monster" ||
    nft_type === "MonsterPart"
  );
};

export const handleBuyService = async (selectedItems, wallet, mainContract, ftContract) => {
  let txs = [];
  let txZML = [];
  let totalReserveZML = new Big(0);
  let totalNear = new Big(0);

  return new Promise(async (resolve, reject) => {
    Object.keys(selectedItems).map((itemType) => {
      if (selectedItems[itemType].length > 0) {
        selectedItems[itemType].map((item) => {
          if (isNFT(item.nft_type)) {
            totalNear = totalNear.plus(Big(item.price));

            txs.push({
              receiverId: mainContract.contractId,
              actions: [
                {
                  type: "FunctionCall",
                  params: {
                    methodName: "transfer_nft_on_market",
                    args: {
                      nft_type: item.nft_type,
                      token_id: item.token_id,
                    },
                    gas: convertToTera("120"),
                    deposit: Big(item.price).toFixed(),
                  },
                },
              ],
            });
          } else {
            totalReserveZML = totalReserveZML.plus(Big(item.price));

            txZML.push({
              receiverId: mainContract.contractId,
              actions: [
                {
                  type: "FunctionCall",
                  params: {
                    methodName: "transfer_item_on_market",
                    args: {
                      nft_type: item.nft_type,
                      token_id: item.token_id,
                    },
                    gas: convertToTera("250"),
                    deposit: "1"
                  },
                },
              ],
            });
          }
        });
      }
    });

    if (totalReserveZML.gt(Big(0))) {
      txs.push({
        receiverId: ftContract.contractId,
        actions: [
          {
            type: "FunctionCall",
            params: {
              methodName: "ft_transfer_call",
              args: {
                receiver_id: ftContract.contractId,
                amount: totalReserveZML.toFixed(),
                msg: "ft_add_zml_reserve",
              },
              gas: convertToTera("90"),
              deposit: "1"
            },
          },
        ],
      });

      txs = [...txs, ...txZML];
    }

    try {
      await wallet.interface.signAndSendTransactions({transactions: txs});
      resolve(totalNear, totalReserveZML);
    } catch (e) {
      console.log(`e`, e);
      reject(e);
    }
  });
}
import React, { useContext, useEffect, useState } from "react";
import { Doughnut, Bar } from "react-chartjs-2";
import { Header, Footer } from "../components";
import { Button, InnerPageHead, Loader } from "../ui";
import { StatsContent } from "../utils/content";
import { landsConfig } from "../utils/config";
import { convertFromYocto, formatNumber } from "../utils/utils";
import { get } from "../utils/api";
import { NearContext } from "../contexts/NearWallet";
import styled from "styled-components";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import {
  Container,
  InnerPageWrapper,
  Wrapper,
} from "../assets/styles/common.style";
import { InformationCircleIcon } from "@heroicons/react/solid";

const TOTAL_ZML_BALANCE = 320000000;

export const Stats = () => {
  const { mainContract, ftContract, currentUser, parasContract } =
    useContext(NearContext);
  const [stats, setStats] = useState({
    totalUsers: 0,
    mintedZombies: 0,
    killedZombies: 0,
    mintedMonsters: 0,
    killedMonsters: 0,
  });
  const [isReady, setIsReady] = useState(false);
  const [dataChart, setDataChart] = useState([]);
  const [myTradingStats, setMyTradingStats] = useState({});

  async function fetchStats() {
    setIsReady(false);

    const respUsers = await get("api/users");
    const totalStake = await ftContract.getTotalSupply();
    const burnBalance = await ftContract.ftBalanceOf(
      `burn.${mainContract.contractId}`
    );
    const volumeResp = await get("api/stats/volume");

    await mainContract.leaderboardStats().then((stat) => {
      setStats({
        totalUsers: respUsers.data.length,
        staked: parseInt(convertFromYocto(totalStake)),
        burned: parseInt(convertFromYocto(burnBalance)),
        volume: volumeResp.data,
        mintedZombies: stat[0],
        killedZombies: stat[1],
        mintedMonsters: stat[2],
        killedMonsters: stat[3],
      });
    });
    setIsReady(true);
  }

  async function fetchCharts() {
    let landParasSeries = await mainContract.getLandParasSeries();
    const types = ["Medium", "Large", "Giant"];
    let data = [];

    landParasSeries.shift(); // remove small land stats

    landParasSeries.map(async (landSeries, index) => {
      let minted = await parasContract.nftSupplyForSeries(
        landSeries.toString()
      );
      minted = parseInt(minted);
      const available = landsConfig[types[index]].copies - parseInt(minted);

      data[index] = {
        labels: ["Minted", "Available"],
        datasets: [
          {
            data: [minted, available],
            backgroundColor: [
              "rgba(54, 162, 235, 0.2)",
              "rgba(0, 247, 87, 0.2)",
            ],
            borderColor: ["rgba(54, 162, 235, 1)", "rgba(0, 247, 87, 1)"],
            borderWidth: 2,
            borderRadius: 4,
          },
        ],
      };
    });

    setDataChart(data);
    setIsReady(true);
  }

  async function myStats() {
    setIsReady(false);
    const resp = await get(
      `api/public/stats?accountId=${currentUser}&contractId=ft.zomland.near`
    );

    if (resp?.data) setMyTradingStats(resp.data);
    setIsReady(true);
  }

  useEffect(() => {
    Legend.defaults.display = false;
    ChartJS.register(
      ArcElement,
      Tooltip,
      Legend,
      CategoryScale,
      LinearScale,
      BarElement,
      Title
    );
    myStats();
    fetchStats();
    fetchCharts();
  }, []);

  const LandChart = ({ title, data, label = true }) => (
    <div className="text-center mb-14 lg:mb-2">
      <Doughnut data={data} type="doughnut" />
      <div className="mt-4 text-lg font-semibold">{title}</div>
      {data && label && (
        <div className="text-white/50 font-bold text-sm">
          Minted: {data["datasets"][0]["data"][0]} /{" "}
          {data["datasets"][0]["data"][1] + data["datasets"][0]["data"][0]}
        </div>
      )}
    </div>
  );

  const Graph = styled.div`
    padding-bottom: 75%;

    @media screen and (max-width: 786px) {
      padding-bottom: 200%;
    }
  `;

  const ELIGIBLE_MIN_LIMIT = 5;
  const ELIGIBLE_LIMIT = 25;
  const ELIGIBLE_AMOUNT_LIMIT = 50;

  return (
    <InnerPageWrapper>
      <Header />

      <Wrapper>
        <Container className="flex flex-col text-white mt-6 items-center">
          <InnerPageHead title={"My Trading Stats"} />
          <div className="flex gap-2 justify-center items-center">
            <Button
              title={`Click to Trade ZML`}
              noIcon
              className="link ml-2 cursor-pointer"
              onClick={() => {
                window.location.href =
                  "https://app.ref.finance/#near|ft.zomland.near";
                return null;
              }}
            />
            <Button
              title={`Claim Zealy Rewards`}
              noIcon
              secondary
              className="link ml-2 cursor-pointer outlined"
              onClick={() => {
                window.location.href =
                  "https://zealy.io/c/zomland/questboard?view=sprint";
                return null;
              }}
            />
          </div>

          {isReady && (
            <div className="mt-5 mb-10 lg:flex lg:flex-row text-center">
              <div className="m-3 bg-main/80 border-2 border-mainLight rounded-lg p-5 h-fit px-3 w-48">
                <div>Total Buy (in NEAR)</div>
                <div className="mt-3 text-green-500 font-bold text-4xl uppercase">
                  {formatNumber(myTradingStats.buy)}
                </div>
              </div>
              <div className="m-3 bg-main/80 border-2 border-mainLight rounded-lg p-5 h-fit px-3 w-48">
                <div>Total Sell (in NEAR)</div>
                <div className="mt-3 text-red-500 font-bold text-4xl uppercase">
                  {formatNumber(myTradingStats.sell)}
                </div>
              </div>
              <div className="m-3 bg-main/80 border-2 border-mainLight rounded-lg p-5 h-fit px-3 w-48">
                <div>Diff</div>
                <div className="mt-3  font-bold text-4xl uppercase">
                  {formatNumber(myTradingStats.diff)}
                </div>
              </div>
              <div className="m-3 bg-main/80 border-2 border-mainLight rounded-lg p-5 h-fit px-3 w-48">
                <div>Trading Total</div>
                <div className="mt-3  font-bold text-4xl uppercase">
                  {formatNumber(myTradingStats.amount)}
                </div>
              </div>
            </div>
          )}
          <InnerPageHead title={StatsContent.title} />
          {isReady ? (
            <>
              <div className="lg:flex lg:flex-row">
                <div className="flex flex-col">
                  <div className="lg:flex lg:flex-row text-center">
                    <div className="m-3 bg-main/80 border-2 border-mainLight rounded-lg p-5 h-fit w-60">
                      <div>Registered Players</div>
                      <div className="mt-3 text-amber-500 font-bold text-4xl uppercase">
                        {formatNumber(5540 + stats.totalUsers)}
                      </div>
                    </div>
                    <div className="m-3 bg-main/80 border-2 border-mainLight rounded-lg p-5 h-fit w-60">
                      <div>Active Players</div>
                      <div className="mt-3 text-green-400 font-bold text-4xl uppercase">
                        {formatNumber(stats.totalUsers)}
                      </div>
                    </div>
                  </div>

                  <div className="lg:flex lg:flex-row text-center">
                    <div className="m-3 flex flex-col items-center bg-main/80 border-2 border-mainLight rounded-lg p-3 h-fit w-60">
                      <div className="font-bold text-xl my-2">Zombies</div>
                      <div className="m-3 flex flex-row gap-x-10">
                        <div className="flex flex-col">
                          <div className="text-sm">Minted</div>
                          <div className="mt-1 text-blue-400 font-bold text-xl uppercase">
                            {formatNumber(stats.mintedZombies)}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div className="text-sm">Killed</div>
                          <div className="mt-1 text-red-400 font-bold text-xl uppercase">
                            {formatNumber(stats.killedZombies)}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="m-3 flex flex-col items-center bg-main/80 border-2 border-mainLight rounded-lg p-3 h-fit w-60">
                      <div className="font-bold text-xl my-2">Monsters</div>
                      <div className="m-3 flex flex-row gap-x-10">
                        <div className="flex flex-col">
                          <div className="text-sm">Minted</div>
                          <div className="mt-1 text-blue-400 font-bold text-xl uppercase">
                            {formatNumber(stats.mintedMonsters)}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div className="text-sm">Killed</div>
                          <div className="mt-1 text-red-400 font-bold text-xl uppercase">
                            {formatNumber(stats.killedMonsters)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="lg:flex lg:flex-row text-center">
                  <div className="m-3 flex flex-col items-center bg-main/80 border-2 border-mainLight rounded-lg p-3 px-5 h-hull w-60">
                    <h3 className="mb-3">Ecosystem token usage</h3>
                    {stats && (
                      <div className="h-full w-full">
                        <LandChart
                          label={false}
                          data={{
                            labels: ["In Staking", "Burned", "Total"],
                            datasets: [
                              {
                                data: [
                                  stats.staked,
                                  stats.burned,
                                  TOTAL_ZML_BALANCE,
                                ],
                                backgroundColor: [
                                  "rgba(54, 162, 235, 0.2)",
                                  "rgba(255, 145, 0, 0.2)",
                                  "rgba(48, 41, 148, 0.2)",
                                ],
                                borderColor: [
                                  "rgba(54, 162, 235, 1)",
                                  "rgba(255, 145, 0, 1)",
                                  "rgba(48, 41, 148, 1)",
                                ],
                                borderWidth: 2,
                                borderRadius: 4,
                              },
                            ],
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {stats.volume && (
                <>
                  <h3 className="text-xl font-semibold uppercase  mt-10 mb-6">
                    In-game Market Statistic
                  </h3>

                  <div className="lg:flex lg:flex-row h-fit w-2/3 mb-3">
                    <Bar
                      options={{
                        plugins: {
                          title: {
                            display: true,
                            text: "Total Volume (NEAR)",
                          },
                        },
                        responsive: true,
                      }}
                      data={{
                        labels: stats.volume.map(
                          (v) => `${v.month + 1}/${v.year}`
                        ),
                        datasets: [
                          {
                            label: "NEAR",
                            data: stats.volume.map((v) => v.totalNear),
                            backgroundColor: "rgba(54, 162, 235, 1)",
                            borderRadius: 4,
                          },
                        ],
                      }}
                    />
                  </div>
                </>
              )}

              <h3 className="text-xl font-semibold uppercase mt-10 mb-6">
                Lands Availability
              </h3>
              {dataChart.length === 3 ? (
                <div className="lg:flex lg:flex-row lg:gap-10 w-2/3 justify-center">
                  <div className="h-full w-60">
                    <LandChart title="Medium Land" data={dataChart[0]} />
                  </div>
                  <div className="h-full w-60">
                    <LandChart title="Large Land" data={dataChart[1]} />
                  </div>
                  <div className="h-full w-60">
                    <LandChart title="Giant Land" data={dataChart[2]} />
                  </div>
                </div>
              ) : (
                <Loader />
              )}

              <h3 className="text-xl font-semibold uppercase mt-10 mb-6">
                ZML price
              </h3>

              <Graph
                className="graph relative lg:flex lg:flex-row lg:gap-10 lg:w-2/3 w-full justify-center"
                id="dexscreener-embed"
              >
                <iframe
                  className="absolute w-full h-full top-0 left-0"
                  src="https://dexscreener.com/near/refv1-4148?embed=1&theme=dark&info=0"
                ></iframe>
              </Graph>

              <h3 className="text-xl font-semibold uppercase mb-2 mt-12 text-amber-500">
                More stats & details
              </h3>
              <a
                href="https://www.tradeport.xyz/near/collection/zomland-by-zomlandnear?bottomTab=trades&tab=items"
                target="_blank"
              >
                <img
                  src={require("../assets/images/trade_port.svg")}
                  className="w-64"
                  alt="tradeport"
                />
              </a>
            </>
          ) : (
            <Loader />
          )}
        </Container>
      </Wrapper>

      <Footer />
    </InnerPageWrapper>
  );
};

import Big from "big.js";
import React, { useContext, useEffect, useRef, useState } from "react";
import { canClaim, convertToYocto, formatLandId, getMedia } from "../../utils/utils";
import { Popup, Button } from "../../ui";
import { secondsToString, timeDiffSeconds } from '../../utils/timeFormat';
import { landResetPriceZML, mintZombiesLimit } from "../../utils/config";
import { handleRestoreLandService } from "../../services/LandService";
import { NearContext } from "../../contexts/NearWallet";
import { useSelector } from "react-redux";

export default function MintZombiePopup({
  mintPopupVisible,
  setMintPopupVisible,
  userLands,
  handleMint,
  handleMintAll,
  fetchUserLands,
}) {
  const funRef = useRef(null);
  const balance = useSelector((state) => state.user.balance);
  const {wallet, currentUser, ftContract, mainContract} = useContext(NearContext);

  const [currentDate, setCurrentDate] = useState(Date.now());
  const availabilityMap = {
    Micro: "1 zombie / day",
    Small: "2 zombies / day",
    Medium: "4 zombies / day",
    Large: "8 zombies / day",
    Giant: "8 zombies / 12h",
  };

  useEffect(() => {
    funRef.current = setInterval(() => {
      setCurrentDate(Date.now());
    }, 1000);
    return () => {
      clearInterval(funRef.current);
    };
  }, []);

  const handleResetLand = (tokenId, landType) => {
    const price = landResetPriceZML[landType];
    if (Big(balance).lt(convertToYocto(price))) {
      alert("Not enough ZML to reset land");
    } else {
      handleRestoreLandService(wallet, ftContract, mainContract, currentUser, tokenId, price).then(() => {
        fetchUserLands();
      });
    }
  }

  useEffect(() => {
    // used for update each second
  }, [currentDate]);

  return (
    <Popup
      title="Mint Zombies"
      width="lg:w-[920px]"
      popupVisible={mintPopupVisible}
      setPopupVisible={setMintPopupVisible}
    >
      <div className="sm:gap-4 hidden sm:flex opacity-50 mb-3 pb-3 border-b border-white/20">
        <div className="basis-1/3 text-left pl-14">
          #Land
        </div>
        <div className="basis-1/4">
          Zombies / day
        </div>
        <div className={"w-16 pl-4"}>
          Minted
        </div>
        <div className="grow text-right"></div>
      </div>

      <div className="mt-2 text-left">
        {userLands.map((land) => (
          <div className="flex sm:gap-4 mb-3" key={land.token_id}>
            <div className="hidden sm:flex">
              <img src={getMedia(land.media)} alt="land" width="40" />
            </div>
            <div className="basis-1/3 sm:pt-4 pt-2 font-semibold">
              {formatLandId(land.land_type, land.token_id)}
            </div>
            <div className="basis-1/4 pt-2 hidden sm:flex">
              <div>
                {land.count_minted_zombies >= mintZombiesLimit[land.land_type] ? (
                  <>
                    <p className="block opacity-70 text-red-300">{availabilityMap[land.land_type]}</p>
                    <small className="block mt-0.5 text-red-300">Resource Depleted</small>
                  </>
                ) : (
                  <p className={"pt-2"}>{availabilityMap[land.land_type]}</p>
                )}
              </div>
            </div>
            <div className={"pt-4 w-20 whitespace-nowrap"}>
              {land.count_minted_zombies} / {mintZombiesLimit[land.land_type]}
            </div>
            <div className="grow text-right">
              {canClaim(land) ? (
                <div className="pt-1 w-56">
                  <Button
                    title="Mint Zombies"
                    size="sm"
                    secondary
                    onClick={() => handleMint(land.token_id, land.land_type)}
                  />
                </div>
              ) : (
                <div className="pt-1 w-56">
                  {timeDiffSeconds(land.last_zombie_claim, land.land_type === "Giant" ? 12 : 24) < 86400 && (
                    <p className="text-red-300 text-right pl-7 text-base pt-2 leading-4 font-[Exo] w-56 relative">
                      {
                        land.count_minted_zombies >= mintZombiesLimit[land.land_type] ? (
                          <>
                            {land.land_type !== "Micro" && (
                              <>
                                <Button
                                  title="Reset Resources"
                                  size="sm"
                                  secondary
                                  className={"!text-red-300 !border-red-400 hover:!text-red-400 hover:!border-red-500"}
                                  onClick={() => handleResetLand(land.token_id, land.land_type)}
                                />
                                <span className={"absolute right-0 top-10 bg-red-400 text-sm px-1 text-white rounded-sm"}>
                                  <small>{landResetPriceZML[land.land_type]} ZML</small>
                                </span>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <small>Next mint:</small>
                            <br />
                            <small>
                              {secondsToString(timeDiffSeconds(land.last_zombie_claim, land.land_type === "Giant" ? 12 : 24))}
                            </small>
                          </>
                        )
                      }
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}

        {userLands.filter(land => land.can_claim).length > 1 && (
          <div className="justify-end flex mt-2 border-white/10 border-t-2 pt-4">
            <Button
              title="Mint All Zombies"
              size="sm"
              onClick={() => handleMintAll()}
            />
          </div>
        )}

      </div>
    </Popup>
  );
}

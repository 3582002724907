import React, { useContext, useState } from "react";
import { Button, Input, TextArea, Popup } from "../../ui";
import { NearContext } from "../../contexts/NearWallet";

export const EditClanPopup = ({clan, setVisible, visible, handleSuccessUpdate}) => {
  const {mainContract} = useContext(NearContext);
  const [name, setName] = useState(clan.name);
  const [about, setAbout] = useState(clan.about);
  const [media, setMedia] = useState(clan.media);
  const [entryFee, setEntryFee] = useState(clan.entry_fee);
  const [locked, setLocked] = useState(clan.locked);

  const params = {
    clan_id: clan.id,
    name: name,
    about: about || "",
    media: media || "",
    entry_fee: entryFee.toString(),
    locked: locked,
  };

  const handleClanSave = () => {
    mainContract.modifyClanInfo(params).then(() => {
      handleSuccessUpdate();
      setVisible(false);
    });
  }

  return (
    <Popup
      title="Edit Clan"
      popupVisible={visible}
      setPopupVisible={setVisible}
    >
      <div className="gap-5 px-6 mb-5 flex flex-col">
        <Input
          placeholder="Clan Name"
          handleChange={(e) => setName(e.target.value)}
          value={name}
        />
        <TextArea
          placeholder="Info about your clan and rules"
          handleChange={(e) => setAbout(e.target.value)}
          value={about}
        />
        <Input
          placeholder="Image URL"
          handleChange={(e) => setMedia(e.target.value)}
          value={media}
        />
        <Input
          type="number"
          placeholder="Entry price (ZML)"
          handleChange={(e) =>
            setEntryFee(e.target.value < 0 ? 0 : e.target.value)
          }
          value={entryFee}
        />
        <Input
          type="checkbox"
          label="Lock clan entrance"
          checked={locked}
          handleChange={(e) => setLocked(e.target.checked)}
          value={locked}
        />
        {/* <Input type="file" onChange={(e) => setMedia(e.target.files[0])} /> */}
      </div>
      <Button
        title="Update"
        disabled={!name}
        onClick={() => handleClanSave()}
      />
    </Popup>
  );
};

import React, { useContext, useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { BattleNotifications } from "./components";
import { NearContext } from "./contexts/NearWallet";
import {
  Collections,
  Faq,
  Landing,
  Lands,
  Market,
  Monsters,
  OneCollection,
  Zombies,
  Terms,
  Privacy,
  Token,
  Leaderboard,
  Inventory,
  Modifiers,
  Clan,
  Clans,
  Stats,
  MonsterParts,
  Roulette,
  ArenaBoard,
  ArenaBattlesList,
  InventoryStore,
  ZombieKiller,
  MonsterKiller,
} from "./pages";
import {
  updateUserBalance,
  dispatchUserRating,
  updateUserZmlReserve,
} from "./services/UserService";
import { useDispatch } from "react-redux";
import { BoxOpen } from "./pages/BoxOpen";
import { EventNotifications } from "./components/EventNotifications";
import { InviteJoin } from "./pages/InviteJoin";
import { Page404 } from "./pages/Page404";

const ZOMBIE_EVENT_STORAGE_KEY = "zomland:event_zombie_killer_2024";
const CLAN_EVENT_STORAGE_KEY = "zomland:event_clan_battles_2024";

export default function App() {
  const {isSigned, currentUser, ftContract} = useContext(NearContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      updateUserBalance(dispatch, ftContract, currentUser);
      updateUserZmlReserve(dispatch, ftContract, currentUser);
      dispatchUserRating(dispatch, currentUser);
    }
  }, [currentUser]);

  const ProtectedRoute = () =>
    !isSigned && !currentUser ? <Navigate to="/" replace /> : <Outlet />;

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Landing />} />

        <Route element={<ProtectedRoute />}>
          <Route exact path="/lands" element={<Lands />} />
          <Route exact path="/zombies" element={<Zombies />} />
          <Route exact path="/collections" element={<Collections />} />
          <Route
            exact
            path="/collections/:collection_id"
            element={<OneCollection />}
          />
          <Route exact path="/monsters" element={<Monsters />} />
          <Route exact path="/monster_parts" element={<MonsterParts />} />
          <Route exact path="/inventory" element={<Inventory />} />
          <Route exact path="/modifiers" element={<Modifiers />} />
          <Route exact path="/clans" element={<Clans />} />
          <Route exact path="/clans/:id" element={<Clan />} />
          <Route exact path="/roulette" element={<Roulette />} />
          <Route exact path="/market" element={<Market />} />
          <Route exact path="/market/:section" element={<Market />} />
          <Route exact path="/store" element={<InventoryStore />} />
          <Route exact path="/store/:section" element={<InventoryStore />} />
          <Route exact path="/token" element={<Token />} />
          <Route exact path="/leaderboard" element={<Leaderboard />} />
          <Route exact path="/zombie-killer" element={<ZombieKiller />} />
          <Route exact path="/monster-killer" element={<MonsterKiller />} />
          <Route exact path="/stats" element={<Stats />} />

          <Route exact path="/arena" element={<ArenaBattlesList />} />
          <Route exact path="/arena/battle/:id" element={<ArenaBoard />} />

          <Route exact path="/box-open" element={<BoxOpen />} />
        </Route>

        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/terms-conditions" element={<Terms />} />
        <Route exact path="/privacy-policy" element={<Privacy />} />
        <Route exact path="/invite/:param" element={<InviteJoin />} />
        <Route exact path="*" element={<Page404 />} />
      </Routes>

      <div className={`bottom-4 right-4 fixed px-2 md:pb-2 py-2 z-40`}>
        <BattleNotifications />
        {/*<EventNotifications*/}
        {/*  keyStore={ZOMBIE_EVENT_STORAGE_KEY}*/}
        {/*  endDate="2024-02-19"*/}
        {/*  url="/zombie-killer"*/}
        {/*  title="Zombie Killer"*/}
        {/*  reward={500}*/}
        {/*  description="Kill zombies to earn $500 rewards &raquo;"*/}
        {/*/>*/}
        <EventNotifications
          keyStore={CLAN_EVENT_STORAGE_KEY}
          endDate="2024-02-14"
          url="/clans"
          title="Clan Battles"
          reward={500}
          description="Join to Ying or Yang clan, fight and earn $500 rewards &raquo;"
        />
      </div>
    </BrowserRouter>
  );
}
